import React from 'react';
import {Mutation} from 'react-apollo';

import deleteNoticeMutation from '../../../gql/delete-notice';
import DeleteNoticePrompt from "./DeleteNoticePrompt";

export default (props) => {
  return (
    <Mutation mutation={deleteNoticeMutation} onCompleted={props.closeModal}>
      {(callDeleteNotice, {data, loading, called, error}) => {
        if (error) {
          console.error('Error while DeleteEvent mutation', error);
        }

        const formSubmitted = ({id}) => {
          callDeleteNotice({
            variables: {
              id: id,
            }
          });
        };

        return (
          <DeleteNoticePrompt
            {...props}
            loading={loading}
            error={!!error}
            onConfirm={formSubmitted}/>
        )
      }}
    </Mutation>
  );
}
