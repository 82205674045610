import styled from 'styled-components';

export const AuthorWrapper = styled.span`
  color: #999;
  text-transform: uppercase;
`;

export const Role = styled.span``;

export const Name = styled.span`
  font-family: ${props => props.theme.fontImportant}; /* Must use  !important, otherwise <Segment> will font style */;
  font-size: ${props => props.theme.fontSizeSmall}; /* Must use  !important, otherwise <Segment> will font style */;
  padding: 0 ${props => props.theme.space1};
`;
