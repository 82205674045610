import React from 'react';

import FormDropdown from '../template/FormDropdown';
import {withTerminals} from "../../hocs/withTerminals";

const TerminalDropdown = ({disabled, terminals, existingTerminals, onChange, showMessage}) => {

  const options = getOptionsFromTerminals(terminals, existingTerminals);

  const placeholder = options.length > 0 ?
    'Example: SYD or Sydney' :
    'There are no terminals available to add';

  return (
    <FormDropdown
      placeholder={placeholder}
      disabled={options.length === 0 || disabled}
      search
      selection
      fluid
      options={options}
      onChange={onChange}
      showMessage={showMessage}
    />
  );
};

function getOptionsFromTerminals(terminals, excludingTerminals) {
    if (!terminals) { return []; }

    if (excludingTerminals) {
      terminals = terminals.filter(t => excludingTerminals.indexOf(t.code) === -1);
    }

    return terminals.map(t => ({
      key: t.id,
      value: t.code,
      text: `${t.code} - ${t.city}, ${t.country}`
    }));
}

export default withTerminals(TerminalDropdown);
