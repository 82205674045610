import React from 'react';
import {LinkButtonWrapper} from "./style";
import loadingSrc from "../../../../images/loading.gif"
import {withTheme} from "styled-components";
import PropTypes from "prop-types";

const LinkButton = ({children, loading, theme, ...props}) => {
  return (
    <LinkButtonWrapper {...props} theme={theme} loading={loading}>
      {
        !loading && children
      }
      {
        loading &&
        <img width={theme.size5} src={loadingSrc} alt="Loading"/>
      }
    </LinkButtonWrapper>
  )
};

LinkButton.defaultProps = {
  loading: false,
};
LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

export default withTheme(LinkButton);
