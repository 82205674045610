import styled, {css} from "styled-components";
import {CardWrapper} from "../../../../common/molecules/Card/style";
import {FLIGHT_DETAIL} from "../../../../../constants/Layer";

const FLIGHT_ICON_OFFSET = 80;

export const PacMan = styled.div`
  position: absolute;
  left: ${FLIGHT_ICON_OFFSET - 2}px;
  transition: top 4s ease 0.5s;
  
  z-index: ${FLIGHT_DETAIL.FLIGHT_ICON};
 
  ${props => !props.y && css`
    top: 0;
  `}
  
  ${props => props.y && css`
    top: ${props => `${props.y}px`};
  `}
`;
export const FlightIcon = styled(CardWrapper)`
  position: absolute;
  width: ${props => props.theme.size7};
  height: ${props => props.theme.size7};
  left: ${FLIGHT_ICON_OFFSET}px;
  padding: ${props => props.theme.space1};
  transition: top 2s ease 0.5s;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid ${props => props.theme.colors.brandSecondary};
  border-radius: 8px;
  
  z-index: ${FLIGHT_DETAIL.FLIGHT_ICON};
 
  ${props => !props.y && css`
    top: 0;
  `}
  
  ${props => props.y && css`
    top: ${props => `${props.y}px`};
  `}
`;

export const EmptyList = styled.div`
  color: grey;
  text-align: center;
`;
