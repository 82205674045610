import React from 'react';
import {Filler, ProgressBarBackground, ProgressBarWrapper} from "./style";
import PropTypes from "prop-types";

const ProgressBar = ({percentage, label}) =>
  <ProgressBarWrapper>
    <ProgressBarBackground>
      <Filler percentage={percentage}/>
    </ProgressBarBackground>
    {
      label &&
      <span>{percentage}%</span>
    }
  </ProgressBarWrapper>;

function withinOneHundred(props, propName, componentName) {
  if (props[propName]) {
    let value = props[propName];
    if (typeof value === 'number') {
      return (value >= 0 && value <= 100) ? null : new Error(propName + ' in ProgressBar is not within 0 to 100');
    }
  }
  // assume all ok
  return null;
}

ProgressBar.defaultProps = {
  label: false
};

ProgressBar.propTypes = {
  label: PropTypes.bool,
  percentage: withinOneHundred,
};

export default ProgressBar;
