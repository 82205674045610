import styled, {css} from 'styled-components';

export const EngineeringStatusWrapper = styled.div`
  display: inline-block;
  border-radius: ${props => props.theme.cornerRadius};
  padding: ${props => props.theme.spaceTiny};
  overflow: hidden;
  background: ${props => props.theme.colors.lightGrey};
`;

export const EngineeringStatusItem = styled.span`
  font-family: ${props => props.theme.fontImportant};
  font-weight: normal;
  line-height: 1.4285;
  color: white;
  text-transform: uppercase;
  padding: ${props => props.theme.space2};
  
  ${props => props.risk && css`
    background: ${props => props.theme.colors.error};
  `};
  
  ${props => props.aog && css`
    background: ${props => props.theme.colors.oldBrandPrimary};
  `};
  
  ${props => props.grn && css`
    background: ${props => props.theme.colors.success};
  `};
`;
