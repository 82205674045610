import React from "react";
import {Query} from "react-apollo";

import terminalsQuery from "../gql/get-terminals";
import {NETWORK_STATUS} from "../constants/Apollo";
import ErrorPage from "../components/common/ErrorPage";
import LoadingPage from "../components/common/molecules/LoadingPage";

export function withTerminals(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <Query query={terminalsQuery} fetchPolicy="cache-first" errorPolicy="all">
          {({networkStatus, error, data = {}}) => {
          if (error) {
            console.error('withTerminals', error);
            return (
                <ErrorPage
                  title="Error loading terminals"
                  message="There was a problem loading the list of terminals"/>
            );
          }

          // Don't show loading on polls/fetchMore's
          const loading = networkStatus <= NETWORK_STATUS.SET_VARIABLES;
          if (loading) {
            return <LoadingPage/>;
          }

          return (
            <WrappedComponent
                terminals={data.getAirports}
                {...this.props}
            />
          );
        }}
        </Query>
      );
    }
  }
}
