export const FLIGHT_DETAIL = {
  FLIGHT_ICON: 300,
  PTS_DOT: 200,
};

/* System Wide */

export const TERMINAL = {
  MENU: 1000,
};

export const POPOUT = {
  CONTENT: 1500,
};

export const MODAL = {
  BACKGROUND: 2000,
  CONTENT: 2100,
};

export const SNACKBAR = {
  CONTAINER: 3000,
};

export const NETWORK_MAP = {
  MENU: 100,
};
