import gql from 'graphql-tag';

export default gql`
  query GetFlightBoardingSummary($id: String!) {
  flight(id: $id) {
    id
    boarding_summary {
      boardedCount
      checkedInCount
      bookedCount
    }
  }
}
`;
