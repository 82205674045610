import styled, {css} from 'styled-components';

const Label = styled.span`
  padding: ${props => props.theme.sizeSmall} ${props => props.theme.size1};
  
  background: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.sizeSmall};
  
  font-family: ${props => props.theme.fontRegular};
  font-size: ${props => props.theme.fontSizeExtraSmall};
  text-transform: uppercase;
  
  ${props => props.primary && css`
    background: ${props => props.theme.colors.oldBrandPrimary};
    color: white;
  `};
`;

export default Label;
