import gql from 'graphql-tag';

export default gql`
  mutation AddNotice($notice_channel_id: Int!, $content: String!, $type: NoticeType!, $assigned_role_ids: [Int!], $is_completeable: Boolean!) {
    addNotice(input: {
      notice_channel_id: $notice_channel_id
      type: $type
      content: $content
      assigned_role_ids: $assigned_role_ids
      is_completeable: $is_completeable
    })
  }`;
