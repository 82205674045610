import React, {Component} from 'react';

import Button from "../../../../common/atoms/Button";
import Divider from "../../../../common/molecules/Divider";
import Pagination from "./Pagination"
import {ReactComponent as IconInfo} from "../../../../../images/icons/information.svg";
import {ReactComponent as IconMore} from "../../../../../images/icons/more.svg";
import {
  ActionSection,
  EventSection,
  MenuButton,
  TaskAssigned,
  TaskDetailWrapper,
  TaskSectionWrapper,
  TaskTitle
} from "./style";
import {withLocalUserData} from "../../../../../hocs/withLocalUserData";
import {withNoticeChannel} from "../../../../../hocs/withNoticeChannel";
import Content from "../../../../notice/common/Content";
import {Mutation} from "@apollo/react-components";
import completeEventGql from "../../../../../gql/complete-notice";
import completePtsStepGql from "../../../../../gql/complete-pts-step";
import {withTheme} from "styled-components";
import Popout from "../../../../common/templates/Popout";
import {getFlightNumber} from "../../../../../utils/flight-utils";
import {ReactComponent as IconAlert} from "../../../../../images/alert-red.svg";
import Icon from "../../../../common/atoms/Icon";
import {TYPE} from "../../../../../constants/Notice";
import moment from "moment";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import DeleteNoticeModal from "./DeleteNoticeModal";
import ResolveEscalationModal from "./ResolveEscalationModal";
import DepartureReportModal from "./DepartureReportModal";
import {ACCESS_LEVEL, PERMISSION} from '../../../../../constants/Permission';
import {hasPermission} from '../../../../../utils/permission';
import {ReactComponent as IconTick} from "../../../../../images/icons/tick.svg";
import {MobileLargeOnly, Mobile, size} from "../../../../../constants/Device";
import {ReactComponent as IconSkip} from "../../../../../images/icons/skip.svg";
import {withRoles} from '../../../../../hocs/withRoles';

class TaskSection extends Component {

  state = {
    selectedTaskId: undefined,
    tasks: [],
  };

  static getDerivedStateFromProps(props, state) {
    const {
      flight,
      noticeChannel: {channel},
      user,
    } = props;

    const tasks = [];

    // Make event tasks
    channel.messages.nodes
      .filter(isOngingEvent)
      .sort((a, b) => (a.messageType === b.messageType) ? 0 : ((a.messageType === TYPE.ESCALATION) ? -1 : 1))
      .forEach(t => tasks.push(t));

    // A PTS is available to do if it's previous steps have been completed and has not itself been completed
    const ptsStepsToDo = flight.pts.filter(step => {
      const stepEvent = channel.messages.nodes.find(e => e.completes_pts_workflow_status === step.code);
      if (stepEvent) {
        // Don't include even if the PTS event was undone - the undone event itself should be completed in that case
        return false;
      }

      if (!step.previous_steps) {
        return true;
      }

      // False if there is a required step that does not have an event associated with it
      return !step.previous_steps.find(s => !channel.messages.nodes.find(e => e.completes_pts_workflow_status === s));
    }).sort((a,b) => a.timing - b.timing);

    if (hasPermission(user, PERMISSION.ptsAboveWing, ACCESS_LEVEL.BASIC)) {
      const canComplete = hasPermission(user, PERMISSION.ptsAboveWing, ACCESS_LEVEL.SENSITIVE);
      const ptsTasks = ptsStepsToDo.map(p => {
        const previousPTSs = channel.messages.nodes.filter(node => p.previous_steps ? p.previous_steps.includes(node.completes_pts_workflow_status) : false);
        const startTimes = previousPTSs.map(ptsNotice => moment(ptsNotice.completed_at));
        const startTime = startTimes.length > 1 ?
          startTimes[0].isBefore(startTimes[1]) ?
            startTimes[1] :
            startTimes[0] :
          startTimes[0];

        const dueTime = p.timing ? moment(flight.offblocks_latest_estimate).add(p.timing, "minutes") : undefined;
        return {
          id: p.code,
          messageType: TYPE.PTS,
          completes_pts_workflow_status: p.code,
          content: p.description,
          actionText: p.action_text,
          is_completeable: true,
          startTime: startTime,
          dueTime: dueTime,
          canComplete,
        }
      });

      tasks.push(...ptsTasks);
    }

    if (hasPermission(user, PERMISSION.departureReport, ACCESS_LEVEL.BASIC) && !flight.departure_report_submitted) {
      tasks.push({
        id: 'DEPARTURE_REPORT',
        messageType: TYPE.DEPARTURE_REPORT,
        content: "Departure Report",
        is_completeable: true,
        canComplete: hasPermission(user, PERMISSION.departureReport, ACCESS_LEVEL.SENSITIVE),
      });
    }

    const selectedTaskId = tasks.find(e => e.id === state.selectedTaskId) ? state.selectedTaskId :
      tasks.length > 0 ? tasks[0].id :
      undefined;

    return {
      ...state,
      selectedTaskId,
      tasks
    };
  }

  render = () => {
    const {
      flight,
      user,
      offset,
    } = this.props;

    const { tasks } = this.state;

    if (tasks.length === 0) {
      return <></>;
    }

    const selectedEvent = tasks.find(e => e.id === this.state.selectedTaskId);

    const isActionable =
      selectedEvent.messageType === TYPE.PTS || selectedEvent.messageType === TYPE.DEPARTURE_REPORT ?
        selectedEvent.canComplete :
        user && (!selectedEvent.assigned_role_ids || selectedEvent.assigned_role_ids.includes(user.role.id));

    const isDeletable = this.canDelete(selectedEvent, user);

    const taskDuration = Math.max(moment.duration(selectedEvent.dueTime?.diff(selectedEvent.startTime)).asSeconds(), 0);
    const remainingTime = Math.max(moment.duration(selectedEvent.dueTime?.diff(moment())).asSeconds(), 0);
    const progress = remainingTime / taskDuration;

    const shouldShowAsDelayed = this.shouldShowAsDelayed(selectedEvent, remainingTime, progress);
    return (
      <TaskSectionWrapper
        type={selectedEvent.messageType}
        delayed={shouldShowAsDelayed}>
        <EventSection>
          {
            selectedEvent.messageType === TYPE.PTS &&
            selectedEvent.startTime !== undefined &&
            selectedEvent.dueTime !== undefined &&
            this.renderCountDown(selectedEvent, taskDuration, remainingTime)
          }
          {
            selectedEvent.messageType === TYPE.ESCALATION &&
            <Icon>
              <IconAlert/>
            </Icon>
          }
          {
            this.renderTaskDetail(selectedEvent)
          }
          {
            (isActionable || isDeletable) &&
            this.renderActionSection(selectedEvent, isActionable, isDeletable, offset, getFlightNumber(flight))
          }
        </EventSection>
        <Divider/>
        {
          tasks.length > 0 &&
          <Pagination
            events={tasks}
            selectedEventId={this.state.selectedTaskId}
            onEventSelected={this.onEventSelected}/>
        }
        <Divider/>
      </TaskSectionWrapper>
    );
  };

  onEventSelected = (eventId) => {
    this.setState({selectedTaskId: eventId});
  };

  renderCountDown = (task, taskDuration, remainingTime) => {

    const {
      theme: {
        colors
      },
    } = this.props;

    return (
      <CountdownCircleTimer
        key={task.id}
        durationSeconds={taskDuration}
        initialRemainingTime={remainingTime}
        size={32}
        strokeWidth={4}
        colors={[
          [colors.brandSecondary, .75],
          [colors.error, .5],
          [colors.brandDarkSecondary, .25],
        ]}
        isPlaying
      />
    )
  };

  renderTaskDetail = (event) => {
    const assignedRoles =
      event.assigned_role_ids ?
        event.assigned_role_ids
          .map(id => this.props.roles.find(r => r.id === id).display_name) :
        null;

    return (
      <TaskDetailWrapper>
        <TaskTitle>
          <Content content={event.content}/>
        </TaskTitle>

        {
          (event.messageType === TYPE.EVENT || event.messageType === TYPE.ESCALATION) &&
        <Popout
          toggleIcon={<IconInfo/>}
          heading='ASSIGNED TO'>
          <TaskAssigned>
            {assignedRoles ? assignedRoles.join(', ') : 'All Roles'}
          </TaskAssigned>
        </Popout>
        }
      </TaskDetailWrapper>
    )
  };

  renderActionSection = (event, isActionable, isDeletable, offset, flightNumber) => {
    return (
      <ActionSection>
        {
          isActionable &&
          this.renderActionButtons(event, offset, flightNumber)
        }
        {
          isDeletable &&
          <MenuButton transparent>
            <Popout
              toggleIcon={<IconMore/>}
              align={"right"}
              mode={"cover"}>
              <DeleteNoticeModal
                notice={event}
                offset={offset}
                trigger={
                  <Button transparent>
                    Delete
                  </Button>
                }/>
            </Popout>
          </MenuButton>
        }
      </ActionSection>
    )
  };

  canDelete = (event, user) => {
    if (event.messageType === TYPE.PTS || event.messageType === TYPE.DEPARTURE_REPORT) {
      return false;
    }

    if (hasPermission(user, PERMISSION.scheduleChat, ACCESS_LEVEL.ADMIN)) {
      return true;
    }

    return event.created_by && event.created_by.id === user.id;
  };

  renderActionButtons = (event, offset) => {
    if (event.messageType === TYPE.ESCALATION) {
      return <ResolveEscalationModal notice={event}
                                     offset={offset}
                                     trigger={<Button basic secondary>Resolve</Button>}/>
    } else if (event.messageType === TYPE.DEPARTURE_REPORT) {
      return this.renderDepartureReport();
    } else if (event.messageType === TYPE.PTS) {
      return this.renderPtsButtons(event);
    } else {
      return this.renderEventButtons(event);
    }
  };

  renderDepartureReport = () => {
    const {
      flight,
      noticeChannel,
    } = this.props;
    return (
      <DepartureReportModal
        flight={flight}
        scheduleChannel={noticeChannel.channel}
        trigger={
          <Button basic secondary>
            Open
          </Button>
        }/>
    );
  };

  renderPtsButtons = (task) => {
    const mutateParams = {
      flight_id: this.props.flight.id,
      step_code: task.completes_pts_workflow_status,
    };

    return (
      <Mutation mutation={completePtsStepGql}>
        {(completePtsStepMutate, {loading: completeLoading}) =>
          <Mutation mutation={completePtsStepGql}>
            {(completePtsStepMutateSkip, {loading: skipLoading}) =>
              <>
                <Mobile>
                  <Button
                    basic
                    outlined={window.innerWidth <= size.laptop}
                    transparent
                    disabled={skipLoading || completeLoading}
                    onClick={() => completePtsStepMutateSkip({ variables: { input: { ...mutateParams, skip: true } } })}>
                      Skip
                  </Button>
                </Mobile>
                <MobileLargeOnly>
                  <Button
                    outlined
                    basic
                    transparent
                    disabled={skipLoading || completeLoading}
                    onClick={() => completePtsStepMutateSkip({ variables: { input: { ...mutateParams, skip: true } } })}>
                      <IconSkip/>
                  </Button>
                </MobileLargeOnly> 

                <Button
                  basic
                  secondary
                  disabled={skipLoading || completeLoading}
                  onClick={() => completePtsStepMutate({ variables: { input: { ...mutateParams, skip: false } } })}
                >
                  <MobileLargeOnly>
                    <IconTick/>
                  </MobileLargeOnly>
                  <Mobile>
                    {task.actionText || 'Complete'}
                  </Mobile>
                </Button>
              </>
            }
          </Mutation>
        }
      </Mutation>
    );
  };

  renderEventButtons = (event) => {
    return (
      <Mutation mutation={completeEventGql}>
        {(completeEventMutate, {loading: completeLoading}) =>
          <Button
            basic
            primary
            loading={completeLoading}
            onClick={() => completeEventMutate({variables: {id: event.id}})}
          >
            Complete
          </Button>
        }
      </Mutation>
    );
  };

  shouldShowAsDelayed = (event, remainingTime, progress) => {
    // If event is PTS && (Event is less than 1 min || event is more than half way through)
    if (event.messageType !== TYPE.PTS) {
      return false
    } else {
      return remainingTime < 60 || progress < 0.5;
    }

  }
}

const isOngingEvent = (event) => {
  return event.is_completeable && !event.completed_at && !event.deleted_at;
};

export default withLocalUserData(withNoticeChannel(withRoles(withTheme(TaskSection))));
