import React from 'react';
import FlexSection from "../../atoms/FlexSection";
import {NotificationSectionWrapper} from "./style";

const NotificationSection = React.memo(({icon, head, body}) => {

  return (
    <NotificationSectionWrapper>
      <FlexSection>
        {
          icon &&
          <>
            {icon}
          </>
        }
        {
          head &&
          <h1>
            {head}
          </h1>
        }
      </FlexSection>
      {
        body &&
        <p>
          {body}
        </p>
      }
    </NotificationSectionWrapper>
  )
});

export default NotificationSection;
