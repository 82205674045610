import React, {Component} from 'react';
import moment from 'moment';

import FlightRow from './FlightRow';
import FlightType, {QUALIFIER, STATUS, TYPE} from "../../../constants/Flight";
import SearchList from "./SearchList";
import ListHead from "./ListHead";
import TypeFlightList from "./TypeFlightList";
import PropTypes from "prop-types";
import UserType from "../../../constants/User";
import {COLUMNS, VIEW_TYPE} from "../../../constants/View";
import {withBasePts} from '../../../hocs/withBasePts';

const TERMINAL_DEPARTURE_COLUMNS = [
  COLUMNS.NUMBER,
  COLUMNS.TO,
  COLUMNS.OFB,
  COLUMNS.DEP_STATUS,
  COLUMNS.DEP_GATE,
  // COLUMNS.ENG,
];

const TERMINAL_ARRIVAL_COLUMNS = [
  COLUMNS.NUMBER,
  COLUMNS.FROM,
  COLUMNS.ONB,
  COLUMNS.ARR_STATUS,
  COLUMNS.ARR_GATE,
  // COLUMNS.ENG,
];

const ROSTER_COLUMNS = [
  COLUMNS.NUMBER,
  COLUMNS.REGO,
  COLUMNS.FROM,
  COLUMNS.OFB,
  COLUMNS.DEP_STATUS,
  // COLUMNS.ENG,
];

const initialState = {
  isCompletedExpanded: false
};

class FlightList extends Component {

  minuteChangeInterval = null;

  // This component should used key={terminalId} otherwise most of the logic
  // in here will break (it is assumed this component will be reconstructed
  // on terminal change)
  constructor(props) {
    super(props);

    // If a flight id is provided when this component is created then we should
    // force the type tab to that flights type
    const { flights, activeFlightId } = props;

    const state = { ...initialState };
    if (activeFlightId) {
      const flight = flights.find(f => f.id === activeFlightId);
      if (flight) {
        state.isCompletedExpanded = this.isFinishedFlight(flight);
      }
    }

    state.uniqueMinute = this.getCurrentMinute();

    this.state = state;
  }

  componentDidMount = () => {
    this.minuteChangeInterval = setInterval(() => this.checkMinuteChange(), 1000);

    this.subscription = this.props.doSubscribe();

    // Scroll to captured flight
    if (!this.scrollFlight) { return; }
    this.scrollFlight.scrollIntoView({block: "center"});
    this.scrollFlight = null;
  };

  checkMinuteChange = () => {
    const currentMinute = this.getCurrentMinute();
    if (this.state.uniqueMinute !== currentMinute) {
      this.setState({
        uniqueMinute: currentMinute
      });
    }
  };

  getCurrentMinute = () => {
    return moment().format("hh:mm");
  };

  componentWillUnmount = () => {
    clearInterval(this.minuteChangeInterval);
    this.subscription.unsubscribe();
  };

  render = () => {
    const {
      flights,
      view,
      viewType,
      searchQuery,
    } = this.props;

    const listCols = this.getColumns(view, viewType);

    return (
      <>
        <ListHead columns={listCols}/>
        {
          searchQuery !== "" ?
            <SearchList flights={flights}
                        renderFlightRow={this.renderFlightRow}
                        searchQuery={searchQuery}/> :
            <TypeFlightList flights={flights}
                            viewType={viewType}
                            renderFlightRow={this.renderFlightRow}
                            isCompletedExpanded={this.state.isCompletedExpanded}
                            toggleCompletedExpanded={this.toggleCompletedExpanded}
                            isFinishedFlight={this.isFinishedFlight}/>
        }
      </>
    );
  };

  renderFlightRow = (flight) => {

    const {
      user,
      view,
      viewType,
      terminalOffset,
      terminalPath,
      context,
      activeFlightId,
      pts,
    } = this.props;

    const listCols = this.getColumns(view, viewType);

    return (
      <FlightRow
        columns={listCols}
        key={flight.id}
        flight={flight}
        user={user}
        pts={pts}
        viewType={viewType}
        terminalOffset={terminalOffset}
        terminalPath={terminalPath}
        context={context}
        active={flight.id === activeFlightId}
        ref={el => this.flightRowRef(el, flight)}/>
    );
  };

  getColumns = (view, viewType) => {
    return view.view_type === VIEW_TYPE.TERMINAL ?
      (viewType === TYPE.DEPARTURE ? TERMINAL_DEPARTURE_COLUMNS : TERMINAL_ARRIVAL_COLUMNS) :
      view.view_type === VIEW_TYPE.ROSTER ? ROSTER_COLUMNS :
        [];
  };

  flightRowRef = (el, flight) => {

    const {
      activeFlightId,
    } = this.props;

    if (this.scrollFlight) {
      return;
    }

    this.captureActiveFlightRow(el, activeFlightId, flight.id);
  };

  captureActiveFlightRow = (el, activeFlightId, flightId) => {
    if (flightId === activeFlightId) {
      this.scrollFlight = el;
    }
  };

  isFinishedFlight = (flight) => {

    const {
      viewType,
    } = this.props;

    const isCancelled = flight.flight_status === STATUS.CANCELLED;

    let scheduled;
    if (viewType === TYPE.DEPARTURE) {
      if (flight.offblocks_qualifier !== QUALIFIER.ACTUAL && !isCancelled) return false;
      scheduled = flight.departure_scheduled_time;
    } else { // ARRIVAL
      if (flight.onblocks_qualifier !== QUALIFIER.ACTUAL && !isCancelled) return false;
      scheduled = flight.arrival_scheduled_time;
    }
    
    return moment().isAfter(scheduled);
  };

  toggleCompletedExpanded = () => {
    this.setState({isCompletedExpanded: !this.state.isCompletedExpanded});
  };
}

FlightList.propTypes = {
  flights: PropTypes.arrayOf(FlightType),
  searchQuery: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.string),
  user: UserType.isRequired,
  terminalOffset: PropTypes.number,
  terminalPath: PropTypes.string.isRequired,
  activeFlightId: PropTypes.string,
  viewType: PropTypes.oneOf([TYPE.ARRIVAL, TYPE.DEPARTURE]),
};

export default withBasePts(FlightList);
