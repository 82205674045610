import styled from 'styled-components';
import FlexSection from "../../common/atoms/FlexSection";
import device from "../../../constants/Device";

export const FlightListWrapper = styled.div`
  padding: 0 ${props => props.theme.space2};
  overflow-y: auto;
  overflow-x: visible;
  font-family: ${props => props.theme.fontMedium};
  font-size: ${props => props.theme.fontSizeExtraLarge};
  line-height: ${props => props.theme.fontSizeExtraLarge};
  @media ${device.tablet} {
    font-size: ${props => props.theme.fontSizeLarge};
    line-height: ${props => props.theme.fontSizeLarge};
  }
  
  ::-webkit-scrollbar {
    width: 0;
  }
`;

export const IconColumn = styled.div`
  width: 40px; /* Icon 32px + Padding 4px */
`;

export const FlightIDColumn = styled(FlexSection)`

  /* Mobile Responsive */
  flex-direction: column;
  @media ${device.laptop} {
    flex-direction: row;
  }
`;

export const Number = styled.div`
  padding: 0 ${props => props.theme.space1};
  
  /* Mobile Responsive */
  width: 78px; /* width: 70px + padding 4px x 2 */
  @media ${device.tablet} {
    width: 68px; /* width: 60px + padding 4px x 2 */
  }
`;

export const Rego = styled.div`
  padding: 0 ${props => props.theme.space1};
  
  /* Mobile Responsive */
  width: 48px; /* width: 40px + padding 4px x 2 */
  @media ${device.tablet} {
    width: 68px; /* width: 60px + padding 4px x 2 */
  }
`;

export const DetailColumn = styled(FlexSection)`
  flex-grow: 1;
  flex-wrap: wrap;
`;

export const Port = styled.div`
  width: 50px;
  @media ${device.tablet} {
    width: 40px;
  }
  padding: 0 ${props => props.theme.space1};
`;

export const Time = styled.div`
  padding: 0 ${props => props.theme.space1};
  @media ${device.laptop} {
    width: 118px;
  }
`;

export const GateHeader = styled.div`
  width: 40px;
  padding: 0 ${props => props.theme.space1};
`;

export const GateColumn = styled(GateHeader)`
  font-family: ${props => props.theme.fontImportant};
  @media ${device.laptop} {
    font-family: ${props => props.theme.fontRegular};
  }
`

export const Status = styled(FlexSection)`
  flex-grow: 1;
  padding: 0 ${props => props.theme.space1};
  
  /* Mobile Responsive */
  @media ${device.mobile} {
    flex: 0 0 100%;
    order: 2;
  }
  @media ${device.tablet} {
    flex: 0 0 100%;
    order: 2;
  }
  @media ${device.laptop} {
    flex: 5;
    order: 0;
  }
  @media ${device.desktop} {
    flex: 5;
    order: 0;
  }
`;

export const Load = styled(FlexSection)`
  width: 72px; /* width: 64px + padding 4px x 2 */
  padding: 0 ${props => props.theme.space1};
  
  flex: 2;
`;

export const Engineering = styled.div`
  width: 88px;
  padding: 0 ${props => props.theme.space1};
`;

export const Unreadcolumn = styled(FlexSection)`
  width: 32px; /* width: 24px + padding 4px x 2 */
`;
