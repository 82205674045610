import { TYPE } from "../constants/Notice";
import moment from "moment";
import { firstBy } from "thenby";

// While generally, the rule set for messages are the same, it is possible that, due to business-case or permission, we will have a more granular control of how message should be greyed out. This function handles those special cases.
export const shouldMessageFade = (message, lastEventId) => {
  switch (message.messageType) {
    case TYPE.CHAT: // For Chat, never grey
      return false;
    case TYPE.EVENT: // For Event and Escalation, if it's deleted or undone, or not last message, then grey
    case TYPE.ESCALATION:
      return (message.deleted_at || message.id !== lastEventId); // Undone is the same as not completing. Ignored case.
    case TYPE.INFO: // For Info, always grey
      return true;
    case TYPE.PTS: // For PTS, if it's Completed, Skipped, Undo, then grey
      return (message.completed_by || message.completed_skipped); // Undone is the same as not completing. Ignored case.
    default:
      return false;
  }
};

export const shouldMessageShowAuthor = (notice, previousOwner) => {
  if (notice.deleted_at) {
    // If notice is deleted, never show title.
    return false;
  }

  switch (notice.messageType) {
    case TYPE.CHAT:
    case TYPE.EVENT:
    case TYPE.ESCALATION:
      return notice.created_by && notice.created_by.id !== previousOwner;
    case TYPE.INFO:
    case TYPE.PTS:
      return false;
    default:
      return false;
  }
};

export const sortNotice = (notice1, notice2) => {
  // Sort ByBothCreatedAndCompleted
  // Follow completed time if it exists
  const date1 = notice1.completed_at || notice1.created_at;
  const date2 = notice2.completed_at || notice2.created_at;

  return new Date(date1) - new Date(date2) || notice1.id - notice2.id;
};

export const getProcessedNodes = (flight, notices, messageTypeWhitelist = []) => {
  if (!flight.pts || !flight.offblocks_latest_estimate) {
    return [];
  }

  return notices
    .filter(node => (messageTypeWhitelist.length ? messageTypeWhitelist.includes(node.messageType) : true) && (( node.is_completeable && node.completed_at) || !node.is_completeable))
    .map(node => {
      if (node.messageType !== TYPE.PTS) {
        return node;
      } else {
        const thePTS = flight.pts.find(pts => pts.code === node.completes_pts_workflow_status);
        const dueTime = thePTS ? moment(flight.offblocks_latest_estimate).add(thePTS.timing, "minutes") : null;

        return {
          ...node,
          wasLate: dueTime ? moment(node.completed_at).isAfter(
            dueTime
          ) : null
        }
      }
    });
};

export const getRemainingPtsSteps = (flight, notices) => {
  if (!flight.pts || !flight.offblocks_latest_estimate) {
    return [];
  }

  const remainingPTS = flight.pts.filter(step => !notices.find(n => n.completes_pts_workflow_status === step.code));
  const processedPTS = remainingPTS.map(pts => {
    const dueTime = pts.timing ? moment(flight.offblocks_latest_estimate).add(pts.timing, "minutes") : null;
    return {
      ...pts,
      dueTime: dueTime,
    }
  });
  processedPTS.sort(firstBy(p => p.timing === null ? -1 : 1).thenBy('timing'));

  return processedPTS;
};
