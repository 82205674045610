import styled, {css} from 'styled-components';
import FlexSection from "../../../../common/atoms/FlexSection";
import Icon from "../../../../common/atoms/Icon";
import device from "../../../../../constants/Device";
import {ContextMenuButton} from "./ContextMenuButton";

export const ContextMenuWrapper = styled(FlexSection)`
  flex-direction: column;
  
  flex: 1;
  height: 100%;
  
  ${props => props.collapsed && css`
    width: 0;
    transition: width 240ms ease;
                
    ${ContextMenuButton}, ${SectionTitle} {
      width: 0;            
      transition: width 240ms ease;
    }
  `};
  
  ${props => !props.collapsed && css`
    width: 48px;
    transition: width 240ms ease;
  `};
`;

export const SectionTitle = styled.div`
  font-size: ${props => props.theme.fontSizeExtraSmall};
  line-height: 1.4;
  color: ${props => props.theme.colors.grey};
  text-transform: uppercase;
  margin-top: ${props => props.theme.size3};
  text-align: center;
`;

export const CollapseButton = styled(FlexSection)`
  height: 100%;
  align-items: flex-end;
  
  ${Icon} {
    position: absolute;
    
    padding-bottom: 106px;
  }
  
  ${props => props.collapsed && css`
  
    ${Icon} {
      right: ${props => props.theme.space2};        
      transition: right 240ms ease;
    }
    
    svg {
      transform: rotate( -180deg );            
      transition: transform 240ms ease;
    }
  `};
  
  ${props => !props.collapsed && css`
  
    ${Icon} {
      right: ${props => props.theme.space8};        
      transition: right 240ms ease;
    }
    
    svg {
      transform: rotate( 0deg );            
      transition: transform 240ms ease;
    }
  `};
  
  /* Mobile Responsive */
  display: flex;
  @media ${device.tablet} {
    display: none;
  }
`;
