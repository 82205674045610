import React from 'react';
import {NameWrapper, RoleWrapper, UserWrapper} from "./style";
import UserType from "../../../../constants/User";

const User = ({user}) =>
  <UserWrapper>
    <NameWrapper>{user.name}</NameWrapper>
    <RoleWrapper>{user.role && user.role.display_name}</RoleWrapper>
  </UserWrapper>;

User.propTypes = {
  user: UserType,
};

export default User;
