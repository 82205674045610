import gql from 'graphql-tag';

import {PermissionFields} from './permission';

export const RoleFields = gql`
  ${PermissionFields}
  
  fragment RoleFields on Role {
    id
    display_name
    permissions {
      ...PermissionFields
    }
  }
`;

export const adminCreateRoleMutation = gql`
  
  mutation adminCreateRole($display_name: String!) {
    adminCreateRole(input: {
      display_name: $display_name
    })
  }
`;

export const getRolesQuery = gql`
  ${RoleFields}
  
  query getRoles {
    getRoles {
      ...RoleFields
    }
  }
`;

export const adminGetRolesQuery = gql`
  ${RoleFields}
  
  query getRoles {
    getRoles {
      users_count
      ...RoleFields
    }
  }
`;

export const adminUpdateRoleMutation = gql`

  mutation adminUpdateRole($id: Int!, $display_name: String!) {
    adminUpdateRole(input: {
      id: $id
      display_name: $display_name
    })
  }
`;

export const adminDeleteRoleMutation = gql`
  mutation adminDeleteRole($id: Int!) {
    adminDeleteRole(id: $id)
  }
`;

export const adminSetRolePermissionsMutation = gql`

  mutation adminSetRolePermissions($input: AdminSetRolePermissionsInput!) {
    adminSetRolePermissions(input: $input)
  }
`;
