import styled from "styled-components";
import FlexSection from "../../atoms/FlexSection";

export const NotificationSectionWrapper = styled(FlexSection)`

  flex-direction: column;
  
  width: 100%;
  
  padding: ${props => props.theme.size10} 0;
  
  h1 {
    margin: 0 0 0 ${props => props.theme.size4};
    font-size: ${props => props.theme.fontRegular};
  }
  
  p {
    font-size: ${props => props.theme.fontSizeLarge};
    color: ${props => props.theme.colors.grey};
    text-align: center;
    max-width: 369px;
  }
`;
