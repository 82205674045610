import styled from "styled-components";

export const FlightBodyWrapper = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
`;

export const MenuColumn = styled.div`
  background: ${props => props.theme.colors.background};
  height: 100%;
`;
