import React from 'react';
import {PaginationButton, PaginationSection} from "./style";
import {ReactComponent as IconLeft} from "../../../../../../images/icons/arrow-left.svg";
import {ReactComponent as IconRight} from "../../../../../../images/icons/arrow-right.svg";
import {ReactComponent as IconSelected} from "../../../../../../images/icons/pagination-selected.svg";
import {ReactComponent as IconUnselected} from "../../../../../../images/icons/pagination-unselected.svg";
import {ReactComponent as IconAlert} from "../../../../../../images/alert-red.svg";
import {ReactComponent as IconReport} from "../../../../../../images/icons/departure-report.svg";
import {TYPE} from "../../../../../../constants/Notice";

export default (props) => {

  const {
    events,
    selectedEventId,
    onEventSelected
  } = props;

  const onPageClicked = (clickedIndex) => {
    const index = Math.min(Math.max(clickedIndex, 0), events.length - 1);
    onEventSelected(events[index].id)
  };

  const onNextClicked = (selectedEventId) => {
    const previousIndex = events.findIndex((event, i) => {
      return event.id === selectedEventId
    }) + 1;
    onPageClicked(previousIndex);
  };

  const onPreviousClicked = (selectedEventId) => {
    const nextIndex = events.findIndex((event, i) => {
      return event.id === selectedEventId
    }) - 1;
    onPageClicked(nextIndex);
  };

  const escalationEvents = events.filter(e => e.messageType && e.messageType === TYPE.ESCALATION);

  return (
    <PaginationSection>
      <PaginationButton
        desktopOnly
        disabled={selectedEventId === events[0].id}
        onClick={() => onPreviousClicked(selectedEventId)}>
        <IconLeft/>
      </PaginationButton>
      {
        events.map((event, i) => {
          let icon;
          if (i < escalationEvents.length) {
            icon = <IconAlert/>
          } else if (event.id === "DEPARTURE_REPORT") {
            icon = <IconReport/>
          } else if (selectedEventId === event.id) {
            icon = <IconSelected/>
          } else {
            icon = <IconUnselected/>
          }
          return <PaginationButton
            key={i}
            selected={selectedEventId === event.id}
            onClick={() => onPageClicked(i)}>
            {icon}
          </PaginationButton>
        })
      }
      <PaginationButton
        desktopOnly
        disabled={selectedEventId === events[events.length - 1].id}
        onClick={() => onNextClicked(selectedEventId)}>
        <IconRight/>
      </PaginationButton>
    </PaginationSection>
  );
};
