import React, {Component} from 'react';
import {Editor, TimeInput, Timer, TimerButton} from "./style";
import Button from "../../../../../common/atoms/Button";
import {ReactComponent as IconMinus} from "../../../../../../images/icons/timer-minus.svg"
import {ReactComponent as IconPlus} from "../../../../../../images/icons/timer-plus.svg"
import * as moment from "moment";
import {getLocalTime} from "../../../../../../utils/flight-utils";
import TimeField from "react-simple-timefield";

class PTSEditor extends Component {

  state = {
    hour: this.props.pts.actual_time ?
      moment.utc(this.props.pts.actual_time).utcOffset(this.props.flight.departure_airport.timezone_offset).hours() :
      this.props.pts.scheduled_time ?
        moment.utc(this.props.pts.scheduled_time).utcOffset(this.props.flight.departure_airport.timezone_offset).hours() :
        moment.utc(this.props.departure_scheduled_time).utcOffset(this.props.flight.departure_airport.timezone_offset).hours(),
    minute: this.props.pts.actual_time ?
      moment.utc(this.props.pts.actual_time).utcOffset(this.props.flight.departure_airport.timezone_offset).minutes() :
      this.props.pts.scheduled_time ?
        moment.utc(this.props.pts.scheduled_time).utcOffset(this.props.flight.departure_airport.timezone_offset).minutes() :
        moment.utc(this.props.departure_scheduled_time).utcOffset(this.props.flight.departure_airport.timezone_offset).minutes(),
  };

  render = () => {
    const {
      flight,
      pts,
      onCancel,
    } = this.props;

    return (
      <Editor>
        <h3>Edit completion time of {pts.description}</h3>
        <p>Original completion time is {getLocalTime(pts.scheduled_time, flight.departure_airport.timezone_offset)}</p>
        <Timer>
          <TimerButton disabled={false}
                       onClick={this.decrementMinute}><IconMinus/></TimerButton>
          <TimeField
            value={`${this.state.hour < 10 ? `0${this.state.hour}` : this.state.hour}}:${this.state.minute < 10 ? `0${this.state.minute}` : this.state.minute}`}
            onChange={(_, timeString) => {
              const hour = timeString.slice(0, 2);
              const minute = timeString.slice(3, 5);
              this.updateHour(parseInt(hour));
              this.updateMinute(parseInt(minute))
            }}
            input={
              <TimeInput placeholder={"00:00"}/>
            }
          />
          <TimerButton disabled={false}
                       onClick={this.incrementMinute}><IconPlus/></TimerButton>
        </Timer>
        <Button primary
                fluid
                onClick={this.savePTS}>Save</Button>
        <Button basic
                fluid
                onClick={onCancel}>Cancel</Button>
      </Editor>
    )
  };

  updateHour = (newHour) => {
    if (newHour < 24 && newHour >= 0) {
      this.setState({hour: newHour})
    }
  };

  updateMinute = (newMinute) => {
    if (newMinute < 60 && newMinute >= 0) {
      this.setState({minute: newMinute})
    }
  };

  incrementMinute = () => {
    if (this.state.hour < 24 && this.state.minute < 60 && !(this.state.hour === 23 && this.state.minute === 59)) {
      this.setState({
        minute: this.state.minute < 59 ? this.state.minute + 1 : 0,
        hour: this.state.minute < 59 ? this.state.hour : this.state.hour + 1
      })
    }
  };

  decrementMinute = () => {
    if (this.state.hour >= 0 && this.state.minute >= 0 && !(this.state.hour === 0 && this.state.minute === 0)) {
      this.setState({
        minute: this.state.minute > 0 ? this.state.minute - 1 : 59,
        hour: this.state.minute > 0 ? this.state.hour : this.state.hour - 1
      })
    }
  };

  savePTS = () => {
    const {
      onSave
    } = this.props;

    onSave(this.state.hour, this.state.minute);
  };
}

export default PTSEditor;
