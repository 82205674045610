import React from 'react';
import {FormRow, FormSection, FormWrapper, Input, InputGroup, Label, TextArea} from "../../../../common/molecules/Form";
import Divider from "../../../../common/molecules/Divider";
import {getFlightNumber, getLocalDate, getLocalTime} from "../../../../../utils/flight-utils";

const FlightDetailReport = ({flight, details, onDetailsUpdate}) => {

  return (
    <FormWrapper>
      <FormSection>
        <FormRow>
          <InputGroup>
            <Label>Flight No.</Label>
            <Input disabled
                   responsive
                   value={getFlightNumber(flight)}
                   type="text"/>
          </InputGroup>
          <InputGroup>
            <Label>Type</Label>
            <Input disabled
                   responsive
                   value={flight.aircraft_type}
                   type="text"/>
          </InputGroup>
          <InputGroup>
            <Label>Rego</Label>
            <Input disabled
                   responsive
                   value={flight.aircraft_registration}
                   type="text"/>
          </InputGroup>
        </FormRow>
        <FormRow>
          <InputGroup>
            <Label>Date</Label>
            <Input disabled
                   responsive
                   value={getLocalDate(flight.departure_scheduled_time, flight.departure_airport.timezone_offset)}
                   type="text"/>
          </InputGroup>
          <InputGroup>
            <Label>STD</Label>
            <Input disabled
                   responsive
                   value={getLocalTime(flight.departure_scheduled_time, flight.departure_airport.timezone_offset)}
                   type="text"/>
          </InputGroup>
          <InputGroup>
            <Label>ATD</Label>
            <Input disabled
                   responsive
                   value={getLocalTime(flight.offblocks_time, flight.departure_airport.timezone_offset)}
                   type="text"/>
          </InputGroup>
        </FormRow>
      </FormSection>
      <Divider/>
      <FormSection>
        <FormRow packed>
          <InputGroup>
            <Label>Gate</Label>
            <Input 
                value={details.gate}
                onChange={(e) => {
                  onDetailsUpdate({
                    ...details,
                    gate: e.target.value,
                  });
                }}
                placeholder={"Gate"}
                type="text"/>
          </InputGroup>
          <InputGroup>
            <Label>Bay</Label>
            <Input 
                value={details.bay}
                onChange={(e) => {
                  onDetailsUpdate({
                    ...details,
                    bay: e.target.value,
                  });
                }}
                placeholder={"Bay"}
                type="text"/>
          </InputGroup>
          {/* <InputGroup>
            <Label>Tug</Label>
            <Input
              value={details.tug}
              onChange={(e) => {
                onDetailsUpdate({
                  ...details,
                  tug: e.target.value,
                });
              }}
              placeholder={"Tug"}
              type="text"/>
          </InputGroup>
          <InputGroup>
            <Label>PCU ext.</Label>
            <Input
              value={details.pcu_ext}
              onChange={(e) => {
                onDetailsUpdate({
                  ...details,
                  pcu_ext: e.target.value,
                });
              }}
              placeholder={"PCU ext."}
              type="text"/>
          </InputGroup> */}
        </FormRow>
        {/* <FormRow>
          <InputGroup>
            <Label>Oscar</Label>
            <Input wide
                   value={details.oscar}
                   onChange={(e) => {
                     onDetailsUpdate({
                       ...details,
                       oscar: e.target.value,
                     });
                   }}
                   placeholder={"Oscar"}
                   type="text"/>
          </InputGroup>
        </FormRow> */}
        <FormRow>
          <InputGroup>
            <Label>Team members</Label>
            <TextArea
              value={details.team_members}
              onChange={(e) => {
                onDetailsUpdate({
                  ...details,
                  team_members: e.target.value,
                });
              }}
              placeholder={"Team members"}/>
          </InputGroup>
        </FormRow>
      </FormSection>
    </FormWrapper>
  )
};

export default FlightDetailReport;
