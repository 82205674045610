import PropTypes from 'prop-types';

export const dummyAirport = {
  id: 1011,
  code: "SYD",
  city: "Sydney",
  country: "Australia",
  timezone_offset: 660,
  latitude: -33.9411,
  longitude: 151.173,
  map_zoom_priority: 1,
  notice_channel_id: 1193,
};

const AirportType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  city: PropTypes.string,
  country: PropTypes.string,
  timezone_offset: PropTypes.number.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  map_zoom_priority: PropTypes.number,
  notice_channel_id: PropTypes.number.isRequired,
});

export default AirportType;
