import userQuery from '../gql/get-user';

export default function(cache, { data: { addUserView } }) {
  const cachedResult = cache.readQuery({ query: userQuery });

  // If the terminal is already in cache then skip
  if (cachedResult.user.views.find(v => v.id === addUserView.id)) {
    return;
  }

  const views = [
    ...cachedResult.user.views,
    addUserView
  ].sort((a, b) => a.id - b.id);

  cache.writeQuery({
    query: userQuery,
    data: {
      user: {
        ...cachedResult.user,
        views
      }
    }
  });
}
