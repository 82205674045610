import React from 'react';
import {Query} from 'react-apollo';

import boardingSummaryQuery from '../gql/get-flight-boarding-summary';
import ErrorPage from "../components/common/ErrorPage";
import BoardingProgress from "../components/flight/FlightDetail/FlightBody/CabinAccordion/BoardingProgress";
import {NETWORK_STATUS, REFRESH_INTERVAL} from "../constants/Apollo";

export default (props) => {

  const {
    flight
  } = props;

  if (!flight) {
    return <ErrorPage
      title="Error getting Cabin Summary"
      message="flightId was missing from the request"
      hideRefresh={true}/>
  }

  return (
    <Query query={boardingSummaryQuery}
           variables={{id: flight.id}}
           fetchPolicy={"network-first"}
           errorPolicy="all"
           pollInterval={REFRESH_INTERVAL.BOARDING_REFRESH_INTERVAL}>
      {({networkStatus, error, data = {}}) => {
        const loading = networkStatus <= NETWORK_STATUS.SET_VARIABLES;
        if (error) {
          console.error("Error loading Boarding Status", error);
          return <ErrorPage
            title="Error getting Boarding Status"
            message="There was a problem getting Boarding Status"
            hideRefresh={true}/>;
        } else {
          return <BoardingProgress {...props}
                                   loading={loading}
                                   boardedCount={data?.flight?.boarding_summary?.boardedCount}
                                   checkedInCount={data?.flight?.boarding_summary?.checkedInCount}
                                   bookedCount={data?.flight?.boarding_summary?.bookedCount}/>
        }
      }}
    </Query>
  )
}
