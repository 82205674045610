import React from 'react';
import {FormRow, FormSection, FormWrapper, InputGroup, Label, TextArea} from "../../../../common/molecules/Form";
import { getLocalTime } from '../../../../../utils/flight-utils';

const CommentsReport = ({comments, ptsAddedEvents, timezoneOffset, onCommentsUpdate}) => {
  const addedEventsFormatted = ptsAddedEvents.map(e => `${getLocalTime(e.createAt, timezoneOffset)}, ${e.content}`).join('\r\n');
  return (
    <FormWrapper>
      <FormSection>
        <FormRow>
          <InputGroup>
            <Label>Added Events</Label>
            <TextArea value={addedEventsFormatted} readOnly disabled/>
          </InputGroup>
        </FormRow>
        <FormRow>
          <InputGroup>
            <Label>Please enter additional flight notes (optional)</Label>
            <TextArea value={comments}
                      onChange={(e) => {
                        onCommentsUpdate(e.target.value)
                      }}
                      placeholder={"Enter additional comments (optional)"}/>
          </InputGroup>
        </FormRow>
      </FormSection>
    </FormWrapper>
  )
};

export default CommentsReport;
