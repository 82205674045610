import React from 'react';
import {EscalatedFlightWrapper, EscalationContent} from "./style";
import FlightType from "../../../../../../constants/Flight";
import {ButtonColumn, DataColumn} from "../../../NetworkSummary/style";
import {getExtraStatus, getFlightNumber, getLocalTime, getMinutesLate} from "../../../../../../utils/flight-utils";
import moment from "moment";
import InfoLabel from "../../../../../common/molecules/InfoLabel";
import FlexSection from "../../../../../common/atoms/FlexSection";
import {AIRPORT} from "../../../../../../constants/Routes";
import {ReactComponent as IconRight} from "../../../../../../images/icons/arrow-right.svg";
import {FlightLink} from "../../../style";

const EscalatedFlight = ({flight}) => {

  const std = moment.utc(flight.departure_scheduled_time);
  const ofb = moment.utc(flight.offblocks_time);

  const depDelay = getMinutesLate(std, ofb);

  return (
    <FlightLink to={`/${AIRPORT}/${flight.departure_airport_code}/dep/${flight.id}`} target="_blank">
      <EscalatedFlightWrapper>
        <FlexSection>
          <DataColumn>
            <InfoLabel
              label={flight.aircraft_registration}
              text={getFlightNumber(flight)}/>
          </DataColumn>
          <DataColumn>
            <InfoLabel
              label={`${flight.departure_airport_code} - ${flight.arrival_airport_code}`}
              text={`${getLocalTime(ofb, flight.departure_airport.timezone_offset)} ${getExtraStatus(depDelay)}`}/>
          </DataColumn>
          <DataColumn>
            <InfoLabel
              label={"Gate"}
              text={flight.departure_gate}/>
          </DataColumn>
          <ButtonColumn>
            <IconRight/>
          </ButtonColumn>
        </FlexSection>
        {flight.escalation_notices.map(({content}) => <EscalationContent>{content}</EscalationContent>)}
      </EscalatedFlightWrapper>
    </FlightLink>
  );
};

EscalatedFlight.propTypes = {
  flight: FlightType.isRequired,
};

export default EscalatedFlight;
