import React from 'react';
import {InitialAvatarWrapper} from './style';
import {getInitials} from "../../../../utils/user-utils";
import UserType from "../../../../constants/User";
import PropTypes from "prop-types";

const InitialAvatar = ({user, isOpen}) =>
  <InitialAvatarWrapper isOpen={isOpen} isSuperuser={user.is_superuser}>
    {getInitials(user.name)}
  </InitialAvatarWrapper>;

InitialAvatar.defaultProps = {
  isOpen: false,
};

InitialAvatar.propTypes = {
  user: UserType,
  isOpen: PropTypes.bool,
};

export default InitialAvatar;
