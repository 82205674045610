import React from 'react';
import {ReactComponent as IconFlight} from '../../../../images/flight.svg';
import {DesktopIconWrapper, FlightNumberWrapper} from "./style";

const FlightNumber = ({flight}) => {
  return (
    <FlightNumberWrapper>
      <DesktopIconWrapper>
        <IconFlight/>
      </DesktopIconWrapper>
      <h3>{flight.operating_airline}{flight.flight_number}</h3>
    </FlightNumberWrapper>
  )
};

export default FlightNumber;
