import React from 'react';
import PropTypes from 'prop-types';
import {NETWORK_MAP} from "../../../../../constants/Routes";
import {ViewSwitchWrapper, ViewLink, ViewLinkMenuTab} from "./style";

const ViewSwitch = ({isNetworkMap}) => {
  return (
    <ViewSwitchWrapper>
      <ViewLink to={`/${NETWORK_MAP}`}>
        <ViewLinkMenuTab active={isNetworkMap}>
            Network Map
        </ViewLinkMenuTab>
      </ViewLink>
      <ViewLink to={`/`}>
        <ViewLinkMenuTab active={!isNetworkMap}>
            Airport View
        </ViewLinkMenuTab>
      </ViewLink>
    </ViewSwitchWrapper>
  );
};

ViewSwitch.propTypes = {
  isNetworkMap: PropTypes.bool.isRequired,
};

export default ViewSwitch;
