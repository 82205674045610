import React, {Component} from 'react'
import {Form, Message} from 'semantic-ui-react';
import styles from "../../PromptForm.module.css";
import sectionStyles from "../../NoticeSection.module.css";
import Event from "../list/Event";
import PromptBox from "../../../common/templates/PromptBox";
import {TYPE} from "../../../../constants/Notice";
import Button from "../../../common/atoms/Button";

export default class CompleteNotice extends Component {

  state = {
    detail: undefined,
  };

  isValidForm = () => {

    const {
      notice
    } = this.props;

    if (notice.messageType === TYPE.ESCALATION) {
      if (!this.state.detail) {
        return false;
      }
    } else if (notice.messageType === TYPE.PTS) {
      return true;
    }
    return true;
  };

  handleDetailChange = (_, {value}) => this.setState({detail: value});

  render = () => {

    const {
      notice,
      flightNumber = "",
      offset,
      loading,
      error,
      onConfirm,
      closeModal,
    } = this.props;

    return (
      <PromptBox>
        <h2>{notice.messageType === TYPE.ESCALATION ? "Resolve escalation" : ("Close Flight " + flightNumber)}</h2>
        <div className={styles.center}>
          {
            notice.messageType === TYPE.ESCALATION ?
              "Please enter a resolution for the following escalation." :
              "Please enter additional flight notes (optional)."
          }
        </div>
        <Form
          className={styles.form}
          loading={loading}
          onSubmit={this.handleSubmit}
          error={error}>
          {
            notice.messageType === TYPE.ESCALATION &&
            <div className={`${sectionStyles.section} ${styles.noticeSection} ${styles.eventline}`}>
              <div className={`${styles.dot} ${styles.red}`}/>
              <Event
                disableDelta={false}
                disableHover={true}
                disableLabel={true}
                offset={offset}
                event={notice}/>
            </div>
          }
          <Form.Field>
            <label>{notice.messageType === TYPE.ESCALATION ? "Resolution" : "Flight notes (optional)"}</label>
            <Form.TextArea
              placeholder={notice.messageType === TYPE.ESCALATION ? 'Enter a summary of the escalation resolution' : 'Enter delay reson, or any additional flight notes, if available.'}
              onChange={this.handleDetailChange}
            />
          </Form.Field>
          <Message
            size='tiny'
            error>
            <Message.Content>An error occurred while trying
              to {notice.messageType === TYPE.ESCALATION ? 'resolve the escalation.' : 'close the flight.'}</Message.Content>
          </Message>
        </Form>
        <Button
          fluid
          primary
          large
          onClick={() => onConfirm({id: notice.id, completed_reason: this.state.detail})}
          disabled={loading || this.isValidForm() === false}>
          {notice.messageType === TYPE.ESCALATION ? "Resolve" : "Close Flight"}
        </Button>
        <Button
          fluid
          basic
          large
          onClick={() => closeModal()}
          disabled={loading}>
          Cancel
        </Button>
      </PromptBox>
    )
  };
}
