import gql from 'graphql-tag';

export const getFlightDepartureReport = gql`
  query getFlightDepartureReport($flight_id: String!) {
    getFlightDepartureReport(flight_id: $flight_id) {
      id
      report
      completed_at
    }
  }
`;

export const addFlightDepartureReport = gql`
  mutation addFlightDepartureReport($input: AddFlightDepartureReportInput!) {
    addFlightDepartureReport(input: $input) {
      id
      report
      completed_at
    }
  }`;
