import styled from 'styled-components';
import FlexSection from "../../../common/atoms/FlexSection";

export const NoticeStatusWrapper = styled(FlexSection)`
  text-transform: uppercase;
  
  svg {
    margin-right: ${props => props.theme.space2}
  }
`;

export const Duration = styled.div`
  font-family: ${props => props.theme.fontRegular};
`;
