import React from "react";
import {AccordionBody, AccordionHead, AccordionHeadContent, AccordionHeadContentWrapper, AccordionIcon, AccordionWrapper, AccordionHeadIcon} from "./style";
import {ReactComponent as IconDown} from "../../../../images/icons/arrow-down.svg";
import PropTypes from 'prop-types';

const Accordion = ({collapsed, onToggle, icon, head, body}) => {
  return (
    <AccordionWrapper>
      <AccordionHead onClick={() => onToggle()}>
        <AccordionHeadContentWrapper>
          <AccordionHeadContent>
            {
              icon &&
              <AccordionHeadIcon>
                {icon}
              </AccordionHeadIcon>
            }
            {head}
          </AccordionHeadContent>
        </AccordionHeadContentWrapper>
        <AccordionIcon collapsed={collapsed}>
          <IconDown/>
        </AccordionIcon>
      </AccordionHead>
      {
        !collapsed &&
        <AccordionBody>
          {body}
        </AccordionBody>
      }
    </AccordionWrapper>
  )
};

Accordion.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default Accordion;
