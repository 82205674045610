import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Accordion from "../../../common/molecules/Accordion";
import {EscalationAccordionWrapper} from "./style";
import FlightType from "../../../../constants/Flight";
import EscalatedFlightList from "./EscalatedFlightList";
import {ReactComponent as IconAlert} from "../../../../images/alert-red.svg";

const EscalationAccordion = ({flights}) => {
  const [isAccordionOpen, setAccordionOpen] = useState(true)

  return (
    <EscalationAccordionWrapper>
      <Accordion
        collapsed={!isAccordionOpen}
        icon={<IconAlert />}
        head={`${flights.length > 0 ? `${flights.length} ` : ''}Escalation`}
        body={<EscalatedFlightList flights={flights}/>}
        onToggle={() => setAccordionOpen(!isAccordionOpen)}/>
    </EscalationAccordionWrapper>
  );
}

EscalationAccordion.propTypes = {
  flights: PropTypes.arrayOf(FlightType),
};

export default EscalationAccordion;
