import React, {Component} from 'react';
import PropTypes from "prop-types";
import Modal from "../../../../../common/molecules/Modal";
import MutatedDepartureReport from "../../../DepartureReport/mutated";
import FlightType from "../../../../../../constants/Flight";
import ChannelType from '../../../../../../constants/Channel';

class DepartureReportModal extends Component {

  state = {
    showModal: false,
  };

  render = () => {

    const {
      flight,
      scheduleChannel,
      trigger,
    } = this.props;

    return (
      <Modal
        isOpen={this.state.showModal}
        onOpen={this.openModal}
        onClose={this.closeModal}
        fixed={true}
        trigger={
          trigger
        }
      >
        <MutatedDepartureReport
          flight={flight}
          scheduleChannel={scheduleChannel}
          onClose={this.closeModal}/>
      </Modal>
    )
  };

  openModal = () => this.setState({showModal: true});

  closeModal = () => this.setState({showModal: false});
}

DepartureReportModal.propTypes = {
  flight: FlightType.isRequired,
  scheduleChannel: ChannelType.isRequired,
  trigger: PropTypes.node,
};

export default DepartureReportModal;
