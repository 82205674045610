import React from 'react';
import moment from 'moment';

import {ReactComponent as IconLeftArrow} from '../../../../../images/icons/left-arrow.svg';
import {ReactComponent as ArrowRightLong} from '../../../../../images/icons/arrow-right-long.svg';
import {getExtraStatus, getLocalTime, getMinutesLate, getRegistration} from "../../../../../utils/flight-utils";
import InfoLabel from "../../../../common/molecules/InfoLabel";
import FlightNumber from "../../../../common/atoms/FlightNumber";
import {ArrowWrapper, DetailSectionWrapper, InfoLabelColumn, MobileLinkWrapper} from "./style";
import Registration from "./Registration";
import MobilePopout from "./MobilePopout";
import Icon from "../../../../common/atoms/Icon";
import {Link} from "react-router-dom";
import FlightType, {QUALIFIER, TYPE} from "../../../../../constants/Flight";
import PropTypes from "prop-types";

const DetailSection = React.memo(({flight, terminalOffset, viewType, terminalPath}) => {

  const {
    aircraft_type,
    departure_gate,
    departure_scheduled_time,
    departure_airport_code,
    offblocks_time,
    offblocks_qualifier,
    arrival_gate,
    arrival_scheduled_time,
    arrival_airport_code,
    onblocks_time,
    onblocks_qualifier,
  } = flight;

  const isDepature = viewType === TYPE.DEPARTURE;

  const scheduled = isDepature ? moment.utc(departure_scheduled_time) : moment.utc(arrival_scheduled_time);
  const actual = isDepature ? moment.utc(offblocks_time) : moment.utc(onblocks_time);
  const port = isDepature ? departure_airport_code : arrival_airport_code;
  const gate = isDepature ? departure_gate : arrival_gate;

  const minutesLate = getMinutesLate(scheduled, actual);
  const extraStatus = getExtraStatus(minutesLate);
  // const stxDisplay = getLocalTime(scheduled, terminalOffset);
  const atxDisplay = `${getLocalTime(actual, terminalOffset)} ${extraStatus}`;

  const registration = getRegistration(flight);

  return (
    <DetailSectionWrapper>
      <MobileLinkWrapper>
        <Link to={terminalPath}>
          <Icon>
            <IconLeftArrow/>
          </Icon>
        </Link>
      </MobileLinkWrapper>
      <FlightNumber flight={flight}/>

      <Registration
        registration={registration}
        type={aircraft_type}/>

      <MobilePopout flight={flight}/>


      {
        !isDepature && (
          <>
            <InfoLabelColumn>
              <InfoLabel
                heading2
                label={"From"}
                text={departure_airport_code}/>
            </InfoLabelColumn>
            <ArrowWrapper>
              <ArrowRightLong/>
            </ArrowWrapper>
          </>
        )
      }
      <InfoLabelColumn>
        <InfoLabel
          heading2
          label={isDepature ? "From" : "To"}
          text={port}/>
      </InfoLabelColumn>
      {
        isDepature && (
        <>
          <ArrowWrapper>
            <ArrowRightLong/>
          </ArrowWrapper>
          <InfoLabelColumn>
            <InfoLabel
              heading2
              label={"To"}
              text={arrival_airport_code}/>
          </InfoLabelColumn> 
        </>)
      }

      {
        gate &&
        <InfoLabelColumn>
          <InfoLabel
            heading2
            divider={"left"}
            label={"Gate"}
            text={gate}/>
        </InfoLabelColumn>
      }

      <InfoLabelColumn>
        <InfoLabel
          heading2
          label={isDepature ?
            (offblocks_qualifier === QUALIFIER.ACTUAL ? "ATD" : "ETD") :
            (onblocks_qualifier === QUALIFIER.ACTUAL ? "ATA" : "ETA")}
          text={atxDisplay}/>
      </InfoLabelColumn>

    </DetailSectionWrapper>
  )
});

DetailSection.propTypes = {
  flight: FlightType,
  terminalOffset: PropTypes.number.isRequired,
  viewType: PropTypes.oneOf([TYPE.ARRIVAL, TYPE.DEPARTURE]),
  terminalPath: PropTypes.string.isRequired,
};

export default DetailSection;
