import React from 'react';
import PropTypes from 'prop-types';
import FlightType from "../../../../../constants/Flight";
import {ContentContainer} from "../../../style";
import UnserviceableFlight from "./UnserviceableFlight";
import Page from "../../../../common/atoms/Page";

const UnserviceableFlightList = ({flights}) => {
  return (
    <ContentContainer>
      {
        flights.length > 0 ?
          flights.map(flight => (
            <UnserviceableFlight key={flight.id}
                                 flight={flight}/>
          )) :
          <Page>
            <p>No unserviceable flights</p>
          </Page>
      }
    </ContentContainer>
  );
};

UnserviceableFlightList.propTypes = {
  flights: PropTypes.arrayOf(FlightType),
};

export default UnserviceableFlightList;
