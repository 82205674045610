import styled, {css} from "styled-components";
import FlexSection from "../../../../../common/atoms/FlexSection";

export const BoardingProgressWrapper = styled(FlexSection)`
  width: 100%;
  flex-wrap: wrap;

  background: white;
  padding: ${props => props.theme.size3} ${props => props.theme.size2};
  border-radius: ${props => props.theme.cornerRadius};

  text-transform: uppercase;
  white-space: nowrap;

  /* Flight Not Open */
  ${props => (!props.boardingStarted) && css`
    color: ${props => props.theme.colors.grey};
  `}

  /* Awaiting Boarding */
  ${props => (props.boardingOpen) && css`
    color: ${props => props.theme.colors.grey};
  `}

  /* Boarding */
  ${props => (props.boardingActive && !props.allBoarded) && css`
    border-left: ${props => props.theme.size1} solid ${props => props.theme.colors.brandSecondary};
  `}

  /* All Boarded */
  ${props => (props.boardingActive && props.allBoarded) && css`
    border-left: ${props => props.theme.size1} solid ${props => props.theme.colors.success};
    color: ${props => props.theme.colors.grey};
  `}

  /* Boarding Closed */
  ${props => props.boardingClosed && css`
    color: ${props => props.theme.colors.grey};
    border-left: none;
  `}
`;

export const BoardingDetails = styled.div`
  padding: ${props => `0 ${props.theme.size1}`};
`;

export const BoardingDetail = styled.span`
  padding: ${props => `0 ${props.theme.size1}`};

  ${props => props.light && css`
    color: ${props => props.theme.colors.grey};
  `}
`;

export const BoardingStatus = styled.div`
  /* We want ProgressBarWrapper to grow, but only when on it's own line so
   * when sharing a line with this element this should grow instead */
  flex-grow: 9999;
  padding: 0 ${props => props.theme.size2};
`;

export const ProgressBarWrapper = styled.div`
  flex-grow: 1;
  padding: ${props => `0 ${props.theme.size2}`};
`;

export const Dot = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 ${props => props.theme.space3} 0 0;
  border-radius: 4px;

  position: relative;
  background: ${props => props.theme.colors.brandSecondary};

  ${props => props.allBoarded && css`
    background: ${props => props.theme.colors.success};
  `}

  ::after, ::before {
    position: absolute;
    border-radius: 4px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    transform: scale(.5);
    transform-origin: center center;
    animation: glow 3s linear infinite;
    background: ${props => props.theme.colors.brandSecondary};

    ${props => props.allBoarded && css`
      background: ${props => props.theme.colors.success};
    `}
  }

  ::after {
    animation-delay: 2s;
  }

  @keyframes glow {
    0% {
      transform: scale(.5);
      opacity: 0
    }
    50% {
      opacity: .1
    }
    70% {
      opacity: .05
    }
    to {
      transform: scale(5);
      opacity: 0
    }
  }
`;
