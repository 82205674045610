import {Icon, Menu} from "semantic-ui-react";
import styles from "./MasterMenu.module.css";
import {Link} from "react-router-dom";
import React from "react";
import {AddLinkButton, DesktopMenuWrapper} from "./style";
import {VIEW_TYPE} from "../../../../../../constants/View";
import {AIRPORT} from "../../../../../../constants/Routes";
import {ReactComponent as ExchagneIcon} from "../../../../../../images/icons/exchange.svg";

// TODO: Replace Semantic with Styled-Components Dropdown
const DesktopMenu = ({isExpanded, toggleMenu, currentViewName, currentView, terminalViews, rosterVisible}) => (
  <DesktopMenuWrapper>
    <Menu
      vertical
      className={styles.menu}>
      <Menu.Item
        onClick={toggleMenu}
        className={styles.smallToBigFont}>
        <Icon>
          <ExchagneIcon/>
        </Icon>
        {currentViewName}
      </Menu.Item>
      {
        isExpanded &&
        <>
          {
            rosterVisible &&
            <Menu.Item className={styles.rosterMenuItem}>
              <Menu.Menu>
                <Menu.Item
                  name={"roster"}
                  active={currentView.view_type === VIEW_TYPE.ROSTER}
                  as={Link}
                  to='/roster'
                  className={styles.smallToBigFont}>
                  Daily Roster
                </Menu.Item>
              </Menu.Menu>
            </Menu.Item>
          }
          <Menu.Item>
            <span className={styles.bigToSmallFont}>TERMINAL</span>
            <Menu.Menu>
              {
                terminalViews.map(v =>
                  <Menu.Item
                    key={v.id}
                    name={v.filter.airport_code}
                    active={v.id === currentView.id}
                    as={Link}
                    to={`/${AIRPORT}/${v.filter.airport_code}`}
                    className={styles.smallToBigFont}>
                    {v.filter.airport_code}
                  </Menu.Item>
                )
              }
            </Menu.Menu>
            <Link to={`/${AIRPORT}/add`}>
              <AddLinkButton>
                + Add Terminal
              </AddLinkButton>
            </Link>
          </Menu.Item>
        </>
      }
    </Menu>
  </DesktopMenuWrapper>
);

export default DesktopMenu;
