export default function subscriptionWrapperBuilder(subFunctionsBuilder, refetch) {
  // We can't just return the unsubscribe function since it may be replaced if a reconnection
  // happens. So return this wrapper in which we can store the latest unsub function
  const unsubWrapper = {};

  return function subscribeFn() {
    const unsubFunctions = [];
    unsubWrapper.unsubscribe = () => unsubFunctions.forEach(fn => fn());

    const onError = () => {
      unsubWrapper.unsubscribe();
      refetch();
      subscribeFn();
    };

    unsubFunctions.push(
      ...subFunctionsBuilder(onError)
    );
    
    return unsubWrapper;
  };
}