import styled, {css} from 'styled-components';
import {ReactComponent as iconClose} from '../../../../images/icons/close.svg';
import FlexSection from "../../atoms/FlexSection";
import {CardWrapper} from "../../molecules/Card/style";
import {POPOUT} from "../../../../constants/Layer";

const iconWidth = 16;
const popoutWidth = 220;

export const PopoutWrapper = styled(FlexSection)`
  position: relative;
`;

export const IconWrapper = styled(FlexSection)`
  
  cursor: pointer;
  
  ${props => !props.open && css`
    svg {
      fill: ${props => props.theme.colors.lightGrey};
    }
  `}
  
  ${props => props.open && css`
    svg {
      fill: ${props => props.theme.colors.grey};
    }
  `}
`;

export const PopoutContent = styled(CardWrapper)`
  position: absolute;
  top: 30px;
  left: ${iconWidth - popoutWidth / 2}px;
  width: 220px;
  z-index: ${POPOUT.CONTENT};
  padding: ${props => props.theme.size4};
  
  display: none;
  
  ${props => props.show && css`
    display: inline-block;
  `}
  
  ${props => props.align === "right" && css`
    right: 0;
    left: initial;
  `}
  
  ${props => props.mode === "cover" && css`
    top: 0;
  `}
`;

export const PopoutHead = styled.h3`
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizeMedium};
  margin: ${props => props.theme.space0};
  padding-bottom: ${props => props.theme.space2};
`;

export const IconClose = styled(iconClose)`
  width: ${props => props.theme.size3};
  float: right;
  cursor: pointer;
  
  ${props => props.mode === "cover" && css`
    display: none;
  `}
`;

export const PopoutBody = styled.div`
`;
