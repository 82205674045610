import React, {Component} from 'react';
import PropTypes from "prop-types";
import {DEFAULT_ZOOM, MAXIMUM_ZOOM, MINIMUM_ZOOM} from "../../../constants/Map";
import {MapWrapper, MiniSummary} from "./style";
import {makeFeature, makeStyle} from "../../../utils/map-utils";
import AirportType from "../../../constants/Airport";
import minBy from 'lodash/minBy';
import MapMiniPortSummary from '../MapMiniPortSummary';

import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSMSource from 'ol/source/OSM';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import {getCenter, getWidth} from 'ol/extent';
import {transformExtent} from 'ol/proj';
import {
  defaults as DefaultControls,
  FullScreen,
  Rotate,
  ScaleLine,
  Zoom,
  ZoomSlider,
  ZoomToExtent,
} from 'ol/control';

import 'ol/ol.css';

class IOMap extends Component {

  state = {
    selectedPortMiniSummary: null
  };

  componentDidMount() {

    const australiaExtent = transformExtent([155.917969, -46.054688, 109.643555, -8.437500], 'EPSG:4326', 'EPSG:3857');

    const view = new View({
      center: getCenter(australiaExtent),
      zoom: DEFAULT_ZOOM,
      minZoom: MINIMUM_ZOOM,
      maxZoom: MAXIMUM_ZOOM,
    });

    const airportZoomLevels = [1,2,3,4];

    const airportLayers = airportZoomLevels.map(level => {
      const resolutionForZoomLevel = view.getResolutionForZoom(level + MINIMUM_ZOOM - 1);

      const levelAirportsFeatures = this.props.airports
        .filter(a => a.map_zoom_priority === level)
        .map(airport => makeFeature(airport));

      return new VectorLayer({
        source: new VectorSource({
          features: levelAirportsFeatures,
        }),
        style: makeStyle,
        maxResolution: resolutionForZoomLevel + 1,
      });
    });

    this.miniSummaryOverlay = new Overlay({
      element: document.getElementById("mini-summary"),
      positioning: 'top-center',
    });

    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSMSource(),
        }),
        ...airportLayers,
      ],
      view,
      controls: DefaultControls().extend([
        new Rotate(),
        new ScaleLine(),
        new FullScreen(),
        new ZoomSlider(),
        new ZoomToExtent({
          label: 'A',
          extent: transformExtent([155.917969, -46.054688, 109.643555, -8.437500], 'EPSG:4326', 'EPSG:3857')
        }),
        new Zoom(),
      ]),
      overlays: [this.miniSummaryOverlay],

    });

    this.map.on('click', e => {
      const features = this.map.getFeaturesAtPixel(e.pixel, {
        hitTolerance: 10
      });

      if (features && features.length > 0) {
        const clickedPoint = new Point(e.coordinate);

        const closestFeature = minBy(features, feature => {
          return new LineString([
            feature.getGeometry().getCoordinates(),
            clickedPoint.getCoordinates()
          ]).getLength()
        });

        this.setState({
          selectedPortMiniSummary: closestFeature.getProperties()?.airport_code
        });

        // We have to ensure we display the overlay in the current worlds coordinates not in the original world's
        // (issue if you scroll to another instance of the world and select a feature)
        // Taken from https://stackoverflow.com/a/54363720
        const worldWidth = getWidth(view.getProjection().getExtent());
        const world = Math.floor((this.map.getCoordinateFromPixel(e.pixel)[0] + worldWidth/2)/worldWidth);

        const featureCoordinate = closestFeature.getGeometry().getCoordinates();
        this.miniSummaryOverlay.setPosition([featureCoordinate[0] + world*worldWidth, featureCoordinate[1]]);
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.networkMenuCollapsed !== prevProps.networkMenuCollapsed) {
      // The network menu takes a while to fully collapse so keep resizing the map for a while (every 50ms 10 times = 500ms)
      (async () => {
        for (let i = 0; i < 10; i++) {
          await new Promise(r => setTimeout(r, 50));
          this.map.updateSize();
        }
      })();
    }
  }

  render() {
    const {
      airlineGroup,
      onPortSelected
    } = this.props;

    const closeMiniSummary = () => {
      this.miniSummaryOverlay.setPosition(null);
      this.setState({
        selectedPortMiniSummary: null
      });
    };

    const portSelected = (port) => {
      onPortSelected(port);
      closeMiniSummary();
    }

    return (
      <MapWrapper>
        <div id='map'/>
        <MiniSummary id='mini-summary'>
          {
            this.state.selectedPortMiniSummary &&
            <MapMiniPortSummary
              port={this.state.selectedPortMiniSummary}
              airlineGroup={airlineGroup}
              onClose={closeMiniSummary}
              onPortSelected={portSelected}
            />
          }
        </MiniSummary>
      </MapWrapper>
    );
  }
}

IOMap.propTypes = {
  airports: PropTypes.arrayOf(AirportType),
};

export default IOMap;
