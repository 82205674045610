import gql from 'graphql-tag';

import flightFragment from './fragments/flight';

export default gql`
  ${flightFragment}

  query GetFlight($id: String!) {
    flight(id: $id) {
      ...FlightBody

      pts {
        code
        description
        action_text
        previous_steps
        next_steps
        timing
      }
    }
  }
`;
