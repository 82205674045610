import React, {Component} from 'react';

import Modal from "../../../../common/molecules/Modal";
import sectionStyles from '../../../NoticeSection.module.css';
import {ReactComponent as AddEventIcon} from "../../../../../images/add-event.svg";
import MutatedAddEventForm from '../MutatedAddEventForm';
import {AddEventButtonWrapper} from "./style";

class AddEventButton extends Component {

  state = {
    showModal: false
  };

  render() {

    const {
      noticeChannelId,
      contextName,
      normalOnly,
    } = this.props;

    return (
      <div className={sectionStyles.section}>
        <AddEventButtonWrapper onClick={() => this.setModal(true)}>
          <AddEventIcon/>
          <span>Add Event</span>
        </AddEventButtonWrapper>

        <Modal
          isOpen={this.state.showModal}
          onOpen={() => this.setModal(true)}
          onClose={() => this.setModal(false)}
        >
          <MutatedAddEventForm
            chatChannelId={noticeChannelId}
            closeModal={() => this.setModal(false)}
            normalOnly={normalOnly}
            notify={contextName}
          />
        </Modal>
      </div>
    );
  }

  setModal = (isOpen) => {
    this.setState({showModal: isOpen})
  };
}

export default AddEventButton;
