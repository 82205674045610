import styled from 'styled-components';
import device from "../../../../constants/Device";
import FlexSection from "../../../common/atoms/FlexSection";

export const TerminalEventsWrapper = styled.div`
  height: 100%;
  display:flex;
  flex-direction: column;
`;

export const DesktopHeader = styled.div`
  height: 79px;
  padding-left: ${props => props.theme.spaceTiny};
  background-color: ${props => props.theme.colors.background};
  flex-shrink: 0;
  align-items: flex-end;

  /* Mobile Responsive */
  display: none;
  @media ${device.tablet} {
    display: flex;
  }
`;

export const Tab = styled.div`
  background-color: #ffffff;
  padding: ${props => props.theme.space5};
  display: inline-block;
  border-radius: ${props => props.theme.size1} ${props => props.theme.size1} 0 0;
  font-size: ${props => props.theme.fontSizeLarge};
  
  img {
    margin-right: 10px;
    vertical-align: top;
  }
`;

export const MobileHeader = styled(FlexSection)`

  box-shadow: 0 1px 2px 0 ${props => props.theme.colors.divider};
  padding: ${props => props.theme.space2};
  
  /* Mobile Responsive */
  display: flex;
  @media ${device.tablet} {
    display: none;
  }
`;

export const Title = styled.div`

  font-family: ${props => props.theme.fontImportant};
  font-size: ${props => props.theme.fontSizeLarge};

  margin-left: ${props => props.theme.space4};
  
  padding-left: ${props => props.theme.space4};

  border-left: ${props => props.theme.sizeTiny} solid ${props => props.theme.colors.divider};
`;
