import React from 'react';

import styles from './FormControls.module.css';

const Message = (props) => {
  const { message } = props;
  
  return (
    <span className={styles.message}>
      {message || 'Please fill in this field'}
    </span>
  )
};

export default Message;