export default function getMoreMessages(prev, { fetchMoreResult }) {
  const {before, nodes} = fetchMoreResult.getMessages;

  return {
    noticeChannel: {
      ...prev.noticeChannel,
      messages: prependMessagePage(prev.noticeChannel.messages, before, nodes)  
    }
  };
}

function prependMessagePage(messagePage, before, newMessages) {  
  return {
    ...messagePage,
    before,
    nodes: [
      ...newMessages,
      ...messagePage.nodes
    ]
  };
}
