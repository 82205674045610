import React from 'react';
import {CheckboxContainer, HiddenCheckbox, Icon, StyledCheckbox} from "./style";

const Checkbox = ({className, checked, disabled, readonly, ...props}) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} disabled={disabled} readonly={readonly} {...props} />
    <StyledCheckbox checked={checked} disabled={disabled} readonly={readonly}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12"/>
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
