import styled, {css} from 'styled-components';
import {ButtonWrapper} from "../../atoms/Button/style";
import {UserWrapper} from "../../atoms/User/style";
import FlexSection from "../../atoms/FlexSection";
import {POPOUT} from '../../../../constants/Layer';

export const DesktopUserControlWrapper = styled.div`

  position: absolute;
  right: 0;
  top: 0;

  z-index: ${POPOUT.CONTENT};

  min-width: 170px;

`;

export const MobileUserControlWrapper = styled.div`
`;

export const MenuWrapper = styled.div`
  display: none;
`;

export const UserDisplayWrapper = styled(FlexSection)`
  cursor: pointer;
`;

export const UserControlWrapper = styled.div`
  padding: ${props => props.theme.space3};

  background-color: white;

  user-select: none;

  box-sizing: border-box;

  ${UserWrapper} {
    flex-grow: 1;
  }

  ${props => props.isOpen && css`
    min-height: 120px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

    /* Dropdown Icon */
    ${UserDisplayWrapper} svg {
      transform: rotate( -180deg );
      transition: transform 240ms ease;
    }
  `};

  ${props => !props.isOpen && css`
    /* Dropdown Icon */
    ${UserDisplayWrapper} svg {
      transform: rotate( 0deg );
      transition: transform 240ms ease;
    }
  `};

  ${props => props.isOpen && css`
    ${MenuWrapper} {
      display: block;
    }
  `}

  ${ButtonWrapper} {
    margin: ${props => props.theme.space4} 0 0 0;
  }
`;
