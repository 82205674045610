import React from 'react';
import DateTimeDisplay from "../../../common/molecules/DateTimeDisplay";
import {ReactComponent as IconCog} from "../../../../images/icons/cog.svg";
import TerminalSettings from "../TerminalSettings";
import TerminalMenu from "./TerminalMenu";
import Popout from "../../../common/templates/Popout";
import {TerminalHeadWrapper, TerminalMenuWrapper} from "./style";
import {Laptop} from "../../../../constants/Device";
import UserType from "../../../../constants/User";
import Icon from "../../../common/atoms/Icon";
import PropTypes from "prop-types";

const TerminalHead = React.memo(({user, view, terminal, terminalPath}) => {
  const viewId = view.id || null;

  return (
    <TerminalHeadWrapper>
      <TerminalMenuWrapper>
        <TerminalMenu
          terminal={terminal}
          terminalPath={terminalPath}
          currentView={view}
          user={user}
        />
      </TerminalMenuWrapper>
      <Laptop>
        <DateTimeDisplay/>
      </Laptop>

      {/*We hide it since we need to re-render to happen (In order to redirect after deleting terminal*/}
      {
        viewId &&
        <Popout
          toggleIcon={
            <Icon>
              <IconCog/>
            </Icon>
          }
          heading='Settings'
          align='right'>
          <TerminalSettings viewId={viewId}/>
        </Popout>
      }
    </TerminalHeadWrapper>
  );
});

TerminalHead.propTypes = {
  user: UserType.isRequired,
  terminalPath: PropTypes.string.isRequired,
};

export default TerminalHead;
