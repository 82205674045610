import React, {Component} from 'react';
import {TerminalMenuWrapper} from "./style";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import {VIEW_TYPE} from '../../../../../constants/View';

class TerminalMenu extends Component {

  state = {
    isExpanded: false
  };

  topNode = React.createRef();

  componentDidMount = () => {
    document.addEventListener('click', this.documentClicked);
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.documentClicked);
  };

  render = () => {

    const {
      currentView,
      user: {
        views,
        staff_number
      },
      user,
      terminal,
      terminalPath,
    } = this.props;

    const {
      isExpanded
    } = this.state;

    const terminalViews = views.filter(v => v.view_type === VIEW_TYPE.TERMINAL);

    const currentViewName =
      currentView.view_type === VIEW_TYPE.TERMINAL ? currentView.filter.airport_code :
      currentView.view_type === VIEW_TYPE.ROSTER ? 'Daily Roster' :
      '';

    const rosterVisible = !!staff_number;

    return (
      <TerminalMenuWrapper ref={this.topNode}>
        <DesktopMenu isExpanded={isExpanded}
                     toggleMenu={this.toggleMenu}
                     currentViewName={currentViewName}
                     terminalViews={terminalViews}
                     currentView={currentView}
                     rosterVisible={rosterVisible}/>
        <MobileMenu isExpanded={isExpanded}
                    toggleMenu={this.toggleMenu}
                    currentViewName={currentViewName}
                    terminalViews={terminalViews}
                    currentView={currentView}
                    terminal={terminal}
                    terminalPath={terminalPath}
                    user={user}/>
      </TerminalMenuWrapper>
    );
  };

  documentClicked = (e) => {
    // Explicity check if topNode.current exists since this may be null when using Edge
    if (this.topNode.current && !this.topNode.current.contains(e.target)) {
      this.setState({isExpanded: false});
    }
  };

  toggleMenu = () => {
    this.setState({
      isExpanded: !this.state.isExpanded
    })
  }
}

export default TerminalMenu;
