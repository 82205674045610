import gql from 'graphql-tag';

import passengerFragment from './fragments/passenger';

export default gql`
  ${passengerFragment}

  query GetFlightInboundConnectionPassengers($id: String!,
    $operating_airline: String!,
    $flight_number: String!,
    $departure_scheduled_time: String!,
    $departure_airport_code: String!) {
      flight(id: $id) {
        id
        inbound_connection_passengers(
          operating_airline: $operating_airline
          flight_number: $flight_number
          departure_scheduled_time: $departure_scheduled_time
          departure_airport_code: $departure_airport_code
        ) {
          ...PassengerFields
        }
      }
    }
`;


