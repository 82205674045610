import styled, {css} from "styled-components";
import FlexSection from "../../../../../common/atoms/FlexSection";
import {FREQUENT_FLYER_STATUS,} from "../../../../../../constants/Passenger";


export const PaxTableSection = styled(FlexSection)`
  flex-direction: column;
  align-items: flex-end;
`;

export const PaxTableBody = styled(FlexSection)`
  background: white;
  width: 100%;
  flex-direction: column;
`;

export const PaxTableRow = styled(FlexSection)`
  padding: ${props => props.theme.size2};
  width: 100%;
  font-family: ${props => props.theme.fontImportant};
  text-transform: uppercase;
`;

export const SeatNumber = styled(FlexSection)`

  width: ${props => props.theme.size8};
  height: ${props => props.theme.size7};
  padding: ${props => props.theme.size1}  ${props => props.theme.space1};
  border-radius: ${props => props.theme.cornerRadius};
  flex-direction: column;

  color: ${props => props.theme.colors.body};
  background: white;
  
  ${props => (props.status === FREQUENT_FLYER_STATUS.CLPO || props.status === FREQUENT_FLYER_STATUS.CLQF) && css`
    color: white;
    background: ${props => props.theme.colors.tierCL};
  `}
  
  ${props => (props.status === FREQUENT_FLYER_STATUS.FFPO) && css`
    color: white;
    background: ${props => props.theme.colors.tierPO};
  `}
  
  ${props => (props.status === FREQUENT_FLYER_STATUS.FFPL) && css`
    color: white;
    background: ${props => props.theme.colors.tierP};
  `}
  
  ${props => (props.status === FREQUENT_FLYER_STATUS.FFGD || props.status === FREQUENT_FLYER_STATUS.QCGD) && css`
    color: white;
    background: ${props => props.theme.colors.tierG};
  `}
  
  ${props => (props.status === FREQUENT_FLYER_STATUS.FFSL || props.status === FREQUENT_FLYER_STATUS.QCSL) && css`
    color: white;
    background: ${props => props.theme.colors.tierS};
  `}
  
  ${props => (props.status === FREQUENT_FLYER_STATUS.FFBR || props.status === FREQUENT_FLYER_STATUS.QCBR) && css`
    color: white;
    background: ${props => props.theme.colors.tierB};
  `}
`;

export const FullName = styled.span`
  margin-left: ${props => props.theme.size2};
  flex-grow: 1;
`;

export const BoardingStatus = styled.span`
  margin-right: ${props => props.theme.size5};
`;

export const Bags = styled.span`
  width: ${props => props.theme.size8};
`;
