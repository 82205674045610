import React from 'react';
import PropTypes from 'prop-types';
import UserType from "../../../constants/User";
import Icon from "../../common/atoms/Icon";
import {NetworkMenuWrapper, ToggleButton} from "./style";
import {Route, Switch} from "react-router-dom";
import {NETWORK_MAP} from "../../../constants/Routes";
import {ReactComponent as IconLeft} from "../../../images/icons/arrow-left.svg";
import NetworkSummaryData from "../../../containers/NetworkSummaryData";
import AirportSummaryData from "../../../containers/AirportSummaryData";

const NetworkMenu = ({user, collapsed, onCollapsedChange}) => {
  return (
    <NetworkMenuWrapper collapsed={collapsed}>
      <ToggleButton collapsed={collapsed}
                    onClick={() => onCollapsedChange(!collapsed)}>
        <Icon>
          <IconLeft/>
        </Icon>
      </ToggleButton>

      <Switch>
        <Route path={`/${NETWORK_MAP}/:code`} render={({match: {params: {code}}}) => (
          <AirportSummaryData user={user} code={code}/>
        )}/>
        <Route path={`/${NETWORK_MAP}`} render={() => (
          <NetworkSummaryData user={user}/>
        )}/>
    </Switch>
    </NetworkMenuWrapper>
  );
}

NetworkMenu.propTypes = {
  user: UserType.isRequired,
  collapsed: PropTypes.bool.isRequired,
  onCollapsedChange: PropTypes.func.isRequired,
};

export default NetworkMenu;
