import React from 'react';
import {MenuIcon, MobileMenuWrapper} from "./style";
import PropTypes from "prop-types";
import {ReactComponent as ExchagneIcon} from "../../../../../../images/icons/exchange.svg"
import Icon from "../../../../../common/atoms/Icon";
import SliderMenu from "./SliderMenu";
import TerminalName from "../../../../../common/atoms/TerminalName";
import UserType from "../../../../../../constants/User";
import MobileTerminalButton from "../../../TerminalEventButton/MobileTerminalButton";

const MobileMenu = ({isExpanded, toggleMenu, currentViewName, currentView, terminalViews, user, terminal, terminalPath}) => (
  <MobileMenuWrapper>
    <MenuIcon onClick={toggleMenu}>
      <Icon>
        <ExchagneIcon/>
      </Icon>
    </MenuIcon>
    <TerminalName>
      {currentViewName}
    </TerminalName>
    {
      terminal &&
      <MobileTerminalButton noticeChannelId={terminal.notice_channel_id}
                            terminalPath={terminalPath}
                            user={user}/>
    }
    <SliderMenu isOpen={isExpanded}
                toggleMenu={toggleMenu}
                currentViewName={currentViewName}
                currentView={currentView}
                terminalViews={terminalViews}
                user={user}/>
  </MobileMenuWrapper>
);

MobileMenu.defaultProps = {
  isExpanded: false,
  terminalViews: [],
};
MobileMenu.propTypes = {
  isExpanded: PropTypes.bool,
  toggleMenu: PropTypes.func,
  currentViewName: PropTypes.string.isRequired,
  terminalViews: PropTypes.array,
  terminalPath: PropTypes.string.isRequired,
  user: UserType.isRequired,
};

export default MobileMenu;
