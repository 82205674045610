import styled from 'styled-components';

export const HeaderWrapper = styled.div`

  display: flex;
  position: relative;
  
  background: white;
  box-shadow: 0 ${props => props.theme.sizeExtraSmall} ${props => props.theme.borderWidth} 0 ${props => props.theme.colors.lightGrey};
  padding: 0 ${props => props.theme.space3};

  height: 56px;

  align-items: center;
`;

export const ViewSwitchWrapper = styled.div`
  flex: 1;
`;
