import styled, {css} from 'styled-components';

export const IconWrapper = styled.div`
  ${props => props.unread && css`
    ::before {
      width: ${props => props.theme.size2};
      height: ${props => props.theme.size2};
      position: absolute;
      top: ${props => props.theme.space1};
      right: ${props => props.theme.space1};
      content: '';
      background-color: ${props => props.theme.colors.brandSecondary};
      border-radius: 50%;
      width: ${props => props.theme.colors.size2};
      height: ${props => props.theme.colors.size2};
      pointer-events: none;
    }
  `}
`;
