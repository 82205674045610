import gql from 'graphql-tag';

import passengerFragment from './fragments/passenger';

export default gql`
  ${passengerFragment}
  
  query GetFlightPassengers($id: String!) {
    flight(id: $id) {
      id
      passengers {
        ...PassengerFields
      }
    }
  }
`;
