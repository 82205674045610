import React from "react";
import {Query} from "react-apollo";

import userQuery from "../gql/get-user";
import {NETWORK_STATUS} from "../constants/Apollo";
import LoadingPage from "../components/common/molecules/LoadingPage";

export const withLocalUserData = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      return (
        <Query query={userQuery} fetch-policy="cache-only">
          {({networkStatus, error, data = {}}) => {
          if (error) {
            return <div>Error loading user details</div>;
          }

          // Don't show loading on polls/fetchMore's
          const loading = networkStatus <= NETWORK_STATUS.SET_VARIABLES;
          if (loading) {
            return <LoadingPage/>;
          }

          return <WrappedComponent {...this.props} user={data.user} />;
        }}
        </Query>
      );
    }
  }
};
