import styled from 'styled-components';
import FlexSection from "../../../atoms/FlexSection";
import device from "../../../../../constants/Device";
import {Link} from "react-router-dom";
import {MenuTab} from "../../../atoms/MenuTab";

export const ViewSwitchWrapper = styled(FlexSection)`
  width: 240px;
  margin: 18px auto 0 auto;
`;

export const ViewLink = styled(Link)`
  & + & {
    margin-left: ${props => props.theme.space2};
    @media ${device.laptop} {
      margin-left: ${props => props.theme.space5};
    }
  }
`;

export const ViewLinkMenuTab = styled(MenuTab)`
  color: ${props => props.theme.colors.black};
`;
