import gql from 'graphql-tag';

import userFields from './fragments/user.js';

export default gql`
  ${userFields}

  query getUser {
    user {
      ...UserFields
    }
  }
`;
