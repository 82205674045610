import styled, {css} from 'styled-components';

export const LinkButtonWrapper = styled.div`
  /* Display */
  display: block;
  
  /* Text */
  font-family: ${props => props.theme.fontRegular};
  font-size: ${props => props.theme.fontSizeExtraLarge};
  color: ${props => props.theme.colors.oldBrandPrimary};
  
  /* Cursor styling */
  cursor: pointer;
  user-select: none; 
  
  :hover {
    color: ${props => props.theme.colors.brandSecondary};
  }
  
  ${props => props.basic && css`
    color: ${props => props.theme.colors.body};
  
    :hover {
      color: ${props => props.theme.colors.black};
    }
  `};
  
  ${props => props.disabled && css`
    pointer-events: none;
    color: ${props => props.theme.colors.grey};
  `};
`;
