import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import {BoardingProgressWrapper, BoardingStatus, Dot, BoardingDetails, BoardingDetail, ProgressBarWrapper} from "./style";
import ProgressBar from "../../../../../common/atoms/ProgressBar";
import FlightType, {BOARDING_STATUS, DOMAIN} from "../../../../../../constants/Flight";

const BoardingProgress = ({flight, boardedCount, checkedInCount, bookedCount, loading}) => {

  // Check in opens 2 hours before SDT for domestic or 3 hours for international
  // We use `valueOf` since we need to give a primitive to useEffect's dep array
  const checkInOpenTime = moment(flight.departure_scheduled_time)
    .subtract(flight.domain === DOMAIN.DOMESTIC ? 2 : 3, 'hours')
    .valueOf();

  const [checkInActive, setCheckInActive] = useState(moment().isAfter(checkInOpenTime));

  useEffect(() => {
    if (!checkInActive) {
      const interval = setInterval(() => {
        if (moment().isAfter(checkInOpenTime)) {
          setCheckInActive(true);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [flight.id, checkInOpenTime, checkInActive]);

  const boardingNumber = `${boardedCount}/${checkedInCount}`;
  const notBoarded = checkedInCount - boardedCount;
  const boardingProgress = checkedInCount === 0 ? 0 : Math.floor(boardedCount / checkedInCount * 100);
  const allPassengersBoarded = (boardedCount === checkedInCount && checkedInCount !== 0);

  const boardingOpen = flight.boarding_status === BOARDING_STATUS.OPEN;
  const boardingActive =
    flight.boarding_status === BOARDING_STATUS.BOARDING ||
    flight.boarding_status === BOARDING_STATUS.FINALCALL ||
    boardedCount > 0;
  const boardingClosed = flight.boarding_status === BOARDING_STATUS.CLOSED;
  const boardingStarted = !!flight.boarding_status;

  const statusText =
    boardingClosed ? "Boarding Closed" :
    boardingActive ? (allPassengersBoarded ? "All Boarded" : "Boarding") :
    boardingOpen ? "Awaiting Boarding" :
    checkInActive ? "Check-In Status" :
      "Flight Not Open";

  return (
    <BoardingProgressWrapper boardingStarted={boardingStarted} boardingOpen={boardingOpen} boardingActive={boardingActive} boardingClosed={boardingClosed} allBoarded={allPassengersBoarded}>
        <BoardingStatus>
          {
            boardingStarted && !boardingClosed &&
            <Dot allBoarded={allPassengersBoarded} />
          }
          <span>{statusText}</span>
        </BoardingStatus>
        {
          !loading &&
          <>
            <BoardingDetails>
            {
              boardingActive && !allPassengersBoarded &&
              <>
                <BoardingDetail>{boardingNumber}</BoardingDetail>
                <BoardingDetail light>{notBoarded} NBD</BoardingDetail>
              </>
            }
            {
              boardingStarted && allPassengersBoarded &&
              <BoardingDetail light>{boardingNumber}</BoardingDetail>
            }
            {
              !boardingStarted && checkInActive &&
              <BoardingDetail light>{checkedInCount}/{bookedCount} Booked</BoardingDetail>
            }
            </BoardingDetails>

            {
              boardingActive &&
              <ProgressBarWrapper>
                <ProgressBar percentage={boardingProgress} label/>
              </ProgressBarWrapper>
            }
          </>
        }
    </BoardingProgressWrapper>
  )
};

BoardingProgress.defaultProps = {
  boardedCount: 0,
};

BoardingProgress.propTypes = {
  flight: FlightType,
  boardedCount: PropTypes.number,
  checkedInCount: PropTypes.number,
};

export default BoardingProgress;
