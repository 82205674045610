import React, {Component} from 'react'
import {Checkbox, Dropdown, Form, Grid} from 'semantic-ui-react'

import {withRoles} from '../../../../../hocs/withRoles';
import PromptBox from "../../../../common/templates/PromptBox";
import {ButtonSection, FormWrapped} from "./style";
import {TYPE} from "../../../../../constants/Notice";
import Button from "../../../../common/atoms/Button";

class AddEventForm extends Component {

  eventTypes = [
    {key: TYPE.EVENT, text: 'Normal event'},
    {key: TYPE.ESCALATION, text: 'Escalation event'},
  ];

  constructor(props) {
    super(props);

    this.state = {
      actionRequired: false,
      detail: "",
      eventType: TYPE.EVENT,
      selectedRoles: this.props.roles.map(r => r.id),
    }
  }

  isValidForm = () => {
    if (!this.state.detail) {
      return false;
    }
    if (this.state.actionRequired && !this.state.selectedRoles.length) {
      return false;
    }
    return true;
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!this.isValidForm()) return;

    if (this.state.detail.length === 0) {
      return
    }

    const assigned_role_ids =
      !this.state.actionRequired ? null :
        this.state.selectedRoles.length === this.props.roles.length ? null :
          this.state.selectedRoles;

    const message = {
      type: this.state.eventType,
      content: this.state.detail,
      is_completeable: this.state.actionRequired,
      assigned_role_ids,
    };

    this.props.onSubmit(message);
  };

  preventInputClick = (e) => {
    e.preventDefault();
    e.stopPropagation()
  };

  onAllRolesSelected = (_, {checked}) => {
    this.setState({
      selectedRoles: checked ? this.props.roles.map(r => r.id) : []
    });
  };

  onRoleSelected = (_, {checked, value}) => {
    this.setState({
      selectedRoles: checked ? [
        ...this.state.selectedRoles,
        value
      ] : this.state.selectedRoles.filter(role => role !== value)
    });
  };

  handleDetailChange = (_, {value}) => this.setState({detail: value});
  handleEventTypeChange = (_, {value}) => {
    this.setState({
      eventType: value,
      detail: "",
      actionRequired: value === TYPE.EVENT ? false : true
    });
  };
  handleActionRequiredChange = (actionRequired) => this.setState({actionRequired: actionRequired});

  render = () => {

    const {
      loading,
      error,
      closeModal,
      notify,
      normalOnly = false,
      roles,
    } = this.props;

    const {
      eventType,
      actionRequired,
      selectedRoles
    } = this.state;

    return (
      <PromptBox>
        <h2>Add an {eventType} to {notify}</h2>
        <FormWrapped
          loading={loading}
          error={error}
          onSubmit={this.handleSubmit}>
          {
            !normalOnly &&
            <>
              <Form.Field>
                <label>Type of event</label>
                <Form.Group>
                  {this.eventTypes.map(event => (
                    <Form.Radio
                      inline
                      key={event.key}
                      label={event.text}
                      value={event.key}
                      checked={eventType === event.key}
                      onChange={this.handleEventTypeChange}/>
                  ))}
                </Form.Group>
              </Form.Field>
            </>
          }
          <Form.Field>
            <label>{eventType === TYPE.EVENT ? "Event Details" : "Reason for escalation"}</label>
            <Form.TextArea
              placeholder={'Enter ' + eventType + ' details'}
              value={this.state.detail}
              onChange={this.handleDetailChange}
            />
          </Form.Field>
          {
            eventType === TYPE.EVENT && !normalOnly &&
            <Form.Field>
              <label>Action required?</label>
              <Form.Group inline>
                <Form.Radio
                  label={"Complete"}
                  checked={actionRequired}
                  onChange={() => this.handleActionRequiredChange(true)}/>
                <Form.Radio
                  label={"None"}
                  checked={!actionRequired}
                  onChange={() => this.handleActionRequiredChange(false)}/>
              </Form.Group>
            </Form.Field>
          }
          {
            actionRequired &&
            <Form.Field>
              <label>Assign escalation to</label>
              <Dropdown
                className={"selection"}
                loading={loading}
                fluid
                clearable
                multiple
                text={selectedRoles.length === roles.length ? "All Roles Selected" : selectedRoles.length + " Roles Selected"}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={this.preventInputClick}>
                    <Checkbox
                      label='All Roles'
                      checked={selectedRoles.length === roles.length}
                      indeterminate={selectedRoles.length > 0 && selectedRoles.length < roles.length}
                      onChange={this.onAllRolesSelected}/>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={this.preventInputClick}>
                    <Grid columns={2}>
                      {
                        roles
                          .map(role => (
                            <Grid.Column key={role.id}>
                              <Checkbox
                                label={role.display_name}
                                checked={selectedRoles.indexOf(role.id) !== -1}
                                value={role.id}
                                onChange={this.onRoleSelected}/>
                            </Grid.Column>
                          ))
                      }
                    </Grid>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Field>
          }
        </FormWrapped>
        <ButtonSection>
          <Button
            primary
            fluid
            large
            disabled={loading || this.isValidForm() === false}
            onClick={this.handleSubmit}>
            Add Event
          </Button>
          <Button
            basic
            fluid
            large
            disabled={loading}
            onClick={closeModal}>
            Cancel
          </Button>
        </ButtonSection>
      </PromptBox>
    );
  }
}

export default withRoles(AddEventForm);
