import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/molecules/Modal';
import PromptBox from "../../../common/templates/PromptBox";
import Button from "../../../common/atoms/Button";
import Icon from "../../../common/atoms/Icon";
import {DOMESTIC, INTERNATIONAL, REGIONAL, DOMAIN_LABELS} from "../../../../constants/FlightDomainFilter";
import RadioSelect from '../../../common/molecules/RadioSelect';
import {GROUP} from '../../../../constants/User';
import {FilterForm, RadioWrapper, FilterSection, TimeWindowSelect} from './style';
import { useSnackbar } from 'notistack';
import updateUserViewMutation from "../../../../gql/update-user-view";
import { useMutation } from 'react-apollo';
import Divider from '../../../common/molecules/Divider';
import {ReactComponent as IconPortSettings} from "../../../../images/icons/port-settings.svg";

// Generate our dropdown options (numbers 1 - 24)
const timeOptions = [...Array(24).keys()]
  .map(n => n + 1)
  .map(n => ({ text: `${n}h`, value: n }));

const FilterSelectModal = ({userGroup, view}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const [domainOptions, setDomainOptions] = useState();
  const [lowerWindow, setLowerWindow] = useState();
  const [upperWindow, setUpperWindow] = useState();

  useEffect(() => {
    setDomainOptions(buildDomainFilterOptions(userGroup, view.domains || []));
    setLowerWindow(view.lower_window * -1);
    setUpperWindow(view.upper_window);
  }, [isModalOpen, userGroup, view.domains, view.lower_window, view.upper_window]);

  const {enqueueSnackbar} = useSnackbar();

  const [callUpdateUserView, { loading }] = useMutation(
    updateUserViewMutation,
    {
      onCompleted: () => {
        setModalOpen(false);
      },
      onError: () => {
        enqueueSnackbar('There was an error saving your view settings', {variant: 'error'})
      }
    }
  );

  return (
    <Modal
      isOpen={isModalOpen}
      onOpen={() => setModalOpen(true)}
      onClose={() => setModalOpen(false)}
      trigger={<Icon><IconPortSettings /></Icon>}
    >
      <PromptBox>
        <h2>Port Settings - {view.filter.airport_code}</h2>

        <FilterForm
          onSubmit={(e) => {
            e.preventDefault();

            callUpdateUserView({
              variables: {
                input: {
                  id: view.id,
                  lower_window: lowerWindow * -1,
                  upper_window: upperWindow,
                  domains: domainOptions.filter(o => o.selected).map(o => o.value),
                },
              }
            });
          }}>
          <FilterSection>
            <h3>Time Window</h3>

            <TimeWindowSelect
              defaultValue={lowerWindow}
              options={timeOptions}
              onChange={(_, { value }) => setLowerWindow(value)}/>
            <label><strong>After</strong> Departure/Arrival</label>

            <Divider head="And" />

            <TimeWindowSelect
              defaultValue={upperWindow}
              options={timeOptions}
              onChange={(_, { value }) => setUpperWindow(value)}
            />
            <label><strong>Before</strong> Departure/Arrival</label>
          </FilterSection>

          {
            domainOptions?.length > 0 &&
            <FilterSection>
              <Divider />
              <h3>Network Filter</h3>
              <span>Show only flights within</span>

              <RadioWrapper>
                <RadioSelect
                  options={domainOptions}
                  onChange={(options) => setDomainOptions(options)}
                />
              </RadioWrapper>
            </FilterSection>
          }

          <Button
            fluid
            large
            primary
            loading={loading}
            type="submit"
          >
            Save Settings
          </Button>

          <Button
            fluid
            large
            basic
            onClick={() => setModalOpen(false)}
          >
            Close
          </Button>
        </FilterForm>
      </PromptBox>
    </Modal>
  );
};

FilterSelectModal.propTypes = {
  userGroup: PropTypes.oneOf(Object.values(GROUP)).isRequired,
  selectedDomains: PropTypes.arrayOf(PropTypes.oneOf([INTERNATIONAL, DOMESTIC, REGIONAL])).isRequired,
  onSave: PropTypes.func.isRequired,
};

function buildDomainFilterOptions(userGroup, selectedDomains) {
  const availableValues = [];
  if (userGroup === GROUP.MAINLINE || userGroup === GROUP.ALL) {
    availableValues.push(INTERNATIONAL, DOMESTIC);
  }
  if (userGroup === GROUP.ALL) {
    availableValues.push(REGIONAL);
  }

  return availableValues.map(value => ({
    value,
    label: DOMAIN_LABELS[value],
    selected: selectedDomains.includes(value),
  }));
}

export default FilterSelectModal;
