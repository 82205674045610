import styled from 'styled-components';
import FlexSection from "../../../common/atoms/FlexSection";
import {ButtonWrapper} from "../../../common/atoms/Button/style";

export const ReportHead = styled.h3`
  
`;

export const ReportBody = styled(FlexSection)`
  flex-direction: column;
  
  width: 100%;
  
  ${ButtonWrapper} + ${ButtonWrapper} {
   margin-top: ${props => props.theme.space1}
  } 
`;

export const MenuHeader = styled(FlexSection)`

`;
