import PropTypes from "prop-types";

export const PERMISSION = {
  terminalEvent: "terminalEvent",
  scheduleChat: "scheduleChat",
  ptsAboveWing: "ptsAboveWing",
  pil: "pil",
  boardingStatus: "boardingStatus",
  connectFlight: "connectFlight",
  aircraftEngineeringStatus: "aircraftEngineeringStatus",
  loadControl: "loadControl",
  departureReport: "departureReport",
  networkMap: "networkMap",
};

export const ACCESS_LEVEL = {
  BASIC: 1,
  SENSITIVE: 2,
  ADMIN: 3,
};


export const PermissionType = PropTypes.shape({
  permission: PropTypes.string,
  display_name: PropTypes.string,
  max_access_level: PropTypes.number,
});

export const BasePermissionType = PermissionType;
