import React from 'react';
import {ReactComponent as IconError} from "../../../images/error.svg";
import Button from "../atoms/Button"
import Page from "../atoms/Page";
import {ErrorMessage} from "./style";

const ErrorPage = ({title, message, hideRefresh}) =>
  <Page>
    <IconError/>
    <h1>{title}</h1>
    <ErrorMessage>{message}</ErrorMessage>
    {
      !hideRefresh &&
      <>
        <Button primary
                onClick={() => window.location.reload()}
        >
          Refresh
        </Button>
      </>
    }
  </Page>;

export default ErrorPage;
