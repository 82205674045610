import React, {Component} from 'react';
import Button from "../../../components/common/atoms/Button";
import MutatedDeleteNotice from "./MutatedDeleteNotice";
import Modal from "../../common/molecules/Modal";

class HoverMenuDeleteButton extends Component {

  state = {
    showModal: false,
  };

  render = () => {

    const {
      notice,
      offset,
    } = this.props;

    return (
      <Modal
        isOpen={this.state.showModal}
        onOpen={this.openModal}
        onClose={this.closeModal}
        closeOnDimmerClick={false}
        trigger={
          <Button
            basic
            small>
            Delete
          </Button>
        }>
        <MutatedDeleteNotice
          offset={offset}
          notice={notice}
          closeModal={this.closeModal} />
      </Modal>
    )
  };

  openModal = () => this.setState({ showModal: true });

  closeModal = () => this.setState({ showModal: false });
}

export default HoverMenuDeleteButton;
