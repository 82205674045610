import React from "react";

import {ReactComponent as IconFlight} from "../../../../../images/icons/flight.svg";
import {ReactComponent as IconEngineering} from "../../../../../images/icons/flight-menu-engineering.svg";
import {ReactComponent as CollapseIcon} from "../../../../../images/icons/collapse.svg";
import {
  VIEW_ARRIVAL_CHANNELS,
  VIEW_DEPARTURE_CHANNELS,
  VIEW_ENGINEERING_RISK,
  VIEW_FLIGHT_DETAILS,
  VIEW_LOAD_CONTROL,
} from "../../../../../constants/FlightDetailMenuTabs"
import {withLocalUserData} from "../../../../../hocs/withLocalUserData";
import Divider from "../../../../common/molecules/Divider";
import {CollapseButton, ContextMenuWrapper, SectionTitle} from "./style";
import ContextMenuButton from "./ContextMenuButton";
import Icon from "../../../../common/atoms/Icon";
import {ACCESS_LEVEL, PERMISSION} from "../../../../../constants/Permission";
import NoticeChannelContextMenuButton from "./NoticeChannelContextMenuButton";
import {hasPermission} from "../../../../../utils/permission";
import {Link} from "react-router-dom";

class ContextMenu extends React.Component {

  state = {
    collapsed: false,
  };

  render() {
    const {
      selectedView,
      terminalPath,
      user,
      departureNoticeChannelId,
      arrivalNoticeChannelId,
      flight,
    } = this.props;

    const scheduleEnabled = hasPermission(user, PERMISSION.scheduleChat, ACCESS_LEVEL.BASIC);
    // const pilEnabled = hasPermission(user, PERMISSION.pil, ACCESS_LEVEL.BASIC);
    const engineeringEnabled = hasPermission(user, PERMISSION.aircraftEngineeringStatus, ACCESS_LEVEL.BASIC);
    const loadEnabled = hasPermission(user, PERMISSION.loadControl, ACCESS_LEVEL.BASIC);
    const terminalEventsEnabled = hasPermission(user, PERMISSION.terminalEvent, ACCESS_LEVEL.BASIC);

    return (
      <ContextMenuWrapper collapsed={this.state.collapsed}>
        <SectionTitle>Flight</SectionTitle>
        {
          scheduleEnabled &&
          <ContextMenuButton
            selected={selectedView === VIEW_FLIGHT_DETAILS}>
            <Link to={`${terminalPath}/${flight.id}/${VIEW_FLIGHT_DETAILS}`}>
              <Icon>
                <IconFlight/>
              </Icon>
            </Link>
          </ContextMenuButton>
        }
        {/* {
          pilEnabled &&
          <ContextMenuButton
            selected={selectedView === VIEW_PASSENGERS_LIST}>
            <Link to={`${terminalPath}/${flight.id}/${VIEW_PASSENGERS_LIST}`}>
              <Icon>
                <IconPassenger/>
              </Icon>
            </Link>
          </ContextMenuButton>
        } */}
        {
          engineeringEnabled &&
          <ContextMenuButton
            selected={selectedView === VIEW_ENGINEERING_RISK}>
            <Link to={`${terminalPath}/${flight.id}/${VIEW_ENGINEERING_RISK}`}>
              <Icon>
                <IconEngineering/>
              </Icon>
            </Link>
          </ContextMenuButton>
        }
        {
          loadEnabled &&
          <ContextMenuButton
            selected={selectedView === VIEW_LOAD_CONTROL}>
            <Link to={`${terminalPath}/${flight.id}/${VIEW_LOAD_CONTROL}`}>
              <Icon>
                LC
              </Icon>
            </Link>
          </ContextMenuButton>
        }
        {
          terminalEventsEnabled &&
          <>
            <Divider/>
            <SectionTitle>Port</SectionTitle>
            <NoticeChannelContextMenuButton
              noticeChannelId={departureNoticeChannelId}
              selected={selectedView === VIEW_DEPARTURE_CHANNELS}>
              <Link to={`${terminalPath}/${flight.id}/${VIEW_DEPARTURE_CHANNELS}`}>
                <Icon>
                  {flight.departure_airport_code}
                </Icon>
              </Link>
            </NoticeChannelContextMenuButton>
            <NoticeChannelContextMenuButton
              noticeChannelId={arrivalNoticeChannelId}
              selected={selectedView === VIEW_ARRIVAL_CHANNELS}>
              <Link to={`${terminalPath}/${flight.id}/${VIEW_ARRIVAL_CHANNELS}`}>
                <Icon>
                  {flight.arrival_airport_code}
                </Icon>
              </Link>
            </NoticeChannelContextMenuButton>
          </>
        }
        <CollapseButton collapsed={this.state.collapsed}
                        onClick={() => this.setState({collapsed: !this.state.collapsed})}>
          <Icon>
            <CollapseIcon/>
          </Icon>
        </CollapseButton>
      </ContextMenuWrapper>
    );
  }

}

export default withLocalUserData(ContextMenu);
