import styled from 'styled-components';
export const OFFSET = 94;
export const Timeline = styled.div`
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent calc(${OFFSET}px - 2.51px),
    ${props => props.theme.colors.divider} calc(${OFFSET}px - 2.5px),
    ${props => props.theme.colors.divider} calc(${OFFSET}px + 2.5px),
    transparent calc(${OFFSET}px + 2.51px),
    transparent 100%);
  background-color: ${props => props.theme.colors.backgroundGrey};
`;

export default Timeline;
