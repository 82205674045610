import React from 'react';
import {getLocalTime} from "../../../../utils/flight-utils";
import {TimestampWrapper} from "./style";
import PropTypes from "prop-types";
import NoticeType from "../../../../constants/Notice";

const Timestamp = React.memo(({notice, offset}) => {
  const timestamp = notice.completed_at ? notice.completed_at : notice.created_at; // Deleted or not, always follow completed_at time.
  return (
    <TimestampWrapper>
      {
        getLocalTime(timestamp, offset)
      }
    </TimestampWrapper>
  );
});

Timestamp.propTypes = {
  notice: NoticeType,
  offset: PropTypes.number.isRequired,
};

export default Timestamp;
