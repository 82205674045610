import React from 'react';
import {Bags, BoardingStatus, FullName, PaxTableBody, PaxTableRow, PaxTableSection, SeatNumber} from "./style";
import PropTypes from "prop-types";
import NotificationSection from "../../../../../common/molecules/NotificationSection";
import PassengerType from "../../../../../../constants/Passenger";
import {useMediaQuery} from "react-responsive";
import device from "../../../../../../constants/Device";

const PaxTable = ({passengerList, isCheckedIn}) =>
  <PaxTableSection>
    {
      passengerList
        .filter(passenger => passenger.isCheckedIn === isCheckedIn)
        .length > 0 ?
        <PaxTableBody>
          {
            passengerList
              .filter(passenger => passenger.isCheckedIn === isCheckedIn)
              .map(passenger => <PassengerRow key={passenger.id}
                                              passenger={passenger}/>)
          }
        </PaxTableBody> : (
          // isCheckedIn ?
          //   <NotificationSection icon={<IconBoarded/>}
          //                        head={"All passengers boarded"}/> :
          <NotificationSection body={"No passengers found matching this criteria."}/>
        )

    }
  </PaxTableSection>;

PaxTable.propTypes = {
  passengerList: PropTypes.array,
  isCheckedIn: PropTypes.bool.isRequired,
};


const PassengerRow = ({passenger}) => {

  const isLaptop = useMediaQuery({query: device.laptop});
  const boarded = isLaptop ? "Boarded" : "BDD";
  const notBoarded = isLaptop ? "Not Boarded" : "NBD";
  const notCheckedIn = isLaptop ? "Not Checked-In" : "";

  return (
    <PaxTableRow>
      <SeatNumber status={passenger.qfTierCode}>{passenger.seatNumber || ""}</SeatNumber>
      <FullName>{passenger.namePrefix} {passenger.firstName} {passenger.lastName}</FullName>
      <BoardingStatus>{passenger.isBoarded ? boarded : (passenger.isCheckedIn ? notBoarded : notCheckedIn)}</BoardingStatus>
      <Bags>{passenger.baggageCount}BG</Bags>
    </PaxTableRow>
  )
};

PassengerRow.propTypes = {
  passenger: PassengerType,
};

export default PaxTable;
