import React from 'react';
import {ConnectionHead, PaxFlightNumber} from "./style";
import FlightNumber from "../../../../../../common/atoms/FlightNumber";
import {ReactComponent as IconConnectionArrow} from "../../../../../../../images/icons/connection-arrow.svg";
import {getLocalTime} from "../../../../../../../utils/flight-utils";

export default ({inboundFlight, outboundFlight}) =>
  <PaxFlightNumber>
    <ConnectionHead>
      {
        inboundFlight &&
        <>
          <div>
            <FlightNumber flight={inboundFlight}/>
            <span>{inboundFlight.departure_airport_code} {inboundFlight.gate && <span>(GATE {inboundFlight.gate})</span>}</span>
          </div>
          <IconConnectionArrow/>
        </>
      }
      <div>
        <FlightNumber flight={outboundFlight}/>
        {
          inboundFlight &&
          <span>{getLocalTime(inboundFlight.onblocks_time || inboundFlight.arrival_scheduled_time, outboundFlight.departure_airport.timezone_offset)} ({inboundFlight.connectionMinutes}m)</span>
        }
      </div>
    </ConnectionHead>
  </PaxFlightNumber>
