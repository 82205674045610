import PropTypes from "prop-types";

export const FREQUENT_FLYER_STATUS = {
  CLPO: 'CLPO',
  CLQF: 'CLQF',
  FFPO: 'FFPO',
  FFPL: 'FFPL',
  FFGD: 'FFGD',
  QCGD: 'QCGD',
  FFSL: 'FFSL',
  QCSL: 'QCSL',
  FFBR: 'FFBR',
  QCBR: 'QCBR',
};

export const FREQUENT_FLYER_STATUS_RANKING = [
  FREQUENT_FLYER_STATUS.CLPO,
  FREQUENT_FLYER_STATUS.CLQF,
  FREQUENT_FLYER_STATUS.FFPO,
  FREQUENT_FLYER_STATUS.FFPL,
  FREQUENT_FLYER_STATUS.FFGD,
  FREQUENT_FLYER_STATUS.QCGD,
  FREQUENT_FLYER_STATUS.FFSL,
  FREQUENT_FLYER_STATUS.QCSL,
  FREQUENT_FLYER_STATUS.FFBR,
  FREQUENT_FLYER_STATUS.QCBR,
];

export const FILTER = {
  WHEELCHAIR: 'WHEELCHAIR',
  MINOR: 'MINOR',
  MEDICAL: 'MEDICAL',
  CHAIRMAN_LOUNGE: 'CHAIRMAN_LOUNGE',
  PLATINUM_ONE: 'PLATINUM_ONE',
  PLATINUM: 'PLATINUM',
  GOLD: 'GOLD',
};

const PassengerType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  namePrefix: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  qfTierCode: PropTypes.oneOf(Object.values(FREQUENT_FLYER_STATUS)),
  isBoarded: PropTypes.bool.isRequired,
  isCheckedIn: PropTypes.bool.isRequired,
  isSsrWheelchair: PropTypes.bool.isRequired,
  isSsrUnattendedMinor: PropTypes.bool.isRequired,
  isSsrMedical: PropTypes.bool.isRequired,
  baggageCount: PropTypes.number.isRequired,
  seatNumber: PropTypes.string,
});

export default PassengerType;
