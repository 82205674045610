import styled, {css} from "styled-components";
import FlexSection from "../../atoms/FlexSection";

export const ConnectionHead = styled(FlexSection)`
  justify-content: space-between;
`;

export const FlightNumber = styled.div`
  /* Font */
  font-family: ${props => props.theme.fontImportant};
  font-size: ${props => props.theme.fontSizeLarge};
  /* Spacing */
  padding: ${props => props.theme.spaceSmall}
`;

export const ConnectionBody = styled(FlexSection)`
  justify-content: space-between;

  width: 320px;
    
  ${props => props.isVertical && css`
    width: 160px;
  `};
`;
