import gql from 'graphql-tag';

export default gql`
  query GetAirportFlightSummary($code: String!, $airline_group: FlightGroupEnum) {
    getAirportFlightSummary(
      code: $code,
      airline_group: $airline_group
      lower_window: -12
      upper_window: 18
    ) {
      code
      city
      delayCount
      cancelledCount
      aogCount
    }
  }
`;
