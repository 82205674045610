// Taken from https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-networkStatus
export const NETWORK_STATUS = {
  LOADING: 1,
  SET_VARIABLES: 2,
  STATUS_FETCH_MORE: 3,
  STATUS_REFETCH: 4,
  // 5 is unused
  STATUS_POLL: 6,
  STATUS_READY: 7,
  STATUS_ERROR: 8,
};

export const REFRESH_INTERVAL = {
  INBOUND_CONNECION_REFRESH_INTERVAL: 1000 * 60 * 5,
  PAX_REFRESH_INTERVAL: 1000 * 10,
  BOARDING_REFRESH_INTERVAL: 1000 * 5,
  SUMMARY_REFRESH_INTERVAL: 1000 * 60 * 5,
};

export const NETWORK_MAP = {
  REFRESH_INTERVAL: 1000 * 10,
};
