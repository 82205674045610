import PropTypes from "prop-types";
import MessageType from "./Notice";

export const TYPE = {
  PRIMARY: "PRIMARY",
  SYSTEM: "SYSTEM",
  TOPIC: "TOPIC",
  TERMINAL: "TERMINAL",
};

export const NAME = {
  SCHEDULE: "Schedule",
  CUSTOMER: "Customer",
  ENGINEERING: "Engineering",
  LOAD_CONTROL: "Load Control",
};

const ChannelType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  channelType: PropTypes.oneOf(Object.values(TYPE)).isRequired,
  messages: PropTypes.shape({
    nodes: PropTypes.arrayOf(MessageType).isRequired,
  }).isRequired,
  user_last_viewed: PropTypes.string,
});

export default ChannelType;
