import styled from 'styled-components';

export const SearchInputWrapper = styled.div`

  display: flex;
  align-items: center;

  padding: ${props => props.theme.space2};

  background: ${props => props.theme.colors.backgroundGrey};
  border-radius: ${props => props.theme.cornerRadius};

  svg {
    fill: ${props => props.isActive ? props.theme.colors.body : props.theme.colors.grey}
  }

  input {
    border: none;
    background: transparent;
    padding-right: 45px; /* Space for the button to show */

    font-family: ${props => props.theme.fontRegular};
    font-size: ${props => props.theme.fontSizeMedium};

    :focus {
      outline: none;
      box-shadow: none;
    }
  }

  button {
    position: absolute;
    right: ${props => props.theme.space2};

    background: none;
    outline: none;
    box-shadow: none;

    font-family: ${props => props.theme.fontRegular};
    font-size: ${props => props.theme.fontSizeMedium};

    border: none;

    user-select: none;
  }
`;
