import React from "react";
import {Query} from "react-apollo";

import {getFlightDepartureReport} from "../gql/fragments/departure-report";
import ErrorPage from "../components/common/ErrorPage";
import LoadingPage from "../components/common/molecules/LoadingPage";
import {NETWORK_STATUS} from "../constants/Apollo";

export function withFlightDepartureReport(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <Query query={getFlightDepartureReport} variables={{ flight_id: this.props.flight.id }} fetchPolicy="network-only" errorPolicy="all">
          {({networkStatus, error, data = {}}) => {
          if (error) {
            console.error('withFlightDepartureReport', error);
            return (
                <ErrorPage
                    title="Error loading departure report"
                    message="There was a problem loading the saved departure report" />
            );
          }

          // Don't show loading on polls/fetchMore's
          const loading = networkStatus <= NETWORK_STATUS.SET_VARIABLES;
          if (loading) {
            return <LoadingPage/>;
          }

          return (
            <WrappedComponent
                {...this.props}
                departureReport={data.getFlightDepartureReport}
            />
          );
        }}
        </Query>
      );
    }
  }
}
