import gql from 'graphql-tag';

export default gql`
  mutation UpdateUserView($input: UpdateUserViewInput!) {
    updateUserView(input: $input) {
      id
      view_type
      filter {
        airport_code
      }
      lower_window
      upper_window
      domains
    }
  }`;
