import styled from "styled-components";
import FlexSection from "../FlexSection";
import device from "../../../../constants/Device";

export const FlightNumberWrapper = styled(FlexSection)`
  
  svg {
    margin: ${props => props.theme.space1};
  }
  
  h3 {
    margin: 0;
    font-family: ${props => props.theme.fontImportant};
    font-weight: normal;
  }
  
  /* Mobile Responsive */
  width: 64px; /* Must-have for iPad-Mini Portrait mode */
  @media ${device.tablet} {
   width: 80px; /* Must-have for iPad-Mini LandScape mode */
  }
`;

export const DesktopIconWrapper = styled.div`
  
  /* Mobile Responsive */
  display: none;
  @media ${device.tablet} {
    display: inline-block;
  }
`;
