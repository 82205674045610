import styled from "styled-components";
import FlexSection from "../../../common/atoms/FlexSection";
import device from "../../../../constants/Device";

export const ListHeadWrapper = styled(FlexSection)`
  flex-shrink: 0;
  margin: 0 10px;
  padding: 8px 5px;
  line-height: 1em;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fontSizeSmall};
  font-family: ${props => props.theme.fontImportant};
  text-transform: uppercase;
  
  /* Mobile Responsive */
  display: none;
  @media ${device.laptop} {
    display: flex;
  }
`;
