import PropTypes from "prop-types";
import UserType from "./User";
import moment from "moment";

export const TYPE = {
  PTS: "PTS",
  INFO: "INFO",
  CHAT: "CHAT",
  EVENT: "EVENT",
  ESCALATION: "ESCALATION",
  DEPARTURE_REPORT: "DEPARTURE_REPORT",
};

const NoticeType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  notice_channel_id: PropTypes.number.isRequired,
  messageType: PropTypes.oneOf(Object.values(TYPE)),
  content: PropTypes.string.isRequired,
  created_at: function (props, propName) {
    return (!props[propName] || moment(props[propName]).isValid()) ? null : Error(`Invalid Date String for '${propName}'`);
  },
  created_by: UserType,
  deleted_at: function (props, propName) {
    return (!props[propName] || moment(props[propName]).isValid()) ? null : Error(`Invalid Date String for '${propName}'`);
  },
  deleted_by: UserType,
  is_completeable: PropTypes.bool.isRequired,
  completed_at: function (props, propName) {
    return (!props[propName] || moment(props[propName]).isValid()) ? null : Error(`Invalid Date String for '${propName}'`);
  },
  completed_reason: PropTypes.string,
  completed_skipped: PropTypes.bool.isRequired,
  completes_pts_workflow_status: PropTypes.string,
  completed_by: UserType,
  assigned_role_ids: PropTypes.array,
});

export default NoticeType;
