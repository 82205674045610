import React from 'react'
import Button from "../../common/atoms/Button";
import styles from "../PromptForm.module.css"
import sectionStyles from '../NoticeSection.module.css';
import Message from "../message/list/Message/index.js";
import Event from "../event/list/Event";
import PromptBox from "../../common/templates/PromptBox";
import {TYPE} from "../../../constants/Notice";

export default React.memo((props) => {
  const {
    notice,
    offset,
    closeModal,
    loading,
    onConfirm
  } = props;

  return (
    <PromptBox>
      <div className={styles.center}>
        <h2>Delete {notice.messageType === TYPE.CHAT ? "Message" : notice.messageType}</h2>
        <div>
          Delete this {notice.messageType === TYPE.CHAT ? "Message" : notice.messageType}? You can't undo this action.
        </div>
      </div>
      <div
        className={`${sectionStyles.section} ${styles.noticeSection} ${notice.messageType === TYPE.CHAT ? "" : styles.eventline}`}>
        {
          notice.messageType === TYPE.CHAT &&
          <Message
            disableDelta={true}
            disableHover={true}
            message={notice}
            terminalOffset={offset}/>
        }
        {
          notice.messageType === TYPE.EVENT &&
          <>
            <div className={`${styles.dot} ${styles.black}`}/>
            <Event
              disableDelta={false}
              disableHover={true}
              disableLabel={false}
              offset={offset}
              event={notice}/>
          </>
        }
        {
          notice.messageType === TYPE.ESCALATION &&
          <>
            <div className={`${styles.dot} ${styles.red}`}/>
            <Event
              disableDelta={false}
              disableHover={true}
              disableLabel={false}
              offset={offset}
              event={notice}/>
          </>
        }
      </div>
      <Button
        fluid
        primary
        large
        onClick={() => onConfirm(notice)}
        loading={loading}>
        Delete
      </Button>
      <Button
        fluid
        basic
        large
        onClick={() => closeModal()}
        disabled={loading}>
        Cancel
      </Button>
    </PromptBox>
  )
});
