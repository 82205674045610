import { includeMessageInChatChannel } from "./utils/include-message";

export default (
  prev,
  {
    subscriptionData: { 
      data: { 
        noticeEvent 
      }
    }
  }
) => {
  return {
    noticeChannel: includeMessageInChatChannel(prev.noticeChannel, noticeEvent)
  };
}
