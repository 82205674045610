import styled from "styled-components";
import device from "../../../../../../constants/Device";
import {TERMINAL} from "../../../../../../constants/Layer";
import LinkButton from "../../../../../common/atoms/LinkButton";

export const DesktopMenuWrapper = styled.div`
  
  /* Mobile Responsive */
  display: none;
  @media ${device.laptop} {
    display: block;
  }
  
  z-index: ${TERMINAL.MENU};
`;

export const AddLinkButton = styled(LinkButton)`
  font-family: ${props => props.theme.fontImportant};
  font-size: ${props => props.theme.fontSizeMedium};
  font-weight: normal;
  
  padding-top: ${props => props.theme.space2};
`;
