import React from 'react';
import {AuthorWrapper, Name, Role} from "./style";
import Label from "../../atoms/Label";
import PropTypes from "prop-types";
import UserType from "../../../../constants/User";

const Author = React.memo(({author, showTerminal}) =>
  <AuthorWrapper>
    {
      author.role &&
      <Role>{author.role && author.role.display_name}</Role>
    }
    {
      author.name &&
      <Name>{author.name}</Name>
    }
    {
      (author.terminal && showTerminal) &&
      <Label>{author.terminal}</Label>
    }
  </AuthorWrapper>
);

Author.defaultProps = {
  showTerminal: false,
};
Author.propTypes = {
  author: UserType,
  showTerminal: PropTypes.bool,
};

export default Author;
