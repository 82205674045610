import styled, {css} from "styled-components";
import FlexSection from "../../atoms/FlexSection";
import device from "../../../../constants/Device";

export const AccordionWrapper = styled.div`
  /* Display */
  display: block;
`;

export const AccordionHead = styled(FlexSection)`
  /* Background */
  background: white;
  /* Text */
  text-transform: uppercase;

  cursor: pointer;

  /* Mobile Responsive */
  /* Spacing */
  padding: 0 ${props => props.theme.space2};
  height: ${props => props.theme.size8};
  @media ${device.tablet} {
    /* Spacing */
    padding: ${props => props.theme.space3} ${props => props.theme.space2} 0 ${props => props.theme.space2};
    height: ${props => props.theme.size11};
  }
`;

export const AccordionHeadIcon = styled.div`
  width: 40px;
  height: 32px;
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
`;

export const AccordionHeadContentWrapper = styled.div`
  flex-grow: 1;
`;

export const AccordionHeadContent = styled.div`
  line-height: ${props => props.theme.size8};
`;

export const AccordionBody = styled.div`
  /* Background */
  background: ${props => props.theme.colors.background};
`;

export const AccordionIcon = styled.div`

    /* Display */
    align-items: end;

    /* Spacing */
    padding: ${props => props.theme.space2};

    svg {
      ${props => !props.collapsed && css`
        transform: rotate(180deg);
      `}
    }
`;
