import React from 'react';
import {UnserviceableFlightWrapper} from "./style";
import FlightType from "../../../../../../constants/Flight";
import {ButtonColumn, DataColumn} from "../../../NetworkSummary/style";
import {getExtraStatus, getFlightNumber, getLocalTime, getMinutesLate} from "../../../../../../utils/flight-utils";
import moment from "moment";
import InfoLabel from "../../../../../common/molecules/InfoLabel";
import EngineeringStatus from "../../../../../common/molecules/EngineeringStatus";
import {ReactComponent as IconRight} from "../../../../../../images/icons/arrow-right.svg";
import {FlightLink} from "../../../style";
import {AIRPORT} from "../../../../../../constants/Routes";

const UnserviceableFlight = ({flight}) => {

  const std = moment.utc(flight.departure_scheduled_time);
  const ofb = moment.utc(flight.offblocks_time);

  const depDelay = getMinutesLate(std, ofb);

  return (
    <FlightLink to={`/${AIRPORT}/${flight.departure_airport_code}/dep/${flight.id}`} target="_blank">
      <UnserviceableFlightWrapper>
        <DataColumn>
          <InfoLabel
            label={flight.aircraft_registration}
            text={getFlightNumber(flight)}/>
        </DataColumn>
        <DataColumn>
          <InfoLabel
            label={`${flight.departure_airport_code} - ${flight.arrival_airport_code}`}
            text={`${getLocalTime(ofb, flight.departure_airport.timezone_offset)} ${getExtraStatus(depDelay)}`}/>
        </DataColumn>
        <DataColumn>
          <InfoLabel
            label={"Gate"}
            text={flight.departure_gate}/>
        </DataColumn>
        <DataColumn>
          <EngineeringStatus status={flight.engineering_status}/>
        </DataColumn>
        <ButtonColumn>
          <IconRight/>
        </ButtonColumn>
      </UnserviceableFlightWrapper>
    </FlightLink>
  );
};

UnserviceableFlight.propTypes = {
  flight: FlightType.isRequired,
};

export default UnserviceableFlight;
