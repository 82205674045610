import gql from 'graphql-tag';

import messagePageFragment from './fragments/message-page';

export default gql`
  ${messagePageFragment}

  query loadMoreMessages($cursor: String!) {
    getMessages(cursor: $cursor) {
      ...MessagesPageFields
      notice_channel_id
    }
  }
`;
