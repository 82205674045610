import React from "react";
import {Query} from "react-apollo";

import userQuery from "../gql/get-user";
import {NETWORK_STATUS} from "../constants/Apollo";
import LoadingPage from "../components/common/molecules/LoadingPage";
import ErrorPage from "../components/common/ErrorPage";
import Auth from "@aws-amplify/auth";

export const withNetworkUserData = (WrappedComponent) => {
  return class extends React.Component {

    redirectNotEnabled = async () => {
      await Auth.signOut();
      window.location = '/login#notenabled';
    };

    render() {
      return (
        <Query query={userQuery} pollInterval={30 * 1000} fetch-policy="network-only">
          {({networkStatus, error, data = {}}) => {
            if (error) {
              console.error('Failed loading user query', error);

              if (error.message && error.message.indexOf('Received status code 403') !== -1) {
                this.redirectNotEnabled();
                return <></>
              }
            }

            // Don't show loading on polls/fetchMore's
            const loading = networkStatus <= NETWORK_STATUS.SET_VARIABLES;
            if (loading) {
              return <LoadingPage/>;
            }

            if (!data || !data.user) {
              return (
                <ErrorPage
                  title={"Something went wrong"}
                  message={"Error loading user data"}/>
              );
            }

            return <WrappedComponent {...this.props} user={data.user}/>;
          }}
        </Query>
      );
    }
  }
};
