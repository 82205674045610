import styled, { css } from "styled-components";

export const CardWrapper = styled.div`
  /* Display */
  display: inline-block;
  /* Background */
  background: white;
  /* Border */
  border-radius: ${props => props.theme.space1};
  box-shadow: 0 ${props => props.theme.sizeExtraSmall} ${props => props.theme.size1} 0 rgba(0,0,0,0.15);

  & + & {
    margin-left: ${props => props.theme.space3};
  }

  ${props => props.onClick ? css`
    :hover {
      cursor: pointer;
    }
  ` : ''}
`;

export const CardHeader = styled.div`
  /* Spacing */
  padding: ${props => props.theme.space2} ${props => props.theme.space3};
`;

export const CardBody = styled.div`
  /* Spacing */
  padding: ${props => props.theme.space2} ${props => props.theme.space3};
`;
