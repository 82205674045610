import styled, {css} from 'styled-components';
import FlexSection from "../../atoms/FlexSection";
import device from "../../../../constants/Device";
import {MODAL} from "../../../../constants/Layer";

export const ModalBackground = styled(FlexSection)`
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  z-index: ${MODAL.BACKGROUND};
  display: none;
  background: rgba(0, 0, 0, 0.6);
  
  ${props => props.isOpen && css`
    display: inherit;
    color: ${props => props.theme.colors.body};
  `};
`;

export const ModalWrapper = styled(FlexSection)`
  position:fixed;
  background: white;
  flex-direction: column;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  
  /* Mobile Responsive */
  width: 95%;
  @media ${device.tablet} {
    width: auto;
    min-width: 480px;
  }
  
  /* Height */
  max-height: 100%;
  overflow-y: auto;
  
  z-index: ${MODAL.CONTENT};
    
    
  /* Font TODO: Same as QantasGlobalStyle, Consider moving this into a Mixin*/
  font-family: ${props => props.theme.fontRegular};
  color: ${props => props.theme.colors.black};

  /* Mobile Responsive TODO: Same as QantasGlobalStyle, Consider moving this into a Mixin*/
  font-size: ${props => props.theme.fontSizeMedium};
  
  ${props => props.fixed && css`
    top: 400px;
    height:700px;
    @media ${device.tablet} {
      width: auto;
      min-width: 640px;
    }
  `};
`;

export const ModalHead = styled(FlexSection)`
`;

export const ModalBody = styled(FlexSection)`
  width: 100%;
`;

