import styled, {css} from 'styled-components';

export const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
  // Hide checkbox visually but remain accessible to screen readers.
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: ${props => props.theme.colors.body};
  stroke-width: ${props => props.theme.size1};
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: ${props => props.theme.size5};
  height: ${props => props.theme.size5};
  background: white;
  border:  ${props => props.theme.borderWidth} solid  ${props => props.theme.colors.lightGrey};
  transition: all 150ms;

  ${props => !props.readonly ? css`
    ${HiddenCheckbox}:focus + & {
      border: ${props => props.theme.borderWidth} solid  ${props => props.theme.colors.teal}
    }
  ` : ''}

  ${Icon} {
    visibility: ${props => props.checked ? 'visible' : 'hidden'}
  }

  ${props => props.disabled ? css`
    opacity: 0.3;
  ` : ''}

  ${props => !props.disabled && !props.readonly ? css`
    cursor: pointer;
  `: ''}
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
