import styled, {css} from "styled-components";
import FlexSection from "../../atoms/FlexSection";
import {CheckboxContainer} from "../../atoms/Checkbox/style";
import device from "../../../../constants/Device";

export const FormWrapper = styled.form`
  width: 100%;
`;

export const FormSection = styled(FlexSection)`
  padding: ${props => props.theme.size7} 0;

  flex-direction: column;
  align-items: flex-start;
`;

export const FormRow = styled(FlexSection)`
  /* Spacing */
  & + & {
    padding-top: ${props => props.theme.space4};
  }

  width: 100%;

  ${props => props.packed && css`
    width: initial;
  `};
`;

export const InputGroup = styled(FlexSection)`
  flex-direction: column;
  align-items: flex-start;
  
  font-family: ${props => props.theme.fontRegular};

  ${props => props.horizontal && css`
    flex-direction: row;
  `};

  /* Spacing */
  & + & {
    margin-left: ${props => props.theme.space2};
    /* Mobile Responsive */
    @media ${device.tablet} {
      margin-left: ${props => props.theme.space5};
    }
  }

  width: 100%;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;

  margin-bottom: ${props => props.theme.space1};

  span {
    flex-grow: 1;
  }

  ${CheckboxContainer} + span {
    margin: 0 ${props => props.theme.space5};
  }

  width: 100%;
`;

export const Input = styled.input`

  color: ${props => props.theme.colors.grey};

  border: ${props => props.theme.borderWidth} solid ${props => props.theme.colors.lightGrey};

  padding: ${props => props.theme.space3};

  :focus {
    outline: none !important;
    border:${props => props.theme.borderWidth} solid ${props => props.theme.colors.teal};
    box-shadow: 0 0 ${props => props.theme.size2} ${props => props.theme.colors.teal};
  }
  
  ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
    -webkit-appearance: none; 
    margin: 0; 
  }

  ${props => props.disabled && css`
    background: ${props => props.theme.colors.background};
  `};
  
  /* Mobile Responsive */
  width: 64px;
  min-width: 64px;

  ${props => props.narrow && css`
    width: 55px;
  `};

  ${props => props.wide && css`
    width: 100px;
  `};

  @media ${device.tablet} {
    width: 100px;
  
    ${props => props.narrow && css`
      width: 70px;
    `};
  
    ${props => props.wide && css`
      width: 200px;
    `};

    // TODO fix duplication
    ${props => props.responsive && css`
      width: 100%;
    `}
  }
  
  // TODO fix duplication
  ${props => props.responsive && css`
    width: 100%;
  `}
`;

export const TextArea = styled.textarea`

  color: ${props => props.theme.colors.grey};
  
  border: ${props => props.theme.borderWidth} solid ${props => props.theme.colors.lightGrey};
  
  padding: ${props => props.theme.space3};
  
  resize: none;
  
  :focus {
    outline: none !important;
    border:${props => props.theme.borderWidth} solid ${props => props.theme.colors.teal};
    box-shadow: 0 0 ${props => props.theme.size2} ${props => props.theme.colors.teal};
  }
  
  width: 100%;
  height: 100px;
  
  ${props => props.disabled && css`
    background: ${props => props.theme.colors.background};
  `};
  
`;

export const FormHead = styled(FlexSection)`
  font-size: ${props => props.theme.fontSizeSmall};
  color: ${props => props.theme.colors.grey};
  
  text-transform: uppercase;
  
  width: 100%;
`;
