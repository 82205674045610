import styled, {css} from 'styled-components';
import device from "../../../../../constants/Device";

const activeStyle = css`
  border-radius: ${props => props.theme.cornerRadius};
  border: 3px solid ${props => props.theme.colors.brandSecondary};
  padding: 5px 6px;

  ::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid ${props => props.theme.colors.brandSecondary};
    right: -6px;
    top: 50%;
    margin-top: -6px;
  }
`;

export const TerminalEventButtonWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  border: solid ${props => props.theme.sizeExtraSmall} ${props => props.theme.colors.divider};
  border-radius: ${props => props.theme.cornerRadius};
  box-shadow: 0 ${props => props.theme.sizeExtraSmall} ${props => props.theme.sizeSmall} 0 rgba(0, 0, 0, 0.1);
  margin: 0 ${props => props.theme.space2};
  padding: 7px 8px;
  line-height: 1.1;

  ${props => props.hasUnread && css`
    font-weight: bold;
  `};

  ${props => props.active && css`
    ${activeStyle}
  `};

  // For Desktop, even when routing is terminal/SYD/dep, without detailPath, terminal should still show as active.
  @media ${device.tablet} {
    ${props => (props.active === null) && css`
      ${activeStyle}
    `};
  }
`;

export const ButtonText = styled.span`
  font-family: ${props => props.theme.fontMedium};
  font-size: ${props => props.theme.fontSizeLarge};
  color: ${props => props.theme.colors.body};
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 17px 14px;
`;
