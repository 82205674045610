import gql from 'graphql-tag';

export default gql`

  query getNetworkSummary($airline_group: FlightGroupEnum) {
    getAUAirportsFlightSummary(
      lower_window: -12
      upper_window: 18
      airline_group: $airline_group
    ) {
      code
      delayCount
      cancelledCount
      aogCount
    }

    lateBoardingFlights: getFlights(
      lower_window: -12
      upper_window: 18
      airline_group: $airline_group
      filter: [
        MAJOR_AUSTRALIAN_PORTS
        DEPARTURE_ONBLOCKS
        LATE_BOARDING
      ]
    ) {
      id
      operating_airline
      flight_number
      aircraft_registration
      departure_airport_code
      departure_airport {
        id
        timezone_offset
      }
      arrival_airport_code
      departure_scheduled_time
      offblocks_time
      departure_gate
      boarding_scheduled_time
    }

    escalatedFlights: getFlights(
      lower_window: -12
      upper_window: 18
      airline_group: $airline_group
      filter:[
        ESLCATION_ACTIVE
        MAJOR_AUSTRALIAN_PORTS
        DEPARTURE_ONBLOCKS
      ]
    ) {
      id
      operating_airline
      flight_number
      aircraft_registration
      departure_airport_code
      departure_airport {
        id
        timezone_offset
      }
      arrival_airport_code
      departure_scheduled_time
      offblocks_time
      departure_gate
      escalation_notices {
        id
        content
      }
    }

    engineeringRiskFlights: getFlights(
      lower_window: -12
      upper_window: 18
      airline_group: $airline_group
      filter:[
        ENGINEERING_RISK_ACTIVE
        MAJOR_AUSTRALIAN_PORTS
        DEPARTURE_ONBLOCKS
      ]
    ) {
      id
      operating_airline
      flight_number
      aircraft_registration
      aircraft_type
      departure_airport_code
      departure_airport {
        id
        timezone_offset
      }
      arrival_airport_code
      departure_scheduled_time
      offblocks_time
      departure_gate
      engineering_status
    }
  }
  `;
