import React from 'react';
import {Query} from 'react-apollo';

import inboundQuery from '../gql/get-flight-inbound-connections';
import ErrorPage from "../components/common/ErrorPage";
import LoadingPage from "../components/common/molecules/LoadingPage";
import InboundConnectionsAccordion from "../components/flight/FlightDetail/FlightBody/InboundConnectionsAccordion";
import {NETWORK_STATUS, REFRESH_INTERVAL} from "../constants/Apollo";

export default (props) => {

  const {
    flight
  } = props;

  if (!props.flight) {
    return <ErrorPage
      title="Error getting Inbound Connections"
      message="flightId was missing from the request"
      hideRefresh={true}/>
  }

  return (
    <Query query={inboundQuery}
           variables={{id: flight.id}}
           fetchPolicy={"network-first"}
           errorPolicy="all"
           pollInterval={REFRESH_INTERVAL.INBOUND_CONNECION_REFRESH_INTERVAL}>
      {({networkStatus, error, data = {}}) => {
        if (error) {
          console.error("Error loading Inbound Connections", error);
          return <ErrorPage
            title="Error getting Inbound Connections"
            message="There was a problem getting Inbound Connections"
            hideRefresh={true}/>;
        } else if (networkStatus <= NETWORK_STATUS.SET_VARIABLES) {
          return <LoadingPage/>;
        } else {
          return <InboundConnectionsAccordion {...props}
                                              connections={data.flight.inbound_connections}/>;
        }
      }}
    </Query>
  )
}
