import React from 'react';
import {Mutation} from 'react-apollo';

import addNotice from '../../../../gql/add-notice';
import AddEventForm from "./AddEventForm";
import {withSnackbar} from "notistack";

const MutatedAddEventForm = (props) => {
  return (
    <Mutation mutation={addNotice} onCompleted={props.closeModal}>
      {(callAddNotice, {data, loading, called, error}) => {
        if (error) {
          this.props.enqueueSnackbar('An error occurred while trying to add the event', {variant: 'error'})
        }

        const formSubmitted = ({content, type, assigned_role_ids, is_completeable}) => {
          callAddNotice({
            variables: {
              notice_channel_id: props.chatChannelId,
              type,
              content,
              assigned_role_ids,
              is_completeable
            }
          });
        };

        return (
          <AddEventForm
            {...props}
            key={props.chatChannelId}
            loading={loading}
            error={!!error}
            onSubmit={formSubmitted}/>
        );
      }}
    </Mutation>
  );
};

export default withSnackbar(MutatedAddEventForm);
