import React from 'react';
import PropTypes from "prop-types";
import NotificationSection from "../../../common/molecules/NotificationSection";
import {ReactComponent as IconCheck} from "../../../../images/check.svg";
import partition from "lodash/partition";
import {FlightListWrapper} from "../style";
import Divider from "../../../common/molecules/Divider";
import Spacer from "../../../common/atoms/Spacer";
import FlightType, {TYPE} from "../../../../constants/Flight";

const TypeFlightList = ({
                          flights,
                          renderFlightRow,
                          isCompletedExpanded,
                          toggleCompletedExpanded,
                          isFinishedFlight,
                          viewType,
                        }) => {
  // Note: The following character is a unicode minus sign, not an ASCII hyphen-minus, so that it vertically aligns with the plus sign
  const finishedSymbol = isCompletedExpanded ? "−" : "+";
  const [finishedFlights, upcomingFlights] = partition(flights, f => isFinishedFlight(f));

  return flights.length > 0 ?
    <FlightListWrapper>
      <Divider dark 
               head={'COMPLETED'}
               icon={finishedSymbol}
               onClick={toggleCompletedExpanded}>
      </Divider>
      {
        isCompletedExpanded && finishedFlights.reverse().map(renderFlightRow)
      }
      <Spacer size={2}/>
      <Divider dark head={'UPCOMING'}/>
      {
        upcomingFlights.map(renderFlightRow)
      }
    </FlightListWrapper> :
    <NotificationSection icon={<IconCheck/>}
                         head={`No upcoming ${viewType === TYPE.DEPARTURE ? 'departures' : 'arrivals'}`}
                         body={"No flights scheduled within the given time window in this terminal. Please check back later."}/>;
};

TypeFlightList.propTypes = {
  flights: PropTypes.arrayOf(FlightType),
  renderFlightRow: PropTypes.func.isRequired,
  isCompletedExpanded: PropTypes.bool.isRequired,
  toggleCompletedExpanded: PropTypes.func.isRequired,
  isFinishedFlight: PropTypes.func.isRequired,
  viewType: PropTypes.oneOf([TYPE.ARRIVAL, TYPE.DEPARTURE]),
};

export default TypeFlightList;
