export default {
  /*
    Blank for now e.g. resolver
    ```
    User: {
      myClientField: myCustomResolver
    }
    ```

    Where myCustomResolver is for example:
    ```
    export default (obj, args, context, info) => {
      if (!obj.role_id) {
        return null;
      }

      const roleCacheKey = context.getCacheKey({ __typename: 'Role', id: obj.role_id });
      const role = context.cache.readFragment({
        fragment: gql`
          fragment role on Role {
            id
            display_name
          }
        `,
        id: roleCacheKey,
      });

      return role;
    };
    ```
  */
};
