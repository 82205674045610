import styled, {css} from "styled-components";
import FlexSection from "../../../../common/atoms/FlexSection";
import device from "../../../../../constants/Device";

export const InboundBody = styled.div`
`;

export const TimeSelectionSectionWrapper = styled(FlexSection)`
  /* Display */
  flex-direction: column;
  /* Spacing */
  padding: ${props => props.theme.space3} ${props => props.theme.space3};

  div {
    display: flex;
    align-items: center;
  }

  p {
    white-space: nowrap;
    /* Mobile Responsive */
    font-size: ${props => props.theme.fontSizeMedium};
    @media ${device.tablet} {
      font-size: ${props => props.theme.fontSizeLarge};
    }
  }
`;

export const ConnectionsSection = styled(FlexSection)`
  /* Spacing */
  padding: ${props => props.theme.space2} ${props => props.theme.space3};

  margin-bottom: ${props => props.theme.size4};

  overflow-x: auto;
`;

export const TimeButton = styled.span`
  /* Display */
  display: flex;
  justify-content: center;
  white-space: nowrap;

  /* Border */
  text-align: center;
  background: white;
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fontRegular};

  /* Mobile Responsive */
  font-size: ${props => props.theme.fontSizeMedium};
  padding: ${props => props.theme.space2} ${props => props.theme.space2};
  @media ${device.tablet} {
    padding: ${props => props.theme.space2} ${props => props.theme.space4};
    font-size: ${props => props.theme.fontSizeLarge};
  }

  margin-left: ${props => props.theme.space5};

  /* Cursor styling */
  cursor: pointer;
  user-select: none;

  :hover {
    color: ${props => props.theme.colors.black};
    font-family: ${props => props.theme.fontImportant};
  }

  ${props => props.selected && css`
    font-family: ${props => props.theme.fontImportant};
    box-shadow:inset 0 0 0 ${props => props.theme.borderWidth} ${props => props.theme.black};
  `}
`;
