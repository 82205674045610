import styled from 'styled-components'

import {ButtonWrapper} from '../Button/style';
import FlexSection from '../FlexSection';

export default styled(FlexSection)`
  ${ButtonWrapper}:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ${ButtonWrapper}:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;