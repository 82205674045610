import gql from 'graphql-tag';

export default gql`
  mutation AddUserView($view_type: UserViewType!, $filter: AddUserViewFilterInput!, $lower_window: Int!, $upper_window: Int!) {
    addUserView(input: {
      view_type: $view_type
      filter: $filter
      lower_window: $lower_window
      upper_window: $upper_window
    }) {
      id
      view_type
      filter {
        airport_code
      }
      lower_window
      upper_window
    }
  }`;
