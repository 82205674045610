import React from 'react';
import {InfoLabelWrapper, Label} from "./style";

const InfoLabel = React.memo(({label, text, divider, ...props}) =>
  <InfoLabelWrapper divider={divider} {...props}>
    <Label {...props}>{label}</Label>
    <span>{text}</span>
  </InfoLabelWrapper>
);

export default InfoLabel;
