import styled from 'styled-components';
import {ReactComponent as iconClose} from '../../../images/icons/close.svg';
import {CardWrapper} from "../../common/molecules/Card/style";
import loadingSrc from "../../../images/loading.gif"

export const Content = styled(CardWrapper)`
  position: relative;
  width: 150px;
  margin-left: -75px;
  padding: ${props => props.theme.size4};
  margin-bottom: 10px;

  ::after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid white;
      left: 50%;
      bottom: -10px;
      margin-left: -10px;
    }
`;

export const Head = styled.h3`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${props => props.theme.fontSizeMedium};
  margin: ${props => props.theme.space0};
  padding-bottom: ${props => props.theme.space2};
`;

export const IconClose = styled(iconClose)`
  width: ${props => props.theme.size3};
  float: right;
  cursor: pointer;
`;

export const Body = styled.div`
  height: 35px;
`;

export const TerminalLinkWrapper = styled.div``;

export const TerminalLink = styled.span`
  color: black;
  text-decoration: underline;
  cursor: pointer;
`;

export const LoadingWrapper = styled.div`
  text-align: center;
`;

export const Loading = styled.img.attrs({
  src: loadingSrc
})`
  width: 32px;
  height: 32px;
`;
