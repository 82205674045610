import React from 'react';
import {Dropdown} from "semantic-ui-react";

import HoverMenuUndoButton from "./HoverMenuUndoButton";
import HoverMenuDeleteButton from "./HoverMenuDeleteButton";

import styles from "./HoverMenu.module.css"
import {withLocalUserData} from '../../../hocs/withLocalUserData';
import {TYPE} from "../../../constants/Notice";

const HoverMenu = ({notice, offset, user, isChannelAdmin}) => {
  const buttons = [];
  if (shouldShowUndoButton(notice, user, isChannelAdmin)) {
    buttons.push(<HoverMenuUndoButton key={'undo'} notice={notice} />);
  }
  if (shouldShowDeleteButton(notice, user, isChannelAdmin)) {
    buttons.push(<HoverMenuDeleteButton key={'delete'} notice={notice} offset={offset} />);
  }

  if (buttons.length === 0) {
    return <></>;
  }

  return (
    <Dropdown
      button
      icon='ellipsis horizontal'
      className={styles.dropdown}>
      <Dropdown.Menu>
        {buttons}
      </Dropdown.Menu>
    </Dropdown>
  )
};

export default withLocalUserData(HoverMenu);

function shouldShowDeleteButton(notice, user, isChannelAdmin) {
  if (notice.deleted_at || notice.messageType === TYPE.INFO || notice.messageType === TYPE.PTS) {
    return false;
  }

  if (isChannelAdmin) {
    return true;
  }

  return !!notice.created_by && notice.created_by.id === user.id;
}

function shouldShowUndoButton(notice, user, isChannelAdmin) {
  if (!notice.completed_at) {
    return false;
  }

  if (notice.messageType === TYPE.PTS) {
    // TODO: PTS undo is currently broken as hell
    return false;
  }

  if (isChannelAdmin) {
    return true;
  }

  return !!notice.completed_by && notice.completed_by.id === user.id;
}
