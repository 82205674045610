import React from 'react';
import Icon from "../../../../common/atoms/Icon";
import {ReactComponent as ControlTowerImage} from "../../../../../images/control-tower.svg";
import {withNoticeChannel} from "../../../../../hocs/withNoticeChannel";
import ChannelType from "../../../../../constants/Channel";
import PropTypes from "prop-types";
import UserType from "../../../../../constants/User";
import {hasUnread} from "../../../../../utils/unread-messages";
import {NavLink as Link} from "react-router-dom";
import {IconWrapper} from "./style";

const MobileTerminalButton = ({
  noticeChannel: {channel},
  terminalPath,
  user
}) => {

  const unreadImportantMessagesCount = channel ? hasUnread(
    channel.user_last_viewed,
    channel.messages,
    user
  ) : 0;

  return (
    <Link to={`${terminalPath}/events`}>
      <IconWrapper unread={unreadImportantMessagesCount > 0}>
        <Icon>
          <ControlTowerImage/>
        </Icon>
      </IconWrapper>
    </Link>
  )
};

MobileTerminalButton.propTypes = {
  noticeChannel: PropTypes.shape({
    channel: ChannelType,
  }),
  terminalPath: PropTypes.string.isRequired,
  user: UserType.isRequired,
};

export default withNoticeChannel(MobileTerminalButton, {
  renderLoadingComponent: true,
  fetchPolicy: "cache-and-network",
});
