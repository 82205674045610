import React from 'react';
import Page from "../../atoms/Page";
import loadingSrc from "../../../../images/loading.gif"

const LoadingPage = () =>
  <Page>
    <img src={loadingSrc} alt="Loading"/>
  </Page>;

export default LoadingPage;
