import styled, {css} from 'styled-components'

import FlexSection from "../../../../common/atoms/FlexSection";
import Button from "../../../../common/atoms/Button";
import {ButtonWrapper} from "../../../../common/atoms/Button/style";
import {TYPE} from "../../../../../constants/Notice";

export const TaskSectionWrapper = styled.div`
  background: ${props => props.theme.colors.background};
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.15);

  ${props => {
    if (props.delayed) {
      return css`
        background: ${props => props.theme.colors.backgroundOrange};
      `;
    }
    switch (props.type) {
      case TYPE.ESCALATION:
        return css`
            background: ${props => props.theme.colors.backgroundRed};
          `;
      default:
        return css`
            background: ${props => props.theme.colors.background};
          `;
    }
  }}
`;

export const EventSection = styled(FlexSection)`
  padding: ${props => props.theme.space1} ${props => props.theme.space2} ${props => props.theme.space1} ${props => props.theme.space4};
  min-height: 60px;
`;

export const TaskDetailWrapper = styled(FlexSection)`
  padding: ${props => props.theme.space0} ${props => props.theme.space2};
  flex-grow: 1;

  * + * {
    margin-left: ${props => props.theme.space2};
  }
`;

export const ActionSection = styled(FlexSection)`
  padding: ${props => props.theme.space0} ${props => props.theme.space2}

  ${ButtonWrapper} + ${ButtonWrapper} {
    margin-left: ${props => props.theme.space3};
  }
`;

export const MenuButton = styled(Button)`
  padding-right: ${props => props.theme.space0};
  padding-left: ${props => props.theme.space0};
`;

export const TaskTitle = styled(FlexSection)`
  font-family: ${props => props.theme.fontImportant};
  font-size: ${props => props.theme.fontSizeLarge};
`;

export const TaskAssigned = styled.div`
  color: ${props => props.theme.darkGrey};
  text-transform: uppercase;
`;
