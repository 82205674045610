import styled from "styled-components";
import SlidingPanel from "react-sliding-side-panel";
import device from "../../../../../../constants/Device";


export const PaxSliderPanel = styled(SlidingPanel)`
`;

export const PanelWrapper = styled.div`
  background: ${props => props.theme.colors.background};
  width: 100%;
  min-height: 100%;
  
  /* Mobile Responsive */
  padding: ${props => props.theme.size3};
  @media ${device.tablet} {
    padding: ${props => props.theme.size5} ${props => props.theme.size7};
  }
`;

export const CloseButton = styled.a`
  position: absolute;
  /* Cursor styling */
  cursor: pointer;
  text-transform: uppercase;
  user-select: none; 
`;
