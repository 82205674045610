import React from 'react';
import {TerminalSettingsWrapper} from "./style";

import {Mutation} from 'react-apollo';
import {Redirect} from 'react-router-dom';

import deleteUserViewMutation from '../../../../gql/delete-user-view';
import updateUserFromRemoveView from '../../../../data/delete-user-view-cache-result';

import PropTypes from "prop-types";

const Index = ({viewId}) =>
  <Mutation mutation={deleteUserViewMutation} update={updateUserFromRemoveView}>
    {(deleteUserView, { data, loading, called, error }) => {
      if (error) {
        console.error('Error while trying to delete user view', error);
      }

      if (called && !loading && !error) {
        return <Redirect to="/" />
      }

      const removeButtonClicked = () => {
        deleteUserView({
          variables: {
            id: viewId
          }
        });
      };

      return <TerminalSettingsWrapper onClick={removeButtonClicked}>Leave terminal →</TerminalSettingsWrapper>
    }}
  </Mutation>;

Index.propTypes = {
  viewId: PropTypes.number.isRequired,
};

export default Index;
