import React from 'react';

import Content from '../../../common/Content';
import Author from "../../../../common/molecules/Author";
import NoticeStatus from "../../../common/NoticeStatus";
import Timestamp from "../../../../common/atoms/Timestamp";
import HoverMenu from "../../../menu/HoverMenu";
import {ContentColumn, ContentBody, Delta, DeltaColumn, DotColumn, MenuColumn, MessageRowWrapper, TimeStampColumn, ContentSecondary} from "./style";
import Dot from "../../../../common/atoms/Dot";
import PropTypes from "prop-types";
import NoticeType, {TYPE} from "../../../../../constants/Notice";

const Message = React.memo(({
                              message,
                              terminalOffset,
                              shouldShowAuthor,
                              shouldShowDelta,
                              shouldFade,
                              disableHover,
                              isLastEvent,
                              isChannelAdmin,
                              innerRef,
                            }) => {

  const isEvent = isNoticeAnEvent(message);
  // const isLate = message.messageType === TYPE.PTS ? message.completed_at : false;

  return (
    <MessageRowWrapper
      shouldFade={shouldFade(message)}
      shouldShowDelta={shouldShowDelta}
      enableHover={!disableHover}
      onClick={doNothing}
      ref={innerRef}>
      {
        shouldShowDelta &&
        <>
          <DeltaColumn>
            <Delta/>
          </DeltaColumn>
          <DotColumn isShowingTitle={shouldShowAuthor}>
            {
              isEvent &&
              <Dot type={message.messageType}
                   isLarge={isLastEvent}
                   isLafte={message.wasLate}
                   isCompleted={message.completed_at !== undefined}
                   isSkipped={message.completed_skipped}/>
            }
          </DotColumn>
        </>
      }
      <ContentColumn>
        <ContentBody>
          {
            shouldShowAuthor && message.created_by &&
            <Author author={message.created_by}/>
          }
          {
            !message.deleted_at && message.content !== "UNDONE" &&
              <Content content={formatContent(message)} offset={terminalOffset}/>
          }
        </ContentBody>
        <ContentSecondary>
          <NoticeStatus
            notice={message}/>
        </ContentSecondary>
      </ContentColumn>
      <MenuColumn>
        <HoverMenu
          notice={message}
          offset={terminalOffset}
          isChannelAdmin={isChannelAdmin}/>
      </MenuColumn>
      <TimeStampColumn>
        <Timestamp
          notice={message}
          offset={terminalOffset}/>
      </TimeStampColumn>
    </MessageRowWrapper>
  )
});

const isNoticeAnEvent = (notice) => {
  return (notice.messageType === TYPE.EVENT || notice.messageType === TYPE.ESCALATION || notice.messageType === TYPE.PTS);
};

const doNothing = () => {
  // Due to Mobile Safari issue, hover is not triggered unless the element has an 'onclick' to it. Hence this method. https://stackoverflow.com/a/20048559/3381997
};

const formatContent = (notice) => {
  const {
    messageType,
    content,
  } = notice;

  switch (messageType) {
    case TYPE.ESCALATION:
      return formatEscalationContent(notice);
    default:
      return content;
  }
};

const formatEscalationContent = (notice) => {
  const {
    completed_at,
    completed_reason,
    content,
  } = notice;

  if (completed_at) {
    return "Escalation Resolved: " + completed_reason
  } else {
    return "Escalation Started: " + content
  }
};

Message.defaultProps = {
  shouldShowAuthor: true,
  shouldShowDelta: false,
  disableHover: false,
  isLastEvent: false,
};
Message.propTypes = {
  message: NoticeType,
  terminalOffset: PropTypes.number,
  shouldShowAuthor: PropTypes.bool,
  deltaZero: PropTypes.string,
  shouldShowDelta: PropTypes.bool.isRequired,
  shouldFade: PropTypes.func.isRequired,
  disableHover: PropTypes.bool.isRequired,
  isLastEvent: PropTypes.bool.isRequired,
  isChannelAdmin: PropTypes.bool.isRequired,
};

export default React.forwardRef((props, ref) => <Message innerRef={ref} {...props} />);
