import {TYPE} from "../constants/Notice";

export const hasUnread = (unreadSince, messagesPage, user) => {
  const messages = messagesPage.nodes;

  if (!messages.length) {
    return false;
  }

  const lastMessage = findLastMessage(messages, user);
  return lastMessage && (!unreadSince || lastMessage.created_at > unreadSince);
};

const findLastMessage = (messages, user) => {
  for (let i = messages.length - 1; i >= 0; i--) {
    const message = messages[i];
    if (!message.created_by || message.created_by.id !== user.id) {
      return message;
    }
  }

  return null;
};

export const countUnreadImportant = (unreadSince, noticesPage, user, includeEvents = true) => {
  const notices = noticesPage.nodes;

  return notices
    .filter(n => (!n.created_by || n.created_by.id !== user.id) && (!unreadSince || n.created_at > unreadSince))
    .filter(n => hasMention(n, user) || (includeEvents && isImportant(n, user)))
    .length;
};

const isImportant = (notice, user) => {
  return notice.messageType === TYPE.EVENT || notice.messageType === TYPE.ESCALATION
};

const hasMention = (notice, user) => {
  return notice.content.indexOf(`[role:${user.role}]`) !== -1;
};
