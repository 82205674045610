import React, {Component} from 'react';
import PropTypes from "prop-types";
import Modal from "../../../../../common/molecules/Modal";
import NoticeType from "../../../../../../constants/Notice";
import MutatedCompleteNotice from "../../../../../notice/event/resolve/MutatedCompleteNotice";

class ResolveEscalationModal extends Component {

  state = {
    showModal: false,
  };

  render = () => {

    const {
      notice,
      offset,
      trigger,
    } = this.props;

    return (
      <Modal
        isOpen={this.state.showModal}
        onOpen={this.openModal}
        onClose={this.closeModal}
        trigger={
          trigger
        }
      >
        <MutatedCompleteNotice
          notice={notice}
          offset={offset}
          closeModal={this.closeModal}/>
      </Modal>
    )
  };

  openModal = () => this.setState({showModal: true});

  closeModal = () => this.setState({showModal: false});
}

ResolveEscalationModal.propTypes = {
  notice: NoticeType.isRequired,
  offset: PropTypes.number.isRequired,
  trigger: PropTypes.node,
};

export default ResolveEscalationModal;
