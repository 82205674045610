import {TimezoneOffsetContext} from "../../../contexts/TimezoneOffsetContext";
import Terminal from "../Terminal";
import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {TYPE} from "../../../constants/Flight";
import {VIEW_FLIGHT_DETAILS} from "../../../constants/FlightDetailMenuTabs";

const TerminalView = ({terminalPath, user, view, flightTypePath, terminal}) => {
  return (
    <Switch>
      <Route
        path={`${terminalPath}/${flightTypePath}/:detailPath?/:context?`}
        render={({match: {params: {detailPath, context}}}) =>
          renderTerminal(terminal, user, view, terminalPath, flightTypePath, detailPath, context ?? VIEW_FLIGHT_DETAILS)
        }/>
      <Redirect to={terminalPath + '/dep'}/>
    </Switch>
  )
};

const renderTerminal = (terminal, user, view, terminalPath, flightTypePath, detailPath, context) => {
  return (
    <TimezoneOffsetContext.Provider value={terminal.timezone_offset}>
      <Terminal
        key={terminal.id}
        terminal={terminal}
        terminalViewType={flightTypePath === "arr" ? TYPE.ARRIVAL : TYPE.DEPARTURE}
        user={user}
        view={view}
        terminalPath={terminalPath}
        detailPath={detailPath}
        context={context}/>
    </TimezoneOffsetContext.Provider>
  );
};

export default TerminalView;
