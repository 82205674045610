import React from "react";
import {ColumnWrapper, RegistrationWrapper} from "./style";

const Registration = React.memo(({registration, type}) =>
  <RegistrationWrapper>
    <ColumnWrapper>
      <div>{registration ? registration : 'Not Assigned'}</div>
      <div>{type ? type : 'Not Assigned'}</div>
    </ColumnWrapper>
  </RegistrationWrapper>
);

export default Registration;
