import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';

import Header from '../../common/organisms/Header';
import TerminalData from '../../../containers/TerminalData';
import AddTerminalPage from '../../terminal/Terminal/AddTerminalPage';
import RosterData from '../../../containers/RosterData';
import {VIEW_TYPE, VIEW_DEFAULTS} from '../../../constants/View';
import UserType from '../../../constants/User';
import {MainDashboard, UserDashboardWrapper} from "./style";
import {Laptop} from "../../../constants/Device";
import NetworkMap from '../../../components/networkmap';
import {AIRPORT, NETWORK_MAP} from "../../../constants/Routes";
import { PERMISSION } from '../../../constants/Permission';
import { hasPermission } from '../../../utils/permission';

const localStorageLastViewedKey = "IO_LAST_VIEW";

class UserDashboard extends Component {

  render = () => {

    const {
      user,
      location: {
        pathname
      },
    } = this.props;

    return (
      <UserDashboardWrapper>
        <Laptop>
          <Header
            user={user}
            isNetworkMap={pathname.startsWith(`/${NETWORK_MAP}`)}
            hasNetworkMapPermission={hasPermission(user, PERMISSION.networkMap, 1)}
          />
        </Laptop>
        <MainDashboard>
          <Switch>
            <Route path={`/${AIRPORT}/add`} render={this.renderAddTerminal}/>
            <Route path={`/${AIRPORT}/:code`} render={this.renderTerminalData}/>
            <Route path="/roster" render={this.renderRoster}/>
            <Route path={`/${NETWORK_MAP}`} render={this.renderMap}/>
            <Route render={this.renderDefault}/>
          </Switch>
        </MainDashboard>
      </UserDashboardWrapper>
    )
  };

  renderAddTerminal = () => {
    const existingTerminals = this.props.user.views
      .filter(v => v.view_type === VIEW_TYPE.TERMINAL)
      .map(v => v.filter.airport_code);

    return <AddTerminalPage existingTerminals={existingTerminals}/>;

  };

  renderTerminalData = ({ match: { url, params: { code } }}) => {
    const view = this.getTerminalUserView(code);

    if (view.id) {
      this.setLastView(view.id);
    }

    return <TerminalData view={view} terminalPath={url} user={this.props.user}/>;
  };

  getTerminalUserView = (airportCode) => {
    const view = this.props.user.views.find(v => v.view_type === VIEW_TYPE.TERMINAL && v.filter.airport_code === airportCode);
    if (view) {
      return view;
    } else {
      return {
        view_type: VIEW_TYPE.TERMINAL,
        filter: {
          airport_code: airportCode,
        },
        lower_window: VIEW_DEFAULTS.timeWindowLower,
        upper_window: VIEW_DEFAULTS.timeWindowUpper
      };
    }
  };

  renderRoster = ({ match: { url }}) => {
    const view = this.getRosterUserView();

    this.setLastView('ROSTER');

    return (
      <RosterData
        view={view}
        terminalPath={url}
        user={this.props.user}
      />
    )
  };

  renderMap = ({match: {url}}) => {
    const {
      user
    } = this.props;

    if (!hasPermission(user, PERMISSION.networkMap, 1)) {
      return <Redirect to='/' />
    }

    return (
      <NetworkMap user={user}/>
    )
  };

  renderDefault = () => {
    const lastView = this.getLastView();

    if (lastView === 'ROSTER') {
      return <Redirect to={'/roster'} />
    } else if (!isNaN(lastView)) {
      const viewId = parseInt(lastView);
      const view = this.props.user.views.find(v => v.id === viewId);

      if (view && view.view_type === VIEW_TYPE.TERMINAL) {
        const child = view?.filter?.airport_code;
        if (child) {
          return <Redirect to={`/${AIRPORT}/${child}`}/>;
        }
      }
    }

    // If no last view or could not resolve it then just go to the first terminal view
    const terminalView = this.props.user.views.find(v => v.view_type === VIEW_TYPE.TERMINAL);
    const child = terminalView?.filter?.airport_code ?? 'add';
    return <Redirect to={`/${AIRPORT}/${child}`}/>;
  };

  getRosterUserView = () => {
    const view = this.props.user.views.find(v => v.view_type === VIEW_TYPE.ROSTER);
    if (view) {
      return view;
    } else {
      return {
        view_type: VIEW_TYPE.ROSTER,
        lower_window: VIEW_DEFAULTS.timeWindowLower,
        upper_window: 12
      }
    }
  };

  setLastView = (value) => {
    try {
      localStorage.setItem(localStorageLastViewedKey, value)
    } catch (e) {
      // Ignore localStorage errors
    }
  };

  getLastView = () => {
    try {
      return localStorage.getItem(localStorageLastViewedKey);
    } catch (e) {
      // Ignore localStorage errors
      return null;
    }
  }
}

UserDashboard.propTypes = {
  user: UserType,
};

export default withRouter(props => <UserDashboard {...props}/>);
