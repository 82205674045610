import styled, {css} from 'styled-components';
import PropTypes from "prop-types";
import device from "../../../../constants/Device";

export const MenuTab = styled.div`

  padding: ${props => props.theme.size2} 0;

  font-family: ${props => props.theme.fontImportant};
  font-size: ${props => props.theme.fontSizeLarge};
  
  color: ${props => props.theme.colors.grey}
  border-bottom: ${props => props.theme.borderWidth} solid transparent;

  ${props => props.active && css`
    color: ${props => props.theme.colors.black};
    border-bottom: ${props => props.theme.borderWidth} solid ${props => props.theme.colors.brandSecondary};
  `};
  
  /* Cursor styling */
  cursor: pointer;
  user-select: none; 
  
  /* Spacing */
  & + & {
    margin-left: ${props => props.theme.space2};
    /* Mobile Responsive */
    @media ${device.laptop} {
      margin-left: ${props => props.theme.space5};
    }
  }
`;

MenuTab.defaultProps = {
  active: false
};
MenuTab.propTypes = {
  active: PropTypes.bool,
};

export default MenuTab;
