import styled from 'styled-components';
import FlexSection from "../../atoms/FlexSection";
import PropTypes from "prop-types";

const PromptBox = styled(FlexSection)`
  flex-direction: column;
  
  border: 0;  
  border-top: ${props => props.theme.size1} solid ${props => props.theme.colors.oldBrandPrimary};
  border-radius: 0 0 ${props => props.theme.size1} ${props => props.theme.size1};
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  
  padding: ${props => props.theme.space8};
  
  width: 100%;
  
  background: white;
`;

PromptBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PromptBox
