import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as LogoIcon} from '../../../../images/logo.svg';
import {HeaderWrapper, ViewSwitchWrapper} from "./style";
import UserType from "../../../../constants/User";
import {DesktopUserControl} from "../../molecules/UserControl";
import ViewSwitch from "./ViewSwitch";
import {Link} from "react-router-dom";
import Icon from "../../atoms/Icon";

const Header = ({user, isAdminDashboard, isNetworkMap, hasNetworkMapPermission}) => (
  <HeaderWrapper>
    <Icon>
      <Link to={"/"}>
        <LogoIcon/>
      </Link>
    </Icon>
    {
      hasNetworkMapPermission && !isAdminDashboard &&
      <ViewSwitchWrapper>
        <ViewSwitch isNetworkMap={isNetworkMap}/>
      </ViewSwitchWrapper>
    }
    <DesktopUserControl user={user} isAdminDashboard={isAdminDashboard}/>
  </HeaderWrapper>
);

Header.propTypes = {
  user: UserType,
  isAdminDashboard: PropTypes.bool,
  isNetworkMap: PropTypes.bool,
  hasNetworkMapPermission: PropTypes.bool,
};

export default Header;
