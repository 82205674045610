import PropTypes from 'prop-types';

const PTSStepType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action_text: PropTypes.string.isRequired,
  previous_steps: PropTypes.arrayOf(PropTypes.string),
  next_steps: PropTypes.arrayOf(PropTypes.string),
  timing: PropTypes.number,
});

export default PTSStepType;
