import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import {VIEW_TYPE} from '../../../../constants/View';
import {TYPE} from "../../../../constants/Flight";
import {
  FlightFilterWrapper,
  SearchFilterWrapper,
  SearchInputWrapper,
  TabWindowWrapper,
  TabWrapper,
  FilterButtonWrapper,
  DomainFilterLabel,
} from "./style";
import MenuTab from "../../../common/atoms/MenuTab";
import SearchInput from "../../../common/molecules/SearchInput";
import FlightFilterSettings from '../FlightFilterSettings';
import { DOMAIN_LABELS } from '../../../../constants/FlightDomainFilter';

class FlightFilter extends Component {

  render = () => {
    const {
      view,
      flightType,
      userGroup,
    } = this.props;

    return (
      <FlightFilterWrapper>
        <TabWindowWrapper>
          {
            view.view_type === VIEW_TYPE.TERMINAL &&
            <TabWrapper>
              {this.renderMenuTab('dep', 'Departures', flightType === TYPE.DEPARTURE)}
              {this.renderMenuTab('arr', 'Arrivals', flightType === TYPE.ARRIVAL)}
            </TabWrapper>
          }
          {
            view.id !== undefined &&
            <FilterButtonWrapper>
              <FlightFilterSettings
                userGroup={userGroup}
                view={view}
              />
              {view.domains && view.domains.length < 3 &&
                <DomainFilterLabel>
                  {view.domains.map((value) => <span> &bull; {DOMAIN_LABELS[value]}</span>)}
                </DomainFilterLabel>
              }
            </FilterButtonWrapper>
          }
        </TabWindowWrapper>
        <SearchFilterWrapper>
          <SearchInputWrapper>
            <SearchInput onChange={this.onSearchChange}
                         placeholder={"Find flight / port"}/>
          </SearchInputWrapper>
        </SearchFilterWrapper>
      </FlightFilterWrapper>
    )
  };

  renderMenuTab = (uriPart, label, active) => {
    const {
      history
    } = this.props;

    const route = `${this.props.terminalPath}/${uriPart}/${this.props.detailPath || ''}`;

    return (
      <MenuTab active={active}
               onClick={() => history.push(route)}>
        {label}
      </MenuTab>
    )
  };

  onSearchChange = (event) => {
    this.props.onSearchChange(event);
  };
}

export default withRouter(FlightFilter);
