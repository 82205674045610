import {defaultDataIdFromObject} from 'apollo-cache-inmemory';

import noticeChannelLastViewedFragment from '../gql/fragments/notice-channel-last-viewed'

export default function(cache, { data: { updateUserChannelViewed } }) {
  if (!updateUserChannelViewed || !updateUserChannelViewed.last_viewed) { return; }

  const { notice_channel_id, last_viewed } = updateUserChannelViewed;

  cache.writeFragment({
    id: defaultDataIdFromObject({ id: notice_channel_id, __typename: "NoticeChannel" }) ,
    fragment: noticeChannelLastViewedFragment,
    data: {
      __typename: 'NoticeChannel',
      user_last_viewed: last_viewed
    }
  })
}
