import styled from 'styled-components';

export const MenuHeadWrapper = styled.div`
  background: white;
  
  width: 100%;
  
  padding: ${props => props.theme.space3};
  
  font-size: ${props => props.theme.fontSizeLarge};
`;

export const MenuTitle = styled.div`
  //line-height: 1.4;
  color: ${props => props.theme.colors.grey};
  //text-transform: uppercase;
  margin-top: ${props => props.theme.size3};
  //text-align: center;
`;
