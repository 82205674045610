import styled from 'styled-components';
import {Form} from "semantic-ui-react";
import {ButtonWrapper} from "../../../../common/atoms/Button/style";

export const FormWrapped = styled(Form)`
  width: 100%;
`;

export const ButtonSection = styled.div`
  
  width: 100%;
  
  padding: ${props => props.theme.space3} 0;
  
  ${ButtonWrapper} + ${ButtonWrapper} {
   margin-top: ${props => props.theme.space3}
  }
`;
