import React from 'react';
import PropTypes from 'prop-types';
import EscalationAccordion from "../EscalationAccordion";
import UnserviceableAccordion from "../UnserviceableAccordion";
import FlightType from "../../../../constants/Flight";
import AirportSummaryType from "../../../../constants/AirportSummary";
import {NetworkSummaryWrapper} from "./style";
import UserType from "../../../../constants/User";
import MenuHead from "./MenuHead";
import AirportList from "./AirportList";
import LateBoardingAccordion from "../LateBoardingAccordion";

const NetworkSummary = ({
                          user,
                          airports,
                          lateFlights,
                          escalatedFlights,
                          engineeringRiskFlights,
                        }) => {
  return (
    <NetworkSummaryWrapper>
      <MenuHead user={user}/>
      <AirportList airports={airports}/>
      <LateBoardingAccordion flights={lateFlights}/>
      <EscalationAccordion flights={escalatedFlights}/>
      <UnserviceableAccordion flights={engineeringRiskFlights}/>
    </NetworkSummaryWrapper>
  );
};

NetworkSummary.propTypes = {
  user: UserType,
  airports: PropTypes.arrayOf(AirportSummaryType),
  lateFlights: PropTypes.arrayOf(FlightType),
  escalatedFlights: PropTypes.arrayOf(FlightType),
  engineeringRiskFlights: PropTypes.arrayOf(FlightType),
};

export default NetworkSummary;
