import React from 'react';
import {AirportHeadWrapper} from "./style";
import {ButtonColumn, DataColumn} from "../../style";

const AirportHead = () => {
  return (
    <AirportHeadWrapper>
      <DataColumn>
        PORT
      </DataColumn>
      <DataColumn>
        DELAY
      </DataColumn>
      <DataColumn>
        CANX
      </DataColumn>
      <DataColumn>
        AOG
      </DataColumn>
      <ButtonColumn/>
    </AirportHeadWrapper>
  );
};

export default AirportHead;
