import gql from 'graphql-tag';

import {PermissionFields} from './permission';

export const UserFields = gql`
  ${PermissionFields}

  fragment UserFields on User {
    __typename

    id
    cognito_id
    is_superuser
    name

    ad_job_title
    role {
      id
      display_name
      permissions {
        ...PermissionFields
      }
    }

    group
    last_seen
    staff_number

    views {
      id
      view_type
      filter {
        airport_code
      }
      lower_window
      upper_window
      domains
    }

    permissions {
      ...PermissionFields
    }

  }
`;

export default UserFields;

export const adminUpdateUserMutation = gql`
  mutation adminUpdateUser($input: AdminUpdateUserInput!) {
    adminUpdateUser(input: $input)
  }
`;

export const adminGetUser = gql`
  ${UserFields}

  query adminGetUser($id: Int!) {
    adminGetUser(id: $id) {
      ...UserFields
    }
  }
`;