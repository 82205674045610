import React from 'react';
import PropTypes from "prop-types";
import {FlightListWrapper} from "../style";
import NotificationSection from "../../../common/molecules/NotificationSection";
import {ReactComponent as IconMissingPlane} from "../../../../images/plane_notfound.svg";
import FlightType from "../../../../constants/Flight";

const SearchList = ({
                      flights,
                      renderFlightRow,
                      searchQuery
                    }) => {

  const searchKeywords = searchQuery.split(",").map(keyword => keyword.toUpperCase().trim());
  const matchingFlights = flights.filter(flight => isSearchMatchFlight(flight, searchKeywords));
  return matchingFlights.length > 0 ?
    <FlightListWrapper>
      {
        matchingFlights.map(renderFlightRow)
      }
    </FlightListWrapper> :
    <NotificationSection icon={<IconMissingPlane/>}
                         head={"No matching flights"}
                         body={"Try entering the exact flight number, port code, or modifying your time window."}/>
};

const isSearchMatchFlight = (flight, searchKeywords) => {
  // All characters in these fields are uppercase (airline + ports) so just try match upper
  return searchKeywords.filter(search => {
    return search === flight.operating_airline + flight.flight_number ||
      search === flight.flight_number ||
      search === flight.departure_airport_code ||
      search === flight.arrival_airport_code
  }).length > 0;
};

SearchList.propTypes = {
  flights: PropTypes.arrayOf(FlightType),
  renderFlightRow: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
};

export default SearchList;
