import React from 'react';
import {Form} from 'semantic-ui-react';

import Message from './Message';

import styles from './FormControls.module.css';

const FormDropdown = (props) =>{
  const { 
    placeholder, 
    options, 
    onChange,
    showMessage,
    disabled,
    loading,
  } = props;

  return (
    <div className={styles.wrapper}>
      <Form.Dropdown
        className={styles.dropdown}
        placeholder={placeholder}
        search
        selection
        fluid
        options={options}
        onChange={onChange}
        disabled={disabled}
        loading={loading}
      />
      {
        showMessage && <Message {...props}/>
      }
    </div>
  );
};

export default FormDropdown;
