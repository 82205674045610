import React from 'react';
import {Query} from 'react-apollo';

import summaryQuery from '../gql/get-flight-ssr-qff-summary';
import ErrorPage from "../components/common/ErrorPage";
import CabinAccordion from "../components/flight/FlightDetail/FlightBody/CabinAccordion";
import {NETWORK_STATUS, REFRESH_INTERVAL} from "../constants/Apollo";

export default (props) => {

  const {
    flight
  } = props;

  if (!flight) {
    return <ErrorPage
      title="Error getting Cabin Summary"
      message="flightId was missing from the request"
      hideRefresh={true}/>
  }

  return (
    <Query query={summaryQuery}
           variables={{id: flight.id}}
           fetchPolicy={"network-first"}
           errorPolicy="all"
           pollInterval={REFRESH_INTERVAL.SUMMARY_REFRESH_INTERVAL}>
      {({networkStatus, error, data = {}}) => {
        const loading = networkStatus <= NETWORK_STATUS.SET_VARIABLES;
        if (error) {
          console.error("Error loading Pax List", error);
          return <ErrorPage
            title="Error getting Pax List"
            message="There was a problem getting Pax List"
            hideRefresh={true}/>;
        } else {
          return <CabinAccordion {...props}
                                 loading={loading}
                                 ssrSummary={data?.flight?.ssr_summary}
                                 qffSummary={data?.flight?.qff_summary}/>
        }
      }}
    </Query>
  )
}
