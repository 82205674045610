import styled from 'styled-components';
import device from "../../../../constants/Device";
import FlexSection from "../../../common/atoms/FlexSection";

export const TerminalHeadWrapper = styled(FlexSection)`
  padding: ${props => props.theme.space2};
  min-height: ${props => props.theme.size9};

  /* Needed otherwise some browsers will incorrectly shrink this to height = 0
     See https://philipwalton.com/articles/normalizing-cross-browser-flexbox-bugs/#minimum-content-sizing-of-flex-items */
  flex-shrink: 0;
  
  /* Mobile Responsive */
  flex-wrap: wrap;
  @media ${device.laptop} {
    flex-wrap: inherit;
  }
`;

export const TerminalMenuWrapper = styled.div`
  
  /* Mobile Responsive */
  width: 120px;
  @media ${device.tablet} {
    width: 160px;
  }
  @media ${device.laptop} {
    width: 287px;
  }
  
  flex-grow: 1;
`;
