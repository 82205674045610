import styled from 'styled-components';

export const NetworkSummaryWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const DataColumn = styled.div`
  flex: 2;
`;

export const ButtonColumn = styled.div`
  flex: 1;
`;
