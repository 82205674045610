import gql from 'graphql-tag';

const fieldTypes = {
  "airline_group": "FlightGroupEnum",
  "departure_airport_code": "String",
  "arrival_airport_code": "String",
  "assigned_staff_number": "String",
};

export default (fields) => {
  const subFields = fields.filter(f => fieldTypes[f]);

  const paramInputStr = subFields.map(f => `$${f}: ${fieldTypes[f]}`).join(', ');
  const paramInputStrWrapped = paramInputStr ? `(${paramInputStr})` : '';

  const subCallParamsStr = subFields.map(f => `${f}: $${f}`).join(', ');
  const subCallParamsStrWrapped = subCallParamsStr ? `(${subCallParamsStr})` : '';

  return gql`
    subscription FlightUpdateEvent${paramInputStrWrapped} {
      flightUpdateEvent${subCallParamsStrWrapped} {
          id
      }
    }
  `;

}
