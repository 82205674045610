import styled from 'styled-components';

export const FlightDetailWrapper = styled.div`
  display:flex;
  flex-direction: column;
  
  border-left: 1px solid ${props => props.theme.colors.divider};
  
  position: relative;
  height: 100%;
  overflow: hidden;
`;
