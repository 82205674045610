import React from 'react';
import {Query} from 'react-apollo';

import getFlightsQuery from '../gql/get-flights';
import {VIEW_TYPE} from '../constants/View';
import {GROUP} from '../constants/User';
import {DOMESTIC, INTERNATIONAL, REGIONAL} from '../constants/FlightDomainFilter';
import ErrorPage from '../components/common/ErrorPage';
import FlightList from '../components/flight/FlightList';
import subscribeFlightUpdatesBuilder from '../gql/subscribe-flight-updates-builder';
import flightEventUpdateQuery from '../data/flight-event-update';
import subscriptionWrapperBuilder from '../utils/subscription-wrapper-builder';
import {TimezoneOffsetContext} from '../contexts/TimezoneOffsetContext';
import LoadingPage from "../components/common/molecules/LoadingPage";
import {DOMAIN, TYPE} from "../constants/Flight";

const FLIGHTS_POLL_INTERVAL = 5 * 60 * 1000;

export default ({user, view, viewType, terminalPath, ...rest}) => {
  const fieldFilters = {};
  if (view.view_type === VIEW_TYPE.TERMINAL) {
    const portKey = viewType === TYPE.DEPARTURE ? 'departure_airport_code' : 'arrival_airport_code';
    fieldFilters[portKey] = view.filter.airport_code;
  } else if (view.view_type === VIEW_TYPE.ROSTER) {
    fieldFilters.assigned_staff_number = view.filter.assigned_staff_number;
  }

  if (user.group !== 'ALL') {
    fieldFilters.airline_group = user.group;
  }

  const queryVariables = {
    lower_window: view.lower_window,
    upper_window: view.upper_window,
    ...fieldFilters
  };

  return (
    <Query query={getFlightsQuery} variables={queryVariables} fetchPolicy={"network-only"} pollInterval={FLIGHTS_POLL_INTERVAL} errorPolicy="all">
      {({error, loading, data = {}, subscribeToMore, refetch}) => {
        if (loading) {
          return <LoadingPage/>
        }

        if (error || !data.getFlights) {
          console.error('Error loading flight list', error);
          return (
            <ErrorPage
              title="Error loading flights"
              message="There was a problem loading the flight list"
            />
          );
        }

        // Filter the flight list if domain filter is provided
        let flights = data.getFlights;
        if (view.domains && view.domains.length > 0 && view.domains.length < 3) {
          const criteria = [];
          if (view.domains.includes(INTERNATIONAL)) {
            criteria.push(f => f.airline_group === GROUP.MAINLINE && f.domain === DOMAIN.INTERNATIONAL);
          }
          if (view.domains.includes(DOMESTIC)) {
            criteria.push(f => f.airline_group === GROUP.MAINLINE && f.domain === DOMAIN.DOMESTIC);
          }
          if (view.domains.includes(REGIONAL)) {
            criteria.push(f => f.airline_group === GROUP.LINK);
          }

          flights = flights.filter(f => criteria.some(criterion => criterion(f)));
        }

        const doSubscribe = buildSubscriptionFn(fieldFilters, subscribeToMore, refetch);

        return (
          <TimezoneOffsetContext.Consumer>
            {terminalOffset =>
              <FlightList
                {...rest}
                key={`${terminalPath}--${viewType}`}
                terminalPath={terminalPath}
                view={view}
                viewType={viewType}
                terminalOffset={terminalOffset}
                doSubscribe={doSubscribe}
                flights={flights}
                user={user}
              />
            }
          </TimezoneOffsetContext.Consumer>
        )
      }}
    </Query>
  );
}

function buildSubscriptionFn(queryVariables, subscribeToMore, refetch) {
  return subscriptionWrapperBuilder((onError) => {
    const variables = {};
    const fields = [];

    Object.entries(queryVariables).forEach(([key, value]) => {
      if (value) {
        variables[key] = value;
        fields.push(key);
      }
    });

    return [
      subscribeToMore({
        document: subscribeFlightUpdatesBuilder(fields),
        updateQuery: flightEventUpdateQuery,
        variables,
        onError
      }),
    ];
  }, refetch);
}
