import styled from 'styled-components';
import {InfoLabelWrapper, Label} from "../../../common/molecules/InfoLabel/style";

export const AirportSummaryWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: ${props => props.theme.space1};
`;

export const IconWrapper = styled.div`
  float: right;
  cursor: pointer;
`;

export const AirportSummaryCard = styled.div`
  background: white;

  padding: ${props => props.theme.space5};
`;

export const InfoLabelDiv = styled.div`

  ${InfoLabelWrapper} {
    font-size: ${props => props.theme.fontSizeLarge};
  }

  ${Label} {
    font-size: ${props => props.theme.fontSizeLarge};
  }

  padding: ${props => props.theme.space3} 0;
`;

export const ButtonWrapper = styled.div`
  padding: ${props => props.theme.space2} 0;
`;
