import moment from 'moment';
import {QUALIFIER, STATUS} from "../constants/Flight";

const DELAY_THRESHOLD = 15; // Number of minutes flight needs to be delayed by more than before it's considered late

export const getMinutesLate = (scheduled, actual) => {
  const delay = getMinutesDiff(scheduled, actual);
  return delay > DELAY_THRESHOLD ? delay : 0;
};

export const getMinutesDiff = (scheduled, actual) => {
  return moment(actual).diff(scheduled, 'minutes');
};

// Extra status gives a readable flight delay status, if not late we get nothing
export const getExtraStatus = (minutesLate) => {
  if (minutesLate === 0) return '';

  const sign = minutesLate > 0 ? '+' : '-';
  minutesLate = Math.abs(minutesLate);

  const hours = Math.floor(minutesLate / 60);
  const minutes = minutesLate % 60;

  return `(${sign}${hours > 0 ? `${hours}h` : ''}${minutes > 0 ? `${minutes}m` : ''})`;
};

/**
 * Format iOS8601 into local time.
 * @param isoTime: Time in IOS8601 Format
 * @param offset: Offset to apply
 * @returns {string} Offset
 */
export const getLocalTime = (isoTime, offset) => {
  return moment.utc(isoTime).utcOffset(offset).format('HH:mm');
};

/**
 * Format iOS8601 into local date.
 * @param isoTime: Time in IOS8601 Format
 * @param offset: Offset to apply
 * @returns {string} Offset
 */
export const getLocalDate = (isoTime, offset) => {
  return moment.utc(isoTime).utcOffset(offset).format('DD/MM/YY');
};

export const getFlightNumber = (flight) => {
  return `${flight.operating_airline}${flight.flight_number}`;
};

export const getRegistration = (flight) => {
  if (!flight || !flight.aircraft_registration) {
    return '';
  }
  return `${flight.aircraft_registration.slice(0, 2)}-${flight.aircraft_registration.slice(2)}`;
};

/**
 * Status Text for departure flight in FlightRow
 * @param flight
 * @returns {string}
 */
export const getDepartureStatus = (flight, pts) => {
  if (flight.flight_status === STATUS.CANCELLED) {
    return 'Cancelled';
  }

  if (flight.offblocks_qualifier === QUALIFIER.ACTUAL) {
    // If the flight has already off-blocks'ed then ignore the PTS
    return 'Off Blocks';
  }

  // Show the previously completed workflow step for our current state
  if (flight.pts_workflow_status) {
    const ptsStep = pts?.find(p => p.code === flight.pts_workflow_status);
    return ptsStep?.description ?? flight.pts_workflow_status;
  }

  return 'Scheduled';
};

/**
 * Status Text for arrival flight in FlightRow
 * @param flight
 * @returns {string}
 */
export const getArrivalStatus = (flight) => {
  if (flight.flight_status === STATUS.CANCELLED) {
    return 'Cancelled';
  } else if (flight.onblocks_qualifier === QUALIFIER.ACTUAL) {
    return 'Arrived';
  } else if (flight.offblocks_qualifier === QUALIFIER.ACTUAL) {
    return 'Airborne';
  } else {
    return 'Scheduled';
  }
};
