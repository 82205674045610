import styled from "styled-components";

const TerminalName = styled.span`
  font-family: ${props => props.theme.fontImportant};
  font-size: ${props => props.theme.fontSizeLarge};
  color: ${props => props.theme.colors.headers};
  
  margin-left: ${props => props.theme.size2};
`;

export default TerminalName;
