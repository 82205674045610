import styled from 'styled-components';

export const TerminalSettingsWrapper = styled.div`
  margin-top: ${props => props.theme.space2};
  font-family: ${props => props.theme.fontImportant};
  font-weight: normal;
  color: ${props => props.theme.colors.oldBrandPrimary};
  font-size: ${props => props.theme.fontSizeLarge};
  cursor: pointer;
`;
