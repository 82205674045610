import React, {Component} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';
import Auth from '@aws-amplify/auth';
// Note: This Hub must be the same that is used by @aws-amplify/auth so the @aws-amplify/core dep
//  MUST NOT be duplicated in the project i.e. if after yarn install your @aws-amplify/auth has
//  its own copy of core in it's node/modules then the core dep version has probably drifted somehow
import {Hub} from '@aws-amplify/core';

import 'draft-js-mention-plugin/lib/plugin.css';
import './semantic/dist/semantic.min.css';

import UserDashboardRouter from './components/user/UserDashboardRouter';
import OfflineMessage from './components/template/OfflineMessage';
import {OfflineContextConsumer} from './contexts/OfflineContext';
import {ThemeProvider} from "styled-components";
import {QantasGlobalStyle, qantasTheme} from "./App.theme"
import ErrorPage from "./components/common/ErrorPage";
import LoadingPage from "./components/common/molecules/LoadingPage";
import {SNACKBAR} from './constants/Layer';

const AUTH_STATE_LOADING = 'loading';
const AUTH_STATE_SIGNED_IN = 'signedIn';
const AUTH_STATE_SIGNED_OUT = 'signedOut';

class App extends Component {

  state = {
    authState: AUTH_STATE_LOADING,
  };

  constructor() {
    super();
    Hub.listen('auth', this.gotAuthEvent);
  }

  gotAuthEvent = async ({ payload }) => {
    if (payload.event === 'signIn') {
      this.setState({
        authState: AUTH_STATE_SIGNED_IN,
      })
    } else if (payload.event === 'signIn_failure') {
      this.setState({
        authState: AUTH_STATE_SIGNED_OUT,
        authError: true,
      });
    }
  };

  componentDidMount = () => {
    (async () => {
      try {
        await Auth.currentAuthenticatedUser();
        this.setState({authState: AUTH_STATE_SIGNED_IN});
      } catch (e) {
        this.setState({authState: AUTH_STATE_SIGNED_OUT});
      }
    })();
  };

  render = () => this.renderTheme();

  /**
   * Adding Theme support for `styled component`.
   * @see https://www.styled-components.com/docs/advanced#theming
   * @author Jacktator
   */
  renderTheme = () =>
    <ThemeProvider theme={qantasTheme()}>
      <QantasGlobalStyle/>
      <div id="snackbar-notifications" style={{ zIndex: SNACKBAR.CONTAINER, position: "absolute" }} />
      <SnackbarProvider
        domRoot={document.getElementById("snackbar-notifications")}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {this.renderRounter()}
      </SnackbarProvider>
    </ThemeProvider>;

  renderRounter = () => {
    if (this.state.authState === AUTH_STATE_LOADING) {
      return <LoadingPage/>;
    }

    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" render={() => this.doAzureLogin()}/>
          <Route path="/secret-login" render={() => this.doCognitoHostedLogin()}/>
          <Route path="/oauth" render={() => this.renderOauth()}/>
          <Route path="/error" render={() =>
            <ErrorPage title="Error logging in" message="There was a problem logging you in"/>
          }/>
          <Route render={() => this.renderDashboard()}/>
        </Switch>
      </BrowserRouter>
    )
  };

  doAzureLogin = () => {
    Auth.federatedSignIn({provider: 'Jetstar-Azure-IDP'});
  };

  doCognitoHostedLogin = () => {
    Auth.federatedSignIn();
  };

  renderOauth = () => {
    if (this.state.authState === AUTH_STATE_SIGNED_IN) {
      return <Redirect to='/' push />;
    }
    else if (this.state.authError) {
      return <ErrorPage title="Error logging in" message="There was a problem logging you in" />
    }
    return <LoadingPage/>;
  };

  renderDashboard = () => {
    if (this.state.authState !== AUTH_STATE_SIGNED_IN) {
      return <Redirect to='/login' />;
    }

    return (
      <>
        <OfflineContextConsumer>
          {offline => offline.isOffline && <OfflineMessage />}
        </OfflineContextConsumer>
        <UserDashboardRouter/>
      </>
    );
  };
}

export default App;
