import React from 'react';

import ErrorPage from "../components/common/ErrorPage";
import RosterView from "../components/terminal/RosterView";

const RosterData = ({view, terminalPath, user}) => {
  if (!user.staff_number) {
    return (
      <ErrorPage
        title="Problem finding your staff number"
        message="There was a problem finding your staff number" />
    );
  }

  const newView = {
    ...view,
    filter: {
      ...view.filter,
      assigned_staff_number: user.staff_number
    }
  };

  return (
    <RosterView terminalPath={terminalPath}
                user={user}
                view={newView}/>
  )
};

export default RosterData;
