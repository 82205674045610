import React, {Component} from 'react';
import PropTypes from "prop-types";
import Modal from "../../../../../common/molecules/Modal";
import MutatedDeleteNotice from "../../../../../notice/menu/MutatedDeleteNotice";
import NoticeType from "../../../../../../constants/Notice";

class DeleteNoticeModal extends Component {

  state = {
    showModal: false,
  };

  render = () => {

    const {
      notice,
      offset,
      trigger,
    } = this.props;

    return (
      <Modal
        isOpen={this.state.showModal}
        onOpen={this.openModal}
        onClose={this.closeModal}
        trigger={
          trigger
        }>
        <MutatedDeleteNotice
          offset={offset}
          notice={notice}
          closeModal={this.closeModal}/>
      </Modal>
    )
  };

  openModal = () => this.setState({showModal: true});

  closeModal = () => this.setState({showModal: false});
}

DeleteNoticeModal.propTypes = {
  notice: NoticeType.isRequired,
  offset: PropTypes.number.isRequired,
  trigger: PropTypes.node,
};

export default DeleteNoticeModal;
