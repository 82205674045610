import styled, {css} from 'styled-components';
import SlidingPanel from "react-sliding-side-panel";
import FlexSection from "../../../../../../common/atoms/FlexSection";

export const SliderMenuPanel = styled(SlidingPanel)`
  height: 100%;
`;

export const SliderPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  background: ${props => props.theme.colors.background};
  position: relative;
  
  width: 100%;
  height: 100%;
`;

export const SliderMenuWrapper = styled.div`
  flex-grow: 1;
`;

export const MenuSection = styled.div`
  padding: ${props => props.theme.space2} 0;
`;

export const SectionHead = styled(FlexSection)`
  padding: ${props => props.theme.space2};
`;

export const SectionTitle = styled.span`
  box-sizing: border-box;
  
  flex-grow: 1;
`;

export const SectionBody = styled.div``;

export const MenuItem = styled.div`
  color: ${props => props.theme.colors.grey};
  font-family: ${props => props.theme.fontImportant};
  font-size: ${props => props.theme.fontSizeLarge};
  
  padding: ${props => props.theme.space3};
  
  ${props => props.active && css`
    color: ${props => props.theme.colors.body};
    
    border-left: ${props => props.theme.size1} solid ${props => props.theme.colors.oldBrandPrimary};
    padding-left: ${props => props.theme.size2};
  `};
`;

export const Muted = styled.div`
  color: ${props => props.theme.colors.divider};
  font-size: ${props => props.theme.fontSizeLarge};
  
  padding: ${props => props.theme.space2};
`;
