import styled from 'styled-components';

export const NetworkMapWrapper = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
`;

export const MenuColumn = styled.div`
  position: relative;
  height: 100%;
  background: ${props => props.theme.colors.background};
`;

export const ContentContainer = styled.div`

  max-height: 320px;
  overflow-y: auto;

  background: ${props => props.theme.colors.background};

  padding: ${props => props.theme.space2};

`;
