import styled, {css} from 'styled-components';
import FlexSection from "../../../../common/atoms/FlexSection";
import {OFFSET as TIMELINE_OFFSET} from "../../../../common/templates/Timeline/style"

const MESSAGE_TIMELINE_OFFSET = TIMELINE_OFFSET - 20;

export const MenuWrapper = styled.div`
  display: none;
  position: relative;
`;

export const MenuColumn = styled.div`
  display: none;
  position: relative;
`;

export const MessageRowWrapper = styled.div`
  border-radius: ${props => props.theme.cornerRadius};
  display: flex;
  white-space: pre-wrap;
  
  padding: 5px 10px;
  
  color: ${props => props.theme.colors.body};
  svg {
    fill: ${props => props.theme.colors.body};
  }
  ${props => props.shouldFade && css`
    color: ${props => props.theme.colors.grey};
    svg {
      fill: ${props => props.theme.colors.grey};
    }
  `};
  
  
  ${props => props.enableHover && css`
    :hover {
      background: ${props => props.theme.colors.background};
    };
    
    &:hover ${MenuWrapper} {
      display: inline-block;
    };
    
    &:hover ${MenuColumn} {
      display: inline-block;
    };
  `};
  
  // @deprecated
  ${props => props.enableDelta && css`
    :hover {
      background: linear-gradient(
        to right,
        ${props => props.theme.colors.background} 0%,
        ${props => props.theme.colors.background} calc(${MESSAGE_TIMELINE_OFFSET}px - 2.51px),
        ${props => props.theme.colors.divider} calc(${MESSAGE_TIMELINE_OFFSET}px - 2.5px),
        ${props => props.theme.colors.divider} calc(${MESSAGE_TIMELINE_OFFSET}px + 2.5px),
        ${props => props.theme.colors.background} calc(${MESSAGE_TIMELINE_OFFSET}px + 2.51px),
        ${props => props.theme.colors.background} 100%);
    }
  `};
  
  ${props => props.shouldShowDelta && css`
    :hover {
      background: linear-gradient(
        to right,
        ${props => props.theme.colors.background} 0%,
        ${props => props.theme.colors.background} calc(${MESSAGE_TIMELINE_OFFSET}px - 2.51px),
        ${props => props.theme.colors.divider} calc(${MESSAGE_TIMELINE_OFFSET}px - 2.5px),
        ${props => props.theme.colors.divider} calc(${MESSAGE_TIMELINE_OFFSET}px + 2.5px),
        ${props => props.theme.colors.background} calc(${MESSAGE_TIMELINE_OFFSET}px + 2.51px),
        ${props => props.theme.colors.background} 100%);
  `};
  
  // @deprecated
  ${props => props.fade && css`
    opacity: 0.5;
  `};
 
`;

export const DeltaColumn = styled.div`
  width: ${props => props.theme.size11};
  text-align: center;
  font-family: ${props => props.theme.fontRegular};
`;

export const DeltaWrapper = styled(FlexSection)`
  flex-direction: column;
  font-size:  ${props => props.theme.fontSizeSmall};
  color: ${props => props.theme.colors.headers};
  text-transform: lowercase;
`;

export const Delta = styled.div`
  font-size:  ${props => props.theme.fontSizeExtraLarge};
`;

export const DotColumn = styled(FlexSection)`
  flex-direction: column;
  width: ${props => props.theme.size10};
  
  ${props => props.isShowingTitle && css`
    padding-top: ${props => props.theme.size5};
  `};
`;

export const ContentColumn = styled.div`
  flex: 1;
`;

export const ContentBody = styled.div`
  font-family:  ${props => props.theme.fontImportant};
  font-size:  ${props => props.theme.fontSizeLarge};
`

export const DueTime = styled.div`
  font-size:  ${props => props.theme.fontSizeSmall};
  color: ${props => props.theme.colors.headers};
  `;

export const TimeStampColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  `;

export const ContentSecondary = styled.div`
  margin-left: ${props => props.theme.space2};
`