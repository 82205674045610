import styled, {css} from "styled-components";
import {FLIGHT_DETAIL} from "../../../../constants/Layer";
import {TYPE} from "../../../../constants/Notice";

export const DotWrapper = styled.div`
  background-color: white;
  width: ${props => props.theme.size3};
  height: ${props => props.theme.size3};
  border-width: ${props => props.theme.borderWidth};
  border-style: solid;
  border-radius: ${props => props.theme.size4};
  border-color: transparent;
  
  z-index: ${FLIGHT_DETAIL.PTS_DOT};
  
  ${props => props.isLarge && css`
    width: ${props => props.theme.size4};
    height: ${props => props.theme.size4};
    border-width: ${props => props.theme.size1};
  `};
  
  ${props => {
  switch (props.type) {
    case TYPE.EVENT:
      return css`
          background: ${props => props.theme.colors.deltaSystem}
        `;
    case TYPE.ESCALATION:
      if (props.isCompleted) {
        return css`
            background: ${props => props.theme.colors.brandPrimary};
          `;
      } else {
        return css`
          background: ${props => props.theme.colors.brandPrimary};
 
          position: relative;
          ::after, ::before {
            position: absolute;
            border-radius: 4px;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            content: "";
            transform: scale(.5);
            transform-origin: center center;
            animation: glow 3s linear infinite;
            background: ${props => props.theme.colors.brandPrimary};
            
            ${props => props.allBoarded && css`
              background: ${props => props.theme.colors.success};
            `}
          }
          
          ::after {
            animation-delay: 2s;
          }
          
          @keyframes glow {
            0% {
              transform: scale(.5);
              opacity: 0
            }
            50% {
              opacity: .1
            }
            70% {
              opacity: .05
            }
            to {
              transform: scale(5);
              opacity: 0
            }
          }
        `;
      }
    case TYPE.PTS:
      if (props.isSkipped) {
        return css`
            border-color: ${props => props.theme.colors.lightGrey};
          `;
      } else if (props.isLate === null) {
        return css`
            border-color: ${props => props.theme.colors.lightGrey};
          `;
      } else if (props.isLate) {
        return css`
            border-color: ${props => props.theme.colors.error};
          `;
      } else if (!props.isLate) {
        return css`
            border-color: ${props => props.theme.colors.brandSecondary};
          `;
      } else {
        // Do Nothing
        return;
      }
    default:
      css`
          display: none;
        `;
      return;
  }
}};
`;
