import styled from "styled-components";
import FlexSection from "../../../../../common/atoms/FlexSection";
import device from "../../../../../../constants/Device";
import InfoLabel from "../../../../../common/molecules/InfoLabel";
import {POPOUT} from "../../../../../../constants/Layer";

export const PopoutSectionWrapper = styled(FlexSection)`
  padding: 5px 10px;
  white-space: nowrap;
  
  display: flex;
  @media ${device.mobileL} {
    display: none;
  }
  
  ${FlexSection} {
    z-index: ${POPOUT.CONTENT};
  }
  
  flex-grow: 1;
`;

export const PopoutInfoLabel = styled(InfoLabel)`
  flex: 1;
`;
