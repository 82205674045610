import React from 'react';

import controlTowerImage from '../../../../images/control-tower.svg';
import {DesktopHeader, MobileHeader, Tab, TerminalEventsWrapper, Title} from "./style";
import Icon from "../../../common/atoms/Icon";
import {ReactComponent as IconLeftArrow} from "../../../../images/icons/left-arrow.svg";
import {Link} from "react-router-dom";
import TerminalName from "../../../common/atoms/TerminalName";
import TerminalChannel from "../TerminalChannel";
import AirportType from "../../../../constants/Airport";
import PropTypes from "prop-types";

const TerminalEvents = ({terminal, terminalPath}) => {
  return (
    <TerminalEventsWrapper>
      {renderDesktopHeader()}
      {renderMobileHeader(terminal, terminalPath)}
      <TerminalChannel terminal={terminal}
                       noticeChannelId={terminal.notice_channel_id}/>
    </TerminalEventsWrapper>
  )
};

const renderDesktopHeader = () =>
  <DesktopHeader>
    <Tab>
      <img src={controlTowerImage} alt=''/>
      Terminal Events
    </Tab>
  </DesktopHeader>;

const renderMobileHeader = (terminal, terminalPath) =>
  <MobileHeader>
    <Link to={terminalPath}>
      <Icon>
        <IconLeftArrow/>
      </Icon>
    </Link>
    <TerminalName>
      {terminal.code}
    </TerminalName>
    <Title>
      Terminal Events
    </Title>
  </MobileHeader>;

TerminalEvents.propTypes = {
  terminal: AirportType.isRequired,
  terminalPath: PropTypes.string.isRequired,
};

export default TerminalEvents;
