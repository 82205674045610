import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Accordion from "../../../common/molecules/Accordion";
import {UnserviceableAccordionWrapper} from "./style";
import FlightType from "../../../../constants/Flight";
import UnserviceableFlightList from "./UnserviceableFlightList";
import {ReactComponent as IconEngineering} from "../../../../images/icons/flight-menu-engineering.svg";

const UnserviceableAccordion = ({flights}) => {
  const [isAccordionOpen, setAccordionOpen] = useState(true)

  return (
    <UnserviceableAccordionWrapper>
      <Accordion
        collapsed={!isAccordionOpen}
        icon={<IconEngineering />}
        head={`${flights.length > 0 ? `${flights.length} ` : ''}Unserviceable`}
        body={<UnserviceableFlightList flights={flights}/>}
        onToggle={() => setAccordionOpen(!isAccordionOpen)}/>
    </UnserviceableAccordionWrapper>
  );
}

UnserviceableAccordion.propTypes = {
  flights: PropTypes.arrayOf(FlightType),
};

export default UnserviceableAccordion;
