import React, {Component} from 'react';
import {SearchInputWrapper} from "./style";
import Icon from "../../atoms/Icon";
import {ReactComponent as SearchIcon} from "../../../../images/icons/search.svg"
import PropTypes from "prop-types";

class SearchInput extends Component {

  escFunction = (event) => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.onBlur();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  state = {
    value: "",
    isActive: false,
  };

  render = () => {

    const {
      placeholder,
    } = this.props;

    return (
      <SearchInputWrapper isActive={this.state.isActive}>
        <label>
          <Icon>
            <SearchIcon/>
          </Icon>
          <input value={this.state.value}
                 onFocus={this.onFocus}
                 onBlur={this.onBlur}
                 onChange={this.onChange}
                 placeholder={placeholder}/>
        </label>
        {
          this.state.value.length > 0 &&
          <button onClick={this.onClear}>Clear</button>
        }
      </SearchInputWrapper>
    )
  };

  onFocus = () => {
    this.setState({
      isActive: true
    });
  };

  onBlur = () => {
    this.setState({
      isActive: false
    });
  };

  onChange = (e) => {
    const {
      onChange,
    } = this.props;

    this.setState({
      value: e.target.value
    });

    onChange(e);
  };

  onClear = (e) => {
    const {
      onChange,
    } = this.props;

    e.preventDefault();
    this.setState({
      value: ""
    });

    onChange(e);
  }
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default SearchInput;
