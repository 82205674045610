import styled from 'styled-components';
import FlexSection from "../../../../../common/atoms/FlexSection";
import {Link} from "react-router-dom";

export const AirportLink = styled(Link)`
  color: ${props => props.theme.colors.body};
  
  :hover {
    color: ${props => props.theme.colors.body};
  }
`;

export const AirportRowWrapper = styled(FlexSection)`
  
  background: white;
  border-radius: 4px;
  position: relative;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  padding: ${props => props.theme.space1} ${props => props.theme.space2};
  
  margin-bottom: ${props => props.theme.space1};
  
`;
