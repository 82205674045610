import React from 'react';
import {useMutation} from 'react-apollo';
import { useSnackbar } from 'notistack';

import addNotice from '../../../../gql/add-notice';
import MessageForm from './MessageForm';

export default ({ chatChannelId, notify }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [callAddNotice, {loading}] = useMutation(
    addNotice,
    {
      onError: () => {
        enqueueSnackbar('An error occurred while trying to send the message', {variant: 'error'});
      }
    }
  );

  const formSubmitted = ({content, type}) => {
    callAddNotice({
      variables: {
        notice_channel_id: chatChannelId,
        type,
        content,
        is_completeable: false
      }
    });
  };

  return (
    <MessageForm
      loading={loading}
      key={chatChannelId}
      notify={notify}
      onSubmit={formSubmitted}/>
  );
}
