import styled from 'styled-components';

export const UserDashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  background-color: white;
  
  width: 100%;
  height: 100%;
  max-height: 100%;
`;

export const MainDashboard = styled.div`
  flex: 1;
  overflow: hidden;
`;
