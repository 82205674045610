import gql from 'graphql-tag';

export default gql`
  query GetBasePts {
    getBasePts {
      code
      description
      action_text
      previous_steps
      next_steps
    }
  }
`;
