import gql from 'graphql-tag';

export default gql`
  fragment MessageFields on Message {
    __typename
    
    id
    notice_channel_id
    messageType: type
    content
    created_at
    created_by {
      id
      name
      role {
        id
        display_name
      }
    }
    deleted_at
    deleted_by {
      id
      name
      role {
        id
        display_name
      }
    }
    is_completeable
    completed_at
    completed_reason
    completed_skipped
    completes_pts_workflow_status
    completed_by {
      id
      name
      role {
        id
        display_name
      }
    }
    is_completeable
    assigned_role_ids
  }
`;
