import React from 'react';
import MessageList from "../../../notice/message/list/MessageList";
import AirportType from "../../../../constants/Airport";
import AddEventButton from "../../../notice/event/add/AddEventButton";
import Timeline from "../../../common/templates/Timeline/style";
import moment from "moment";
import {withNoticeChannel} from "../../../../hocs/withNoticeChannel";
import PropTypes from "prop-types";
import {withLocalUserData} from '../../../../hocs/withLocalUserData';
import {hasPermission} from '../../../../utils/permission';
import {ACCESS_LEVEL, PERMISSION} from '../../../../constants/Permission';

const TerminalChannel = ({
                           terminal,
                           user,
                           noticeChannel: {
                             channel,
                             channel: {
                               messages: {
                                 nodes,
                               },
                             },
                             fetchMoreMessages,
                           }
                         }) => {
  /**
   * For Terminal Events, only events within 24 hours should be black. Prior to 24hours, all grey.
   *
   * Confirmed on 13/02/2020
   *
   * @author Jacktator Shelsa
   */
  const now = moment.utc().utcOffset(terminal.timezone_offset);
  const shouldMessageFade = message => !now.isSame(message.created_at, 'day');

  const isChannelAdmin = hasPermission(user, PERMISSION.terminalEvent, ACCESS_LEVEL.ADMIN);

  return (
    <>
      <MessageList
        channel={channel}
        nodes={nodes}
        fetchMoreMessages={fetchMoreMessages}
        terminalOffset={terminal.timezone_offset}
        shouldShowDelta={true}
        shouldMessageFade={shouldMessageFade}
        isChannelAdmin={isChannelAdmin}/>
      <Timeline>
        <AddEventButton
          noticeChannelId={terminal.notice_channel_id}
          normalOnly={true}
          contextName={terminal.code}/>
      </Timeline>
    </>
  );
};

TerminalChannel.propTypes = {
  terminal: AirportType.isRequired,
  noticeChannelId: PropTypes.number.isRequired,
};

export default withNoticeChannel(withLocalUserData(TerminalChannel));
