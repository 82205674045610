import styled from 'styled-components';
import FlexSection from "../../../common/atoms/FlexSection";

export const FlightFilterWrapper = styled(FlexSection)`
  flex-wrap: wrap-reverse;

  font-size: ${props => props.theme.fontSizeLarge};
  font-family: ${props => props.theme.fontImportant};
  position: relative;
  min-height: 40px;

  flex-shrink: 0;
`;

export const TabWindowWrapper = styled.div`
  flex-grow: 9999;
  display: flex;
  margin: ${props => props.theme.space1} ${props => props.theme.space2};
`;

export const TabWrapper = styled(FlexSection)`
`;

export const FilterButtonWrapper = styled.div`
    padding-left: ${props => props.theme.space4};
    flex-grow: 0;
    display: flex;
    align-items: center;
`;

export const DomainFilterLabel = styled.div`
  color: ${props => props.theme.colors.grey};
  cursor: default;
`;

export const SearchFilterWrapper = styled(FlexSection)`
  flex-wrap: wrap-reverse;
  flex-grow: 1;
`;

export const SearchInputWrapper = styled.div`
  flex-grow: 9999;
  margin: ${props => props.theme.space1} ${props => props.theme.space2};
`;
