import styled from 'styled-components';
import device from "../../../../constants/Device";

export const AddTerminalPageWrapper = styled.div`
  padding-top: 24px;
  margin: auto;
  width: 95%;
  max-width: 480px;
  text-align: center;
  @media ${device.tablet} {
    padding-top: 80px;
  }
`;
