import React from 'react';
import {Query} from 'react-apollo';

import ErrorPage from '../components/common/ErrorPage';

import getNetworkSummaryQuery from '../gql/get-network-summary';
import {NETWORK_MAP, NETWORK_STATUS} from "../constants/Apollo";
import UserType from "../constants/User";
import LoadingPage from "../components/common/molecules/LoadingPage";
import NetworkSummary from "../components/networkmap/NetworkMenu/NetworkSummary";

const NetworkSummaryData = ({user}) => {
  return (
    <Query query={getNetworkSummaryQuery}
           fetchPolicy="network-only"
           errorPolicy="all"
           variables={{airline_group: user.group !== 'ALL' ? user.group : undefined}}
           pollInterval={NETWORK_MAP.REFRESH_INTERVAL}>
      {({networkStatus, error, data = {}}) => {
        if (error) {
          console.error('Failed loading network map query', error);
          return (
            <ErrorPage
              title="Error loading network map"
              message="There was a problem loading the data for network map"/>);
        }

        const loading = networkStatus <= NETWORK_STATUS.SET_VARIABLES;
        if (loading) {
          return <LoadingPage/>;
        }

        const {
          getAUAirportsFlightSummary,
          lateBoardingFlights,
          escalatedFlights,
          engineeringRiskFlights,
        } = data;

        return (
          <NetworkSummary user={user}
                          airports={getAUAirportsFlightSummary}
                          lateFlights={lateBoardingFlights}
                          escalatedFlights={escalatedFlights}
                          engineeringRiskFlights={engineeringRiskFlights}/>
        );
      }}
    </Query>
  )
};

NetworkSummaryData.propTypes = {
  user: UserType.isRequired,
};

export default NetworkSummaryData;
