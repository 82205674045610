import React from 'react';
import PropTypes from 'prop-types';
import FlightType from "../../../../../constants/Flight";
import {ContentContainer} from "../../../style";
import LateFlight from "./LateFlight";
import Page from "../../../../common/atoms/Page";

const LateFlightList = ({flights}) => {
  return (
    <ContentContainer>
      {
        flights.length > 0 ?
          flights.map(flight => (
            <LateFlight key={flight.id}
                        flight={flight}/>
          )) :
          <Page>
            <p>No late boarding flights</p>
          </Page>
      }
    </ContentContainer>
  );
};

LateFlightList.propTypes = {
  flights: PropTypes.arrayOf(FlightType),
};

export default LateFlightList;
