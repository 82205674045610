import styled from 'styled-components';
import FlexSection from "../../atoms/FlexSection";
import device from "../../../../constants/Device";

export const DateTimeDisplayWrapper = styled(FlexSection)`

  padding: ${props => props.theme.space1};

  /* Mobile Responsive */
  display: none;
  @media ${device.tablet} {
    display: flex;
  }
`;

export const TimeDisplay = styled.div`
  font-family: ${props => props.theme.fontMedium};
  width: 65px;
  padding: ${props => props.theme.space1};
`;

export const TimeZoneDisplay = styled.div`
  min-width: 75px;
  padding: ${props => props.theme.space1};
`;

export const DateDisplay = styled.div`
  font-family: ${props => props.theme.fontMedium};
  width: 60px;
  padding: ${props => props.theme.space1};
  text-transform: uppercase;
`;
