import React, {Component} from 'react';
import {ScrollContainerWrapper} from "./style";

class ScrollContainer extends Component {

  getSnapshotBeforeUpdate = (prevProps, prevState) => {
    // Get scroll position of container for post-update
    if(this.props.scroll === 'down') {
      return this.scrollParent.scrollTop;
    } else {
      return this.scrollParent.scrollHeight - this.scrollParent.scrollTop;
    }
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const oldScrollOffset = snapshot;

    // When new messages get added we need to maintain scroll position inside the list
    if(this.props.scroll === 'down') {
      this.scrollParent.scrollTop = oldScrollOffset;
    } else {
      this.scrollParent.scrollTop = this.scrollParent.scrollHeight - oldScrollOffset;
    }

   this.parentScrolled();
  };

  componentWillUnmount = () => {
    if (this.scrollParent) {
      this.scrollParent.removeEventListener('scroll', this.parentScrolled);
    }
  };

  componentDidMount = () => {
    // If start is scrolled to the bottom then pretend to scroll
    // in-order to trigger load more or messages viewed
    if(this.props.scroll === 'up') {
      this.scrollParent.scrollTop = this.scrollParent.scrollHeight;
    } else if (this.props.scroll === 'down') {
      this.scrollParent.scrollTop = 0;
    }

    this.parentScrolled();
  };

  messageParentMounted = (node) => {
    if (this.scrollParent) {
      this.scrollParent.removeEventListener('scroll', this.parentScrolled);
    }

    this.scrollParent = node;

    if (this.scrollParent) {
      this.scrollParent.addEventListener('scroll', this.parentScrolled);
    }
  };

  isAtTop = () => this.scrollParent.scrollTop === 0;

  // We subtract 1 pixel from this calcutation as clientHeight will round to closest integer
  //  when the element has decimal height (in case sub-pixel of rendering)
  isAtBottom = () => this.scrollParent.scrollHeight - this.scrollParent.scrollTop - this.scrollParent.clientHeight - 1 <= 0;

  currentScroll = () => ({atTop: this.isAtTop(), atBottom: this.isAtBottom()});

  parentScrolled = () => {
    this.props.parentScrolled(this.currentScroll());
  };

  render() {
    return (
      <ScrollContainerWrapper className={this.props.className} ref={this.messageParentMounted}>
        {this.props.children}
      </ScrollContainerWrapper>
    )
  }
}

export default ScrollContainer;
