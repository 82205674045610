import React from "react";

import {withNoticeChannel} from "../../../../../hocs/withNoticeChannel";
import {hasUnread} from "../../../../../utils/unread-messages";
import ContextMenuButton from "./ContextMenuButton";
import {withLocalUserData} from "../../../../../hocs/withLocalUserData";

const NoticeChannelContextMenuButton = ({
  noticeChannel,
  user,
  selected,
  onClick,
  children,
}) => {
  const unread = noticeChannel.channel && hasUnread(noticeChannel.channel.user_last_viewed, noticeChannel.channel.messages, user);

  return (
    <ContextMenuButton
      unread={unread}
      selected={selected}
      onClick={onClick}>
      {children}
    </ContextMenuButton>
  );
};

export default withNoticeChannel(
  withLocalUserData(NoticeChannelContextMenuButton),
  {
    renderLoadingComponent: true,
  });
