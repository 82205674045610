import React from 'react';
import MessageList from "../../../../notice/message/list/MessageList";
import FlightType from "../../../../../constants/Flight";
import PropTypes from "prop-types";
import {getFlightNumber} from "../../../../../utils/flight-utils";
import MutatedMessageForm from "../../../../notice/message/add/MutatedMessageForm";
import {withNoticeChannel} from "../../../../../hocs/withNoticeChannel";
import {withLocalUserData} from '../../../../../hocs/withLocalUserData';
import {hasPermission} from '../../../../../utils/permission';
import {ACCESS_LEVEL, PERMISSION} from '../../../../../constants/Permission';

const LoadControlTab = ({
                          flight,
                          terminalOffset,
                          user,
                          noticeChannel: {
                            channel,
                            channel: {
                              messages: {
                                nodes,
                              },
                            },
                            fetchMoreMessages,
                          }
                        }) => {

  const contextName = getFlightNumber(flight);

  /**
   * For Load Control, all messages should be black.
   *
   * Confirmed on 13/02/2020
   *
   * @author Jacktator Shelsa
   */
  const shouldMessageFade = _ => false;

  const isChannelAdmin = hasPermission(user, PERMISSION.loadControl, ACCESS_LEVEL.ADMIN);

  return (
    <>
      <MessageList
        channel={channel}
        nodes={nodes}
        fetchMoreMessages={fetchMoreMessages}
        terminalOffset={terminalOffset}
        shouldMessageFade={shouldMessageFade}
        isChannelAdmin={isChannelAdmin}/>
      <MutatedMessageForm
        chatChannelId={channel.id}
        notify={`${contextName} - ${channel.name}`}/>
    </>
  )
};

LoadControlTab.propTypes = {
  flight: FlightType,
  noticeChannelId: PropTypes.number.isRequired,
  terminalOffset: PropTypes.number,
};

export default withNoticeChannel(withLocalUserData(LoadControlTab));
