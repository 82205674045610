import styled from 'styled-components';

export const EscalatedFlightWrapper = styled.div`
  background: white;
  border-radius: 4px;
  position: relative;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  padding: ${props => props.theme.space1} ${props => props.theme.space2};
  margin-bottom: ${props => props.theme.space1};
`;

export const EscalationContent = styled.div`
  font-size: 0.8em;
`;
