import React, {Component} from 'react';
import {Mutation} from 'react-apollo';
import {Redirect} from 'react-router-dom';
import {Form, Message} from 'semantic-ui-react';
import styles from '../Auth.module.css';

import FormDropdown from '../../template/FormDropdown'

import updateUser from '../../../gql/update-user';
import getUser from '../../../gql/get-user';
import Logo from "../../common/atoms/Logo";
import PromptBox from "../../common/templates/PromptBox";
import {SetupProfileWrapper} from "./style";
import Button from "../../common/atoms/Button";

class SetupProfile extends Component {
  state = {
    group: null,
    fieldError: {
      group: false,
    }
  };

  updateGroup = (e, { value }) => this.setState({ 'group': value });

  validateForm = () => {
    this.setState({
      fieldError: {
        group: !this.state.group,
      }
    });

    return !!this.state.group;
  };

  render = () =>
    <SetupProfileWrapper>
      <Logo/>
      <PromptBox>
        <h2>
          Welcome, {this.props.user.name}!
        </h2>
        <Mutation mutation={updateUser} refetchQueries={[{query: getUser}]} awaitRefetchQueries={true}>
          {(mutate, {data, called, loading, error}) => {
            if (error) { console.error('Failed saving user profile', error); }

            if (called && !loading && !error) {
              return <Redirect to='/' />
            }

            const formSubmitted = () => {
              if(!this.validateForm()) return;

              const {group} = this.state;
              mutate({
                variables: {
                  group,
                }
              });
            };

            return (
              <Form onSubmit={formSubmitted} size='large' className={styles.form} error={!!error}>

                <div className={styles.wrapper}>
                  <label className={styles.subHeading}>
                    Please select your airline to get started
                  </label>
                </div>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <label className={styles.label}>Airline</label>
                    <FormDropdown
                      placeholder='Select Airline'
                      options={this.getGroupOptions()}
                      showMessage={this.state.fieldError.group}
                      onChange={this.updateGroup}
                    />
                  </Form.Field>
                </Form.Group>

                <Button primary
                        fluid
                        large
                        type="submit"
                        loading={loading}>
                  Get Started
                </Button>

                <Message error>
                  <Message.Content>An error occured while trying to save your user profile</Message.Content>
                </Message>
              </Form>
            )
          }}
        </Mutation>
      </PromptBox>
    </SetupProfileWrapper>;

  getGroupOptions = () => {
    return [
      { text: 'Jetstar', value: 'MAINLINE' },
      { text: 'All Flights', value: 'ALL' }
    ];
  }
}

export default SetupProfile;
