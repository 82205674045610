import gql from 'graphql-tag';

export default gql`
  query GetFlightPassengerSummaries($id: String!) {
  flight(id: $id) {
    id
    ssr_summary {
      wheelchairsCount
      unattendedMinorsCount
      medicalCount
    }
    qff_summary {
      chairmansLoungeCount
      platinumOneCount
      platinumCount
      goldCount
    }
  }
}
`;
