import React from 'react';
import PropTypes from "prop-types";
import {Query} from 'react-apollo';

import ErrorPage from '../components/common/ErrorPage';

import getAirportSummaryQuery from '../gql/get-airport-summary';
import {NETWORK_MAP, NETWORK_STATUS} from "../constants/Apollo";
import LoadingPage from "../components/common/molecules/LoadingPage";
import AirportSummary from "../components/networkmap/NetworkMenu/AirportSummary";
import UserType from "../constants/User";

const AirportSummaryData = ({user, code}) => {
  return (
    <Query query={getAirportSummaryQuery}
           fetchPolicy="network-only"
           errorPolicy="all"
           variables={{
             airline_group: user.group !== 'ALL' ? user.group : undefined,
             code: code
           }}
           pollInterval={NETWORK_MAP.REFRESH_INTERVAL}>
      {({networkStatus, error, data = {}}) => {
        if (error) {
          console.error('Failed loading airport summary query', error);
          return (
            <ErrorPage
              title="Error loading airport summary"
              message="There was a problem loading the data for airport summary"/>);
        }

        const loading = networkStatus <= NETWORK_STATUS.SET_VARIABLES;
        if (loading) {
          return <LoadingPage/>;
        }

        const {
          getAirportFlightSummary,
          lateBoardingFlights,
          escalatedFlights,
          engineeringRiskFlights,
        } = data;

        return (
          <AirportSummary airport={getAirportFlightSummary}
                          lateFlights={lateBoardingFlights}
                          escalatedFlights={escalatedFlights}
                          engineeringRiskFlights={engineeringRiskFlights}/>
        );
      }}
    </Query>
  )
};

AirportSummaryData.propTypes = {
  user: UserType.isRequired,
  code: PropTypes.string.isRequired,
};

export default AirportSummaryData;
