import React from 'react';
import Author from "../../../common/molecules/Author";
import {getMinutesDiff} from "../../../../utils/flight-utils";
import NoticeType, {TYPE} from "../../../../constants/Notice";
import {Duration, NoticeStatusWrapper} from "./style";
import {ReactComponent as IconBin} from "../../../../images/icons/bin.svg";
import {ReactComponent as IconSkip} from "../../../../images/icons/skip.svg";
import {ReactComponent as IconUndo} from "../../../../images/icons/undo.svg";
import {ReactComponent as IconTick} from "../../../../images/icons/tick.svg";

const NoticeStatus = React.memo(({notice}) => {

  const {
    messageType,
    is_completeable,
    created_at,
    created_by,
    completed_at,
    completed_by,
    completed_skipped,
    content,
    deleted_at,
    deleted_by,
  } = notice;

  if (deleted_at) {
    return (
      <NoticeStatusWrapper>
        <IconBin/>
        <Author author={deleted_by}/>
      </NoticeStatusWrapper>
    )
  }

  // This is a hack by @Chris
  if (messageType === TYPE.INFO && content === 'UNDONE') {
    return (
      <NoticeStatusWrapper>
        <IconUndo/>
        <Author author={created_by}/>
      </NoticeStatusWrapper>
    );
  }

  if (completed_skipped) {
    return (
      <NoticeStatusWrapper>
        <IconSkip/>
        {
          completed_by &&
          <Author author={completed_by}/>
        }
      </NoticeStatusWrapper>
    )
  }

  if (is_completeable && completed_at) {
    return (
      <NoticeStatusWrapper>
        {
          completed_by &&
          <>
            <IconTick/>
            {
              completed_by &&
              <Author author={completed_by}/>
            }
          </>
        }
        {
          messageType === TYPE.ESCALATION &&
          <Duration>Duration: {getMinutesDiff(created_at, completed_at)}m</Duration>
        }
      </NoticeStatusWrapper>
    )
  } else {
    return (<></>)
  }
});

NoticeStatus.propTypes = {
  notice: NoticeType,
};

export default NoticeStatus;
