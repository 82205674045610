import styled from 'styled-components';
import { Select } from 'semantic-ui-react';

export const FilterForm = styled.form`
  width: 100%;
`;

export const TimeWindowSelect = styled(Select)`
  margin-right: ${props => props.theme.space5};
`;

export const FilterSection = styled.div`
  width: 100%;
  padding: ${props => props.theme.space5} 0;

  label {
    white-space: nowrap;
  }
`;

export const RadioWrapper = styled.div`
  padding: ${props => props.theme.space5} 0;
`;
