import styled from "styled-components";
import FlexSection from "../FlexSection";

export const ProgressBarWrapper = styled(FlexSection)`
  span {
    margin-left: ${props => props.theme.space5};
    font-family: ${props => props.theme.fontImportant};
  }
`;

export const ProgressBarBackground = styled.div`
  width: 100%;
  position: relative;
  background: ${props => props.theme.colors.background};
  height: ${props => props.theme.size2};
  min-width: 125px;
  border-radius: 50px;
`;

export const Filler = styled.div`
  background: ${props => props.percentage === 100 ? props.theme.colors.success : props.theme.colors.brandSecondary};
  height: 100%;
  width: ${props => `${props.percentage}%`};
  border-radius: inherit;
  transition: width .2s ease-in;
`;
