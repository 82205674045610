import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import ErrorPage from "../components/common/ErrorPage";
import {withTerminals} from '../hocs/withTerminals';
import TerminalView from "../components/terminal/TerminalView";

const TerminalData = ({view, terminalPath, user, terminals}) => {

  const terminal = terminals.find(t => t.code === view.filter.airport_code);

  if (!terminal) {
    return (
      <ErrorPage
        title="Problem finding port"
        message="There was a problem finding the specified port" />
    );
  }

  return (
    <Switch>
      <Route
        path={`${terminalPath}/:flightType(dep|arr)?`}
        render={({match: {params: {flightType}}}) =>
          <TerminalView view={view}
                        terminalPath={terminalPath}
                        flightTypePath={flightType}
                        user={user}
                        terminal={terminal}/>
        }/>
      <Redirect to={terminalPath + '/dep'} />
    </Switch>
  )
};

export default withTerminals(TerminalData);
