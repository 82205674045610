import React, {Component} from 'react';

import Accordion from "../../../../common/molecules/Accordion";
import {ReactComponent as IconConnections} from "../../../../../images/icons/connections.svg";
import {ConnectionsSection, InboundBody, TimeButton, TimeSelectionSectionWrapper} from "./style";
import ConnectionCard from "../../../../common/organisms/ConnectionCard"
import moment from "moment";
import PaxPanel from "../CabinAccordion/PaxPanel";
import {firstBy} from "thenby";
import {getFlightNumber} from "../../../../../utils/flight-utils";
import Page from "../../../../common/atoms/Page";
import {useMediaQuery} from "react-responsive";
import device from "../../../../../constants/Device";
import {hasPermission} from '../../../../../utils/permission';
import {ACCESS_LEVEL, PERMISSION} from '../../../../../constants/Permission';
import {withLocalUserData} from '../../../../../hocs/withLocalUserData';

const timeframeOptions = [
  60,
  90,
  120,
];

class InboundConnectionsAccordion extends Component {

  state = {
    selectedTimeframeIndex: timeframeOptions.indexOf(90),
    isPaxPanelOpen: false,
  };

  render = () => {

    const {
      collapsed,
      toggleAccordion,
    } = this.props;

    return (
      <Accordion
        collapsed={collapsed}
        icon={<IconConnections/>}
        head="Inbound Connection Flights"
        body={this.renderInboundBody()}
        onToggle={toggleAccordion}/>
    )
  };

  renderInboundBody = () =>
    <InboundBody>
      <TimeSelectionSection selectedIndex={this.state.selectedTimeframeIndex} onSelect={this.selectedTimeframe}/>
      {this.renderConnections()}
    </InboundBody>;

  renderConnections = () => {
    const {
      user,
      connections,
    } = this.props;

    const connectFlightPaxEnabled = hasPermission(user, PERMISSION.connectFlight, ACCESS_LEVEL.SENSITIVE);

    const filteredConnection = connections
      .map(connection => {
        const inboundTime = moment(connection.onblocks_time || connection.arrival_scheduled_time);
        const departureTime = moment(this.props.flight.offblocks_time || this.props.flight.departure_scheduled_time);
        const connectionMinutes = moment.duration(departureTime.diff(inboundTime)).asMinutes();
        return {
          ...connection,
          connectionMinutes
        };
      })
      .filter(connection => connection.connectionMinutes <= timeframeOptions[this.state.selectedTimeframeIndex])
      .sort(firstBy("connectionMinutes", 1));

    if (filteredConnection.length === 0) {
      return (
        <Page>
          <p>No inbound connections with this connection time</p>
        </Page>
      );
    }

    return (
      <ConnectionsSection connections={filteredConnection.length}>
        {
          filteredConnection.map(connection =>
            <ConnectionCard {...this.props}
                            isVertical={true}
                            key={getFlightNumber(connection)}
                            connection={connection}
                            connectionTime={connection.connectionMinutes}
                            onClick={() => connectFlightPaxEnabled && this.togglePaxPanel(connection)}/>
          )
        }
        {this.renderPaxSliderPanel()}
      </ConnectionsSection>
    );
  };

  selectedTimeframe = (i) =>
    this.setState({selectedTimeframeIndex: i});

  renderPaxSliderPanel = () => {
    const {
      flight,
      terminalOffset,
    } = this.props;

    const {
      isPaxPanelOpen,
      selectedConnection
    } = this.state;

    return (
      <PaxPanel
        isOpen={isPaxPanelOpen}
        togglePanel={this.togglePaxPanel}
        flight={flight}
        inboundFlight={selectedConnection}
        terminalOffset={terminalOffset}
      />
    )
  };

  togglePaxPanel = (connection) => {
    this.setState({
      isPaxPanelOpen: !this.state.isPaxPanelOpen,
      selectedConnection: connection,
    })
  }
}

const TimeSelectionSection = ({selectedIndex, onSelect}) => {

  const isLaptop = useMediaQuery({query: device.laptop});

  return (
    <TimeSelectionSectionWrapper>
      <div>
        <p>{isLaptop ? 'Show connections within' : 'Connex time:'}</p>
        {
          timeframeOptions.map((option, i) =>
            <TimeButton key={option}
                        selected={selectedIndex === i}
                        onClick={() => onSelect(i)}>
              {`${option} min`}
            </TimeButton>
          )
        }
      </div>
    </TimeSelectionSectionWrapper>
  )
};

export default withLocalUserData(InboundConnectionsAccordion);
