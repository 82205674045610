import React from 'react';
import PropTypes from 'prop-types';

import {RadioButton} from './style';
import FlexSection from '../../atoms/FlexSection';

const RadioSelect = ({label, options, onChange}) => {
  return (
    <FlexSection>
      {label}
      {options.map(option => (
        <RadioButton
          key={option.label}
          selected={option.selected}
          onClick={() => onChange(
            options.map(o => o.value === option.value ? {...o, selected: option.selected ? false : true} : o)
          )}
        >
          {option.label}
        </RadioButton>
      ))}
    </FlexSection>
  )
};

RadioSelect.propTypes = {
  label: PropTypes.oneOf([PropTypes.string, PropTypes.element]).isRequired,
  options: PropTypes.shape({
    label: PropTypes.oneOf([PropTypes.string, PropTypes.element]).isRequired,
    value: PropTypes.any.isRequired,
    selected: PropTypes.bool
  }),
};

export default RadioSelect;
