import React from 'react';
import moment from 'moment';

import Content from '../../../common/Content';
import Author from "../../../../common/molecules/Author";
import NoticeStatus from "../../../common/NoticeStatus";
import Timestamp from "../../../../common/atoms/Timestamp";
import {getMinutesDiff} from "../../../../../utils/flight-utils";
import HoverMenu from "../../../menu/HoverMenu";
import {ContentColumn, Delta, DeltaColumn, DotColumn, MenuWrapper, MessageRowWrapper, TimeStampColumn} from "./style";
import Dot from "../../../../common/atoms/Dot";
import MessageType, {TYPE} from "../../../../../constants/Notice";

// @deprecated
const Message = React.memo((props) => {

  const {
    message,
    terminalOffset,
    showTitle = true,
    deltaZero = false,
    shouldFade = false,
    disableHover = false,
    disableDelta = false,
    isEventCurrent = false,
    isChannelAdmin,
    innerRef,
  } = props;

  const isEvent = isMessageAnEvent(message);
  const shouldRenderDelta = (
    deltaZero && // Delta Zero is required in order to show delta
    !message.completed_skipped && // Message not completed nor skipped
    isEvent // Message is a Normal Event/Escalation/PTS
  );

  return (
    <MessageRowWrapper
      fade={shouldFade}
      enableDelta={!disableDelta}
      enableHover={!disableHover}
      onClick={doNothing}
      ref={innerRef}>
      {
        !disableDelta &&
        <>
          <DeltaColumn>
            <Delta isShowingTitle={showTitle}>
              {
                shouldRenderDelta &&
                renderDelta(message, deltaZero)
              }
            </Delta>
          </DeltaColumn>
          <DotColumn isShowingTitle={showTitle}>
            {
              isEvent &&
              <Dot type={message.messageType}
                   isLarge={isEventCurrent}
                   isLate={false}
                   isCompleted={message.completed_at !== undefined}
                   isSkipped={message.completed_skipped}/>
            }
          </DotColumn>
        </>
      }
      <ContentColumn>
        {
          showTitle && message.created_by &&
          <Author author={message.created_by}/>
        }
        {
          !message.deleted_at &&
          <Content content={formatContent(message)} offset={terminalOffset}/>
        }
        <NoticeStatus
          notice={message}/>
      </ContentColumn>
      <MenuWrapper>
        <HoverMenu
          notice={message}
          offset={terminalOffset}
          isChannelAdmin={isChannelAdmin}/>
      </MenuWrapper>
      <TimeStampColumn>
        <Timestamp
          notice={message}
          offset={terminalOffset}/>
      </TimeStampColumn>
    </MessageRowWrapper>
  )
});

const isMessageAnEvent = (notice) => {
  return (notice.messageType === TYPE.EVENT || notice.messageType === TYPE.ESCALATION || notice.messageType === TYPE.PTS);
};

const doNothing = () => {
  // Due to Mobile Safari issue, hover is not triggered unless the element has an 'onclick' to it. Hence this method. https://stackoverflow.com/a/20048559/3381997
};

const renderDelta = (notice, deltaZero) => {
  const timestamp = notice.is_completeable && notice.completed_at ?
    notice.completed_at : notice.created_at;

  if (moment(timestamp).isSameOrAfter(deltaZero)) {
    const deltaDiff = getMinutesDiff(deltaZero, timestamp);
    return <span>{deltaDiff === 0 ? "" : "+"}{deltaDiff}</span>
  }
};

const formatContent = (notice) => {
  const {
    messageType,
    content,
  } = notice;

  switch (messageType) {
    case TYPE.ESCALATION:
      return formatEscalationContent(notice);
    default: return content;
  }
};

const formatEscalationContent = (notice) => {
  const {
    completed_at,
    completed_reason,
    content,
  } = notice;

  if (completed_at) {
    return "Escalation Resolved: " + completed_reason
  } else {
    return "Escalation Started: " + content
  }
};

Message.propTypes = {
  message: MessageType,
};

export default React.forwardRef((props, ref) => <Message innerRef={ref} {...props} />);
