import styled, {css} from "styled-components";

const Heading = size => css`
  font-family: ${props => props.theme.fontImportant}; 

  font-size: ${props => {
    switch(size) {
      case 1:
        return props.theme.fontSizeExtraLarge;
      case 2:
        return props.theme.fontSizeLarge;
      case 3:
      default:
        return props.themefontSizeMedium;
    }
  }};
`;

export const InfoLabelWrapper = styled.div`
  /* Display */
  display: inline-block;
  /* Spacing */
  padding: ${props => props.theme.spaceSmall};
  /* Size */
  min-width: 35px;
  /* Font */
  font-family: ${props => props.theme.fontRegular};
  line-height: 1em;
  
  ${props => props.divider === "left" && css`
    margin-left: 3px;
    padding-left: 19px; /* 16px + 3px */
    border-left: ${props => props.theme.sizeExtraSmall} solid ${props.theme.colors.divider};
  `};

  ${props => props.heading1 && Heading(1)}
  ${props => props.heading2 && Heading(2)}
  ${props => props.heading3 && Heading(3)}
`;

export const Label = styled.span`
  /* Display */
  display: block;
  /* Font */
  font-size: ${props => props.theme.fontSizeExtraSmall};
  color: ${props => props.theme.colors.grey};
  text-transform: uppercase;

  ${props => (props.heading1 || props.heading2 || props.heading3) && css`
    color: ${props => props.theme.colors.black};
  `}
`;
