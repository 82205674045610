import {PopoutInfoLabel, PopoutSectionWrapper} from "./style";
import Popout from "../../../../../common/templates/Popout";
import {ReactComponent as IconInfo} from "../../../../../../images/icons/information.svg";
import FlexSection from "../../../../../common/atoms/FlexSection";
import {getRegistration} from "../../../../../../utils/flight-utils";
import React from "react";

const MobilePopout = React.memo(({flight}) =>
  <PopoutSectionWrapper>
    <Popout
      key={flight.id}
      toggleIcon={<IconInfo/>}
      heading='Flight Info'>
      <FlexSection>
        <PopoutInfoLabel
          label={"Reg."}
          text={getRegistration(flight)}/>
        <PopoutInfoLabel
          label={"Aircraft"}
          text={flight.aircraft_type}/>
      </FlexSection>
    </Popout>
  </PopoutSectionWrapper>
);

export default MobilePopout;
