import React, {Component} from "react";
import {FlightBodyWrapper, MainColumn, MenuColumn} from "./style";
import {
  VIEW_ARRIVAL_CHANNELS,
  VIEW_DEPARTURE_CHANNELS,
  VIEW_ENGINEERING_RISK,
  VIEW_FLIGHT_DETAILS,
  VIEW_LOAD_CONTROL,
  VIEW_PASSENGERS_LIST
} from "../../../../constants/FlightDetailMenuTabs";
import ContextMenu from "./ContextMenu";
import ScheduleTab from "./ScheduleTab";
import PassengerTab from "./PassengerTab";
import EngineeringTab from "./EngineeringTab";
import LoadControlTab from "./LoadControlTab";
import TerminalChannel from "../../../terminal/Terminal/TerminalChannel";
import EngineeringSection from "../../../common/organisms/EngineeringSection";

class FlightBody extends Component {

  render = () => {
    return (
      <FlightBodyWrapper>
        {this.renderMainColumn()}
        {this.renderMenuColumn()}
      </FlightBodyWrapper>
    )
  };

  renderMainColumn = () => {
    return (
      <MainColumn>
        {
          this.renderEngineeringSection()
        }
        {
          this.renderMainContent()
        }
      </MainColumn>
    )
  };

  renderEngineeringSection = () => {
    const {
      flight,
      selectedView,
    } = this.props;

    if (selectedView === VIEW_FLIGHT_DETAILS || selectedView === VIEW_ENGINEERING_RISK) {
      return <EngineeringSection flight={flight}/>
    } else {
      return <></>
    }
  };

  renderMainContent = () => {
    const {
      flight,
      terminalOffset,
      context,
    } = this.props;

    const departureTerminal = flight.departure_airport;
    const arrivalTerminal = flight.arrival_airport;

    switch (context) {
      case VIEW_FLIGHT_DETAILS:
        return <ScheduleTab flight={flight}
                            noticeChannelId={flight.schedule_channel_id}
                            terminalOffset={terminalOffset}/>;
      case VIEW_PASSENGERS_LIST:
        return <PassengerTab flight={flight}
                             noticeChannelId={flight.customer_channel_id}
                             terminalOffset={terminalOffset}/>;
      case VIEW_ENGINEERING_RISK:
        return <EngineeringTab noticeChannelId={flight.engineering_channel_id}
                               terminalOffset={terminalOffset}/>;
      case VIEW_LOAD_CONTROL:
        return <LoadControlTab flight={flight}
                               noticeChannelId={flight.load_control_channel_id}
                               terminalOffset={terminalOffset}/>;
      case VIEW_DEPARTURE_CHANNELS:
        return <TerminalChannel terminal={departureTerminal}
                                noticeChannelId={departureTerminal.notice_channel_id}/>;
      case VIEW_ARRIVAL_CHANNELS:
        return <TerminalChannel terminal={arrivalTerminal}
                                noticeChannelId={arrivalTerminal.notice_channel_id}/>;
      default:
        break;
    }
  };

  renderMenuColumn = () => {
    const {
      flight,
      context,
      terminalPath,
    } = this.props;

    const departureTerminal = flight.departure_airport;
    const arrivalTerminal = flight.arrival_airport;

    return (
      <MenuColumn>
        <ContextMenu
          flight={flight}
          selectedView={context}
          terminalPath={terminalPath}
          departureNoticeChannelId={departureTerminal.notice_channel_id}
          arrivalNoticeChannelId={arrivalTerminal.notice_channel_id}/>
      </MenuColumn>
    )
  };
}

export default FlightBody;
