import styled from 'styled-components';

export const UserWrapper = styled.div`
  margin-right: ${props => props.theme.space2};
  padding-left: ${props => props.theme.space2};
`;

export const NameWrapper = styled.div`
  text-transform: uppercase;
  text-align: right;
  
  font-family: ${props => props.theme.fontRegular};
  font-weight: normal;
  height: 14px;
  font-size: ${props => props.theme.fontSizeExtraSmall};
  line-height: 1.4;
  color: ${props => props.theme.colors.grey};
`;

export const RoleWrapper = styled.div`
  text-transform: uppercase;
  text-align: right;
  
  font-family: ${props => props.theme.fontMedium};
  font-weight: normal;
  height: 18px;
  font-size: ${props => props.theme.fontSizeMedium};
  line-height: 1.29;
  color: ${props => props.theme.colors.body};
`;
