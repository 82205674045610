import React, {Component} from 'react';
import flatten from 'lodash/flatten';
import PaxFlightNumber from "../PaxFlightNumber";
import {
  PaxFilterBody,
  PaxFilterControl,
  PaxFilterHead,
  PaxFilterSectionWrapper,
  PaxUpdatedAt
} from "./style";
import Divider from "../../../../../../common/molecules/Divider";
import Switch from "react-switch";
import {ReactComponent as IconWheelChair} from "../../../../../../../images/icons/wheelchair.svg";
import {ReactComponent as IconMinor} from "../../../../../../../images/icons/minor.svg";
import {ReactComponent as IconMedical} from "../../../../../../../images/icons/medical.svg";
import LoadingPage from "../../../../../../common/molecules/LoadingPage";
import PaxTable from "../../PaxTable";
import {FILTER, FREQUENT_FLYER_STATUS} from "../../../../../../../constants/Passenger";
import {firstBy} from "thenby";
import {getLocalTime} from "../../../../../../../utils/flight-utils";
import NotificationSection from "../../../../../../common/molecules/NotificationSection";
import Accordion from "../../../../../../common/molecules/Accordion";
import {MobileOnly, Tablet} from "../../../../../../../constants/Device";
import RadioSelect from '../../../../../../common/molecules/RadioSelect';

class PaxList extends Component {

  state = {
    filtersCollapsed: false,
    updatedAt: getLocalTime(new Date(), this.props.offset),
    filterNotBoardedOnly: false,

    ssrFilter: [
      {
        label: <IconWheelChair/>,
        value: 'isSsrWheelchair',
        selected: this.props.initialFilter === FILTER.WHEELCHAIR,
      },
      {
        label: <IconMinor/>,
        value: 'isSsrUnattendedMinor',
        selected: this.props.initialFilter === FILTER.MINOR,
      },
      {
        label: <IconMedical/>,
        value: 'isSsrMedical',
        selected: this.props.initialFilter === FILTER.MEDICAL,
      },
    ],

    qffFilter: [
      {
        label: 'CL',
        value: [FREQUENT_FLYER_STATUS.CLPO, FREQUENT_FLYER_STATUS.CLQF],
        selected: this.props.initialFilter === FILTER.CHAIRMAN_LOUNGE,
      },
      {
        label: 'P1',
        value: [FREQUENT_FLYER_STATUS.FFPO],
        selected: this.props.initialFilter === FILTER.PLATINUM_ONE
      },
      {
        label: 'P',
        value: [FREQUENT_FLYER_STATUS.FFPL],
        selected: this.props.initialFilter === FILTER.PLATINUM
      },
      {
        label: 'G',
        value: [FREQUENT_FLYER_STATUS.FFGD, FREQUENT_FLYER_STATUS.QCGD],
        selected: this.props.initialFilter === FILTER.GOLD
      },
    ]
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.passengerList !== prevProps.passengerList) {
      this.setState({
        updatedAt: getLocalTime(new Date(), this.props.offset)
      });
    }
  }

  render = () => {
    return (
      <div>
        {this.renderPaxHead()}
        {this.renderPaxFilterSection()}
        {this.renderUpdatedAt()}
        {this.renderPaxTable()}
      </div>
    )
  };

  renderPaxHead = () => {
    const {
      inboundFlight,
      flight,
    } = this.props;
    return (
      <PaxFlightNumber
        inboundFlight={inboundFlight}
        outboundFlight={flight}/>
    )
  };

  renderPaxFilterSection = () =>
    <>
      <MobileOnly>
        {this.renderMobilePaxSection()}
      </MobileOnly>
      <Tablet>
        {this.renderTabletPaxSection()}
      </Tablet>
    </>;

  toggleAccordion = () => {
    this.setState({
      filtersCollapsed: !this.state.filtersCollapsed
    });
  };

  renderMobilePaxSection = () =>
    <PaxFilterSectionWrapper>
      <Accordion head="Filters"
                 body={this.renderPaxFilterHead()}
                 collapsed={this.state.filtersCollapsed}
                 onToggle={this.toggleAccordion}/>
      <Divider/>
      {this.renderPaxFilterBody()}
    </PaxFilterSectionWrapper>;

  renderTabletPaxSection = () =>
    <PaxFilterSectionWrapper>
      {this.renderPaxFilterHead()}
      <Divider/>
      {this.renderPaxFilterBody()}
    </PaxFilterSectionWrapper>;

  renderPaxFilterHead = () =>
    <PaxFilterHead>
      <PaxFilterControl>
        <RadioSelect
          label='SSR'
          options={this.state.ssrFilter}
          onChange={(options) => this.setState({ssrFilter: options})}
        />
      </PaxFilterControl>
      <PaxFilterControl>
        <RadioSelect
          label='QFF'
          options={this.state.qffFilter}
          onChange={(options) => this.setState({qffFilter: options})}
        />
      </PaxFilterControl>
    </PaxFilterHead>;

  renderPaxFilterBody = () =>
    <PaxFilterBody>
      <Switch
        onChange={(checked) => this.setState({filterNotBoardedOnly: checked})}
        checked={this.state.filterNotBoardedOnly}
        onColor={"#FF5115"}
        width={48}
        height={24}/>
      <strong>Show not boarded only</strong>
    </PaxFilterBody>;

  renderUpdatedAt = () =>
    <PaxUpdatedAt>
      <p>Updated at {this.state.updatedAt}</p>
    </PaxUpdatedAt>;

  renderPaxTable = () => {
    const {
      passengerList,
      loading,
    } = this.props;

    const checkedIn = passengerList.filter(passenger => passenger.isCheckedIn);

    const filteredPassengerList = this.filterPassengerList(passengerList);

    if (loading) {
      return <LoadingPage/>
    } else if (checkedIn.length === 0) {
      return (
        <NotificationSection body={"No checked-in passengers yet"}/>
      )
    } else {
      return (
        <div>
          <PaxTable passengerList={filteredPassengerList} isCheckedIn={true}/>
          <Divider body={"Not Checked In"}/>
          <PaxTable passengerList={filteredPassengerList} isCheckedIn={false}/>
        </div>
      )
    }
  };

  filterPassengerList = (passengerList) => {
    const criteria = [];

    if (this.state.filterNotBoardedOnly) {
      criteria.push(passenger => !passenger.isBoarded);
    }

    // Build SSR criteria - check if truthy key exists
    this.state.ssrFilter
      .filter(o => o.selected)
      .forEach(o => criteria.push((passenger) => passenger[o.value]));

    // Build QFF criteria - check if qfTierCode matches any option
    const selectedQffFilters = this.state.qffFilter.filter(o => o.selected);
    if (selectedQffFilters.length > 0) {
      const flatValues = flatten(selectedQffFilters.map(o => o.value));
      criteria.push(passenger => flatValues.includes(passenger.qfTierCode))
    }

    if (criteria.length) {
      passengerList = passengerList.filter(passenger => criteria.some(criterion => criterion(passenger)));
    }

    return this.sortPassengerList(passengerList);
  };

  sortPassengerList = (passengerList) => {
    // Sort by seat numbers (blank seats last) numeric part, then seat number whole (including letters), then lastName
    return passengerList.sort(
      firstBy((pax) => parseInt(pax.seatNumber) || Infinity)
        .thenBy("seatNumber")
        .thenBy("lastName")
    );
  };
}

export default PaxList;
