import React, {Component} from 'react';

import Accordion from "../../../../common/molecules/Accordion";
import {ReactComponent as IconPassengers} from "../../../../../images/icons/passengers.svg";
import {ReactComponent as IconWheelChair} from "../../../../../images/icons/wheelchair.svg";
import {ReactComponent as IconMinor} from "../../../../../images/icons/minor.svg";
import {ReactComponent as IconMedical} from "../../../../../images/icons/medical.svg";
import {CabinBody, InfoSection, ProgressSection, QffSection, SsrSection, PaxButtonWrapper,} from "./style";
import Button from "../../../../common/atoms/Button";
import ButtonGroup from "../../../../common/atoms/ButtonGroup";
import PaxPanel from "./PaxPanel";
import LoadingPage from "../../../../common/molecules/LoadingPage";
import Icon from "../../../../common/atoms/Icon";
import IconGroup from "../../../../common/molecules/IconGroup";
import {FILTER} from "../../../../../constants/Passenger";
import BoardingSummaryData from '../../../../../containers/FlightBoardingSummaryData.js';
import {hasPermission} from '../../../../../utils/permission';
import {ACCESS_LEVEL, PERMISSION} from '../../../../../constants/Permission';
import {withLocalUserData} from '../../../../../hocs/withLocalUserData';

class CabinAccordion extends Component {

  state = {
    isPaxPanelOpen: false,
  };

  render = () => {

    const {
      collapsed,
      toggleAccordion,
    } = this.props;

    return (
      <Accordion
        collapsed={collapsed}
        icon={<IconPassengers/>}
        head="Passenger"
        body={this.renderAccordionBody()}
        onToggle={toggleAccordion}/>
    )
  };

  renderAccordionBody = () => {
    const {
      loading,
      user,
    } = this.props;

    const boardingStatusBasicEnabled = hasPermission(user, PERMISSION.boardingStatus, ACCESS_LEVEL.BASIC);
    const pilSensitiveEnabled = hasPermission(user, PERMISSION.pil, ACCESS_LEVEL.SENSITIVE);

    return (
      <CabinBody>
        {
          loading &&
          <LoadingPage/>
        }
        {
          !loading &&
          <>
            {this.renderProgressSection(boardingStatusBasicEnabled, pilSensitiveEnabled)}
            {this.renderInfoSection(pilSensitiveEnabled)}
          </>
        }
      </CabinBody>
    )
  };

  renderProgressSection = (boardingStatusBasicEnabled, pilSensitiveEnabled) => {
    return (
      <ProgressSection>
        {
          boardingStatusBasicEnabled &&
          <BoardingSummaryData flight={this.props.flight}/>
        }
        {
          pilSensitiveEnabled &&
          <>
            <PaxButtonWrapper>
              <Button large onClick={this.togglePaxPanel}>Pax List</Button>
            </PaxButtonWrapper>
            <PaxPanel
              {...this.props}
              isOpen={this.state.isPaxPanelOpen}
              initialFilter={this.state.selectedFilter}
              togglePanel={this.togglePaxPanel}/>
          </>
        }
      </ProgressSection>
    )
  };

  togglePaxPanel = (filter) => {
    this.setState({
      isPaxPanelOpen: !this.state.isPaxPanelOpen,
      selectedFilter: filter,
    })
  }

  renderInfoSection = (pilSensitiveEnabled) => {
    const {
      ssrSummary: {
        wheelchairsCount,
        unattendedMinorsCount,
        medicalCount,
      },
      qffSummary: {
        chairmansLoungeCount,
        goldCount,
        platinumCount,
        platinumOneCount
      },
    } = this.props;

    return (
      <InfoSection>
        <SsrSection>
          <span>SSR</span>
          <ButtonGroup>
            {this.renderInfoButton(pilSensitiveEnabled, FILTER.WHEELCHAIR, this.wrapIcon(<IconWheelChair/>, wheelchairsCount))}
            {this.renderInfoButton(pilSensitiveEnabled, FILTER.MINOR, this.wrapIcon(<IconMinor/>, unattendedMinorsCount))}
            {this.renderInfoButton(pilSensitiveEnabled, FILTER.MEDICAL, this.wrapIcon(<IconMedical/>, medicalCount))}
          </ButtonGroup>
        </SsrSection>
        <QffSection>
          <span>QFF</span>
          <ButtonGroup>
            {this.renderInfoButton(pilSensitiveEnabled, FILTER.CHAIRMAN_LOUNGE, `CL ${chairmansLoungeCount}`)}
            {this.renderInfoButton(pilSensitiveEnabled, FILTER.PLATINUM_ONE, `P1 ${platinumOneCount}`)}
            {this.renderInfoButton(pilSensitiveEnabled, FILTER.PLATINUM, `P ${platinumCount}`)}
            {this.renderInfoButton(pilSensitiveEnabled, FILTER.GOLD, `G ${goldCount}`)}
          </ButtonGroup>
        </QffSection>
      </InfoSection>
    )
  };

  renderInfoButton = (pilSensitiveEnabled, paxFilter, label) =>
    <Button
      small
      onClick={() => pilSensitiveEnabled && this.togglePaxPanel(paxFilter)}
    >
        {label}
    </Button>;

  wrapIcon = (iconElement, label) =>
    <IconGroup important>
      <Icon small>
        {iconElement}
      </Icon>
      <span>{label}</span>
    </IconGroup>;
}

export default withLocalUserData(CabinAccordion);
