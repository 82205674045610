import React, {Component} from 'react';

import TerminalEvents from './TerminalEvents';
import FlightData from '../../../containers/FlightData';

import {VIEW_TYPE} from "../../../constants/View";
import FlightFilter from "../../flight/FlightList/FlightFilter";
import TerminalHead from "./TerminalHead";
import DesktopTerminalButton from "./TerminalEventButton/DesktopTerminalButton";
import FlightListData from '../../../containers/FlightListData';
import {TerminalWrapper} from "./style";
import {TYPE} from "../../../constants/Flight";
import {Laptop} from "../../../constants/Device";
import {DetailColumn, MasterColumn} from "../../common/templates/MasterDetail";

class Terminal extends Component {

  state = {
    searchQuery: "",
  };

  render = () => {
    const {
      detailPath
    } = this.props;

    return (
      <TerminalWrapper path={detailPath}>
        <MasterColumn>
          {this.renderMasterColumn()}
        </MasterColumn>
        <DetailColumn>
          {this.renderDetailColumn()}
        </DetailColumn>
      </TerminalWrapper>
    );
  };

  renderMasterColumn = () => {
    const {
      detailPath,
      terminalViewType,
      view,
      user,
      terminal,
      terminalPath,
    } = this.props;

    return (
      <>
        <TerminalHead
          user={user}
          view={view}
          terminal={terminal}
          terminalPath={`${terminalPath}/${terminalViewType === TYPE.DEPARTURE ? 'dep' : 'arr'}`}/>
        {
          view.view_type === VIEW_TYPE.TERMINAL &&
          <Laptop>
            <DesktopTerminalButton
              noticeChannelId={this.props.terminal.notice_channel_id}
              terminalPath={`${terminalPath}/${terminalViewType === TYPE.DEPARTURE ? 'dep' : 'arr'}`}
              user={user}
              active={detailPath ? detailPath === 'events' : null}
            />
          </Laptop>
        }

        {this.renderFlightFilter(terminalViewType, detailPath)}
        {this.renderFlightList(terminalViewType, detailPath)}
      </>
    )
  };

  renderFlightFilter = (flightType, detailPath) => {
    return (
      <FlightFilter
        view={this.props.view}
        userGroup={this.props.user.group}
        onSearchChange={this.searchChange}
        flightType={flightType}
        terminalPath={this.props.terminalPath}
        detailPath={detailPath}
      />
    )
  };

  searchChange = (event) => {
    const {
      value
    } = event.target;
    this.setState({searchQuery: value});
  };

  renderFlightList = (viewType, detailPath) => {

    const {
      user,
      terminalPath,
      view,
      context,
    } = this.props;

    const newTerminalPath = this.buildTerminalPath(view, terminalPath, viewType);

    return (
      <FlightListData
        view={view}
        viewType={viewType}
        searchQuery={this.state.searchQuery}
        activeFlightId={detailPath}
        user={user}
        terminalPath={newTerminalPath}
        context={context}
      />
    )
  };

  renderDetailColumn = () => {
    const {
      terminalViewType,
      terminalPath,
      view,
      terminal,
      detailPath,
      context,
    } = this.props;

    const newTerminalPath = this.buildTerminalPath(view, terminalPath, terminalViewType);

    switch (detailPath) {
      case undefined:
        return view.view_type === VIEW_TYPE.TERMINAL && this.renderTerminalEvents(terminal, newTerminalPath);
      case 'events':
        return this.renderTerminalEvents(terminal, newTerminalPath);
      default:
        return this.renderFlightDetail(terminalViewType, detailPath, newTerminalPath, context)
    }
  };

  renderFlightDetail = (viewType, flightId, terminalPath, context) => {

    return (
      <FlightData
        flightId={flightId}
        viewType={viewType}
        terminalPath={terminalPath}
        context={context}
        user={this.props.user}
      />
    );
  };

  renderTerminalEvents = (terminal, terminalPath) =>
    <TerminalEvents
      terminal={terminal}
      terminalPath={terminalPath}/>;

  buildTerminalPath = (view, terminalPath, viewType) => {
    return view.view_type === VIEW_TYPE.TERMINAL ? `${terminalPath}/${viewType === TYPE.DEPARTURE ? 'dep' : 'arr'}` :
      view.view_type === VIEW_TYPE.ROSTER ? terminalPath :
        null;
  }
}

export default Terminal;
