import React from 'react';
import MessageList from "../../../../notice/message/list/MessageList";
import FlightType, {QUALIFIER} from "../../../../../constants/Flight";
import PropTypes from "prop-types";
import {getFlightNumber} from "../../../../../utils/flight-utils";
import iconMore from "../../../../../images/more.gif";
import AddEventButton from "../../../../notice/event/add/AddEventButton";
import MutatedMessageForm from "../../../../notice/message/add/MutatedMessageForm";
import {AwaitingWrapper} from "./style";
import Timeline from "../../../../common/templates/Timeline/style";
import {withNoticeChannel} from "../../../../../hocs/withNoticeChannel";
import {TYPE} from "../../../../../constants/Notice";
import {getProcessedNodes, getRemainingPtsSteps, shouldMessageFade} from "../../../../../utils/notice-utils";
import {withLocalUserData} from '../../../../../hocs/withLocalUserData';
import {hasPermission} from '../../../../../utils/permission';
import {ACCESS_LEVEL, PERMISSION} from '../../../../../constants/Permission';

const ScheduleTab = ({
                       flight,
                       terminalOffset,
                       user,
                       noticeChannel: {
                         channel,
                         channel: {
                           messages: {
                             nodes,
                           },
                         },
                         fetchMoreMessages,
                       }
                     }) => {

  const contextName = getFlightNumber(flight);

  /**
   * For Schedule,
   *
   * Group Event & Escalation, lastest one show as black.
   * PTS, if completed, skipped or undone, grey. (Undone is INFO)
   * Otherwise, use standard rule.
   *
   * Confirmed on 13/02/2020
   *
   * @author Jacktator Shelsa
   */
  const eventAndEscalation = nodes.filter(notice => (notice.messageType === TYPE.EVENT || notice.messageType === TYPE.ESCALATION));
  const lastEventId = eventAndEscalation.length > 0 ? eventAndEscalation[eventAndEscalation.length - 1].id : undefined;
  const shouldFade = message => {
    if (message.messageType === TYPE.PTS) {
      return message.completed_at;
    } else {
      // While generally, the rule set for messages are the same, it is possible that, due to business-case or permission, we will have a more granular control of how message should be greyed out. This function handles those special cases.
      return shouldMessageFade(message, lastEventId);
    }
  };

  // Schedule Related Values
  const processedNodes = getProcessedNodes(flight, nodes);
  const remainingPtsSteps = getRemainingPtsSteps(flight, nodes);
  const isFlightOffblocks = flight.offblocks_qualifier === QUALIFIER.ACTUAL;
  const shouldShowFlightIcon = flight.offblocks_qualifier !== QUALIFIER.ACTUAL;

  const showAwaitingOffblocks = flight.pts && remainingPtsSteps.length === 0 && !isFlightOffblocks;

  const isChannelAdmin = hasPermission(user, PERMISSION.scheduleChat, ACCESS_LEVEL.ADMIN);

  return (
    <>
      <MessageList
        channel={channel}
        nodes={processedNodes}
        fetchMoreMessages={fetchMoreMessages}
        terminalOffset={terminalOffset}
        shouldShowDelta={true}
        shouldMessageFade={shouldFade}
        shouldShowFlightIcon={shouldShowFlightIcon}
        isChannelAdmin={isChannelAdmin}
        pts={remainingPtsSteps}/>
      <Timeline>
        {
          showAwaitingOffblocks &&
          <AwaitingWrapper>
            <img src={iconMore} alt=""/>
            <span>Awaiting Off Blocks</span>
          </AwaitingWrapper>
        }
        <AddEventButton
          noticeChannelId={channel.id}
          normalOnly={false}
          contextName={contextName || channel.name}/>
      </Timeline>

      <MutatedMessageForm
        chatChannelId={channel.id}
        notify={`${contextName} - ${channel.name}`}/>
    </>
  )
};

ScheduleTab.propTypes = {
  flight: FlightType,
  noticeChannelId: PropTypes.number.isRequired,
  terminalOffset: PropTypes.number,
};

export default withNoticeChannel(withLocalUserData(ScheduleTab));
