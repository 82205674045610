import gql from 'graphql-tag';

export default gql`
  query GetFlightInboundConnections($id: String!) {
  flight(id: $id) {
    id
    inbound_connections {
      operating_airline
      flight_number
      departure_scheduled_time
      departure_airport_code
      gate
      connectingPaxCount
      arrival_scheduled_time
      onblocks_time
      onblocks_qualifier
    }
  }
}
`;
