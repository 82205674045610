import PTSStepType from "../../../../../../constants/PTSStep";
import React from "react";
import {
  ContentColumn,
  ContentBody,
  Delta,
  DeltaColumn,
  DeltaWrapper,
  DotColumn,
  DueTime,
  MessageRowWrapper,
  ContentSecondary
} from "../../../../../notice/message/list/Message/style";
import moment from "moment";
import Dot from "../../../../../common/atoms/Dot";
import {TYPE} from "../../../../../../constants/Notice";
import PropTypes from "prop-types";
import {getLocalTime} from "../../../../../../utils/flight-utils";

const PTSStep = ({
                   pts,
                   isFirst,
                   isDisrupted,
                   terminalOffset,
                   innerRef
                 }) => {

  const dueTime = pts.dueTime;

  return (
    <MessageRowWrapper key={pts.code}
                       shouldFade={false}
                       shouldShowDelta
                       enableHover={false}
                       ref={innerRef}>
      <DeltaColumn>
        {
          dueTime &&
          renderDelta(dueTime)
        }
      </DeltaColumn>
      <DotColumn>
        <Dot type={TYPE.PTS}
             isLarge={isFirst}
             isLate={isDisrupted}/>
      </DotColumn>
      <ContentColumn>
        <ContentBody>
          {pts.description}
        </ContentBody>
        {
          dueTime &&
          <ContentSecondary>
            <DueTime>
              No later than {getLocalTime(dueTime, terminalOffset)}
            </DueTime>
          </ContentSecondary>
        }
      </ContentColumn>
    </MessageRowWrapper>
  )
};

const renderDelta = (dueTime) => {
  const isLate = dueTime.diff(moment(), 'minutes') <= 1;

  return isLate ?
    <Delta>
      Now
    </Delta> :
    <DeltaWrapper>
      <Delta>
        {dueTime.diff(moment(), 'minutes')}
      </Delta>
      min
    </DeltaWrapper>
};

PTSStep.defaultProps = {
  ifFirst: false,
  isDisrupted: false,
};
PTSStep.propTypes = {
  ptsStep: PTSStepType,
  isFirst: PropTypes.bool.isRequired,
  terminalOffset: PropTypes.number.isRequired,
  isDisrupted: PropTypes.bool.isRequired,
};

export default React.forwardRef((props, ref) => <PTSStep innerRef={ref} {...props} />);
