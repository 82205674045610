import React from 'react';
import {CloseButton, PanelWrapper, PaxSliderPanel,} from "./style";
import {ReactComponent as IconClose} from "../../../../../../images/icons/close.svg";
import PaxListData from "../../../../../../containers/PaxListData";
import {useMediaQuery} from "react-responsive";
import device from "../../../../../../constants/Device";

const PaxPanel = ({
                    isOpen,
                    initialFilter,
                    isOnBlock,
                    togglePanel,
                    flight,
                    inboundFlight,
                    terminalOffset,
                  }) => {

  const isMobile = useMediaQuery({query: device.mobile});
  const isDesktop = useMediaQuery({query: device.desktop});

    return (
      <PaxSliderPanel
        type={'right'}
        isOpen={isOpen}
        size={isMobile ? 100 : (isDesktop ? 40 : 64)}
        backdropClicked={togglePanel}>
        <PanelWrapper>
          <CloseButton onClick={togglePanel}><IconClose/></CloseButton>
          {
            isOpen &&
            <PaxListData isOnblock={isOnBlock}
                         initialFilter={initialFilter}
                         flight={flight}
                         inboundFlight={inboundFlight}
                         offset={terminalOffset}/>
          }
        </PanelWrapper>
      </PaxSliderPanel>
    )
  };

export default PaxPanel;
