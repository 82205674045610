import {transform} from 'ol/proj'
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {Circle as CircleStyle, Fill, Stroke, Style} from "ol/style";
import {qantasTheme} from "../App.theme";

export const translateCoordinate = (latlong) => {
  return transform(latlong, 'EPSG:4326', 'EPSG:3857');
};

export const makeFeature = airport => {
  const feature = new Feature(new Point(translateCoordinate([airport.longitude, airport.latitude])));

  feature.setProperties({
    airport_code: airport.code,
    priority: airport.map_zoom_priority,
  });

  return feature;
};

export const makeStyle = (feature, resolution) => {

  const theme = qantasTheme();

  return new Style({
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: feature.getProperties()?.priority === 1 ? theme.colors.black : theme.colors.grey,
      }),
      stroke: new Stroke({
        color: 'white', width: 3
      })
    })
  })
};
