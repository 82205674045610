import React, {Component} from 'react';
import {ActionColumn, ActivityColumn, ActualColumn, CompletionColumn, PTSBody, PTSRow} from "./style";
import Divider from "../../../../common/molecules/Divider";
import Dot from "../../../../common/atoms/Dot";
import {ReactComponent as IconPencil} from "../../../../../images/icons/pencil.svg";
import Modal from "../../../../common/molecules/Modal";
import {FormHead, FormSection, FormWrapper} from "../../../../common/molecules/Form";
import PTSEditor from "./PTSEditor";
import {getLocalTime} from "../../../../../utils/flight-utils";
import {TYPE} from "../../../../../constants/Notice";
import * as moment from "moment";

class PTSReport extends Component {

  state = {
    showModal: false,
    selectedPTSIndex: 0,
  };

  render = () => {
    const {
      ptsList,
    } = this.props;

    return (
      <FormWrapper>
        <FormSection>
          {this.renderPTSHead()}
          <Divider/>
          {this.renderPTSBody()}
          {
            ptsList[this.state.selectedPTSIndex] &&
            this.renderPTSEditor()
          }
        </FormSection>
      </FormWrapper>
    )
  };

  renderPTSHead = () => {
    return (
      <FormHead>
        <ActivityColumn>
          Activity
        </ActivityColumn>
        <CompletionColumn>
          Scheduled
        </CompletionColumn>
        <ActualColumn>
          Actual
        </ActualColumn>
        <ActionColumn>
          Edit
        </ActionColumn>
      </FormHead>
    )
  };

  renderPTSBody = () => {

    const {
      ptsList,
    } = this.props;

    return (
      <PTSBody>
        {ptsList.map((pts, i) => this.renderPTSRow(pts, i))}
      </PTSBody>
    )
  };

  renderPTSRow = (pts, i) => {

    const {
      flight,
    } = this.props;

    return (
      <PTSRow key={i}>
        <ActivityColumn>
          <Dot type={TYPE.PTS}
               isLate={moment.utc(pts.scheduled_time).isBefore(moment(pts.actual_time))}
               isSkipped={!pts.actual_time}/>
          {pts.description}
        </ActivityColumn>
        <CompletionColumn>
          {pts.scheduled_delta} / {pts.scheduled_time ? getLocalTime(pts.scheduled_time, flight.departure_airport.timezone_offset) : ""}
        </CompletionColumn>
        <ActualColumn>
          <span>{pts.actual_delta} / {pts.actual_time ? getLocalTime(pts.actual_time, flight.departure_airport.timezone_offset) : ""}</span>
        </ActualColumn>
        <ActionColumn onClick={() => this.setState({
          selectedPTSIndex: i,
          showModal: true,
        })}>
          <IconPencil/>
        </ActionColumn>
      </PTSRow>
    )
  };

  renderPTSEditor = () => {

    const {
      flight,
      ptsList,
    } = this.props;

    return (
      <Modal
        isOpen={this.state.showModal}
        onOpen={() => this.setModal(true)}
        onClose={() => this.setModal(false)}
      >
        <PTSEditor key={this.state.selectedPTSIndex}
                   flight={flight}
                   pts={ptsList[this.state.selectedPTSIndex]}
                   onCancel={() => this.setModal(false)}
                   onSave={this.updatePTSTime}/>
      </Modal>
    )
  };

  updatePTSTime = (newHour, newMinute) => {

    const {
      flight,
      ptsList,
      onPTSUpdate,
    } = this.props;

    const originalPTS = ptsList[this.state.selectedPTSIndex];
    const actualTime = originalPTS.actual_time || originalPTS.scheduled_time;
    const localTime = moment.utc(actualTime).utcOffset(flight.departure_airport.timezone_offset);
    localTime.set({
      hour: newHour,
      minute: newMinute,
    });
    ptsList[this.state.selectedPTSIndex] = {
      ...originalPTS,
      actual_time: localTime.toISOString(true),
      actual_delta: Math.ceil(moment.duration(localTime.diff(moment(flight.offblocks_latest_estimate))).asMinutes()),
    };

    onPTSUpdate(ptsList);

    this.setModal(false);
  };

  setModal = (isOpen) => {
    this.setState({showModal: isOpen})
  };
}

export default PTSReport;
