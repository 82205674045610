import PropTypes from 'prop-types';

export const STATUS = {
  CANCELLED: "Cancelled",
  PLANNED: "Planned",
  UNPLANNED: "Unplanned",
};

export const QUALIFIER = {
  ESTIMATE: 'Estimate',
  PLAN: 'Plan',
  PLAN_REV: 'PlanRev',
  ACTUAL: 'Actual',
  VERGE: 'Verge',
};

export const ENGINEERING_STATUS = {
  OK: "OK",
  RISK: "RISK",
  ATN: "ATN",
  AOG: "AOG",
  AOG_GRN: "AOG GRN",
};

export const BOARDING_STATUS = {
  OPEN: "open",
  BOARDING: "boarding",
  FINALCALL: "finalcall",
  CLOSED: "closed"
};

export const TYPE = {
  DEPARTURE: 'DEPARTURE',
  ARRIVAL: 'ARRIVAL',
};

export const DOMAIN = {
  DOMESTIC: 'DOMESTIC',
  INTERNATIONAL: 'INTERNATIONAL',
};

export const dummyFlight = {
  "__typename": "Flight",
  "id": "QF530_SYD_2020-03-27",
  "airline_group": "MAINLINE",
  "updated_at": "2020-03-27T00:32:36.951Z",
  "created_at": "2020-03-18T14:13:18.094Z",
  "flight.escalation_notices": [],
  "ogs_last_updated_at": "2020-03-27T00:30:36.702Z",
  "schedule_channel_id": 2231705,
  "customer_channel_id": 2231706,
  "load_control_channel_id": 2231707,
  "engineering_channel_id": 2231708,
  "owner_airline": "QF",
  "operating_airline": "QF",
  "flight_number": "530",
  "flight_status": "Planned",
  "domain": "DOMESTIC",
  "aircraft_registration": "VHVXI",
  "aircraft_type": "73H",
  "aircraft_group": "737",
  "departure_airport_code": "SYD",
  "departure_airport": {
    "id": 1011,
    "timezone_offset": 660,
    "notice_channel_id": 1193,
    "__typename": "Airport"
  },
  "departure_gate": "12",
  "departure_terminal": "T3",
  "departure_scheduled_time": "2020-03-27T03:00:00.000Z",
  "offblocks_time": "2020-03-27T03:00:00.000Z",
  "offblocks_qualifier": "Plan",
  "offblocks_latest_estimate": "2020-03-27T03:00:00.000Z",
  "arrival_airport_code": "BNE",
  "arrival_airport": {
    "id": 137,
    "timezone_offset": 600,
    "notice_channel_id": 157,
    "__typename": "Airport"
  },
  "arrival_gate": "20",
  "arrival_terminal": null,
  "arrival_scheduled_time": "2020-03-27T04:30:00.000Z",
  "onblocks_time": "2020-03-27T04:30:00.000Z",
  "onblocks_qualifier": "Plan",
  "onblocks_latest_estimate": "2020-03-27T04:30:00.000Z",
  "fuel_total": null,
  "engineering_status": "RISK",
  "engineering_event_title": "Left Engine Fall Off",
  "boarding_status": null,
  "pts_workflow_status": null,
  "escalation_active": false,
  "departure_report_submitted": false
};

const FlightType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  airline_group: PropTypes.string.isRequired,
  updated_at: PropTypes.string,
  ogs_last_updated_at: PropTypes.string,
  created_at: PropTypes.string.isRequired,
  owner_airline: PropTypes.string.isRequired,
  operating_airline: PropTypes.string.isRequired,
  flight_number: PropTypes.string.isRequired,
  flight_status: PropTypes.oneOf(Object.values(STATUS)),
  domain: PropTypes.oneOf(Object.values(DOMAIN)),
  aircraft_registration: PropTypes.string,
  aircraft_type: PropTypes.string.isRequired,
  aircraft_group: PropTypes.string.isRequired,
  departure_airport_code: PropTypes.string.isRequired,
  departure_airport: PropTypes.object,
  departure_gate: PropTypes.string,
  departure_terminal: PropTypes.string,
  departure_scheduled_time: PropTypes.string.isRequired,
  offblocks_time: PropTypes.string,
  offblocks_qualifier: PropTypes.oneOf(Object.values(QUALIFIER)),
  offblocks_latest_estimate: PropTypes.string.isRequired,
  arrival_airport_code: PropTypes.string.isRequired,
  arrival_airport: PropTypes.object,
  arrival_gate: PropTypes.string,
  arrival_terminal: PropTypes.string,
  arrival_scheduled_time: PropTypes.string,
  onblocks_time: PropTypes.string,
  onblocks_qualifier: PropTypes.oneOf(Object.values(QUALIFIER)),
  onblocks_latest_estimate: PropTypes.string.isRequired,
  fuel_total: PropTypes.string,
  engineering_status: PropTypes.oneOf(Object.values(ENGINEERING_STATUS)),
  engineering_event_title: PropTypes.string,
  pts_workflow_status: PropTypes.string,
  escalation_active: PropTypes.bool.isRequired,
  departure_report_submitted: PropTypes.bool.isRequired,
  boarding_status: PropTypes.oneOf(Object.values(BOARDING_STATUS)),
});

export default FlightType;
