import Auth from '@aws-amplify/auth';

const region = process.env.REACT_APP_AWS_REGION;
const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID;
const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const userPoolWebClientId = process.env.REACT_APP_APP_CLIENT_ID;
const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;

export function initAuth() {
  // Configure Amplify Auth
  Auth.configure({
    identityPoolId,
    region,
    userPoolId,
    userPoolWebClientId,
    oauth: {
      domain: cognitoDomain,
      scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
      redirectSignIn: `${window.location.origin}/oauth`,
      redirectSignOut: `${window.location.origin}/login`,
      responseType: 'code'
    }
  });
}
