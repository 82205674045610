import React from 'react';
import {AirportSummaryCard, AirportSummaryWrapper, ButtonWrapper, IconWrapper, InfoLabelDiv} from "./style";
import Button from "../../../common/atoms/Button";
import InfoLabel from "../../../common/molecules/InfoLabel";
import {AIRPORT, NETWORK_MAP} from "../../../../constants/Routes";
import PropTypes from "prop-types";
import FlightType from "../../../../constants/Flight";
import AirportSummaryType from "../../../../constants/AirportSummary";
import EscalationAccordion from "../EscalationAccordion";
import UnserviceableAccordion from "../UnserviceableAccordion";
import LateBoardingAccordion from "../LateBoardingAccordion";
import {ReactComponent as IconClose} from '../../../../images/icons/close.svg';
import Icon from "../../../common/atoms/Icon";
import {Link} from "react-router-dom";

const AirportSummary = ({airport, lateFlights, escalatedFlights, engineeringRiskFlights}) => {
  return (
    <AirportSummaryWrapper>
      <Link to={`/${NETWORK_MAP}`}>
        <IconWrapper>
          <Icon>
            <IconClose/>
          </Icon>
        </IconWrapper>
      </Link>
      <AirportSummaryCard>
        <h2>
          {airport.code}
        </h2>
        <InfoLabelDiv>
          <InfoLabel label={"Delay"}
                     text={airport.delayCount}/>
          <InfoLabel label={"Canx"}
                     text={airport.cancelledCount}/>
          <InfoLabel label={"AOG"}
                     text={airport.aogCount}/>
        </InfoLabelDiv>
        <ButtonWrapper>
          <Button primary
                  onClick={() => {
                    window.open(`/${AIRPORT}/${airport.code}`, '_blank');
                  }}
          >
            GO TO AIRPORT DASHBOARD
          </Button>
        </ButtonWrapper>
      </AirportSummaryCard>
      <LateBoardingAccordion flights={lateFlights}/>
      <EscalationAccordion flights={escalatedFlights}/>
      <UnserviceableAccordion flights={engineeringRiskFlights}/>
    </AirportSummaryWrapper>
  );
};

AirportSummary.propTypes = {
  airport: AirportSummaryType.isRequired,
  lateFlights: PropTypes.arrayOf(FlightType),
  escalatedFlights: PropTypes.arrayOf(FlightType),
  engineeringRiskFlights: PropTypes.arrayOf(FlightType),
};

export default AirportSummary;
