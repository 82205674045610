import gql from 'graphql-tag';

import messageFragment from './message';

export default gql`
  ${messageFragment}

  fragment MessagesPageFields on MessagesPage {
    before
    nodes {
      ...MessageFields
    }
  }
`;
