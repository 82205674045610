import React from 'react';
import {DividerBody, DividerHead, DividerIcon, DividerWrapper} from "./style";

const Divider = (props) => {
  return (
    <DividerWrapper {...props}>
      {
        props.icon &&
        <DividerIcon 
          dark={props.dark}>
          {props.icon}
        </DividerIcon>
      }
      {
        props.head &&
        <DividerHead>
          {props.head}
        </DividerHead>
      }
      {
        props.body &&
        <DividerBody>
          {props.body}
        </DividerBody>
      }
    </DividerWrapper>
  )
};

export default Divider;
