import styled from 'styled-components';

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;

  #map {
    width: 100%;
    height: 100%;
  }

  /** Overriddes react-openlayers map.css **/
  .openlayers-map {
    width: 100%;
    height: 100%;
  }

  .ol-zoom {
    top: 4.5em;
  }

  .ol-zoom-extent {
    top: 8.6em
  }

  .ol-zoomslider {
    top: 11.1em;
  }
`;

export const MiniSummary = styled.div`
  position: absolute;
  bottom: 0;
`;
