import styled from 'styled-components';
import FlexSection from '../../../common/atoms/FlexSection';

export const Wrapper = styled(FlexSection)`
  justify-content: center;
  border-top: 1px solid ${props => props.theme.colors.divider};
`;

export const EditorWrapper = styled.div`
  width: 100%;
  height: 68px;
  cursor: text;
  padding: 18px 26px 18px 10px;
  background: #fefefe;
  overflow-y: auto;
`;

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  padding-right: 10px;
  cursor: pointer;
  align-self: center;
  flex: 0 0;
  flex-shrink: 0;
`;
