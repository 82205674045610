import React, {Component} from 'react';

import {IconClose, IconWrapper, PopoutBody, PopoutContent, PopoutHead, PopoutWrapper} from "./style";

class Popout extends Component {
  state = {isOpen: false};

  topNode = React.createRef();

  componentDidMount = () => {
    document.addEventListener('click', this.documentClicked);
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.documentClicked);
  };

  render = () => {
    const {
      children,
      toggleIcon,
      heading,
      align,
      mode,
    } = this.props;

    return (
      <PopoutWrapper ref={this.topNode}>
        <IconWrapper onClick={this.toggleOpen}
                     open={this.state.isOpen}>
          {toggleIcon}
        </IconWrapper>
        <PopoutContent align={align}
                       show={this.state.isOpen}
                       mode={mode}>
          <IconClose mode={mode}
                     onClick={this.toggleOpen}/>
          {
            heading &&
            <PopoutHead>
              {heading}
            </PopoutHead>
          }
          <PopoutBody>
            {children}
          </PopoutBody>
        </PopoutContent>
      </PopoutWrapper>
    );
  };

  toggleOpen = () => {
    this.setState({isOpen: !this.state.isOpen});
  };

  documentClicked = (e) => {
    // Explicity check if topNode.current exists since this may be null when using Edge
    if (this.topNode.current && !this.topNode.current.contains(e.target)) {
      this.setState({isOpen: false});
    }
  }
}

export default Popout;
