import React from 'react';
import styled from 'styled-components';
import {ReactComponent as LogoIcon} from "../../../../images/logo.svg";

const LogoWrapper = styled.div`
  width: 100%;
  height: ${props => props.theme.size6};
  margin-bottom: ${props => props.theme.space5};
`;

const Logo = () =>
  <LogoWrapper>
    <LogoIcon/>
  </LogoWrapper>;

export default Logo;
