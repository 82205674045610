import {createGlobalStyle} from 'styled-components';

const sizeBase = 4;

const spaceBase = sizeBase;

const fontRegular = "Roboto-Regular, Arial Narrow, sans-serif";
const fontMedium = "Roboto-Medium, Arial, sans-serif";
const fontImportant = "Roboto-Bold, Arial, sans-serif";

const orange = "#ee710c";
const black = "#323232";
const darkGrey = "#555555";
const grey = "#999999";
const lightGrey = "#d8d8d8";
const lighterGrey = "#f7f7f7";

export const QantasGlobalStyle = createGlobalStyle`
  body {
    /* Font */
    font-family: ${props => props.theme.fontRegular};
    color: ${props => props.theme.colors.black};
  
    /* Mobile Responsive */
    font-size: ${props => props.theme.fontSizeMedium};
  }
`;

export const qantasTheme = () => {

  return {
    /**
     * Colors
     * This should be kept consistent as Zeplin `Color Palette` page in `Styleguide`.
     */
    colors: {
      black: black,
      headers: black,
      body: darkGrey,
      grey: grey,
      lightGrey: lightGrey,
      divider: "#dddddd",
      background: "#ffffff",
      backgroundGrey: lighterGrey,
      backgroundRed: "#FFF2F2",
      backgroundOrange: "#FCEBCE",
      oldBrandPrimary: "#FF5115",
      brandPrimary: "#FF5115",
      brandSecondary: "#0692FC",
      brandDarkSecondary: "#007DDB",
      error: orange,
      delta: orange,
      errorBackground: "#fcebce",
      success: "#4bab3d",
      teal: "#8de2df",
      tealLight: "#bff4f3",
      tealLighter: "#e8f9f9",
      tierCL: "#000000",
      tierPO: "#323232",
      tierP: darkGrey,
      tierG: "#B29A5A",
      tierS: "#DDDDDD",
      tierB: "#860100",
      deltaSystem: darkGrey,
    },

    /**
     * Fonts
     */
    fontRegular: fontRegular,
    fontMedium: fontMedium,
    fontImportant: fontImportant,
    fontSizeExtraSmall: `${sizeBase * 2.5}px`, // 10
    fontSizeSmall: `${sizeBase * 3}px`, // 12
    fontSizeMedium: `${sizeBase * 3.5}px`, // 14
    fontSizeLarge: `${sizeBase * 4}px`, // 16
    fontSizeExtraLarge: `${sizeBase * 5}px`, // 20

    /**
     * Button
     */
    cornerRadius: `${sizeBase / 4 * 6}px`,
    borderWidth: `${sizeBase / 2}px`,

    /**
     * Spacing
     */
    spaceTiny: `${spaceBase / 8}px`,
    spaceExtraSmall: `${spaceBase / 4}px`,
    spaceSmall: `${spaceBase / 2}px`,
    space0: 0,
    space1: `${spaceBase}px`,
    space2: `${spaceBase * 2}px`,
    space3: `${spaceBase * 3}px`,
    space4: `${spaceBase * 4}px`,
    space5: `${spaceBase * 5}px`,
    space6: `${spaceBase * 6}px`,
    space7: `${spaceBase * 7}px`,
    space8: `${spaceBase * 8}px`,
    space9: `${spaceBase * 9}px`,
    space10: `${spaceBase * 10}px`,

    /**
     * Sizes
     */
    sizeTiny: `${sizeBase / 8}px`,
    sizeExtraSmall: `${sizeBase / 4}px`,
    sizeSmall: `${sizeBase / 2}px`,
    size0: 0,
    size1: `${sizeBase}px`,
    size2: `${sizeBase * 2}px`,
    size3: `${sizeBase * 3}px`,
    size4: `${sizeBase * 4}px`,
    size5: `${sizeBase * 5}px`,
    size6: `${sizeBase * 6}px`,
    size7: `${sizeBase * 7}px`,
    size8: `${sizeBase * 8}px`,
    size9: `${sizeBase * 9}px`,
    size10: `${sizeBase * 10}px`,
    size11: `${sizeBase * 11}px`,

    /**
     * States
     */
    backgroundDisabled: lightGrey,
  };
};
