import styled from 'styled-components';
import FlexSection from "../../../../common/atoms/FlexSection";
import {DotWrapper} from "../../../../common/atoms/Dot/style";

export const PTSBody = styled(FlexSection)`
  
  flex-direction: column;
  
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent calc(11px - 2.51px),
    ${props => props.theme.colors.lightGrey} calc(11px - 2.5px),
    ${props => props.theme.colors.lightGrey} calc(11px + 2.5px),
    transparent calc(11px + 2.51px),
    transparent 100%);
  
  width: 100%;
  
`;

export const PTSRow = styled(FlexSection)`
  align-items: stretch;
  
  width: 100%;
  min-height: ${props => props.theme.size10};
`;

export const ActivityColumn = styled(FlexSection)`
  width: 50%;
  
  ${DotWrapper} {
    margin-left: 5px;
    margin-right: ${props => props.theme.size5};
  }
`;

export const CompletionColumn = styled(FlexSection)`
  width: 20%;
`;

export const ActualColumn = styled(FlexSection)`
  width: 20%;
  
  span {
    font-family: ${props => props.theme.fontImportant};
  }
`;

export const ActionColumn = styled(FlexSection)`
  width: 10%;
`;
