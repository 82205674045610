import React from 'react';
import PropTypes from 'prop-types';
import FlightType from "../../../../../constants/Flight";
import {ContentContainer} from "../../../style";
import EscalatedFlight from "./EscalatedFlight";
import Page from "../../../../common/atoms/Page";

const EscalatedFlightList = ({flights}) => {
  return (
    <ContentContainer>
      {
        flights.length > 0 ?
          flights.map(flight => (
            <EscalatedFlight key={flight.id}
                             flight={flight}/>
          )) :
          <Page>
            <p>No escalated flights</p>
          </Page>
      }
    </ContentContainer>
  );
};

EscalatedFlightList.propTypes = {
  flights: PropTypes.arrayOf(FlightType),
};

export default EscalatedFlightList;
