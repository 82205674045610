import React, {Component} from 'react';
import Auth from '@aws-amplify/auth';
import PropTypes from 'prop-types';
import Button from "../../atoms/Button";
import {
  DesktopUserControlWrapper,
  MenuWrapper,
  MobileUserControlWrapper,
  UserControlWrapper,
  UserDisplayWrapper
} from "./style";
import UserType from "../../../../constants/User";
import {ReactComponent as DownArrowIcon} from "../../../../images/chevron-down-grey.svg";
import User from "../../atoms/User";
import InitialAvatar from "../../atoms/Initial";
import Icon from "../../atoms/Icon";

class UserControl extends Component {

  state = {
    open: false,
  };

  topNode = React.createRef();

  componentDidMount = () => {
    document.addEventListener('click', this.documentClicked);
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.documentClicked);
  };

  toggleDropdown = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render = () => {
    const {
      user,
    } = this.props;

    const {
      open
    } = this.state;

    return (
      <UserControlWrapper ref={this.topNode}
                          isOpen={open}>
        <UserDisplayWrapper onClick={this.toggleDropdown}>
          <User user={user}/>
          <InitialAvatar user={user} isOpen={open}/>
          <Icon>
            <DownArrowIcon/>
          </Icon>
        </UserDisplayWrapper>
        <MenuWrapper>
          {/* {
            !isAdminDashboard &&
            <RequestAccess/>
          } */}

          <Button
              primary
              fluid
              onClick={() => this.signOut()}>
              Log Out
            </Button>
        </MenuWrapper>
      </UserControlWrapper>
    );
  };

  signOut = async () => {
    await Auth.signOut();
  };

  documentClicked = (e) => {
    // Explicity check if topNode.current exists since this may be null when using Edge
    if (this.topNode.current && !this.topNode.current.contains(e.target)) {
      this.setState({open: false});
    }
  };
}

UserControl.propTypes = {
  user: UserType,
  isAdminDashboard: PropTypes.bool,
};

export const DesktopUserControl = ({user, isAdminDashboard}) =>
  <DesktopUserControlWrapper>
    <UserControl user={user} isAdminDashboard={isAdminDashboard}/>
  </DesktopUserControlWrapper>;


export const MobileUserControl = ({user}) =>
  <MobileUserControlWrapper>
    <UserControl user={user}/>
  </MobileUserControlWrapper>;

MobileUserControl.propTypes = {
  user: UserType,
};

DesktopUserControl.propTypes = {
  user: UserType,
  isAdminDashboard: PropTypes.bool,
};
