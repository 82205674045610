import styled from "styled-components";
import device from "../../../../../../constants/Device";
import FlexSection from "../../../../../common/atoms/FlexSection";
import TerminalName from "../../../../../common/atoms/TerminalName";
import {TERMINAL} from "../../../../../../constants/Layer";

export const MobileMenuWrapper = styled(FlexSection)`
    
  /* Mobile Responsive */
  display: flex;
  @media ${device.laptop} {
    display: none;
  }
  
  ${TerminalName} {
    flex-grow: 1;
  }
  
  flex-grow: 1;
  
  z-index: ${TERMINAL.MENU};
`;

export const MenuIcon = styled.div`

  display: inline-block;
  
  box-sizing: border-box;
  border: solid ${props => props.theme.sizeExtraSmall} ${props => props.theme.colors.divider};
  border-radius: ${props => props.theme.cornerRadius};
  box-shadow: 0 ${props => props.theme.sizeExtraSmall} ${props => props.theme.sizeSmall} 0 rgba(0, 0, 0, 0.1);
  
  padding: ${props => props.theme.space1}
`;
