import React from 'react';
import {withRoles} from '../../../hocs/withRoles';

/**
 * Shows the role's current display name given by the role id.
 * If unable to find the role then use the display name stored in the second
 * part of mention.
 */
const RoleMention = ({roles, mentionString}) => {
  const parts = mentionString.split("|");
  const roleId = parseInt(parts[0]);
  const backupName = parts[1] || "ROLE NOT FOUND";

  const role = roles.find(r => r.id === roleId);
  return <span>{role ? `@${role.display_name}` : backupName}</span>
};

export default withRoles(RoleMention);
