import PropTypes from "prop-types";

const AirportSummaryType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  delayCount: PropTypes.number.isRequired,
  cancelledCount: PropTypes.number.isRequired,
  aogCount: PropTypes.number.isRequired,
});

export default AirportSummaryType;
