import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {
  getArrivalStatus,
  getDepartureStatus,
  getExtraStatus,
  getFlightNumber,
  getLocalTime,
  getMinutesLate,
  getRegistration
} from '../../../../utils/flight-utils';

import styles from './style.module.css';
import {Dot, FlightRowWrapper} from "./style";
import EngineeringStatus from "../../../common/molecules/EngineeringStatus";
import PropTypes from "prop-types";
import FlightType, {
  DOMAIN,
  QUALIFIER,
  STATUS as FLIGHT_STATUS,
  TYPE as FLIGHT_TYPE
} from "../../../../constants/Flight";
import {COLUMNS} from "../../../../constants/View";
import {DetailColumn, Engineering, FlightIDColumn, GateColumn, IconColumn, Number, Port, Rego, Status, Time,} from "../style";
import UserType from "../../../../constants/User";

const FlightRow = ({
                     columns,
                     flight,
                    //  user,
                     pts,
                     viewType,
                     terminalOffset,
                     terminalPath,
                     context,
                     active,
                     innerRef
                   }) => {

  const {
    id,
    escalation_active,
    flight_status,
    departure_airport_code,
    departure_scheduled_time,
    offblocks_latest_estimate,
    arrival_airport_code,
    arrival_scheduled_time,
    onblocks_time,
    engineering_status,
    departure_gate,
    arrival_gate,
  } = flight;

  const flightNumber = getFlightNumber(flight);

  const std = moment.utc(departure_scheduled_time);
  const ofb = moment.utc(offblocks_latest_estimate);

  const sta = moment.utc(arrival_scheduled_time);
  const onb = moment.utc(onblocks_time);

  const depDelay = getMinutesLate(std, ofb);
  const arrDelay = getMinutesLate(sta, onb);

  const extraCss = getExtraCSS(flight, viewType, depDelay);

  return (
    <Link to={`${terminalPath}/${id}/${context}`}>
      <FlightRowWrapper active={active}
                        // hasUnread={hasUnreadMessages}
                        className={extraCss}
                        ref={innerRef}>
        <IconColumn>
          <div className={styles.icon}/>
        </IconColumn>
        <FlightIDColumn>
          {
            columns.includes(COLUMNS.NUMBER) &&
            <Number>
              {flightNumber}
            </Number>
          }
          {
            columns.includes(COLUMNS.REGO) &&
            <Rego>
              {getRegistration(flight)}
            </Rego>
          }
        </FlightIDColumn>
        <DetailColumn>
          {
            columns.includes(COLUMNS.FROM) &&
            <Port>
              {departure_airport_code}
            </Port>
          }
          {
            columns.includes(COLUMNS.TO) &&
            <Port>
              {arrival_airport_code}
            </Port>
          }
          {
            columns.includes(COLUMNS.STD) &&
            <Time>
              {
                getLocalTime(std, terminalOffset)
              }
            </Time>
          }
          {
            columns.includes(COLUMNS.OFB) &&
            <Time>
              {
                getLocalTime(ofb, terminalOffset)
              }&nbsp;
              {
                getExtraStatus(depDelay)
              }
            </Time>
          }
          {
            columns.includes(COLUMNS.DEP_GATE) &&
            <GateColumn>
              {departure_gate}
            </GateColumn>
          }
          {
            columns.includes(COLUMNS.ONB) &&
            <Time>
              {
                getLocalTime(onb, terminalOffset)
              }&nbsp;
              {
                getExtraStatus(arrDelay)
              }
            </Time>
          }
          {
            columns.includes(COLUMNS.ARR_GATE) &&
            <GateColumn>
              {arrival_gate}
            </GateColumn>
          }
          {
            columns.includes(COLUMNS.DEP_STATUS) &&
            <Status>
              <Dot delayed={depDelay}
                   escalated={escalation_active}
                   cancelled={flight_status === FLIGHT_STATUS.CANCELLED}/>
              {getDepartureStatus(flight, pts)}
            </Status>
          }
          {
            columns.includes(COLUMNS.ARR_STATUS) &&
            <Status>
              <Dot delayed={arrDelay}
                   escalated={escalation_active}
                   cancelled={flight_status === FLIGHT_STATUS.CANCELLED}/>
              {getArrivalStatus(flight)}
            </Status>
          }
          {
            columns.includes(COLUMNS.ENG) &&
            <Engineering>
              {
                engineering_status &&
                <EngineeringStatus status={engineering_status}/>
              }
            </Engineering>
          }
        </DetailColumn>
        {/* <Unreadcolumn>
          {
            unreadImportantMessages > 0 &&
            <UnreadBadge>
              {
                unreadImportantMessages || " "
              }
            </UnreadBadge>
          }
        </Unreadcolumn> */}
      </FlightRowWrapper>
    </Link>
  );
};

const getExtraCSS = (flight, viewType, depDelay) => {
  if (flight.flight_status === FLIGHT_STATUS.CANCELLED) {
    return `${styles.cancelled} ${styles.inactive}`;
  }

  if (flight.escalation_active) {
    return `${styles.escalated}`
  }

  if (viewType === FLIGHT_TYPE.DEPARTURE) {
    if (flight.offblocks_qualifier === QUALIFIER.ACTUAL) {
      return styles.departed;
    } else {
      const groundStyle = flight.pts_workflow_status ? styles.depOnBlocks : "";
      const isDomestic = flight.domain === DOMAIN.DOMESTIC;
      if (moment(flight.departure_scheduled_time).isSameOrAfter(moment().add(isDomestic ? 2 : 3, 'hours'))) {
        return `${groundStyle} ${styles.inactive}`;
      } else if (depDelay !== 0) {
        return `${groundStyle} ${styles.delayed}`;
      } else {
        return groundStyle;
      }
    }
  } else if (viewType === FLIGHT_TYPE.ARRIVAL) {
    if (flight.onblocks_qualifier === QUALIFIER.ACTUAL) {
      return styles.arrived;
    } else if (flight.offblocks_qualifier === QUALIFIER.ACTUAL) {
      return styles.inair;
    } else {
      return styles.inactive;
    }
  }
};

FlightRow.defaultProps = {
  active: false,
};
FlightRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  flight: FlightType,
  user: UserType,
  active: PropTypes.bool.isRequired,
  viewType: PropTypes.oneOf([FLIGHT_TYPE.ARRIVAL, FLIGHT_TYPE.DEPARTURE]),
};

export default React.forwardRef((props, ref) =>
  <FlightRow innerRef={ref}
             {...props} />
);
