import gql from 'graphql-tag';

import flightFragment from './fragments/flight';

export default gql`
  ${flightFragment}

  query getFlights($lower_window: Int!, $upper_window: Int!, $airline_group: FlightGroupEnum, $departure_airport_code: String, $arrival_airport_code: String, $domain: FlightDomain, $assigned_staff_number: String) {
    getFlights(
      lower_window: $lower_window
      upper_window: $upper_window
      airline_group: $airline_group
      departure_airport_code: $departure_airport_code
      arrival_airport_code: $arrival_airport_code
      domain: $domain
      assigned_staff_number: $assigned_staff_number
    ) {
      ...FlightBody
    }
  }
  `;
