export default (
  prev,
  {
    subscriptionData: { 
      data: { 
        flightUpdateEvent: newFlight 
      }
    }
  }
) => {
  const {getFlights: flights} = prev;  

  const oldFlightIndex = flights.findIndex(f => f.id === newFlight.id);
  if (oldFlightIndex === -1) {
    // Skip updates for flights we can't see (outside of query time window or recently added)
    return prev;
  }

  const oldFlight = flights[oldFlightIndex];

  const updatedFlight = {
    ...oldFlight,
    ...newFlight
  };

  // Return new list of flights with the existing flight still in place
  return {
    getFlights: [
      ...flights.slice(0, oldFlightIndex),
      updatedFlight,
      ...flights.slice(oldFlightIndex + 1)
    ]
  };
}