export function includeMessageInChatChannel(chatChannel, newMessage) {
  return {
    ...chatChannel,
    messages: {
      ...chatChannel.messages,
      nodes: includeMessage(chatChannel.messages.nodes, transformMessage(newMessage))
    }
  }
}

function includeMessage(nodes, newMessage) {
  const existingMessageIndex = nodes.findIndex(m => m.id === newMessage.id);
  if (existingMessageIndex !== -1) {
    // Replace the existing message
    return [
      ...nodes.slice(0, existingMessageIndex),
      newMessage,
      ...nodes.slice(existingMessageIndex + 1)
    ]
  } else {
    // Append the new message
    const newNodes = [
      ...nodes,
      newMessage
    ];
  
    const length = nodes.length;
    if (length > 2 && new Date(newNodes[length - 1].created_at) < new Date(newNodes[length - 2].created_at)) {
      // The last two messages are out of order - sort the list
      newNodes.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    }
  
    return newNodes;
  }
}

function transformMessage(message) {
  if (message.type) {
    message.messageType = message.type;
    delete message.type;
  }
  return message;
}