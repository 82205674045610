import styled from 'styled-components';
import FlexSection from "../../../../../../common/atoms/FlexSection";

export const PaxFilterSectionWrapper = styled.div`
  background: white;
`;

export const PaxFilterControl = styled(FlexSection)`
  flex: 1;
  width: 100%;
  padding: ${props => props.theme.size1} 0;
`;

export const PaxFilterHead = styled(FlexSection)`
  background: white;

  padding: ${props => props.theme.size1} ${props => props.theme.size5};

  flex-direction: row;
  flex-wrap: wrap;

  ${PaxFilterControl}:not(:last-child) {
    margin-right: ${props => props.theme.size4};
  }
`;

export const PaxFilterBody = styled(FlexSection)`
  background: white;

  padding: ${props => props.theme.size2} ${props => props.theme.size5};

  strong {
    margin-left: ${props => props.theme.size2};
  }
`;

export const PaxUpdatedAt = styled(FlexSection)`
  p {
    margin: ${props => props.theme.size3} 0 ${props => props.theme.size3} auto;
    color: ${props => props.theme.colors.grey};
    text-transform: uppercase;
    font-size: ${props => props.theme.fontSizeMedium};
  }
`;
