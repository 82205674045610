// This must be the first line in src/index.js
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import {ApolloProvider} from 'react-apollo';
import {createApolloClient} from './init/apollo';
import {initAuth} from './init/auth';

import './index.css';
import App from './App';
import {OfflineContextProvider} from './contexts/OfflineContext';

// Init AWS Amplify Auth
initAuth();

const offlineContextProviderRef = React.createRef();
const apolloClient = createApolloClient(offlineContextProviderRef);

const render = Component => {
  return ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <OfflineContextProvider ref={offlineContextProviderRef}>
        <Component/>
      </OfflineContextProvider>
    </ApolloProvider>,
    document.getElementById('root')
  )
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
