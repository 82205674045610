import React, {Component} from 'react';
import LoadingPage from "../../common/molecules/LoadingPage";
import {FlightDetailWrapper} from "./style";
import FlightHead from "./FlightHead";
import FlightBody from "./FlightBody";

class FlightDetail extends Component {

  render = () => {
    const {
      loading,
      flight,
      viewType,
      terminalOffset,
      terminalPath,
      context,
    } = this.props;

    if (loading) {
      return (
        <FlightDetailWrapper>
          <LoadingPage/>
        </FlightDetailWrapper>
      )
    }
    return (
      <FlightDetailWrapper>
        <FlightHead flight={flight}
                    viewType={viewType}
                    terminalOffset={terminalOffset}
                    terminalPath={terminalPath}/>
        <FlightBody flight={flight}
                    terminalOffset={terminalOffset}
                    terminalPath={terminalPath}
                    context={context}/>
      </FlightDetailWrapper>
    )
  };
}

export default FlightDetail;
