import styled from "styled-components";
import FlexSection from "../../../../common/atoms/FlexSection";
import device from "../../../../../constants/Device";
import {ButtonWrapper} from "../../../../common/atoms/Button/style";

export const CabinBody = styled.div`
  padding: ${props => props.theme.space3};
`;

export const ProgressSection = styled(FlexSection)`
  font-family: ${props => props.theme.fontImportant};

  ${ButtonWrapper} {
    margin-left: ${props => props.theme.space2};
  }
`;

export const PaxButtonWrapper = styled.div`
  @media ${device.laptop} {
    width: 120px;
    flex-shrink: 0;
  }
`;

export const InfoSection = styled(FlexSection)`
  span {
    padding: ${props => props.theme.space2};
    font-family: ${props => props.theme.fontImportant};
    font-size: ${props => props.theme.fontSizeLarge};
  }
  a {
    margin-left: ${props => props.theme.space2};
  }

  flex-direction: row;
  flex-wrap: wrap;

  ${ButtonWrapper} {
    flex-shrink: 0;
    min-width: 60px;
    padding: ${props => props.theme.space2};
  }

  @media ${device.laptop} {
    ${ButtonWrapper} {
      padding: ${props => props.theme.space3} ${props => props.theme.space4} ${props => props.theme.space2} ${props => props.theme.space4};
    }
  }
`;

export const SsrSection = styled(FlexSection)`
  margin-top: ${props => props.theme.space2};
  margin-right: ${props => props.theme.space6};
`;

export const QffSection = styled(FlexSection)`
  margin-top: ${props => props.theme.space2};
`;
