import React, {Component} from 'react';
import {Form} from 'semantic-ui-react';
import {convertToRaw, EditorState, getDefaultKeyBinding, Modifier, SelectionState} from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, {defaultSuggestionsFilter} from 'draft-js-mention-plugin';

import {withRoles} from '../../../../hocs/withRoles';
import {TYPE} from "../../../../constants/Notice";
import { Wrapper, IconWrapper, EditorWrapper } from './style';

import {ReactComponent as IconSendMessageActive} from '../../../../images/send-message-active.svg';
import {ReactComponent as IconSendMessageDisabled} from '../../../../images/send-message-disabled.svg';
import {ReactComponent as IconMention} from '../../../../images/mention.svg';

class MessageForm extends Component {

  editor = React.createRef();

  constructor(props) {
    super(props);

    const mentions = props.roles.map((r, i) => ({
      key: i,
      name: r.display_name,
      roleId: r.id,
    }));

    this.mentionPlugin = createMentionPlugin({
      mentions,
      positionSuggestions: this.positionSuggestions,
      mentionPrefix: '@',
      entityMutability: 'IMMUTABLE'
    });

    let editorState = EditorState.createEmpty();
    editorState = EditorState.set(editorState, { allowUndo: false });

    this.state = {
      editorState,
      mentions,
      suggestions: mentions,
      awaitingSubmit: false,
    };
  }

  positionSuggestions = ({ state, props }) => {
    let transform;

    if (state.isActive && props.suggestions.length > 0) {
      transform = 'scale(1) translateY(-115%)';
    } else if (state.isActive) {
      transform = 'scaleY(0)';
    }

    return {
      transform,
    };
  };

  contentTextChanged = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: defaultSuggestionsFilter(value, this.state.mentions),
    });
  };

  focus = () => {
    this.editor.current.focus();
  };

  getContentLength = () => {
    return this.state.editorState.getCurrentContent().getPlainText().length
  }

  formSubmitted = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (this.props.loading || this.state.awaitingSubmit || this.getContentLength() === 0) {
      return;
    }

    // We have to blur the editor and only submit after a delay due to issues of how DraftJS
    // handles mobile keyboards. Especially on Android Chrome if the submit button is pressed
    // the browser may not of added all text in a way that DraftJS has handled. So we blur
    // the editor which should prompt the browser to dump any in progress word, though this
    // doesn't seem to happen straight away so delay to give time for proper key events to fire
    // and for DraftJS's state to be up to date.

    this.setState({
      awaitingSubmit: true,
    });

    this.editor.current.blur();

    setTimeout(() => {
      const message = {
        type: TYPE.CHAT,
        content: this.getMarkdownText(),
      };

      this.setState({
        awaitingSubmit: false,
      });

      this.props.onSubmit(message);
      this.resetEditor();
    }, 100);
  };

  resetEditor = () => {
    // The editorState should never be re-init'd (unless tearing everything down and
    // starting from scratch) so we should manually clear out the content
    // Taken from https://github.com/facebook/draft-js/issues/1630#issue-291845662
    let { editorState } = this.state;
    let contentState = editorState.getCurrentContent();
    const firstBlock = contentState.getFirstBlock();
    const lastBlock = contentState.getLastBlock();
    const allSelected = new SelectionState({
      anchorKey: firstBlock.getKey(),
      anchorOffset: 0,
      focusKey: lastBlock.getKey(),
      focusOffset: lastBlock.getLength(),
      hasFocus: true,
    });
    contentState = Modifier.removeRange(contentState, allSelected, 'backward');
    editorState = EditorState.push(editorState, contentState, 'remove-range');
    editorState = EditorState.forceSelection(
      editorState,
      contentState.getSelectionAfter(),
    );
    this.setState({ editorState });
  };

  chatKeyBindingFn = (e) => {
    if (e.keyCode === 13 &&
        e.shiftKey === false &&
        e.ctrlKey === false) {
      return 'enter-pressed';
    }

   return getDefaultKeyBinding(e);
  };

  handleKeyCommand = (command) =>  {
    if (command === 'enter-pressed') {
      this.formSubmitted(); // If "enter" keycode is detected, submit form
      return 'handled';
    }

    // This wasn't the 'enter-pressed' command, so we want Draft to handle it instead.
    // We do this by telling Draft we haven't handled it.
    return 'not-handled';
  };

  getMarkdownText = () => {
    const {blocks, entityMap} = convertToRaw(this.state.editorState.getCurrentContent());

    const lines = [];

    // Blocks are the lines in the text
    for (const {entityRanges, text} of blocks) {
      let line = '';
      let startRange = 0;

      // Run over all entities, processing them making sure to add any text surrounding them
      for (const entityRange of entityRanges) {
        // Add all text before this entity since the last entity
        line += text.substr(startRange, entityRange.offset - startRange);

        // Process the entity
        const entity = entityMap[entityRange.key];
        if (entity && entity.type === 'mention') {
          const mentionData = entity.data.mention;
          line += `[role:${mentionData.roleId}|${mentionData.name}]`;
        } else {
          // If the entity type is unknown to us then just dump the entity's text
          line += text.substr(entityRange.offset, entityRange.length);
        }

        // Set where the next text begins (where this entity ends)
        startRange = entityRange.offset + entityRange.length;
      }

      // Add all remaining text
      line += text.substr(startRange, text.length);

      lines.push(line);
    }

    return lines.join('\n');
  };

  showMentionsOnClick = () => {
    let { editorState } = this.state;
    let contentState = editorState.getCurrentContent();
    const lastBlock = contentState.getLastBlock();
    const allSelected = new SelectionState({
      anchorKey: lastBlock.getKey(),
      anchorOffset: lastBlock.getLength(),
      focusKey: lastBlock.getKey(),
      focusOffset: lastBlock.getLength(),
      hasFocus: true,
    });
    contentState = Modifier.insertText(
      contentState,
      allSelected,
      editorState.getCurrentContent().getPlainText().length > 0 ? ' @' : '@');
    editorState = EditorState.push(editorState, contentState, 'insert-characters');
    editorState = EditorState.forceSelection(
      editorState,
      contentState.getSelectionAfter(),
    );
    this.setState({ editorState });
  };

  render = () => {
    const {
      loading,
      notify
    } = this.props;

    const {
      awaitingSubmit,
      editorState,
      suggestions

    } = this.state;

    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin];

    return (
      <Form
        loading={loading || awaitingSubmit}
        onSubmit={this.formSubmitted}>
        <Wrapper>
          <EditorWrapper onClick={this.focus}>
            <Editor
              editorState={editorState}
              onChange={this.contentTextChanged}
              plugins={plugins}
              spellCheck={true}
              placeholder={`Send to ${notify}`}
              keyBindingFn={this.chatKeyBindingFn}
              handleKeyCommand={this.handleKeyCommand}
              ref={this.editor}
            />
            <MentionSuggestions
              onSearchChange={this.onSearchChange}
              suggestions={suggestions}
            />
          </EditorWrapper>

          <IconWrapper onClick={this.showMentionsOnClick}>
            <IconMention />
          </IconWrapper>
          <IconWrapper onClick={this.formSubmitted}>
            {
              this.getContentLength() > 0 ? <IconSendMessageActive /> : <IconSendMessageDisabled />
            }
          </IconWrapper>
        </Wrapper>
      </Form>
    );
  }
}

export default withRoles(MessageForm);
