import React from 'react';
import PropTypes from 'prop-types';
import {AirportListWrapper} from "./style";
import AirportRow from "./AirportRow";
import AirportHead from "./AirportHead";
import AirportSummaryType from "../../../../../constants/AirportSummary";

const AirportList = ({airports}) => {
  return (
    <AirportListWrapper>
      <AirportHead/>
      {
        airports.map(airport => (
          <AirportRow key={airport.code}
                      airport={airport}/>
        ))
      }
    </AirportListWrapper>
  );
};

AirportList.propTypes = {
  airports: PropTypes.arrayOf(AirportSummaryType),
};

export default AirportList;
