import React from 'react';
import {AirportLink, AirportRowWrapper} from "./style";
import {ButtonColumn, DataColumn} from "../../style";
import AirportSummaryType from "../../../../../../constants/AirportSummary";
import {NETWORK_MAP} from "../../../../../../constants/Routes";
import {ReactComponent as IconRight} from "../../../../../../images/icons/arrow-right.svg";

const AirportRow = ({airport}) => (
  <AirportLink to={`/${NETWORK_MAP}/${airport.code}`}>
    <AirportRowWrapper>
      <DataColumn>
        {airport.code}
      </DataColumn>
      <DataColumn>
        {airport.delayCount}
      </DataColumn>
      <DataColumn>
        {airport.cancelledCount}
      </DataColumn>
      <DataColumn>
        {airport.aogCount}
      </DataColumn>
      <ButtonColumn>
        <IconRight/>
      </ButtonColumn>
    </AirportRowWrapper>
  </AirportLink>);

AirportRow.propTypes = {
  airport: AirportSummaryType,
};

export default AirportRow;
