import React from 'react';
import {Query} from 'react-apollo';

import flightPaxQuery from '../gql/get-flight-passengers';
import inboundPaxQuery from '../gql/get-flight-inbound-connections-passengers'
import ErrorPage from "../components/common/ErrorPage";
import PaxList from "../components/flight/FlightDetail/FlightBody/CabinAccordion/PaxPanel/PaxList";
import {NETWORK_STATUS, REFRESH_INTERVAL} from "../constants/Apollo";

const PaxListData = (props) => {

  const {
    flight,
    inboundFlight,
  } = props;

  if (!flight) {
    return (
      <ErrorPage
        title="Error getting Pax List"
        message="flightId was missing from the request"
        hideRefresh={true}/>
    )
  }

  let paxQuery;
  let variables;

  if (inboundFlight) {
    paxQuery = inboundPaxQuery;
    variables = {
      id: flight.id,
      operating_airline: inboundFlight.operating_airline,
      flight_number: inboundFlight.flight_number,
      departure_scheduled_time: inboundFlight.departure_scheduled_time,
      departure_airport_code: inboundFlight.departure_airport_code,
    }
  } else {
    paxQuery = flightPaxQuery;
    variables = {
      id: flight.id
    }
  }

  return (
    <Query query={paxQuery}
           variables={variables}
           fetchPolicy={"network-only"}
           errorPolicy="all"
           pollInterval={REFRESH_INTERVAL.PAX_REFRESH_INTERVAL}>
      {({networkStatus, error, data = {}}) => {
        if (error) {
          console.error("Error loading Pax List", error);
          return <ErrorPage
            title="Error getting Pax List"
            message="There was a problem getting Pax List"
            hideRefresh={true}/>;
        } else {
          const passengersKey = inboundFlight ? 'inbound_connection_passengers' : 'passengers';
          const passengerList = data.flight && data.flight[passengersKey];

          return <PaxList {...props}
                          loading={networkStatus <= NETWORK_STATUS.SET_VARIABLES}
                          passengerList={passengerList || []}/>;
        }
      }}
    </Query>
  )
};

export default PaxListData;
