import React from "react";
import {CardBody, CardHeader, CardWrapper} from "./style";
import PropTypes from 'prop-types';
import Divider from "../Divider";

const Card = (props) => {

  const {
    head,
    body,
  } = props;

  return (
    <CardWrapper {...props}>
      {
        head &&
        <>
          <CardHeader>
            {head}
          </CardHeader>
          <Divider/>
        </>
      }
      {
        body &&
        <CardBody>
          {body}
        </CardBody>
      }
    </CardWrapper>
  )
};

Card.propTypes = {
  head: PropTypes.node,
  body: PropTypes.node,
  onClick: PropTypes.func,
};

export default Card;
