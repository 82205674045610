import styled, {css} from 'styled-components';
import PropTypes from "prop-types";
import Icon from "../../atoms/Icon";

const IconGroup = styled.div`
  display: inline-block;
  text-align: center;
  
  height: ${props => props.theme.size5};
  line-height: ${props => props.theme.size5};
  
  ${Icon} {
    vertical-align: middle;
  }
  
  span {
    padding: ${props => props.theme.spaceTiny} 0 0 ${props => props.theme.space1};
    
    user-select: none;
  }
  
  ${props => props.important && css`
    font-size: ${props => props.theme.fontSizeLarge};
    font-family: ${props => props.theme.fontImportant};
  `};
`;

IconGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IconGroup;
