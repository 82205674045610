import React from 'react';

import styles from './OfflineMessage.module.css';
import refreshImg from '../../images/refresh.svg';

const bannerClicked = () => window.location.reload();

export default (props) =>
  <div className={styles.content} onClick={bannerClicked}>
    Connection lost. Attempting to reconnect...
    <div className={styles.refresh}>
      <img src={refreshImg} alt='' />
      <span>Refresh page</span>
    </div>
  </div>
