import React from 'react';
import PropTypes from "prop-types";
import {ListHeadWrapper} from "./style";
import {COLUMNS} from "../../../../constants/View";
import {DetailColumn, Engineering, FlightIDColumn, GateHeader, IconColumn, Load, Number, Port, Rego, Status, Time} from "../style";

const ListHead = ({columns}) =>
  <ListHeadWrapper>
    <IconColumn/>
    <FlightIDColumn>
      {
        columns.includes(COLUMNS.NUMBER) &&
        <Number>
          Flight
        </Number>
      }
      {
        columns.includes(COLUMNS.REGO) &&
        <Rego>
          Reg.
        </Rego>
      }
    </FlightIDColumn>
    <DetailColumn>
      {
        columns.includes(COLUMNS.FROM) &&
        <Port>
          From
        </Port>
      }
      {
        columns.includes(COLUMNS.TO) &&
        <Port>
          To
        </Port>
      }
      {
        columns.includes(COLUMNS.STD) &&
        <Time>
          STD
        </Time>
      }
      {
        columns.includes(COLUMNS.OFB) &&
        <Time>
          ETD
        </Time>
      }
      {
        columns.includes(COLUMNS.DEP_GATE) &&
        <GateHeader>
          GATE
        </GateHeader>
      }
      {
        columns.includes(COLUMNS.STA) &&
        <Time>
          STA
        </Time>
      }
      {
        columns.includes(COLUMNS.ONB) &&
        <Time>
          ETA
        </Time>
      }
      {
        columns.includes(COLUMNS.DEP_STATUS) &&
        <Status>
          Status
        </Status>
      }
      {
        columns.includes(COLUMNS.ARR_GATE) &&
        <GateHeader>
          GATE
        </GateHeader>
      }
      {
        columns.includes(COLUMNS.ARR_STATUS) &&
        <Status>
          Status
        </Status>
      }
      {
        columns.includes(COLUMNS.LOAD) &&
        <Load>
          Load
        </Load>
      }
      {
        columns.includes(COLUMNS.ENG) &&
        <Engineering>
          ENG
        </Engineering>
      }
    </DetailColumn>
  </ListHeadWrapper>;

ListHead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
};

export default ListHead;
