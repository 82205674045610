import styled, {css} from 'styled-components';

export const ContextMenuButton = styled.div`
  position: relative;

  font-family: ${props => props.theme.fontImportant};
  font-size: ${props => props.theme.fontSizeSmall};

  opacity: 0.698;
  
  margin: ${props => props.theme.space2} auto;
  border-radius: ${props => props.theme.size1};
  
  cursor: pointer;
  
  svg {
    margin: 0 auto;
  }
  
  svg, svg path {
    fill: ${props => props.theme.colors.black}
  }
  
  ${props => props.selected && css`
    opacity: 1.0;
    box-shadow: 0 ${props => props.theme.sizeExtraSmall} ${props => props.theme.size1} 0 ${props => props.theme.colors.grey};
    border: 3px solid ${props => props.theme.colors.black};
    border-radius: ${props => props.theme.size2};
  `};
  
  ${props => props.unread && css`
    ::before {
      position: absolute;
      right: 0;
      content: '';
      background-color: ${props => props.theme.colors.oldBrandPrimary};
      border-radius: 50%;
      width: ${props => props.theme.size2};
      height: ${props => props.theme.size2};
      pointer-events: none;
    }
  `};
`;

export default ContextMenuButton;
