import styled from "styled-components";
import device from "../../../../constants/Device";

export const MasterDetail = styled.div`
  display: flex;
  height: 100%;
`;

export const MasterColumn = styled.div`
  
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-x: hidden;
  
  /* Mobile Responsive */
  flex: 1 0 100%;
  @media ${device.tablet} {
    flex: 1 0 30%;
  }
  @media ${device.laptop} {
    flex: 1 0 40%;
  }
`;

export const DetailColumn = styled.div`
  flex: 1 0 50%;
  
  display: block;
  overflow-x: hidden; /* Needed so child flex containers don't get greedy */
  position: relative;
`;
