import styled from "styled-components";
import FlexSection from "../FlexSection";
import PropTypes from "prop-types";
import device from "../../../../constants/Device";

const Page = styled(FlexSection)`
  flex-direction: column;
  justify-content: center;
  background: ${props => props.theme.colors.background};
  width: 100%;
  height: 100%;
  
  /* Mobile Responsive */
  padding: ${props => props.theme.space4};
  @media ${device.tablet} {
    padding: ${props => props.theme.space10};
  }
`;

FlexSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
