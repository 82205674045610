import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import SetupProfile from './SetupProfile';
import UserDashboard from './UserDashboard';

import {withRoles} from '../../hocs/withRoles';
import {withNetworkUserData} from "../../hocs/withNetworkUserData";
import UserType from "../../constants/User";

const UserDashboardRouter = ({user}) =>
  <Switch>
    <Route exact path="/setup-profile" render={() => renderSetupProfile(user)}/>
    <Route render={() => renderDashboard(user)}/>
  </Switch>;

const renderSetupProfile = (user) => {
  if (user.group) {
    // User profile already exists
    return <Redirect to='/'/>
  }
  return <SetupProfile user={user}/>
};

const renderDashboard = (user) => {
  if (!user.group) {
    // User has not added their profile yet
    return <Redirect to='/setup-profile'/>
  }
  return <UserDashboard user={user}/>
};

UserDashboardRouter.profile = {
  user: UserType,
};

export default withNetworkUserData(withRoles(UserDashboardRouter));
