import React from 'react';
import UserType, {airlineOptions} from "../../../../../constants/User";
import {MenuHeadWrapper, MenuTitle} from "./style";

const MenuHead = ({user}) => {
  return (
    <MenuHeadWrapper>
      <MenuTitle>
        Airline Operation
      </MenuTitle>
      <div>
        {
          airlineOptions.find(option => option.value === user.group).label
        }
      </div>
    </MenuHeadWrapper>
  );
};

MenuHead.propTypes = {
  user: UserType.isRequired,
};

export default MenuHead;
