import React from "react";
import {Query} from "react-apollo";

import noticeChannelQuery from "../gql/get-notice-channel";
import subscriptionWrapperBuilder from "../utils/subscription-wrapper-builder";
import noticeChannelSubscription from '../gql/subscribe-notice-event';
import loadMoreMessages from '../gql/load-more-messages';
import noticeChannelEventUpdate from '../data/notice-event-update';
import getMoreMessagesUpdate from '../data/get-more-messages-update';
import LoadingPage from "../components/common/molecules/LoadingPage";
import {NETWORK_STATUS} from "../constants/Apollo";

const defaultConfig = {
  renderLoadingComponent: false,
  inputProp: 'noticeChannelId',
  outputProp: 'noticeChannel',
  fetchPolicy: 'cache-first',
  subscribe: true,
};

export function withNoticeChannel(WrappedComponent, config = defaultConfig) {
  return class extends React.Component {
    render() {
      const renderLoadingComponent = config.renderLoadingComponent || defaultConfig.renderLoadingComponent;
      const inputProp = config.inputProp || defaultConfig.inputProp;
      const outputProp = config.outputProp || defaultConfig.outputProp;
      const fetchPolicy = config.fetchPolicy || defaultConfig.fetchPolicy;
      const subscribe = config.subscribe || defaultConfig.subscribe;

      const noticeChannelId = this.props[inputProp];

      return (
        <Query query={noticeChannelQuery} variables={{ id: noticeChannelId }} fetchPolicy={fetchPolicy}>
          {({networkStatus, error, data = {}, subscribeToMore, refetch, fetchMore}) => {
          if (error) {
            console.error('withNoticeChannel', error);
            return <div>Problem loading channel</div>
          }

          // Don't show loading on polls/fetchMore's
          if (!renderLoadingComponent && networkStatus <= NETWORK_STATUS.SET_VARIABLES) {
            return <LoadingPage/>;
          }

          const fetchMoreMessages = (cursor) => fetchMore({
            query: loadMoreMessages,
            variables: { cursor },
            updateQuery: getMoreMessagesUpdate,
          });

          const output = {
            ...this.props,
            [outputProp]: {
              channel: data.noticeChannel,
              fetchMoreMessages: fetchMoreMessages,
            }
          };

          if (subscribe) {
            const doSubscribe = subscriptionWrapperBuilder((onError) => {
              return [subscribeToMore({
                document: noticeChannelSubscription,
                updateQuery: noticeChannelEventUpdate,
                variables: {
                  notice_channel_id: noticeChannelId
                },
                onError
              })]
            }, refetch);

            // Include `key` so subscription gets replaced
            return (
              <QuerySubscription key={noticeChannelId} doSubscribe={doSubscribe}>
                <WrappedComponent {...output} />
              </QuerySubscription>
            );
          } else {
            return <WrappedComponent {...output} />
          }
        }}
        </Query>
      );
    }
  }
}

class QuerySubscription extends React.Component {
  componentDidMount() {
    this.subscription = this.props.doSubscribe();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  render() {
    return <>{this.props.children}</>
  }
}
