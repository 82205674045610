import styled, {css} from 'styled-components';
import FlexSection from "../../common/atoms/FlexSection";
import {NETWORK_MAP} from "../../../constants/Layer";
import Icon from "../../common/atoms/Icon";
import {Link} from "react-router-dom";

export const NetworkMenuWrapper = styled(FlexSection)`
  flex-direction: column;

  background: ${props => props.theme.colors.background};

  height: 100%;
  width: 320px;
  overflow-y: auto;

  margin-left: 0px;
  transition: margin-left 240ms ease;

  ${props => props.collapsed && css`
    margin-left: -320px;
  `};

  border-right: 1px solid ${props => props.theme.colors.divider};
`;

export const ToggleButton = styled.div`

  background: white;
  width: 48px;
  height: 48px;
  padding: 8px;

  position: absolute;
  right: -49px;

  z-index: ${NETWORK_MAP.MENU};

  ${props => props.collapsed && css`

    ${Icon} {
      right: -80px;
      transition: right 240ms ease;
    }

    svg {
      transform: rotate( -180deg );
      transition: transform 240ms ease;
    }
  `};

  ${props => !props.collapsed && css`

    ${Icon} {
      right: ${props => props.theme.space2};
      transition: right 240ms ease;
    }

    svg {
      transform: rotate( 0 );
      transition: transform 240ms ease;
    }
  `};
`;

export const FlightLink = styled(Link)`
  color: ${props => props.theme.colors.body};

  :hover {
    color: ${props => props.theme.colors.body};
  }
`;
