import React from 'react';
import DetailSection from "./DetailSection";
import TaskSection from "./TaskSection";

const FlightHead = (props) => {

  const {
    flight,
    viewType,
    terminalOffset,
    terminalPath,
  } = props;

  return (
    <>
      <DetailSection
        viewType={viewType}
        flight={flight}
        terminalOffset={terminalOffset}
        terminalPath={terminalPath}/>
      <TaskSection
        flight={flight}
        escalationActive={flight.escalation_active}
        noticeChannelId={flight.schedule_channel_id}
        offset={terminalOffset}/>
    </>
  )
};

export default FlightHead;
