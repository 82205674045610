import React, {Component} from 'react';
import {Mutation} from 'react-apollo';
import {Form, Message} from 'semantic-ui-react';
import LinkButton from '../../../common/atoms/LinkButton';
import {Link, Redirect} from 'react-router-dom';
import styles from './AddTerminalPage.module.css';

import TerminalDropdown from '../../../common/TerminalDropdown';

import addUserView from '../../../../gql/add-user-view';
import updateMeQueryFromAddTerminal from '../../../../data/add-user-terminal-cache-result';
import PromptBox from "../../../common/templates/PromptBox";
import {VIEW_TYPE, VIEW_DEFAULTS} from '../../../../constants/View';
import {AddTerminalPageWrapper} from "./style";
import {AIRPORT} from "../../../../constants/Routes";
import Button from "../../../common/atoms/Button";

class AddTerminalPage extends Component {
  state = {
    airport_code: null
  };

  render = () =>
    <AddTerminalPageWrapper>
      <h1 className={styles.heading}>Add Terminal to Dashboard</h1>
      <Mutation mutation={addUserView} update={updateMeQueryFromAddTerminal}>
        {(addUserView, { data, called, loading, error }) => {
          if (error) {
            console.error('Error while addUserView mutation', error);
          }

          if (called && !error && !loading) {
            const addedCode = data.addUserView.filter.airport_code;
            return <Redirect to={`/${AIRPORT}/${addedCode}`}/>
          }

          const formSubmitted = () => {
            if (!this.state.airport_code) { return; }

            addUserView({
              variables: {
                view_type: VIEW_TYPE.TERMINAL,
                filter: {
                  airport_code: this.state.airport_code,
                },
                lower_window: VIEW_DEFAULTS.timeWindowLower,
                upper_window: VIEW_DEFAULTS.timeWindowUpper
              }
            });
          };

          return (
            <PromptBox>
              <Form onSubmit={formSubmitted} error={!!error} className={styles.form}>
                <div className={styles.wrapper}>
                  <p>Please enter a terminal callsign or name</p>
                  <Form.Field className={styles.wrapper}>
                    <label className={styles.label}>Terminal or Port</label>
                    <TerminalDropdown
                      disabled={false}
                      onChange={this.terminalChanged}
                      existingTerminals={this.props.existingTerminals}
                    />
                  </Form.Field>
                  <div className={styles.wrapper}>
                    <Button
                      fluid
                      primary
                      type="submit"
                      disabled={loading || !this.state.airport_code}
                      loading={loading}
                    >
                      Add Terminal
                    </Button>
                  </div>
                  <div className={styles.wrapper}>
                    <Link to={`/`}>
                      <LinkButton basic
                                  loading={loading}>
                        Cancel
                      </LinkButton>
                    </Link>
                  </div>
                </div>
                <Message error>
                  <Message.Content>An error occured while trying to add the terminal</Message.Content>
                </Message>
              </Form>
            </PromptBox>
          );
        }}
      </Mutation>
    </AddTerminalPageWrapper>;

  terminalChanged = (e, { value }) => {
    this.setState({ airport_code: value });
  };
}

export default AddTerminalPage;
