import gql from 'graphql-tag';

export default gql`
  fragment PassengerFields on Passenger {
    __typename
    
    id
    namePrefix
    firstName
    lastName
    qfTierCode
    isBoarded
    isCheckedIn
    isSsrWheelchair
    isSsrUnattendedMinor
    isSsrMedical
    baggageCount
    seatNumber
  }
`;


