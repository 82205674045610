import styled, {css} from 'styled-components';
import FlexSection from "../../atoms/FlexSection";

export const DividerWrapper = styled(FlexSection)`
  position: relative;
  font-family: ${props => props.theme.fontImportant};
  font-size: ${props => props.theme.fontSizeExtraSmall};
  line-height: 15px;
  text-align: center;
  min-height: 2px;
  width: 100%;
  margin: 8px 0;
  padding-left: 10px;
  padding-right: 10px;
  color: ${props => props.theme.colors.grey};
  border-color: ${props => props.theme.colors.divider};
  
  ::before {
    position: absolute;
    left: 10px;
    display: inline-block;
    padding: ${props => props.theme.spaceSmall} ${props => props.theme.space2};
    z-index: 2;
    background-color: white;
    line-height: 17px;
  }
  
  ::after {
    content: '';
    position: absolute;
    left: 0;
    background-color: white;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.divider};
  }
  
  ${props => (props.head || props.body || props.icon) && css`
      padding-top: 3px;
      padding-bottom: 3px;
      height: 26px;
      
      ::after {
        top: 13px;
      }
  `};
  
  ${props => props.onClick && css`
    cursor: pointer;
  `};
  
  ${props => props.primary && css` 
    color: ${props => props.theme.colors.oldBrandPrimary};

    ::after {
      border-bottom: 1px solid ${props => props.theme.colors.oldBrandPrimary};
    }
  `};
    
  ${props => props.dark && css`
    color: ${props => props.theme.colors.black};
    border-color: ${props => props.theme.colors.black}
    
    ::after {
      border-bottom: 1px solid ${props => props.theme.colors.dark};
    }
  `};
`;

const Content = styled.div`
  position: absolute;
  z-index: 1;
  background-color: white;
  padding: 4px 8px;
  border-radius: 3px;
  text-transform: uppercase;
`;

export const DividerHead = styled(Content)`
  margin-left: ${props => props.theme.space9};
`

export const DividerBody = styled(Content)`
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 90px;
`;

export const DividerIcon = styled.div`
  z-index: 1;
  width: ${props => props.theme.size6};
  height: ${props => props.theme.size4};
  background-color: white;
  padding: 0 ${props => props.theme.space1};
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  text-transform: uppercase;
  font-family: ${props => props.theme.fontRegular};
  font-size: ${props => props.theme.fontSizeExtraLarge};
  height: ${props => props.theme.size5};
  line-height: 19px;
  user-select: none;
`;
