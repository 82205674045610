import React from 'react';

import {ReactComponent as IconEngineering} from "../../../../images/icons/flight-menu-engineering.svg";
import {EngineeringSectionWrapper, StatusTitle} from "./style";
import EngineeringStatus from "../../molecules/EngineeringStatus";
import Icon from "../../atoms/Icon";
import FlightType, {ENGINEERING_STATUS} from "../../../../constants/Flight";
import {getRegistration} from "../../../../utils/flight-utils";

const EngineeringSection = ({flight}) => {

  const {
    engineering_event_title,
    engineering_status,
  } = flight;

  if (![ENGINEERING_STATUS.RISK, ENGINEERING_STATUS.AOG, ENGINEERING_STATUS.AOG_GRN].includes(engineering_status)) {
    return <></>
  }

  return (
    <EngineeringSectionWrapper>
      <Icon>
        <IconEngineering/>
      </Icon>
      <StatusTitle>{engineering_event_title || getRegistration(flight)}</StatusTitle>
      <EngineeringStatus status={engineering_status}/>
    </EngineeringSectionWrapper>
  );
};

EngineeringSection.propTypes = {
  flight: FlightType,
};

export default EngineeringSection;
