import React from 'react';
import {Mutation} from 'react-apollo';

import completeNotice from '../../../../gql/complete-notice';
import CompleteNotice from "./CompleteNotice";

export default (props) => {
  return (
    <Mutation mutation={completeNotice} onCompleted={props.closeModal}>
      {(callCompleteNotice, {data, loading, called, error}) => {
        if (error) {
          console.error('Error while Complete Notice mutation', error);
        }

        const formSubmitted = ({id, completed_reason}) => {
          callCompleteNotice({
            variables: {
              id,
              completed_reason
            }
          });
        };

        return (
          <CompleteNotice
            {...props}
            loading={loading}
            error={!!error}
            onConfirm={formSubmitted}/>
        )
      }}
    </Mutation>
  );
}
