import React from 'react';
import MessageList from "../../../../notice/message/list/MessageList";
import PropTypes from "prop-types";
import {withNoticeChannel} from "../../../../../hocs/withNoticeChannel";
import {withLocalUserData} from '../../../../../hocs/withLocalUserData';
import {hasPermission} from '../../../../../utils/permission';
import {ACCESS_LEVEL, PERMISSION} from '../../../../../constants/Permission';

const EngineeringTab = ({
                          terminalOffset,
                          user,
                          noticeChannel: {
                            channel,
                            channel: {
                              messages: {
                                nodes,
                              },
                            },
                            fetchMoreMessages,
                          }
                        }) => {

  /**
   * For Engineering, all messages should be black.
   *
   * Confirmed on 13/02/2020
   *
   * @author Jacktator Shelsa
   */
  const shouldMessageFade = _ => false;

  const isChannelAdmin = hasPermission(user, PERMISSION.aircraftEngineeringStatus, ACCESS_LEVEL.ADMIN);

  return (
    <MessageList
      channel={channel}
      nodes={nodes}
      fetchMoreMessages={fetchMoreMessages}
      terminalOffset={terminalOffset}
      shouldMessageFade={shouldMessageFade}
      isChannelAdmin={isChannelAdmin}/>
  )
};

EngineeringTab.propTypes = {
  noticeChannelId: PropTypes.number.isRequired,
  terminalOffset: PropTypes.number,
};

export default withNoticeChannel(withLocalUserData(EngineeringTab));
