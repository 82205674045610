import React from 'react';
import {DeltaWrapper} from "./style";
import PropTypes from "prop-types";

const Delta = ({delta}) => {
  return (
    <DeltaWrapper>{delta < 0 ? `${delta}` : `+${delta}`}</DeltaWrapper>
  )
};

Delta.propTypes = {
  delta: PropTypes.number.isRequired,
};

export default Delta;
