import styled from 'styled-components';
import FlexSection from "../../../../../../common/atoms/FlexSection";

export const PaxFlightNumber = styled(FlexSection)`
  flex-direction: column;
`;

export const ConnectionHead = styled(FlexSection)`
  padding: ${props => props.theme.space3}
`;
