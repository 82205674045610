import PropTypes from 'prop-types';

export const VIEW_DEFAULTS = {
  timeWindowLower: -24,
  timeWindowUpper: 12,
}

export const VIEW_TYPE = {
  ROSTER: "ROSTER",
  TERMINAL: "TERMINAL",
};

export const COLUMNS = {
  NUMBER: 'number',
  REGO: 'registration',
  TO: 'to',
  FROM: 'from',
  STD: "scheduled_time_of_departure",
  STA: "scheduled_time_of_arrival",
  ONB: 'on_block',
  OFB: 'off_block',
  ARR_STATUS: 'arrival_status',
  DEP_STATUS: 'departure_status',
  LOAD: 'loading',
  ENG: 'engineering',
  ARR_GATE: 'arrival_gate',
  DEP_GATE: 'departure_gate',
};

const ViewType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  view_type: PropTypes.oneOf(Object.values(VIEW_TYPE)),
  filter: PropTypes.object,
  lower_window: PropTypes.number.isRequired,
  upper_window: PropTypes.number.isRequired,
});

export default ViewType;
