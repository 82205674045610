import React, {Component} from 'react';
import {FormRow, FormSection, FormWrapper, Input, InputGroup, Label} from "../../../../common/molecules/Form";
import Checkbox from "../../../../common/atoms/Checkbox";
import TimeField from "react-simple-timefield";

class PreDepartureReport extends Component {

  constructor(props) {
    super(props);

    const { preDeparture } = props;

    this.state = {
      checked: {
        usa_security_pre_screen: !!preDeparture.usa_security_pre_screen,
        cleaning_complete: !!preDeparture.cleaning_complete,
        catering_complete: !!preDeparture.catering_complete,
      }
    };
  }

  toggleChecked = (item) => {
    const {
      preDeparture,
      onPreDepartureUpdate
    } = this.props;

    onPreDepartureUpdate({
      ...preDeparture,
      [item]: null,
    });

    this.setState({
      checked: {
        ...this.state.checked,
        [item]: !this.state.checked[item]
      }
    });
  }

  render = () => {

    const {
      preDeparture,
      onPreDepartureUpdate
    } = this.props;
    return (
      <FormWrapper>
        <FormSection>
          <FormRow>
            <InputGroup horizontal>
              <Label>
                <Checkbox checked={this.state.checked.usa_security_pre_screen}
                          onChange={(e) => { this.toggleChecked('usa_security_pre_screen') }}/>
                <span>USA flights only - security pre-screening</span>
                <TimeField
                  value={preDeparture.usa_security_pre_screen || '0:00'}
                  onChange={(_, timeString) => {
                    onPreDepartureUpdate({
                      ...preDeparture,
                      usa_security_pre_screen: timeString,
                    })
                  }}
                  input={
                    <Input placeholder={"Time (off)"}
                           disabled={!this.state.checked.usa_security_pre_screen}/>
                  }
                />
              </Label>
            </InputGroup>
          </FormRow>
          <FormRow>
            <InputGroup horizontal>
              <Label>
                <Checkbox checked={this.state.checked.cleaning_complete}
                          onChange={(e) => { this.toggleChecked('cleaning_complete'); }}/>
                <span>Cleaning complete</span>
                <TimeField
                  value={preDeparture.cleaning_complete || '0:00'}
                  onChange={(_, timeString) => {
                    onPreDepartureUpdate({
                      ...preDeparture,
                      cleaning_complete: timeString,
                    })
                  }}
                  input={
                    <Input placeholder={"Time (off)"}
                           disabled={!this.state.checked.cleaning_complete}/>
                  }
                />
              </Label>
            </InputGroup>
          </FormRow>
          <FormRow>
            <InputGroup horizontal>
              <Label>
                <Checkbox checked={this.state.checked.catering_complete}
                          onChange={(e) => { this.toggleChecked('catering_complete'); }}/>
                <span>Catering complete</span>
                <TimeField
                  value={preDeparture.catering_complete || '0:00'}
                  onChange={(_, timeString) => {
                    onPreDepartureUpdate({
                      ...preDeparture,
                      catering_complete: timeString,
                    })
                  }}
                  input={
                    <Input placeholder={"Time (off)"}
                           disabled={!this.state.checked.catering_complete}/>
                  }
                />
              </Label>
            </InputGroup>
          </FormRow>
        </FormSection>
      </FormWrapper>
    )
  };

}

export default PreDepartureReport
