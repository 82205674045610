import React from 'react';
import {
  MenuItem,
  MenuSection,
  Muted,
  SectionBody,
  SectionHead,
  SectionTitle,
  SliderMenuPanel,
  SliderMenuWrapper,
  SliderPanelWrapper
} from "./style";
import PropTypes from "prop-types";
import Divider from "../../../../../../common/molecules/Divider";
import Icon from "../../../../../../common/atoms/Icon";
import {ReactComponent as PlusIcon} from "../../../../../../../images/icons/mobile-plus.svg"
import {Link} from "react-router-dom";
import {MobileUserControl} from "../../../../../../common/molecules/UserControl";
import UserType from "../../../../../../../constants/User";
import device from "../../../../../../../constants/Device";
import {useMediaQuery} from "react-responsive";
import {AIRPORT, NETWORK_MAP} from "../../../../../../../constants/Routes";

const SliderMenu = ({isOpen, toggleMenu, currentViewName, currentView, terminalViews, user}) => {

  const isMobile = useMediaQuery({query: device.mobile});
  const isDesktop = useMediaQuery({query: device.desktop});

  return (
    <SliderMenuPanel type={'left'}
                     isOpen={isOpen}
                     size={isMobile ? 60 : (isDesktop ? 20 : 40)}
                     backdropClicked={toggleMenu}>
      <SliderPanelWrapper>
        <MobileUserControl user={user}/>
        <Divider/>
        <SliderMenuWrapper>
          {renderTerminalSection(currentViewName, currentView, terminalViews)}
          <Divider/>
        </SliderMenuWrapper>
      </SliderPanelWrapper>
    </SliderMenuPanel>
  )
};

const renderTerminalSection = (currentViewName, currentView, terminalViews) => {
  return (
    <MenuSection>
      <SectionHead>
        <SectionTitle>Network</SectionTitle>
      </SectionHead>
      <SectionBody>
        <Link to={`/${NETWORK_MAP}`}>
          <MenuItem>
            Map
          </MenuItem>
        </Link>
      </SectionBody>
      <SectionHead>
        <SectionTitle>Terminal</SectionTitle>
        <Link to={`/${AIRPORT}/add`}>
          <Icon>
            <PlusIcon/>
          </Icon>
        </Link>
      </SectionHead>
      <SectionBody>
        {
          terminalViews.length > 0 ?
            terminalViews.map(v =>
              <Link to={`/${AIRPORT}/${v.filter.airport_code}`}
                    key={v.id}>
                <MenuItem active={v.id === currentView.id}>
                  {v.filter.airport_code}
                </MenuItem>
              </Link>
            ) : (
              <Muted>
                No terminal connected
              </Muted>
            )
        }
      </SectionBody>
    </MenuSection>
  )
};

SliderMenu.defaultProps = {
  isOpen: false,
  terminalViews: [],
};
SliderMenu.propTypes = {
  isOpen: PropTypes.bool,
  toggleMenu: PropTypes.func,
  currentViewName: PropTypes.string.isRequired,
  terminalViews: PropTypes.array,
  user: UserType.isRequired,
};

export default SliderMenu;
