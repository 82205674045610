import styled, {css} from "styled-components";

export const InitialAvatarWrapper = styled.div`

  cursor: pointer;

  height: 30px;
  width: 30px;

  padding-top: ${props => props.theme.sizeSmall};
  margin-right: ${props => props.theme.space2};

  background-color: ${props => props.theme.colors.grey};
  border-radius: 50%;

  text-align: center;
  font-size: ${props => props.theme.fontSizeMedium};
  line-height: 30px;
  color: white;

  ${props => props.isSuperuser && css`
    background-color: ${props => props.theme.colors.brandPrimary};
  `}

  ${props => props.isOpen && !props.isSuperuser && css`
    background-color: ${props => props.theme.colors.body};
  `};
`;
