import React from 'react';
import styles from './Content.module.css'
import {getExtraStatus, getLocalTime, getMinutesDiff} from "../../../utils/flight-utils";
import RoleMention from './RoleMention';

export default React.memo(({content, offset}) => {
  const prefix = '[role:';
  const suffix = ']';
  const lines = content.split("\n");

  return (
    <div className={styles.content}>
      {
        lines.map(line => {
          let processed = [];
          let start = 0;
          let end = 0;

          while (line.indexOf('[role:') > -1 || line.indexOf('[date:') > -1) {
            let type = null;
            start = line.indexOf('[role:');
            if (start !== -1) {
              type = 'role';
            }
            else {
              start = line.indexOf('[date:');
              if (start !== -1) {
                type = 'date';
              }
            }

            end = line.indexOf(suffix);

            if(end === -1) {
              end = prefix.length;
            }

            if (type === 'role') {
              processed.push(line.slice(0, start));
              // Adding span with formatting to highlight the role mention
              processed.push(
                <span className={styles.role}>
                  <RoleMention mentionString={line.slice(start + prefix.length, end)}/>
                </span>
              );
            }
            else if (type === 'date') {
              const dateConfig = line.slice(start + prefix.length, end);
              const [actual, scheduled] = dateConfig.split('|');

              let str = getLocalTime(actual, offset);
              if (scheduled) {
                str += ` ${getExtraStatus(getMinutesDiff(scheduled, actual))}`;
              }

              processed.push(line.slice(0, start));
              processed.push(<span>{str}</span>);
            }

            line = line.slice(end + 1); // Reassign line to what's left of the unprocessed string
          }

          if(line.length > 0) {
            processed.push(line); // Adding the remaining line when no further role mentions found
            processed.push(<br/>); // Adding line break for multiline messages
          }

          return processed.map((p, index) => <span key={index}>{p}</span>);
        })
      }
    </div>
  )
});
