import {TimezoneOffsetContext} from "../../../contexts/TimezoneOffsetContext";
import Terminal from "../Terminal";
import React from "react";
import {Route, Switch} from "react-router-dom";
import {TYPE} from "../../../constants/Flight";

const RosterView = ({terminalPath, user, view}) => {

  return (
    <Switch>
      <Route
        path={`${terminalPath}/:flightId?/:context?`}
        render={({match: {params: {flightId, context}}}) => renderTerminal(user, view, terminalPath, flightId, context)}/>
    </Switch>
  )
};

const renderTerminal = (user, view, terminalPath, flightId, context) => {

  const localTimezoneOffset = new Date().getTimezoneOffset() * -1;

  return (
    <TimezoneOffsetContext.Provider value={localTimezoneOffset}>
      <Terminal
        key={"roster"}
        terminalViewType={TYPE.DEPARTURE}
        user={user}
        view={view}
        terminalPath={terminalPath}
        detailPath={flightId}
        context={context}
      />
    </TimezoneOffsetContext.Provider>
  );
};

export default RosterView;
