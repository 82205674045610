import React from 'react';
import {Query} from 'react-apollo';

import FlightDetail from '../components/flight/FlightDetail';
import ErrorPage from '../components/common/ErrorPage';

import flightQuery from '../gql/flight';

import {TimezoneOffsetContext} from '../contexts/TimezoneOffsetContext';
import {NETWORK_STATUS} from "../constants/Apollo";

const FlightData = ({flightId, ...restProps}) => {
  if (!flightId) {
    return getError(true);
  }
  return (
    <Query query={flightQuery}
           variables={{id: flightId}}
           fetchPolicy="cache-first"
           errorPolicy="all">
      {({networkStatus, error, data = {}}) => {
      if (error) {
        console.error('Failed loading flight query', error);
        const flightNotFound = error && error.message.indexOf('Flight not found') !== -1;
        return getError(flightNotFound);
      }

      const loading = networkStatus <= NETWORK_STATUS.SET_VARIABLES;

      const { flight } = data;

      return (
        <TimezoneOffsetContext.Consumer>
          {terminalOffset =>
            <FlightDetail
              {...restProps}
              loading={loading}
              terminalOffset={terminalOffset}
              flight={flight}
              key={flight ? flight.id : "flight.id"}/>
        }
        </TimezoneOffsetContext.Consumer>
      );
    }}
    </Query>
  )
};

function getError(flightNotFound) {
  const msg = flightNotFound ?
    "The flight could not be found" :
    "There was a problem loading the flight";

  return (
    <ErrorPage
      title="Error loading flight"
      message={msg}
      hideRefresh={flightNotFound}
    />
  );
}

export default FlightData;
