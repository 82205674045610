import styled from 'styled-components';

const UnreadBadge = styled.div`
  display: inline-block;
  background-color: ${props => props.theme.colors.oldBrandPrimary};
  border-radius: 50%;
  padding-top: 1px;
  width: ${props => props.theme.size5};
  height: ${props => props.theme.size5};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  line-height: 20px;
  color: white;
  font-size: ${props => props.theme.fontSizeMedium};
  text-align: center;
`;

export default UnreadBadge;
