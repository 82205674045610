import styled from 'styled-components';
import FlexSection from "../../atoms/FlexSection";
import {EngineeringStatusWrapper} from "../../molecules/EngineeringStatus/style";

export const EngineeringSectionWrapper = styled(FlexSection)`
  padding: ${props => props.theme.space2};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  
  ${EngineeringStatusWrapper} {
    margin: auto 0 auto auto;
  }
`;

export const StatusTitle = styled.div`
  font-family: ${props => props.theme.fontImportant};
  font-size: ${props => props.theme.fontSizeLarge};
  color: ${props => props.theme.colors.body};
`;
