import React from 'react';
import {useQuery} from 'react-apollo';
import {Body, Content, Head, IconClose, Loading, LoadingWrapper, TerminalLink, TerminalLinkWrapper} from "./style";
import InfoLabel from '../../common/molecules/InfoLabel';

import getAirportFlightSummaryQuery from '../../../gql/get-airports-flight-summary.js';

const MapMiniPortSummary = ({port, airlineGroup, onClose, onPortSelected}) => {
  const {loading, error, data = {}} = useQuery(getAirportFlightSummaryQuery, {
    fetchPolicy: 'network-only',
    variables: {
      code: port,
      airline_group: airlineGroup,
    }
  });

  return (
    <Content>
      <IconClose onClick={onClose}/>
      {getBody(error, loading, data.getAirportFlightSummary, onPortSelected)}
    </Content>
  )
};

const getBody = (error, loading, airport, onPortSelected) => {
  if (error) {
    return 'Error loading port summary';
  }

  if (loading || !airport) {
    return (
      <LoadingWrapper>
        <Loading/>
      </LoadingWrapper>
    );
  }

  return (
    <>
      <Head>
        {airport.code} | {airport.city}
      </Head>
      <Body>
        <InfoLabel label={"Delay"} text={airport.delayCount}/>
        <InfoLabel label={"Canx"} text={airport.cancelledCount}/>
        <InfoLabel label={"AOG"} text={airport.aogCount}/>
      </Body>
      <TerminalLinkWrapper>
        <TerminalLink onClick={() => onPortSelected(airport.code)}>
          View port summary
        </TerminalLink>
      </TerminalLinkWrapper>
    </>
  );
};

export default MapMiniPortSummary;
