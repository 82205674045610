import React, {Component} from "react";
import styles from "./Event.module.css";
import noticeListStyles from '../../NoticeSection.module.css';
import Author from "../../../common/molecules/Author";
import Content from "../../common/Content";
import NoticeStatus from "../../common/NoticeStatus";
import Timestamp from "../../../common/atoms/Timestamp";

import HoverMenu from "../../menu/HoverMenu";
import {TYPE} from "../../../../constants/Notice";
import Label from "../../../common/atoms/Label";

export default class Event extends Component {

  state = {
    showModal: false,
  };

  render = () => {

    const {
      event,
      offset,
      disableLabel = true,
      disableHover = false,
    } = this.props;

    return (
      <div className={`${styles.eventRow} ${disableHover ? noticeListStyles.hoverDisabled : ''}`}
            onClick={this.doNothing}>
        <div className={noticeListStyles.contentCol}>
          {
            event.created_by &&
            <Author author={event.created_by}/>
          }
          <Content content={event.content}/>
          <NoticeStatus
            notice={event}/>
        </div>
        <div className={styles.menu}>
          <HoverMenu
            notice={event}
            offset={offset}/>
        </div>
        <div className={noticeListStyles.timestampCol}>
          {
            !disableLabel && event.is_completeable &&
            <Label>
              {
                event.messageType === TYPE.EVENT ? (
                  <span>{event.completed_at ? "Done" : "Not Done"}</span>
                ) : (
                  <span>{event.completed_at ? "Resolved" : "Not Resolved"}</span>
                )
              }
            </Label>
          }
          <Timestamp
            notice={event}
            offset={offset}/>
        </div>
      </div>
    )
  };

  doNothing = () => {
    // Due to Mobile Safari issue, hover is not triggered unless the element has an 'onclick' to it. Hence this method. https://stackoverflow.com/a/20048559/3381997
  };

  setModal = (isOpen) => {
    this.setState({showModal: isOpen})
  };

};
