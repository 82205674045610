import userQuery from '../gql/get-user';

export default function(cache, { data: { deleteUserView: id } }) {
  const cachedResult = cache.readQuery({ query: userQuery });

  // If the view is not found in cache then skip
  if (!cachedResult.user.views.find(v => v.id === id)) {
    return;
  }

  cache.writeQuery({
    query: userQuery,
    data: {
      user: {
        ...cachedResult.user,
        views: [
          ...cachedResult.user.views.filter(v => v.id !== id),
        ]
      }
    }
  });
}
