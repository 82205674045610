import React from 'react';
import {DotWrapper} from "./style";
import PropTypes from "prop-types";
import {TYPE} from "../../../../constants/Notice";

const Dot = ({type, isLarge, isLate, isCompleted, isSkipped}) =>
  <DotWrapper isLarge={isLarge}
              type={type}
              isCompleted={isCompleted}
              isLate={isLate}
              isSkipped={isSkipped}/>;

Dot.defaultProps = {
  isLarge: false,
  isLate: false,
  isCompleted: false,
  isSkipped: false,
};
Dot.propTypes = {
  type: PropTypes.oneOf(Object.values(TYPE)),
  isLarge: PropTypes.bool,
  isLate: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isSkipped: PropTypes.bool,
};

export default Dot;
