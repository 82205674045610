import React, {Component} from 'react';
import {withApollo} from 'react-apollo';
import Button from "../../../components/common/atoms/Button";

import undoNoticeMutation from "../../../gql/undo-notice";

class HoverMenuUndoButton extends Component {

  render = () => (
    <Button
      basic
      small
      onClick={this.doUndo}>
      Undo
    </Button>
  );

  doUndo = () => {
    const {
      notice,
      client
    } = this.props;

    client.mutate({
      mutation: undoNoticeMutation,
      variables: {
        id: notice.id,
      },
    });
  }

}

export default withApollo(HoverMenuUndoButton);
