import gql from 'graphql-tag';

export default gql`
  fragment FlightBody on Flight {
    __typename

    id
    airline_group
    updated_at
    created_at

    ogs_last_updated_at

    schedule_channel_id
    customer_channel_id
    load_control_channel_id
    engineering_channel_id

    owner_airline
    operating_airline
    flight_number
    flight_status
    domain

    aircraft_registration
    aircraft_type
    aircraft_group

    departure_airport_code
    departure_airport {
      id
      timezone_offset
      notice_channel_id
    }
    departure_gate
    departure_terminal
    departure_scheduled_time
    offblocks_time
    offblocks_qualifier
    offblocks_latest_estimate

    arrival_airport_code
    arrival_airport {
      id
      timezone_offset
      notice_channel_id
    }
    arrival_gate
    arrival_terminal
    arrival_scheduled_time
    onblocks_time
    onblocks_qualifier
    onblocks_latest_estimate

    fuel_total

    engineering_status
    engineering_event_title

    boarding_status

    pts_workflow_status
    escalation_active

    departure_report_submitted
  }
`;
