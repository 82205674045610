import styled from 'styled-components'
import PropTypes from 'prop-types';

const FlexSection = styled.div`
  display: flex;
  align-items: center;
`;

FlexSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FlexSection;
