import styled, {css} from 'styled-components';
import PropTypes from "prop-types";

const Icon = styled.div`
  display: inline-block;
  text-align: center;

  color: ${props => props.theme.colors.black};

  width: ${props => props.theme.size8};
  height: ${props => props.theme.size8};
  line-height: ${props => props.theme.size7};

  svg {
    vertical-align: middle;
  }

  ${props => props.small && css`
    width: ${props => props.theme.size4};
    height: ${props => props.theme.size4};
    line-height: ${props => props.theme.size4};
  `}
`;

Icon.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Icon;
