import styled, {css} from 'styled-components';
import device from "../../../constants/Device";
import {DetailColumn, MasterColumn, MasterDetail} from "../../common/templates/MasterDetail";

export const TerminalWrapper = styled(MasterDetail)`

  /* Mobile Responsive */
  ${props => {
  switch (props.path) {
    case undefined:
      return css`
                      ${DetailColumn} {
                          display: none;
                      }

                      @media ${device.tablet} {
                        ${DetailColumn} {
                          display: block;
                        }
                      }
                    `;
    case 'events':
      return css`
                      ${MasterColumn} {
                          display: none;
                      }

                      @media ${device.tablet} {
                        ${MasterColumn} {
                          display: flex;
                        }
                      }
                    `;
    default:
      return css`
                      ${MasterColumn} {
                          display: none;
                      }

                      @media ${device.tablet} {
                        ${MasterColumn} {
                          display: flex;
                        }
                      }
                    `;
  }
}}
`;
