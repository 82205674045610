import React from 'react';
import {useMutation} from 'react-apollo';
import {useSnackbar} from 'notistack';

import {addFlightDepartureReport} from '../../../../gql/fragments/departure-report';
import DepartureReport from "./index"
import { useState } from 'react';

const MutatedDepartureReport = ({flight, scheduleChannel, onClose}) => {
  const {enqueueSnackbar} = useSnackbar();

  const [loadingReportCompletion, setLoadingReportCompletion] = useState(null);
  const [callAddFlightDepartureReport, { loading }] = useMutation(
    addFlightDepartureReport,
    {
      onCompleted: (data) => {
        const completed = !!data.addFlightDepartureReport?.completed_at;
        const msg = completed ?
          'Departure report has been submitted' :
          'In progress departure report has been saved';

        enqueueSnackbar(msg, { variant: 'success' });
        setLoadingReportCompletion(null);
        onClose();
      },
      onError: () => {
        setLoadingReportCompletion(null);
        enqueueSnackbar('There was an error saving the departure report', { variant: 'error' });
      }
    }
  );

  const saveForm = (complete, reportData) => {
    setLoadingReportCompletion(complete);
    callAddFlightDepartureReport({
      variables: {
        input: {
          flight_id: flight.id,
          complete,
          ...reportData,
        },
      },
    });
  }

  return (
    <DepartureReport
      flight={flight}
      scheduleChannel={scheduleChannel}

      loading={loading}
      loadingReportCompletion={loadingReportCompletion}
      onConfirm={saveForm}/>
  )
};

export default MutatedDepartureReport;
