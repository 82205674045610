import React, {useState} from 'react';
import MessageList from "../../../../notice/message/list/MessageList";
import FlightType, {STATUS} from "../../../../../constants/Flight";
import PropTypes from "prop-types";
import {getFlightNumber} from "../../../../../utils/flight-utils";
import CabinAccordionData from "../../../../../containers/FlightSummaryData";
import Divider from "../../../../common/molecules/Divider";
import InboundConnectionsAccordionData from "../../../../../containers/InboundConnectionsAccordionData";
import MutatedMessageForm from "../../../../notice/message/add/MutatedMessageForm";
import {withNoticeChannel} from "../../../../../hocs/withNoticeChannel";
import {withLocalUserData} from '../../../../../hocs/withLocalUserData';
import {hasPermission} from '../../../../../utils/permission';
import {ACCESS_LEVEL, PERMISSION} from '../../../../../constants/Permission';
import {useMediaQuery} from "react-responsive";
import device from "../../../../../constants/Device";

const PassengerTab = ({
  flight,
  terminalOffset,
  user,
  noticeChannel: {
    channel,
    channel: {
      messages: {
        nodes,
      },
    },
    fetchMoreMessages,
  }
}) => {

  const isMobile = useMediaQuery({query: device.mobile});

  const [cabinAccordionCollapsed, setCabinAccordionCollapsed] = useState(false);
  const [inboundConnectionsCollapsed, setInboundConnectionsCollapsed] = useState(isMobile);

  const contextName = getFlightNumber(flight);

  /**
   * For Passenger, all messages should be black.
   *
   * Confirmed on 13/02/2020
   *
   * @author Jacktator Shelsa
   */
  const shouldMessageFade = _ => false;

  const connectFlightEnabled = hasPermission(user, PERMISSION.connectFlight, ACCESS_LEVEL.BASIC);
  const isChannelAdmin = hasPermission(user, PERMISSION.pil, ACCESS_LEVEL.ADMIN);

  return (
    <>
      {
        flight.flight_status !== STATUS.CANCELLED &&
        <>
          <CabinAccordionData
            collapsed={cabinAccordionCollapsed}
            toggleAccordion={() => {
              if (isMobile && cabinAccordionCollapsed && !inboundConnectionsCollapsed) {
                setInboundConnectionsCollapsed(cabinAccordionCollapsed)
              }
              setCabinAccordionCollapsed(!cabinAccordionCollapsed)
            }}
            flight={flight}
            terminalOffset={terminalOffset}/>
          {
            connectFlightEnabled &&
            <>
              <Divider/>
              <InboundConnectionsAccordionData
                collapsed={inboundConnectionsCollapsed}
                toggleAccordion={() => {
                  if (isMobile && !cabinAccordionCollapsed && inboundConnectionsCollapsed) {
                    setCabinAccordionCollapsed(inboundConnectionsCollapsed)
                  }
                  setInboundConnectionsCollapsed(!inboundConnectionsCollapsed)
                }}
                flight={flight}
                terminalOffset={terminalOffset}/>
            </>
          }
        </>
      }
      <MessageList
        channel={channel}
        nodes={nodes}
        fetchMoreMessages={fetchMoreMessages}
        terminalOffset={terminalOffset}
        shouldMessageFade={shouldMessageFade}
        isChannelAdmin={isChannelAdmin}/>

      <MutatedMessageForm
        chatChannelId={channel.id}
        notify={`${contextName} - ${channel.name}`}/>
    </>
  )
};

PassengerTab.propTypes = {
  flight: FlightType,
  noticeChannelId: PropTypes.number.isRequired,
  terminalOffset: PropTypes.number,
};

export default withNoticeChannel(withLocalUserData(PassengerTab));
