import gql from 'graphql-tag';

import noticeChannelFragment from './fragments/notice-channel';

export default gql`
  ${noticeChannelFragment}

  query getNoticeChannel($id: Int!) {
    noticeChannel(id: $id) {
      ...NoticeChannelBody
    }
  }
`;
