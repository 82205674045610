import gql from 'graphql-tag';

export default gql`query getAirports {
  getAirports {
    id
    code
    city
    country
    timezone_offset
    latitude
    longitude
    map_zoom_priority
    notice_channel_id
  }
}`;
