import styled from 'styled-components';
import FlexSection from "../../../../common/atoms/FlexSection";

export const AddEventButtonWrapper = styled(FlexSection)`

  font-family: ${props => props.theme.fontImportant};
  color: ${props => props.theme.colors.brandSecondary};
  
  margin-left: 66px;
  
  span {
    margin-left: ${props => props.theme.size3};
  }
  
  /* Cursor styling */
  cursor: pointer;
  text-transform: uppercase;
  user-select: none; 
`;
