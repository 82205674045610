import React, { Component } from 'react';

const OfflineContext = React.createContext(null);

export class OfflineContextProvider extends Component {
  state = {
    isOffline: false
  }

  setOffline = (isOffline) => {
    this.setState({
      isOffline
    });
  }

  render = () => {
    const { children } = this.props;

    const value = {
      setOffline: this.setOffline,
      isOffline: this.state.isOffline
    }

    return (
      <OfflineContext.Provider value={value}>
        {children}
      </OfflineContext.Provider>
    );
  }
}

export const OfflineContextConsumer = OfflineContext.Consumer;
