import styled, {css} from 'styled-components';
import FlexSection from "../../../../common/atoms/FlexSection";
import device from "../../../../../constants/Device";

export const DetailSectionWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  padding: ${props => props.theme.space4};
  border-bottom: 1px solid #f5f5f5;
`;

export const InfoLabelColumn = styled(FlexSection)`
  padding: 0 10px;
  white-space: nowrap;
  
  /* Mobile Responsive */
  ${props => props.laptop && css`
    display: none;
    
    @media ${device.laptop} {
      display: flex;
    }
  `};
  
  ${props => props.lessImportant && css`
    opacity: 0.5;
  `};
`;

export const MobileLinkWrapper = styled.div`
  
  /* Mobile Responsive */
  display: inline-block;
  @media ${device.tablet} {
    display: none;
  }
`;

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.space1} 0 0;
`;