import gql from 'graphql-tag';

import messagePageFragment from './message-page';
import noticeChannelLastViewed from './notice-channel-last-viewed';

export default gql`
  ${messagePageFragment}
  ${noticeChannelLastViewed}

  fragment NoticeChannelBody on NoticeChannel {
    __typename
    id
    name
    channelType
    messages {
    ...MessagesPageFields
    }
    ...NoticeChannelLastViewedBody
  }
`;
