import React, { useState } from 'react';
import UserType from "../../constants/User";
import IOMap from "./Map";
import {MainColumn, MenuColumn, NetworkMapWrapper} from "./style";
import {withTerminals} from "../../hocs/withTerminals";
import PropTypes from "prop-types";
import AirportType from "../../constants/Airport";
import NetworkMenu from "./NetworkMenu";
import { withRouter } from 'react-router-dom';
import { NETWORK_MAP } from '../../constants/Routes';

const NetworkMap = ({user, history, terminals: airports, }) => {

  const [isCollapsed, setCollapsed] = useState(false);

  return (
    <NetworkMapWrapper>
      <MenuColumn>
        <NetworkMenu user={user} collapsed={isCollapsed} onCollapsedChange={(collapsed) => setCollapsed(collapsed)} />
      </MenuColumn>
      <MainColumn>
        <IOMap
          airports={airports}
          airlineGroup={user.group !== 'ALL' ? user.group : undefined}
          networkMenuCollapsed={isCollapsed}
          onPortSelected={(port) => {
            setCollapsed(false);
            history.push(`/${NETWORK_MAP}/${port}`);
          }}
        />
      </MainColumn>
    </NetworkMapWrapper>
  );
};

NetworkMap.propTypes = {
  user: UserType,
  terminals: PropTypes.arrayOf(AirportType),
};

export default withRouter(withTerminals(NetworkMap));
