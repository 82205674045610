import styled, {css} from 'styled-components';
import FlexSection from "../../../common/atoms/FlexSection";
import device from '../../../../constants/Device';

export const FlightRowWrapper = styled(FlexSection)`
  color: ${props => props.theme.colors.body};
  box-sizing: border-box;
  width: 100%;
  @media ${device.tablet} {
    height: 48px;
  }
  margin-bottom: 8px;
  border-radius: 4px;
  position: relative;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  padding: ${props => props.theme.space2};
  line-height: 1.1;

  :hover {
    color: ${props => props.theme.colors.body};
  }

  ${props => props.hasUnread && css`
    font-weight: bold;
  `};

  ${props => props.active && css`
    border-radius: 5px;
    border: 3px solid ${props => props.theme.colors.brandSecondary};
    padding: 5px;

    ::after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid ${props => props.theme.colors.brandSecondary};
      right: -6px;
      top: 50%;
      margin-top: -6px;
    }
  `};
`;

export const Dot = styled.div`
  width: ${props => props.theme.size1};
  height: ${props => props.theme.size1};
  border-radius: ${props => props.theme.cornerRadius};

  margin-right: ${props => props.theme.space1};

  background-color: ${props => props.theme.colors.teal};

  ${props => props.delayed && css`
    background-color: ${props => props.theme.colors.delta};
  `}

  ${props => props.escalated && css`
    background-color: ${props => props.theme.colors.oldBrandPrimary};
  `}

  ${props => props.cancelled && css`
    background-color: ${props => props.theme.colors.grey};
  `}
`;
