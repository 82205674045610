import React from "react";
import {hasUnread} from "../../../../../utils/unread-messages";
import {NavLink as Link} from "react-router-dom";
import UnreadBadge from "../../../../common/atoms/UnreadBadge";
import {ReactComponent as IconTerminal} from "../../../../../images/control-tower.svg";
import {withNoticeChannel} from "../../../../../hocs/withNoticeChannel";
import {BadgeWrapper, ButtonText, TerminalEventButtonWrapper} from "./style";
import Icon from "../../../../common/atoms/Icon";
import ChannelType from "../../../../../constants/Channel";
import PropTypes from "prop-types";
import UserType from "../../../../../constants/User";

const DesktopTerminalEventButton = ({
  noticeChannel: {channel},
  terminalPath,
  user,
  active
}) => {
  const unreadImportantMessagesCount = channel ? hasUnread(
    channel.user_last_viewed,
    channel.messages,
    user
  ) : 0;

  return (
    <Link to={`${terminalPath}/events`}>
      <TerminalEventButtonWrapper
        hasUnread={unreadImportantMessagesCount > 0}
        active={active}>
        <Icon>
          <IconTerminal/>
        </Icon>
        <ButtonText>Terminal Events</ButtonText>
        {
          unreadImportantMessagesCount > 0 &&
          <BadgeWrapper>
            <UnreadBadge>{unreadImportantMessagesCount || " "}</UnreadBadge>
          </BadgeWrapper>
        }
      </TerminalEventButtonWrapper>
    </Link>
  );
};

DesktopTerminalEventButton.propTypes = {
  noticeChannel: PropTypes.shape({
    channel: ChannelType.isRequired,
  }),
  terminalPath: PropTypes.string.isRequired,
  user: UserType.isRequired,
};

export default withNoticeChannel(DesktopTerminalEventButton, {
  renderLoadingComponent: true,
  fetchPolicy: "cache-and-network",
});
