import styled from 'styled-components';

export const AwaitingWrapper = styled.div`
  padding: 0 106px;
  font-family: ${props => props.theme.fontImportant};
  color: ${props => props.theme.grey}; 
  
  img {
    width: ${props => props.theme.size8};
    vertical-align:middle;
  }
`;
