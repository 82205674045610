import {onError} from 'apollo-link-error';
import {ApolloLink} from 'apollo-link';
import Auth from '@aws-amplify/auth';
import {createAuthLink} from 'aws-appsync-auth-link';
import {createSubscriptionHandshakeLink} from 'aws-appsync-subscription-link';
import ApolloClient from 'apollo-client';
import {createHttpLink} from 'apollo-link-http';
import {setContext} from "apollo-link-context";
import {InMemoryCache} from 'apollo-cache-inmemory';

import resolvers from '../local-resolvers';

const graphApiUrl = process.env.REACT_APP_GRAPH_API_URL;
const region = process.env.REACT_APP_AWS_REGION;

function buildCache() {
  return new InMemoryCache({
    cacheRedirects: {
      Query: {
        flight: (_, args, {getCacheKey}) => getCacheKey({__typename: 'Flight', id: args.id}),
        noticeChannel: (_, args, {getCacheKey}) => getCacheKey({__typename: 'NoticeChannel', id: args.id}),
        adminGetUser: (_, args, {getCacheKey}) => getCacheKey({__typename: 'User', id: args.id}),
      },
    },
  });
}

function buildNetworkLostAndResumedLinks(offlineContextProviderRef) {
  // This link should set the offline context to offline when an error is found that
  // means we have lost network
  const networkLostLink = onError(({graphQLErrors, networkError}) => {
    if (networkError) {
      console.error('ERRORLINK: Got network error', networkError);
      const offlineContextProvider = offlineContextProviderRef.current;
      if (offlineContextProvider && !offlineContextProvider.state.isOffline) {
        offlineContextProvider.setOffline(true);
      }
    }
  });

  // This link should set the offline context to not offline when a successful
  // operation occurs
  const networkResumedLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      const offlineContextProvider = offlineContextProviderRef.current;
      if (offlineContextProvider && offlineContextProvider.state.isOffline) {
        offlineContextProvider.setOffline(false);
      }
      return data;
    });
  });

  return [
    networkLostLink,
    networkResumedLink,
  ];
}

function buildAuthLink() {
  return createAuthLink({
    url: graphApiUrl, region, auth: {
      type: "AWS_IAM",
      credentials: async () => {
        const session = await Auth.currentSession();
        if (!session) {
          return null;
        }

        const credentials = await Auth.currentCredentials();
        return Auth.essentialCredentials(credentials);
      }
    }
  });
}

function buildIdTokenHeaderLink() {
  return setContext(async (request, context) => ({
    ...context,
    headers: {
      ...context.headers,
      'io-id-token': (await Auth.currentSession()).idToken.jwtToken,
    },
  }));
}

export function createApolloClient(offlineContextProviderRef) {
  const cache = buildCache();

  const httpLink = createHttpLink({uri: graphApiUrl});
  const link = ApolloLink.from([
    ...buildNetworkLostAndResumedLinks(offlineContextProviderRef),
    buildAuthLink(),
    buildIdTokenHeaderLink(),
    createSubscriptionHandshakeLink(graphApiUrl, httpLink)
  ]);

  return new ApolloClient({
    cache,
    link,
    resolvers,
  });
}
