import React from "react";
import {ModalBackground, ModalBody, ModalHead, ModalWrapper} from "./style";
import PropTypes from "prop-types";
import ModalPortal from './ModalPortal';

class Modal extends React.Component {

  escFunction = (event) => {
    const {
      onClose,
    } = this.props;

    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      onClose();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    const {
      isOpen,
      fixed,
      onOpen,
      onClose,
      closeOnDimmerClick,
      head,
      trigger,
      disableTrigger,
      children
    } = this.props;

    return (
      <div>
        {
          trigger &&
          <div onClick={() => !disableTrigger && onOpen()}>
            {trigger}
          </div>
        }
        {
          isOpen &&
          <ModalPortal>
            <ModalBackground
              isOpen={isOpen}
              onClick={closeOnDimmerClick ? onClose : () => {
              }}>
              <ModalWrapper fixed={fixed}
                            onClick={(event) => event.stopPropagation()}>
                {
                  head &&
                  <ModalHead>
                    {head}
                  </ModalHead>
                }
                <ModalBody>
                  {children}
                </ModalBody>
              </ModalWrapper>
            </ModalBackground>
          </ModalPortal>
        }
      </div>
    )
  }
}

Modal.defaultProps = {
  isOpen: false,
  fixed: false,
  closeOnDimmerClick: false,
  disableTrigger: false,
};
Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  fixed: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnDimmerClick: PropTypes.bool,
  head: PropTypes.node,
  trigger: PropTypes.node,
  disableTrigger: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Modal;
