import styled, {css} from "styled-components";
import FlexSection from "../../../../../common/atoms/FlexSection";
import device from "../../../../../../constants/Device";


export const PaginationSection = styled(FlexSection)`
`;

export const PaginationButton = styled.a`
  /* Display */
  display: flex; 
  align-items: center;
  justify-content: center;
  /* Size */
  width: ${props => props.theme.space10};
  /* Mobile Responsive */
  height: ${props => props.theme.size5};
  @media ${device.tablet} {
    height: ${props => props.theme.size7};
  }
  /* Cursor styling */
  cursor: pointer;
  text-transform: uppercase;
  /* SVG Button coloring*/
  svg {
    fill: ${props => props.theme.colors.black}
  }

  ${props => props.desktopOnly && css`
    /* Mobile Responsive */
    display: none;
    @media ${device.tablet} {
      display: flex;
    }
  `}

  ${props => props.selected && css`
    background: white;
  `}
  
  ${props => props.disabled && css`
    svg {
      fill: ${props => props.theme.colors.grey}
    }
  `}
`;
