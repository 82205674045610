import React from 'react';
import {EngineeringStatusItem, EngineeringStatusWrapper} from "./style";
import PropTypes from 'prop-types';
import {ENGINEERING_STATUS} from "../../../../constants/Flight";

const EngineeringStatus = ({status}) => {
  return (
    <EngineeringStatusWrapper>
      {
        status === ENGINEERING_STATUS.RISK &&
        <EngineeringStatusItem risk>RISK</EngineeringStatusItem>
      }
      {
        (status === ENGINEERING_STATUS.AOG || status === ENGINEERING_STATUS.AOG_GRN) &&
        <EngineeringStatusItem aog>AOG</EngineeringStatusItem>
      }
      {
        status === ENGINEERING_STATUS.AOG_GRN &&
        <EngineeringStatusItem grn>GRN</EngineeringStatusItem>
      }
    </EngineeringStatusWrapper>
  )
};

EngineeringStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(ENGINEERING_STATUS)),
};

export default EngineeringStatus;
