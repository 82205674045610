import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Accordion from "../../../common/molecules/Accordion";
import {LateBoardingAccordionWrapper} from "./style";
import {ReactComponent as IconConnections} from "../../../../images/icons/connections.svg";
import FlightType from "../../../../constants/Flight";
import LateFlightList from "./LateFlightList";

const LateBoardingAccordion = ({flights}) => {
  const [isAccordionOpen, setAccordionOpen] = useState(true)

  return (
    <LateBoardingAccordionWrapper>
      <Accordion
        collapsed={!isAccordionOpen}
        icon={<IconConnections />}
        head={`${flights.length > 0 ? `${flights.length} ` : ''}Late Boarding`}
        body={<LateFlightList flights={flights}/>}
        onToggle={() => setAccordionOpen(!isAccordionOpen)}/>
    </LateBoardingAccordionWrapper>
  );
}

LateBoardingAccordion.propTypes = {
  flights: PropTypes.arrayOf(FlightType),
};

export default LateBoardingAccordion;
