import React, {Component} from 'react';
import moment from 'moment';

import {ReactComponent as IconClock} from '../../../../images/clock.svg';
import {TimezoneOffsetContext} from '../../../../contexts/TimezoneOffsetContext';
import {DateDisplay, DateTimeDisplayWrapper, TimeDisplay, TimeZoneDisplay} from "./style";
import Icon from "../../atoms/Icon";

class DateTimeDisplay extends Component {

  componentDidMount = () => {
    this.interval = setInterval(() => this.forceUpdate(), 1000);
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  render = () => {
    return (
      <TimezoneOffsetContext.Consumer>
        {
          timezoneOffset => {
        const timezoneDisplay = this.getOffsetForDisplay(timezoneOffset);
        const time = moment().utcOffset(timezoneOffset);

        return (
          <DateTimeDisplayWrapper>
            <Icon>
              <IconClock/>
            </Icon>
            <TimeDisplay>{time.format('HH:mm:ss')}</TimeDisplay>
            <TimeZoneDisplay>{timezoneDisplay}</TimeZoneDisplay>
            <DateDisplay>{time.format('DD MMM')}</DateDisplay>
          </DateTimeDisplayWrapper>
        );
      }}
      </TimezoneOffsetContext.Consumer>
    );
  };

  getOffsetForDisplay = (offset) => {
    const sign = offset >= 0 ? '+' : '-';

    const offsetHours = Math.abs(Math.floor(offset / 60));
    const offsetMinutes = Math.abs(offset % 60);

    const display = offsetHours + (offsetMinutes ? `:${offsetMinutes}` : '');

    return `(UTC ${sign} ${display})`;
  };
}

export default DateTimeDisplay;
