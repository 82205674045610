import PropTypes from 'prop-types';
import ViewType from "./View";

export const GROUP = {
  'ALL': 'ALL',
  'MAINLINE': 'MAINLINE',
  'LINK': 'LINK',
};

export const airlineOptions = [
  {
    value: "MAINLINE",
    label: "Jetstar Mainline"
  },
  {
    value: "ALL",
    label: "All"
  },
];

const UserType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  // role: PropTypes.oneOf(Object.values(ROLE)), // TODO: Currently there is no way to check for roles, due to roles being dynamically pulled down from server. However, later in IO3 when we work on Access Control, roles will change to object, and we can circle back to it.
  terminal: PropTypes.string,
  group: PropTypes.oneOf(Object.keys(GROUP)),
  last_seen: PropTypes.string,
  views: PropTypes.arrayOf(ViewType),
});

export default UserType;
