import styled from 'styled-components';
import FlexSection from "../../../../../common/atoms/FlexSection";
import device from "../../../../../../constants/Device.js"

export const RegistrationWrapper = styled(FlexSection)`
  font-family: ${props => props.theme.fontImportant};
  font-size: ${props => props.theme.fontSizeLarge};

  flex-grow: 1;
  
  /* Mobile Responsive */
  display: none;
  @media ${device.mobileL} {
    display: block;
  }
`;
export const ColumnWrapper = styled(FlexSection)`
  flex-grow: 1;
  
  flex-direction: column;
  align-items: start;
  
  padding: 0 ${props => props.theme.space8};
  white-space: nowrap;
`;
