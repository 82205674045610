import styled, {css} from 'styled-components'

const includeOutlinedStyle = (props, color) => props.outlined && css`
  border: ${props.theme.borderWidth} solid ${color};
  background: transparent;
  color: ${color};
  :hover {
    color: ${color};
  }
  svg {
    fill: ${color};
  }
`

export const ButtonWrapper = styled.button.attrs(({type = 'button'}) => ({type}))`
  /* Display */
  display: flex;
  justify-content: center;
  background: white;
  box-sizing: border-box;

  /* Border */
  border-radius: ${props => props.theme.cornerRadius};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  border-width: ${props => props.theme.borderWidth};
  border-style: solid;
  border-color: transparent;

  /* Spacing */
  padding: ${props => props.theme.space3} ${props => props.theme.space4} ${props => props.theme.space2} ${props => props.theme.space4};
  min-height: ${props => props.theme.size5};

  /* Text */
  text-align: center;
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fontImportant};
  font-size: ${props => props.theme.fontSizeLarge};
  letter-spacing: 1.71px;

  /* Cursor styling */
  cursor: pointer;
  text-transform: uppercase;
  user-select: none;

  :hover {
    color: ${props => props.theme.colors.black};
  }

  :focus {
    outline: none;
  }

  ${props => props.basic && css`
    box-shadow: none;
    color: ${props => props.theme.colors.body};

    ${props => includeOutlinedStyle(props, props.theme.colors.body)};
  `};

  ${props => props.primary && css`
    color: white;
    :hover {
      color: white;
    }
    svg {
      fill: #FFFFFF;
    }
    background: ${props => props.theme.colors.brandPrimary};
    ${props => includeOutlinedStyle(props, props.theme.colors.brandPrimary)};
  `};

  ${props => props.secondary && css`
    color: white;
    :hover {
      color: white;
    }
    svg {
      fill: #FFFFFF;
    }
    background: ${props => props.theme.colors.brandSecondary};
    ${props => includeOutlinedStyle(props, props.theme.colors.brandSecondary)};

  `};

  ${props => props.secondary && props.text && css`
    color: ${props => props.theme.colors.brandSecondary};
    :hover {
      color: ${props => props.theme.colors.brandDarkSecondary};
    }
    background: initial;
    svg {
      fill: initial;
    }
  `};

  ${props => props.small && css`
    padding: ${props => props.theme.space2} ${props => props.theme.space3};
  `};

  ${props => props.large && css`
    padding: ${props => props.theme.space3} ${props => props.theme.space5};
  `};

  ${props => props.transparent && css`
    background: transparent;
    color: ${props => props.theme.colors.body};
    box-shadow: none;

    :hover {
      color: ${props => props.theme.colors.body};
    }
  `};

  ${props => props.loading && css`
    pointer-events: none;
    color: ${props => props.theme.colors.grey};
  `};

  ${props => props.disabled && css`
    pointer-events: none;
    opacity: 50%
  `};

  ${props => props.fluid && css`
    width: 100%;
  `};
`;
