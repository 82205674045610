import React from "react";
import Card from "../../molecules/Card";
import {ConnectionBody, ConnectionHead, FlightNumber} from "./style";
import InfoLabel from "../../molecules/InfoLabel";
import Delta from "../../atoms/Delta";
import {getLocalTime} from "../../../../utils/flight-utils";
import {QUALIFIER} from "../../../../constants/Flight";

const ConnectionCard = ({isVertical, connection, connectionTime, terminalOffset, ...props}) => {

  const renderConnectionTime = () => getLocalTime(connection.onblocks_time || connection.arrival_scheduled_time, terminalOffset);

  const renderConnectionDelta = () => <Delta delta={connectionTime}/>;

  const renderSTA = () =>
    <span>
      {renderConnectionTime()}
      {renderConnectionDelta()}
    </span>;

  const renderHead = () => {
    if (!isVertical) {
      return null
    } else {
      return (
        <ConnectionHead>
          <FlightNumber>{connection.operating_airline}{connection.flight_number}</FlightNumber>
          <InfoLabel
            label={renderArrivalLabel(connection)}
            text={renderConnectionTime()}/>
          {`${connectionTime < 0 ? `+${Math.abs(connectionTime)}` : connectionTime} min`}
        </ConnectionHead>
      )
    }
  };

  const renderBody = () =>
    <ConnectionBody isVertical={isVertical}>
      {
        !isVertical &&
        <>
          <FlightNumber>{connection.operating_airline}{connection.flight_number}</FlightNumber>
          <InfoLabel
            label={renderArrivalLabel(connection)}
            text={renderSTA()}/>
        </>
      }
      <InfoLabel
        label={"From"}
        text={connection.departure_airport_code}/>
      {
        connection.gate &&
        <InfoLabel
          label={"Gate"}
          text={connection.gate}/>
      }
      <InfoLabel
        label={"PXN"}
        text={connection.connectingPaxCount}/>
    </ConnectionBody>;

  const renderArrivalLabel = (connection) => {
    switch (connection.onblocks_qualifier) {
      case null:
        return "STA";
      case QUALIFIER.ACTUAL:
        return "ATA";
      default:
        return "ETA";
    }
  };

  return (
    <Card
      {...props}
      head={renderHead()}
      body={renderBody()}/>
  )
};

export default ConnectionCard;
